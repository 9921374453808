
import './not-found.scss';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import BolaError from '../../assets/videos/bola_error.mp4'
import { FormattedMessage } from 'react-intl';
import { HmyBtn } from 'src/components';
import { useNavigate } from 'react-router-dom';

const NotFound: FC = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  }

  return (
    <Box
      className="not-found-container"
    >
      <Box
        className="not-found-video-container"
      >
        <video className="not-found-video" src={BolaError} autoPlay muted loop />
      </Box>
      <Box
          className="not-found-text"
      >
        <Typography className="not-found-text-title" ><FormattedMessage id="error"/><span style={{marginLeft: "15px"}}>404</span></Typography>
        <Typography className="not-found-text-body" >
            <FormattedMessage 
                id="error404"
            />
        </Typography>
        <Box
            className="not-found-btn"
        >
          <HmyBtn 
              title="btn.comeBack"
              width={300}
              fullwith={false}
              handleClick={handleClick}
              color="secondary"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default NotFound;