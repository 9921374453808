import './inbox-edit-form.scss';
import { FC } from 'react';
import { Box, FormControl, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import { useForm, DefaultValues, SubmitHandler, Controller } from "react-hook-form";
import { BlobDocument, DocumentProps } from 'src/models';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate } from 'src/utilities';

export type InboxEditFormValues = {
    description: string;
    date: string;
    documentType: string;
};

type InboxEditFormProps = {
    document: BlobDocument;
    documentProperties: DocumentProps;
    handleEditDocument: (resultForm: InboxEditFormValues) => void;
    handleCancel: () => void;
    handleDeleteDocument: () => void;
};

const InboxEditForm: FC<InboxEditFormProps> = ({document, documentProperties, handleEditDocument, handleCancel, handleDeleteDocument}) => {
    const intl = useIntl();
    const matches = useMediaQuery('(max-width: 768px)');
    const defaultValues: DefaultValues<InboxEditFormValues> = {
        description: document.description,
        date: formatDate(new Date(document.date)),
        documentType: document.documentType
    };

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<InboxEditFormValues>({
        defaultValues
    });

    const onSubmit: SubmitHandler<InboxEditFormValues> = (data) => {
        handleEditDocument(data);
        reset();
        handleCancel();
    }

    const handleCancelForm = () => {
        reset();
        handleCancel();
    }
    
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="inbox-edit-form">
        <Box
            className="inbox-edit-form-inputs"
        >
            <section>
                <Controller
                name="date"
                control={control}
                render={({ field }) => 
                <TextField 
                    {...field} 
                    label={intl.formatMessage({ id: "date" })}
                    fullWidth 
                    type="date" 
                    variant="standard" 
                    color="primary"
                    error={Boolean(errors["date"])}
                    helperText={errors["date"] ? errors["date"].message : ''}
                    inputProps={{style: {fontSize: matches ? 16 : 20}}}
                    InputLabelProps={{style: {fontSize: matches ? 18 : 20, color: "#fff"}}}
                />
                }
                rules={{ validate: (value) => {
                    if (new Date(value) > new Date(2021, 1, 1)) return true;
                    return intl.formatMessage({ id: "error.dateGreater" })
                }}}
                />
            </section>
            <section>
                <Controller
                name="description"
                control={control}
                render={({ field }) => 
                    <TextField 
                        {...field} 
                        label={intl.formatMessage({ id: "description" })}
                        fullWidth 
                        variant="standard" 
                        error={Boolean(errors["description"])}
                        helperText={errors["description"] ? errors["description"].message : ''}
                        inputProps={{style: {fontSize: matches ? 16 : 20}}}
                        InputLabelProps={{style: {fontSize: matches ? 18 : 20, color: "#fff"}}}
                    />
                    }
                    rules={{ required: { value: true, message: intl.formatMessage({ id: "error.required" }) } }}
                />
            </section>
            <section>
                <Controller
                name="documentType"
                control={control}
                rules={{ required: { value: true, message: intl.formatMessage({ id: "error.required" }) } }}
                render={
                    ({ field: { value, onChange }, fieldState: { error } }) => (
                        <FormControl
                            fullWidth
                        >
                            <Select
                                labelId="select-label"
                                value={value}
                                onChange={onChange}
                                variant="standard"
                                sx={{fontSize: matches ? "18px" : "20px"}}
                            >
                                {documentProperties.documentTypes.map((documentType, index) => (
                                    (documentType.alias === 'IMG' ? null :
                                        <MenuItem
                                            key={documentType.documentTypeId}
                                            value={documentType.alias}
                                            sx={{fontSize: matches ? "18px" : "20px"}}
                                        >
                                            <FormattedMessage id={documentType.alias.toLowerCase()} />
                                        </MenuItem>
                                    )                             
                                ))
                                }                           
                            </Select>
                            {error?.message && <p className='inbox-edit-error-message'>{error.message}</p>}
                        </FormControl>
                    )
                }
                />
            </section>
        </Box>
        <Box className="inbox-edit-form-button-container" >
            <Box
              className="icon-delete-document"
              onClick={() => handleDeleteDocument()}
            >
              <DeleteIcon fontSize="inherit" />
            </Box>
            <Box className="inbox-form-button" >
                <Box 
                    className="btn-edit-inbox-accept"
                    onClick={handleSubmit(onSubmit)}
                >
                    <FormattedMessage id="accept" />
                </Box>
                <Box
                    className="btn-edit-inbox-delete"
                    onClick={() => handleCancelForm()}
                >
                    <FormattedMessage id="btn.comeBack" />
                </Box>
            </Box>
        </Box> 
    </form>
  )
}

export default InboxEditForm;