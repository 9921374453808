import './profile.scss'
import { FC, useState } from 'react'
import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, logOut, setCurrentUserLanguage, setLanguage, setMessage, setName, setNotifications, setSurName } from 'src/redux/states';
import { HmyFormSection, HmyInputPassword, HmySelectableSmart, HmySingleForm, ROUTE_LANDING } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppLanguages } from 'src/components/language-menu/languages';
import { ErrorMessage, Language, Selectable, SuccessMessage } from 'src/models';
import { authServices, storageServices } from 'src/services';
import { useFetchAndLoad } from 'src/hooks';
import { UpdateBasicProfileDto, UpdateLanguageDto, UpdatePasswordDto } from 'src/dtos';

const Profile: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const intl = useIntl();
  const { callEndpoint } = useFetchAndLoad();

  const [newName, setNewName] = useState<string>(currentUser.name);
  const [errorNewName, setErrorNewName] = useState<string | null>(null);
  const [newSurname, setNewSurname] = useState<string>(currentUser.surName);
  const [errorNewSurname, setErrorNewSurname] = useState<string | null>(null);
  const [notificationsActive, setNotificationsActive] = useState<boolean>(currentUser.notificationsActive);
  const [newLanguage, setNewLanguage] = useState<string>(currentUser.language);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  const updateLangUser = async (value: UpdateLanguageDto) => await callEndpoint(authServices.updateLanguageUser(value));
  const updateBasics = async (value: UpdateBasicProfileDto) => await callEndpoint(authServices.updateBasicProfile(value));
  const updatePassword = async (value: UpdatePasswordDto) => await callEndpoint(authServices.updatePassword(value));

  const adaptLanguageToSelectable = (value: Language): Selectable => {
    return {
        id: value.name,
        text: value.translate
    }
  }

  const handleUpdateBasic = async (name: string, surname: string, notifications: boolean) => {
    if(currentUser.name !== name.trim() || currentUser.surName !== surname.trim() || currentUser.notificationsActive !== notifications)
    {
     
      const request: UpdateBasicProfileDto = {
        name: name,
        surName: surname,
        notificationsActive: notifications
      };
      try {
        await updateBasics(request);
        if(name.trim() !== currentUser.name)
        {
          dispatch(setName(name));
        }
        else if(surname.trim() !== currentUser.surName)
        {
          dispatch(setSurName(surname));
        }
        else{
          dispatch(setNotifications(notifications));
        }
        
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleEditName = () => {
    handleUpdateBasic(newName, currentUser.surName, currentUser.notificationsActive);
  }

  const handleEditSurname = () => {
    handleUpdateBasic(currentUser.name, newSurname, currentUser.notificationsActive);
  }

  const handleEditLanguage = () => {
      if(newLanguage !== null && newLanguage !== currentUser.language){
        const request: UpdateLanguageDto = {
          userId: currentUser.id,
          language: newLanguage
        };
        updateLangUser(request).then(response => {
            if(response.status === 200){
                storageServices.setLanguage(newLanguage);
            }
        });
            
        dispatch(setCurrentUserLanguage(AppLanguages.find(x => x.name === newLanguage)!));
        dispatch(setLanguage(AppLanguages.find(x => x.name === newLanguage)!));
      }
  }

  const handleEditPassword = async () => {
    if(isValid !== null && password === password2 && currentPassword !== ""){
      const request: UpdatePasswordDto = {
        id: currentUser.id,
        password: currentPassword,
        newPassword: password
      };
      
      try {
        await updatePassword(request);
        dispatch(setMessage(SuccessMessage("success.passwordUpdate", true)));
      }
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.errorOccurred", true)));
      }
    }
  }
  
  const handleEditNotifications = () => {
    handleUpdateBasic(currentUser.name, currentUser.surName, notificationsActive);
  }
  
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === null || event.target.value.trim().length === 0){
        setErrorNewName("error.required")
    }else{
        setErrorNewName(null)
    }     
    setNewName(event.target.value);
  }

  const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.value === null || event.target.value.trim().length === 0){
          setErrorNewSurname("error.required")
      }else{
          setErrorNewSurname(null)
      }     
      setNewSurname(event.target.value);
  }

  const handleUpdateLanguage = (value: string) => {
      setNewLanguage(value);
  }

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationsActive(event.target.checked);
  }

  const handleCancelForm = () => {
    setNewName(currentUser.name);
    setErrorNewName(null);
    setNewSurname(currentUser.surName);
    setErrorNewSurname(null);
    setNewLanguage(currentUser.language);
    setCurrentPassword("");
    setPassword("");
    setPassword2("");
    setIsValid(false);
  }

  const handleClose = () => {
      storageServices.closeSession();
      dispatch(logOut());
      navigate(ROUTE_LANDING);
  }
  
  return (
    <Box 
      className="profile-container"
    >
      <Box
        className="profile-main"
      >
        <Box
          className="profile-title"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon fontSize="inherit" /> 
          <Typography className="profile-title-text">
            {currentUser.fullName}
          </Typography>
        </Box>
        <Box
          className="profile-subtitle"
        >
          <FormattedMessage id="accountData" />:
        </Box>
        <HmyFormSection>
          <HmySingleForm
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="name"
            textValues={[currentUser.name]}
            canEdit={!currentUser.hmy}
            canDelete={false}
            hasErrors={errorNewName !== null}
            onClickSubmitForm={handleEditName}
            onClickCancelForm={handleCancelForm}
            onDeleteForm={() => console.log()}
          >
            <TextField 
              label="" 
              variant="standard" 
              value={newName}
              onChange={handleChangeName}
              error={errorNewName !== null}
              helperText={errorNewName !== null ? <FormattedMessage id={errorNewName} /> : null}
              fullWidth
              inputProps={{style: {fontSize: 20}}}
            />
          </HmySingleForm>
          <HmySingleForm
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="surnames"
            textValues={[currentUser.surName]}
            canEdit={!currentUser.hmy}
            canDelete={false}
            hasErrors={errorNewSurname !== null}
            onClickSubmitForm={handleEditSurname}
            onClickCancelForm={handleCancelForm}
            onDeleteForm={() => console.log()}
          >
            <TextField 
              label="" 
              variant="standard" 
              value={newSurname}
              onChange={handleChangeSurname}
              error={errorNewSurname !== null}
              helperText={errorNewSurname !== null ? <FormattedMessage id={errorNewSurname} /> : null}
              fullWidth
              inputProps={{style: {fontSize: 20}}}
            />
          </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
          <HmySingleForm
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="email"
            textValues={[currentUser.userName]}
            canEdit={false}
            canDelete={false}
            hasErrors={false}
            onClickSubmitForm={() => console.log()}
            onClickCancelForm={handleCancelForm}
            onDeleteForm={() => console.log()}
          >
          </HmySingleForm>
          <HmySingleForm
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="language"
            textValues={[intl.formatMessage({id: AppLanguages.find(x => x.name === currentUser.language)?.translate})]}
            canEdit={true}
            canDelete={false}
            hasErrors={false}
            onClickSubmitForm={handleEditLanguage}
            onClickCancelForm={handleCancelForm}
            onDeleteForm={() => console.log()}
          >
            <HmySelectableSmart 
              title="language"   
              showTitle={false}       
              defaultValue=""
              elements={AppLanguages.map(x => adaptLanguageToSelectable(x))}
              value={newLanguage}
              isFormattedValue={true}
              fullWidth={true}
              setValue={handleUpdateLanguage}
            />
          </HmySingleForm>
        </HmyFormSection>
        {!currentUser.hmy
          ?
          <>
            <Box
              className="profile-subtitle"
            >
              <FormattedMessage id="security" />:
            </Box>
            <HmyFormSection>
              <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="password"
                textValues={["*************"]}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditPassword}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
              >
                <HmyInputPassword 
                  withValidation={false}
                  label="currentPassword"
                  userName={currentUser.name}
                  email={currentUser.email}
                  password={currentPassword}
                  setPassword={setCurrentPassword}
                  setIsValid={setIsValid}
                />
                <HmyInputPassword 
                  withValidation={true}
                  label="newPassword"
                  userName={currentUser.name}
                  email={currentUser.email}
                  password={password}
                  setPassword={setPassword}
                  setIsValid={setIsValid}
                />
                <HmyInputPassword 
                  withValidation={false}
                  label="repeatNewPassword"
                  userName={currentUser.name}
                  email={currentUser.email}
                  password={password2}
                  setPassword={setPassword2}
                  setIsValid={setIsValid}
                />
              </HmySingleForm>
            </HmyFormSection>
            <Box
              className="profile-subtitle"
            >
              <FormattedMessage id="notifications" />:
            </Box>
            <HmyFormSection>
              <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="emailNotifications"
                textValues={[intl.formatMessage({id: currentUser.notificationsActive === true ? "activated" : "disabled"})]}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditNotifications}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
              >
                <FormControlLabel 
                  control={
                    <Switch 
                      color="secondary"
                      checked={notificationsActive}
                      onChange={handleChangeSwitch} 
                    />
                  } 
                  label={<Typography fontSize={20} ><FormattedMessage id="emailNotifications" /></Typography>} 
                />
              </HmySingleForm>
            </HmyFormSection>
          </>       
          :
            null
        }
        <Box
          className="profile-signOff-container"
        >
          <Box
            onClick={handleClose}
            className="profile-signOff-button"
          >
            <FormattedMessage id="signOff" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile;