import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Checkbox, Dialog, FormControlLabel, Typography, useMediaQuery } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { HmyBtn } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { useCreateOrder } from 'src/hooks/use-order.hook';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { setMessage } from 'src/redux/states';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { formatDate } from 'src/utilities';
import { calculeOrderDelivery } from '../calcule-order-delivery.helpers';
import OrderTotals from '../order-totals';
import './order-confirmation-modal.scss';
import { FaTruckFast } from 'react-icons/fa6';
import { useTransport } from 'src/hooks/use-transport.hook';

type OrderConfirmationModalProps = {
  isOpen: boolean;
  close: () => void;
  shoppingList: BasicShoppingOrder;
  setPhoneNumber: (phoneNumber: string) => void;
  customerData: { id: String; email: string } | null;
};

const OrderConfirmationModal: FC<OrderConfirmationModalProps> = ({
  isOpen,
  close,
  shoppingList,
  setPhoneNumber,
  customerData,
}) => {
  const [editPhoneNumber, setEditPhoneNumber] = useState<string>(shoppingList.phoneNumber);
  const [observationText, setObservationText] = useState<string>('');
  const [orderUrgent, setOrderUrgent] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const { mutationCreateOrder } = useCreateOrder();
  const { deliveryDate, deliveryDays } = useTransport({ ...shoppingList, urgent: orderUrgent });
  const inputRef = useRef<HTMLInputElement>(null);

  const allowsUrgent = shoppingList.lines.every((item) => item.allowsUrgent);

  const isValidPhoneNumber = (currentPhoneNumber: string): boolean => {
    if (currentPhoneNumber) {
      const phoneNumber = currentPhoneNumber.replace(/\s+/g, '');
      // Regular expression to match phone numbers
      const phoneNumberPattern = /^\d{9}$/;

      // Regular expression to match international phone numbers
      // This pattern matches a + followed by 1 to 3 digits (country code) and 6 to 14 digits (national number)
      const internationalPhoneNumberPattern = /^\+(\d{1,3})\d{6,14}$/;

      return phoneNumberPattern.test(phoneNumber) || internationalPhoneNumberPattern.test(phoneNumber);
    }
    return false;
  };

  const handleEditPhone = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChangeCheckboxUrgent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderUrgent(event.target.checked);
  };

  const handleSentOrder = () => {
    if (isValidPhoneNumber(editPhoneNumber)) {
      mutationCreateOrder.mutate(
        {
          ...shoppingList,
          customerUserId: customerData?.id || null,
          comments: observationText,
          urgent: orderUrgent,
          projectId: [
            shoppingList.projectId,
            ...(shoppingList.additionalProjects ? shoppingList.additionalProjects : []),
          ],
        },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CreateOrder', true)));
            navigate(-1);
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CreateOrder', true)));
          },
        }
      );
    }
  };

  return (
    <Dialog open={isOpen} className="order-confirmation-modal" fullWidth maxWidth="xl">
      <Box className="order-confirmation-modal-container">
        <Typography className="order-title-text">
          <FormattedMessage id="orderConfirmation" />
        </Typography>
        <Box className="order-confirmation-modal-information">
          <Box className="order-confirmation-modal-information-box">
            <div className="order-confirmation-modal-information-phone">
              <FormattedMessage id="contactPhone" />:
              <input
                ref={inputRef}
                value={editPhoneNumber}
                onChange={(e) => setEditPhoneNumber(e.target.value)}
                onBlur={(e) => setPhoneNumber(e.target.value)}
              />
              <span onClick={handleEditPhone}>
                <EditOutlinedIcon style={{ fontSize: '18px' }} />
              </span>
            </div>
            {!isValidPhoneNumber(editPhoneNumber) && (
              <div className="order-confirmation-modal-information-phone-error">
                <FormattedMessage id="errorContactPhone" />
              </div>
            )}
          </Box>
          <Box className="order-confirmation-modal-information-box">
            <div>
              <FormattedMessage id="purchaseDate" /> <FormattedDate value={formatDate(new Date())} />
            </div>

            <div>
              {deliveryDate && (
                <>
                  <FormattedMessage id="deliveryDate" />
                  {deliveryDays !== 0 ? <FormattedDate value={deliveryDate} /> : <>-</>}
                </>
              )}
            </div>
          </Box>
        </Box>
        <Typography className="order-title-text">
          <FormattedMessage id="orderObservations" />
        </Typography>
        <HmyInputTextarea
          value={observationText}
          title="addObservation"
          setValue={setObservationText}
          maxLength={500}
          minRows={1}
        />

        {allowsUrgent && (
          <>
            <Typography className="order-title-text">
              <FormattedMessage id="orderUrgentTitle" />
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
              <FaTruckFast />
              <Checkbox checked={orderUrgent} onChange={handleChangeCheckboxUrgent} name={'urgente'} size="small" />
              <Typography>
                <FormattedMessage id="orderUrgentTitle" />
              </Typography>
            </div>
          </>
        )}
        <OrderTotals orderData={shoppingList} orderEdited={true} urgent={orderUrgent} />

        <Box className="order-confirmation-modal-controls">
          <HmyBtn title="btn.cancel" width={250} fullwith={false} color="primary" handleClick={close} />
          <HmyBtn
            title="btn.accept"
            width={250}
            fullwith={false}
            color="primary"
            filled
            handleClick={handleSentOrder}
            disabled={!isValidPhoneNumber(editPhoneNumber)}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default OrderConfirmationModal;
