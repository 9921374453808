import './project-settings-user-list-form-element.scss'
import { FC } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { ProjectProfile, UserProject } from 'src/models';
import { HmySelectableSmart } from 'src/components';
import { FormattedMessage } from 'react-intl';
import { projectProfileSelectableAdapater } from 'src/adapters';
import DeleteIcon from '@mui/icons-material/Delete';

type ProjectSettingsUserListFormElementProps = {
    user: UserProject;
    isHmy: boolean;
    canEditProfile: (value: string) => boolean;
    canDeleteUser: (profileName: string, userId: string)=> boolean;
    getAvailableProfiles: (value: string) => ProjectProfile[];
    onUpdateProfile: (userId: string, profileId: string) => void;
    handleDeleteUser: (userId: string, profileId: string) => void;
};

const ProjectSettingsUserListFormElement: FC<ProjectSettingsUserListFormElementProps> = ({user, isHmy, canEditProfile, canDeleteUser, getAvailableProfiles, onUpdateProfile, handleDeleteUser}) => {
    
    const matches = useMediaQuery('(max-width: 768px)');

    const handleUpdateProfile = (profileId: string) => {
        onUpdateProfile(user.email, profileId);
    }

    return (
    <Box
        className="project-settings-users-list-form-element"
    >
        <Box
            className="project-settings-user-form"
        >
            <Box
                className="project-settings-user-form-email"
            >
                {user.email}
            </Box>
            {isHmy
                ?
                <Box
                    className="project-settings-user-form-profile"
                >
                    {canEditProfile(user.profileName)
                    ?
                        <HmySelectableSmart 
                            title="profile"   
                            showTitle={false}       
                            defaultValue=""
                            elements={getAvailableProfiles(user.profileId).map(x => projectProfileSelectableAdapater(x))}
                            value={user.profileId}
                            isFormattedValue={true}
                            fullWidth={matches}
                            setValue={handleUpdateProfile}
                        />
                    :
                        <FormattedMessage id={user.profileName.toLowerCase()} />
                    }                          
                </Box>
            :
                null
            }
        </Box>
        {canDeleteUser(user.profileName, user.id)
        ?
            <Box
                className="project-settings-user-form-icon-delete"
                onClick={() => handleDeleteUser(user.id, user.profileId)}
            >
                <DeleteIcon />
            </Box>
        :
            null
        }      
    </Box>
  )
}

export default ProjectSettingsUserListFormElement;