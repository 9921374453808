import { Box, Dialog, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { HmyBtn } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { useCreateIncidence } from 'src/hooks/use-incidence.hook';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { setMessage } from 'src/redux/states';

import { useOrder } from 'src/hooks/use-order.hook';
import IncidenceArticlesList from 'src/pages/order/incidences/incidence-articles-list';
import './incidence-modal.scss';

type IncidenceButtonProps = {
  orderId: string;
};

const IncidenceButton: FC<IncidenceButtonProps> = ({ orderId }) => {
  const [description, setDescription] = useState<string>('');
  const [checkedArticles, setCheckedArticles] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const matches = useMediaQuery('(max-width: 768px)');
  const { mutationCreateIncidence } = useCreateIncidence(orderId);
  const { data, isLoading } = useOrder(orderId, undefined);

  const dispatch = useDispatch();

  const handleSubmitIncidence = () => {
    const incidenceData = {
      description,
      lines: checkedArticles.map((article) => ({ saleOrderLineId: article })),
      saleOrderId: data.saleOrderId,
      blobs: [], //TODO
    };

    if (incidenceData.description.trim() !== '') {
      mutationCreateIncidence.mutate(incidenceData, {
        onSuccess: () => {
          dispatch(setMessage(SuccessMessage('success.CreateIncidence', true)));
          setOpenModal(false);
        },
        onError: () => {
          dispatch(setMessage(ErrorMessage('error.CreateIncidence', true)));
        },
      });
    }
  };

  return (
    <>
      <Box className="orders-button orders-button-view" onClick={() => setOpenModal(true)}>
        <FormattedMessage id="btn.addIncidence" />
      </Box>
      {openModal && (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} className="incidence-modal" fullScreen={matches}>
          <Box className="incidence-modal-container">
            <Box className="incidence-modal-header">
              <span>
                <FormattedMessage id="orderIncidenceTitle" />
              </span>
              <span>
                <FormattedMessage id="orderIncidenceSubtitle" />
              </span>
            </Box>
            <HmyInputTextarea value={description} setValue={setDescription} />
            <span className="incidence-modal-section-text">
              <FormattedMessage id="orderIncidenceArticles" />
            </span>
            {data && (
              <IncidenceArticlesList
                orderData={data}
                checkedArticles={checkedArticles}
                setCheckedArticles={setCheckedArticles}
              />
            )}

            <Box className="incidence-modal-controls">
              <HmyBtn
                title="btn.cancel"
                width={100}
                fullwith={false}
                color="secondary"
                handleClick={() => setOpenModal(false)}
              />

              <HmyBtn
                title="btn.addIncidence"
                width={250}
                fullwith={false}
                filled
                color="primary"
                handleClick={handleSubmitIncidence}
                disabled={description.trim() === ''}
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default IncidenceButton;
