import { FC, useEffect, useState, KeyboardEvent } from 'react';
import BasicAutocomplete from './basic-autocomplete';

type HmyAutocompleteProps = {
    label: string;
    showLabel: boolean;
    fullWidth: boolean;
    value: string | null;
    options: string[];
    loading: boolean;
    helperText: string | null;
    setValue: (value: string | null) => void;
    loadResults: (filter: string) => void;
  };

const HmyAutocomplete: FC<HmyAutocompleteProps> = ({ label, showLabel, fullWidth, value, options, loading, helperText, setValue, loadResults }) => {

    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        loadResults(inputValue);  
    },[]);

    return (
        <BasicAutocomplete 
            label={label}
            showLabel={showLabel}
            fullWidth={fullWidth}
            value={value}
            options={options}
            loading={loading}
            helperText={helperText}
            inputValue={inputValue}
            setValue={setValue}
            setInputValue={setInputValue}
        />
    )
}

export { HmyAutocomplete }