import InputIcon from '@mui/icons-material/Input';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DocumentProps, ErrorMessage } from 'src/models';
import { setMessage } from 'src/redux/states';
import { HmyDropZone } from '../hmy-dropzone';
import { HMY_INPUT_FILE_ID, HmyInputFile } from '../hmy-input-file';
import './hmy-inbox-file-zone.scss';

const MAX_FILES = 20;

type HmyInboxFileZoneProps = {
  documentProperties: DocumentProps;
  files: File[];
  setFiles: (value: File[]) => void;
};

const HmyInboxFileZone: FC<HmyInboxFileZoneProps> = ({ documentProperties, files, setFiles }) => {
  const dispatch = useDispatch();
  const [isDropActive, setIsDropActive] = useState<boolean>(false);
  const matches = useMediaQuery('(max-width: 768px)');

  // Create handler for dropzone's onDragStateChange:
  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const validateFiles = (newFiles: File[]): boolean => {
    return newFiles.every((newFile) => {
      const arr = newFile.name.split('.');
      const lastPosition = arr[arr.length - 1];
      if (documentProperties.extensions.findIndex((x) => x.toLowerCase() === lastPosition.toLowerCase()) === -1) {
        dispatch(setMessage(ErrorMessage('error.documentType', true)));
        (document.getElementById('hmy-input-file') as HTMLInputElement).value = '';
        return false;
      } else if (newFile.size > documentProperties.maxSize * 1024 * 1024) {
        dispatch(setMessage(ErrorMessage('error.documentSize', true)));
        (document.getElementById('hmy-input-file') as HTMLInputElement).value = '';
        return false;
      } else {
        return true;
      }
    });
  };

  // Create handler for dropzone's onFilesDrop:
  const onFilesDrop = useCallback((files: File[]) => {
    handleSetFile(files);
  }, []);

  const handleSetFile = (files: File[]) => {
    if (files.length > MAX_FILES) {
      dispatch(setMessage(ErrorMessage('error.documentsNumber', true)));
    } else if (validateFiles(files)) {
      setFiles(files);
      setIsDropActive(false);
    }
  };

  return (
    <Box className="hmy-inbox-file-zone">
      <Box className={isDropActive ? 'hmy-inbox-file-zone-container active' : 'hmy-inbox-file-zone-container'}>
        {files.length === 0 && (
          <Box className="hmy-dropzone-container">
            {matches ? (
              <Box className="hmy-inbox-container-dropzone-btn">
                <HmyInputFile title="chooseOnDevice" width={250} setFile={handleSetFile} />
              </Box>
            ) : (
              <HmyDropZone onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop}>
                <Typography className="hmy-inbox-container-dropzone-title">
                  <FormattedMessage id="dragDropFile" /> <InputIcon />
                </Typography>
                <Typography className="hmy-inbox-container-dropzone-text">
                  <FormattedMessage id="or" />
                </Typography>
                <Box className="hmy-inbox-container-dropzone-btn">
                  <HmyInputFile title="chooseOnDevice" width={300} setFile={handleSetFile} />
                </Box>
              </HmyDropZone>
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Typography className="hmy-inbox-file-zone-info">
          <FormattedMessage id="documentInfo" />
          {` ${documentProperties.maxSize}mb`}
        </Typography>
      </Box>
      <Box>
        <Typography className="hmy-inbox-file-zone-info">
          <FormattedMessage id="documentInfo2" />
          {` ${documentProperties.extensions.join(', ')}`}
        </Typography>
      </Box>
    </Box>
  );
};

export { HmyInboxFileZone };
