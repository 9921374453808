import { BasicOrder } from 'src/models/orders';
import { BasicShoppingOrder } from 'src/services/catalogue';
const SHIPPING_TAX = 0.21;

export type OrderPrice = {
  subTotal: number;
  shippingPrice: number;
  total: number;
};

export const calculeOrderPrice = (orderData: BasicOrder | BasicShoppingOrder): OrderPrice => {
  const order = orderData as BasicShoppingOrder;
  if (order && order.lines && order.lines.length > 0) {
    const calculatedSubTotal = order.lines.reduce((total: number, line: any) => {
      if (line.quantity && line.salePrice) {
        total += line.quantity * line.salePrice;
      }
      return total;
    }, 0);
    const shippingPrice = calculatedSubTotal * SHIPPING_TAX;
    return { subTotal: calculatedSubTotal, shippingPrice, total: calculatedSubTotal + shippingPrice };
  }
  return { subTotal: 0, shippingPrice: 0, total: 0 };
};
