import { CurrentUser, Roles } from "src/models";

export const IsRoot = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.ROOT) > -1;
};

export const IsAdmin = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.ROOT || x === Roles.ADMIN) > -1;
};

export const IsBossSupervisor = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.BOSSSUPERVISOR) > -1;
};

export const IsSupervisor = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.SUPERVISOR) > -1;
};

export const IsCommercial = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.COMMERCIAL) > -1;
};

export const IsCustomer = (user: CurrentUser): boolean => {
      return user.roles.findIndex(x => x === Roles.CUSTOMER) > -1;
};