import { CompleteGrouping, UserGroupingBase, UserGroupingCategory, UserGroupingCategoryBase, UserGroupingFile, UserGroupingProfile, UserGroupingTag, UserGroupingType, UserGroupingTypeBase, UserGroupingUser } from "src/models";
import { CompleteGroupingModelService, UserGroupingBaseModelService, UserGroupingCategoryBaseModelService, UserGroupingCategoryModelService, UserGroupingFileModelService, UserGroupingProfileModelService, UserGroupingTagModelService, UserGroupingTypeBaseModelService, UserGroupingTypeModelService, UserGroupingUserModelService } from "src/services";

export const completeGroupingAdapter = (grouping: CompleteGroupingModelService): CompleteGrouping => {
    
    let types = grouping.kinds.map((x: UserGroupingTypeBaseModelService) => { return groupingTypeBaseAdapter(x) });
    let categories = grouping.categories.map((x: UserGroupingCategoryBaseModelService) => { return groupingCategoryBaseAdapter(x) });
    let tags = grouping.tags.map((x: UserGroupingTagModelService) => { return groupingTagAdapter(x) });
    let users = grouping.users.map((x: UserGroupingUserModelService) => { return groupingUserAdapter(x) });
    let hmyUsers = grouping.hmyUsers.map((x: UserGroupingUserModelService) => { return groupingUserAdapter(x) });

    return{
        id: grouping.id,
        name: grouping.name,
        created: grouping.created,
        kinds: types,
        categories: categories,
        tags: tags,
        users: users,
        hmyUsers: hmyUsers,
        files: grouping.files ?? 0,
        permissions: grouping.permissions
    }
};

export const groupingTypeBaseAdapter = (groupingTypeBase: UserGroupingTypeBaseModelService): UserGroupingTypeBase => ({
    id: groupingTypeBase.id,
    name: groupingTypeBase.name
});

export const groupingCategoryBaseAdapter = (groupingCategoryBase: UserGroupingCategoryBaseModelService): UserGroupingCategoryBase => ({
    id: groupingCategoryBase.id,
    name: groupingCategoryBase.name
});

export const groupingTypeAdapter = (groupingType: UserGroupingTypeModelService): UserGroupingType => {
    
    let categoriesNew = groupingType.categories.map(x => groupingCategoryBaseAdapter(x));

    return {
        id: groupingType.id,
        name: groupingType.name,
        categories: categoriesNew
    }
};

export const groupingCategoryAdapter = (groupingCategory: UserGroupingCategoryModelService): UserGroupingCategory => ({
    id: groupingCategory.id,
    name: groupingCategory.name,
    type: groupingTypeBaseAdapter(groupingCategory.type)
});

export const groupingTagAdapter = (groupingTag: UserGroupingTagModelService): UserGroupingTag => ({
    id: groupingTag.id,
    name: groupingTag.name
});

export const groupingProfileAdapter = (groupingProfile: UserGroupingProfileModelService): UserGroupingProfile => ({
    profileId: groupingProfile.profileId,
    name: groupingProfile.name
});

export const groupingUserAdapter = (groupingUser: UserGroupingUserModelService): UserGroupingUser => ({
    id: groupingUser.id,
    email: groupingUser.email,
    fullName: groupingUser.fullName,
    profile: groupingProfileAdapter(groupingUser.profile)
});

export const groupingBaseAdapter = (groupingBase: UserGroupingBaseModelService): UserGroupingBase => {
    
    let users = groupingBase.users.map((x: UserGroupingUserModelService) => { return groupingUserAdapter(x) });
    let hmyUsers = groupingBase.hmyUsers.map((x: UserGroupingUserModelService) => { return groupingUserAdapter(x) });

    return{
        id: groupingBase.id,
        name: groupingBase.name,
        created: groupingBase.created,
        users: users,
        hmyUsers: hmyUsers,
        files: groupingBase.files ?? 0
    }
};

export const groupingFileAdapter = (groupingFile: UserGroupingFileModelService): UserGroupingFile => {
    
    let tags = groupingFile.tags.map((x: UserGroupingTagModelService) => { return groupingTagAdapter(x) });

    return{
        id: groupingFile.id,
        name: groupingFile.name,
        description: groupingFile.description,
        date: groupingFile.date,
        extension: groupingFile.extension,
        isImage: groupingFile.isImage,
        kind: groupingTypeAdapter(groupingFile.kind),
        category: groupingCategoryAdapter(groupingFile.category),
        tags: tags,
        url: groupingFile.url
    }
};