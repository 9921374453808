import "./hmy-flip.scss"
import "swiper/css";
import "swiper/css/effect-flip";
import { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip } from "swiper";
import SwiperCore from "swiper";
import { Box } from "@mui/material";

type HmyFlipProps = {
  children: JSX.Element[],
  direction: "vertical" | "horizontal"
  flipped: boolean
}

const HmyFlip: FC<HmyFlipProps> = ({ children, direction, flipped }) => {

  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    if(flipped){
      swiper?.slideTo(1);
    }else{
      swiper?.slideTo(0);
    }
  },[flipped]);

  return (
    <Box
      className="flip-component-container"
    >
      <Swiper
        direction={direction}
        allowTouchMove={false}
        slidesPerView={1}
        onSwiper={setSwiper}
        effect="flip"
        modules={[EffectFlip]}
      >
        {children.map((e, index) => (
          <SwiperSlide
            key={`side-flip-${index}`}
            className="flip-slide-container"
          >
            {e}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export { HmyFlip };