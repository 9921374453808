import './no-projects.scss';
import { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Gatete from '../../../assets/images/GATETESORPRENDIDO.png';
import Light from '../../../assets/images/bombilla.png';
import { FormattedMessage } from 'react-intl';
import CAN from '../../../permissions';
import { useSelector } from 'react-redux';
import { HmyBtn, ROUTE_IMPORT_PROJECTS } from 'src/components';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';
import { useNavigate } from 'react-router-dom';
import { ADD_PROJECTS } from 'src/permissions/permissions';

const NoProjects: FC = () => {

    const matches = useMediaQuery('(max-width: 768px)');
    const currentUser = useSelector(currentUserSelector);
    const navigate = useNavigate();

    const handleClickImport = () => {
        navigate(ROUTE_IMPORT_PROJECTS)
    }

  return (
    <Box
        className="no-project-container"
    >
        {IsCustomer(currentUser)
        ?        
            <>
                <Box
                    className="no-project-image-customer"
                >
                    <Box
                        className="no-project-image-customer-container"
                    >
                        <Box
                            className="no-project-image-customer-circle"
                        >
                        </Box> 
                        <img src={Light} alt="light"/> 
                    </Box>   
                </Box>
                <Box
                    className="no-project-text-customer"
                >
                    <Typography className="customer-text-title" ><FormattedMessage id="customerNoProjects"/></Typography>
                    <Typography className="customer-text-body" >
                        <FormattedMessage 
                            id="customerNoProjectsBody"
                            values={{
                                b: (chunks) => <strong>{chunks}</strong>
                            }}
                        />
                    </Typography>
                </Box>
            </>
        :
            <>
                <Box
                    className="no-project-image"
                >
                    <img src={Gatete} alt="surprised-cat"/>      
                </Box>
                <Box
                    className="no-project-text"
                >
                    <Typography className="text-title" ><FormattedMessage id="noProjectsImported"/></Typography>
                    <Typography className="text-body" ><FormattedMessage id="importNewProject"/></Typography>
                    {CAN(ADD_PROJECTS) && (
                    <HmyBtn 
                        title="addNewProject"
                        width={matches ? 230 : 300}
                        fullwith={false}
                        color="primary"
                        handleClick={handleClickImport}
                    />
                    )}
                </Box>
            </>   
        }  
    </Box>
  )
}

export default NoProjects;