import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';
import { CurrentUser, Language } from 'src/models';

export interface currentUserState extends CurrentUser {}

const initialState: currentUserState = {
  id: '',
  email: '',
  phoneNumber: '',
  name: '',
  surName: '',
  fullName: '',
  userName: '',
  mustBeActive: false,
  language: '',
  hmy: false,
  roles: [],
  hasGroupings: true,
  permissions: [],
  notificationsActive: false,
  observatoryAccess: '',
  showMateriaLab: true,
  showRoomSpain: true,
  showRoomFrance: true,
  showRoomEuroshop: true,
  sectors: [],
  products: [],
};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<CurrentUser>) => (state = { ...payload }),
    logOut: (state) => (state = { ...initialState }),
    setCurrentUserLanguage: (state, { payload }: PayloadAction<Language>) => {
      state.language = payload.name;
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setSurName: (state, { payload }: PayloadAction<string>) => {
      state.surName = payload;
    },
    setNotifications: (state, { payload }: PayloadAction<boolean>) => {
      state.notificationsActive = payload;
    },
  },
});

export const { setCurrentUser, logOut, setCurrentUserLanguage, setName, setSurName, setNotifications } =
  currentUserSlice.actions;
export const currentUserSelector = (state: RootState) => state.currentUser;
export default currentUserSlice.reducer;
