import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { HmyModalFileViewer } from 'src/components';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices } from 'src/services';
import './hmy-modal-order.scss';

type ImageArticleProps = {
  thumbnail: string;
  imageId: string;
};

const ImageArticle: FC<ImageArticleProps> = ({ thumbnail, imageId }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState<any>();
  const { callEndpoint } = useFetchAndLoad();
  const getImage = async (id: string) => await callEndpoint(blobServices.getBlob(id));

  useEffect(() => {
    if (imageId && isOpen) {
      getImage(imageId).then((response) => setImage(response.data));
    }
  }, [imageId, isOpen]);

  return (
    <>
      <div className="image" style={{ backgroundImage: `url(${thumbnail})` }} onClick={() => setIsOpen(true)} />
      {isOpen && image && (
        <HmyModalFileViewer
          isOpen={isOpen}
          canEdit={false}
          file={image}
          handleClose={() => setIsOpen(false)}
          hanldeClickVisible={() => setIsOpen(true)}
        >
          <img src={image.url} style={{ height: 'calc(100vh - 100px)' }} />
        </HmyModalFileViewer>
      )}
    </>
  );
};

export default ImageArticle;
