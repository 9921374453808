import './grouping-file-card.scss';
import { FC } from 'react'
import { UserGroupingFile } from 'src/models';
import { Box } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { StringMultiple } from 'src/components';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import imageIcon from '../../../../../assets/images/imageIcon.png';
import { adjustString } from 'src/utilities';
import { FormattedDate } from 'react-intl';

type GroupingFileCardProps = {
    file: UserGroupingFile;
    canEdit: boolean;
    setSelectedFile: (fileId: string) => void;
    setEditFile: (fileId: string) => void;
  };

const GroupingFileCard: FC<GroupingFileCardProps> = ({ file, canEdit, setSelectedFile, setEditFile }) => {
  return (
    <Box  
      className="grouping-file-card-container"
    >
        <Box
            className="grouping-file-card-main"
        >
            <Box
                className="grouping-file-card-date"
            >
                <Box>
                    <FormattedDate year="numeric" month="2-digit" day="2-digit" value={file.date} />
                </Box>
                {canEdit
                    ?
                        <Box
                            className="grouping-file-card-edit-icon"
                            onClick={() => setEditFile(file.id)}
                        >
                            <DriveFileRenameOutlineIcon fontSize="inherit" />
                        </Box> 
                    :
                        null
                }
            </Box>
            <Box
            >
                {adjustString( file.name, 25)}  
            </Box>
            <Box
            >
                {file.kind.name}   
            </Box>
            <Box
            >
                {file.category.name}   
            </Box>
            <Box
                className="grouping-file-card-document"
            >
                <StringMultiple 
                    elements={file.tags.map(x => x.name)}
                    cutString={25}
                /> 
                {file.name.includes('.pdf')
                    ?
                        <Box
                            className="grouping-file-card-document-icon"
                            onClick={() => setSelectedFile(file.id)}
                        >
                            <TextSnippetIcon fontSize="inherit" />
                        </Box>                  
                    :
                        <Box
                            className="grouping-file-card-document-icon-image"
                            onClick={() => setSelectedFile(file.id)}
                        >
                            <img src={imageIcon} style={{width: "45px", height: "45px"}} />
                        </Box>                      
                }
            </Box>
        </Box>
    </Box>
  )
}

export default GroupingFileCard;