import './hmy-paginator.scss';
import { FC, useEffect, useState } from 'react'
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

type HmyPaginatorProps = {
    currentPage: number;
    pages: number;
    rowsPerPage: number;
    showTablePaginator: boolean;
    handleChangePage: (page: number) => void;
    handleChangeRowsPerPage: (value: number) => void;
};

const HmyPaginator: FC<HmyPaginatorProps> = ({pages, currentPage, rowsPerPage, showTablePaginator, handleChangePage, handleChangeRowsPerPage}) => {

    const intl = useIntl();
    
    const [arrayOfPages, setArrayOfPages] = useState<number[]>([]);

    const pag = intl.formatMessage({id: "page"});
    const of = intl.formatMessage({id: "of"});

    const handleSelectedPage = (page: number) => {
        if(page !== currentPage && page >= 1 && page <= pages){
            handleChangePage(page);
        }
    }

    const handleChangeRows = (event: SelectChangeEvent) => {
        handleChangeRowsPerPage(Number(event.target.value));
    }

    useEffect(() => {
        let newPages: number[] = [];
        for(let i=1; i <= pages; i++){
            newPages.push(i);
        }
        setArrayOfPages(newPages);
    },[pages]);
    
    return (
        <Box
            className="hmy-paginator"
        >
        {pages && pages <= 25
            ?
            <Box className="stepper-horizontal">
                <Box className="stepper-horizontal-circle">
                    <Box className="circle"></Box>
                </Box>
                {arrayOfPages.map((x, index) => (
                    <Box 
                        className={currentPage === index + 1 ? "step active" : "step"} 
                        style={{width: `calc(100% / ${pages})`}} 
                        key={`step-${index + 1}`}
                        onClick={() => handleSelectedPage(index + 1)}
                    >
                        <Box className="step-circle"></Box>
                        <Box className="step-title" sx={{paddingRight: index === 0 ? "3px" : "0px"}} >{index + 1}</Box>
                    </Box> 
                ))}
                <Box className="stepper-horizontal-circle">
                    <Box className="circle"></Box>
                </Box>
            </Box>
            :
            null
        }
        {pages && pages > 0 && showTablePaginator
        ?
            <Box className="basic-paginator">
                <Box 
                    className={currentPage === 1 ? "selectable-pages" : "selectable-pages active"}
                    onClick={() => handleSelectedPage(currentPage - 1)}
                >
                    <FormattedMessage id="previous" />
                </Box>
                <Box 
                    className="pages"
                >
                    {`${pag} ${currentPage} ${of} ${pages}`}
                </Box>
                <Box 
                    className={currentPage === pages ? "selectable-pages" : "selectable-pages active"}
                    onClick={() => handleSelectedPage(currentPage + 1)}
                >
                    <FormattedMessage id="next" />
                </Box>
                <FormControl size="small" sx={{marginLeft: 5}}>
                    <Select
                        value={rowsPerPage.toString()}
                        onChange={handleChangeRows}
                        size="small"
                        variant="standard"
                    >
                        <MenuItem value={5}><span style={{marginRight: "5px", fontSize: "20px"}}>5</span></MenuItem>
                        <MenuItem value={10}><span style={{marginRight: "5px", fontSize: "20px"}}>10</span></MenuItem>
                        <MenuItem value={25}><span style={{marginRight: "5px", fontSize: "20px"}}>25</span></MenuItem>
                        <MenuItem value={50}><span style={{marginRight: "5px", fontSize: "20px"}}>50</span></MenuItem>
                    </Select>
                </FormControl>
            </Box>
            :
            null
        }
        </Box>  
    )
}

export { HmyPaginator };