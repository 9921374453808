import {http} from '../http-client';
import { TinyCustomerModelService } from './model-service';

const getCustomers = () => {
    const controller = new AbortController();

    return { call: http.get<TinyCustomerModelService[]>("customer", { signal: controller.signal }), controller };
}

export const customerServices = {
    getCustomers
}