import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  isUrgent: boolean;
  editable: boolean;
  setUrgent: (urgent: boolean) => void;
}
export function TogglerFastShipment({ isUrgent, editable, setUrgent }: Props) {
  const [visible, setVisible] = useState<boolean>(isUrgent);
  const [changed, setChanged] = useState<boolean>(false);
  const intl = useIntl();

  function handleCheck() {
    setUrgent(false);
    setChanged(true);
  }

  if (!visible) {
    return null;
  }

  return (
    <>
      <FormControlLabel
        control={<Switch disabled={!isUrgent || !editable} checked={isUrgent} onChange={handleCheck} />}
        label={intl.formatMessage({ id: 'tooltip.ChangeUrgent' })}
      />
      {changed && (
        <span style={{ color: '#384CFF' }}>
          <FormattedMessage id="msg.ChangedUrgent" />
        </span>
      )}
    </>
  );
}

export default TogglerFastShipment;
