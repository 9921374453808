import './grouping-file-edit.scss';
import { FC, useState } from 'react';
import { ErrorMessage, SuccessMessage, UserGroupingFile } from 'src/models';
import { Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormattedMessage } from 'react-intl';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { groupingServices, UserGroupingFileModelService } from 'src/services';
import { groupingFileAdapter } from 'src/adapters';
import { LoadComponent, ModalComponent } from 'src/components';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import GroupingFileEditForm from './grouping-file-edit-form';
import ErrorIcon from '@mui/icons-material/Error';

type GroupingFileEditProps = {
  userGroupingId: string;
  fileId: string;
  canDelete: boolean;
  goBack: () => void;
  deleteGroupingFile: (fileId: string) => void;
};

const GroupingFileEdit: FC<GroupingFileEditProps> = ({ userGroupingId, fileId, goBack, deleteGroupingFile }) => {

  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();

  const [file, setFile] = useState<UserGroupingFile | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const getFile = async () => await callEndpoint(groupingServices.getFile(fileId));

  const updateGroupingFile = async (fileId: string, property: string, value: string) => await callEndpoint(groupingServices.updateGroupingFile(fileId, property, value));
  const updateGroupingFileTags = async (fileId: string, tags: string[]) => await callEndpoint(groupingServices.updateGroupingFileTags(fileId, tags));
  const updateGroupingFileTypeCategory = async (fileId: string, type: string, category: string) => await callEndpoint(groupingServices.updateGroupingFileTypeCategory(fileId, type, category));

  const adaptFile = async (data: UserGroupingFileModelService) => {
    setFile(groupingFileAdapter(data));
  }

  const reloadFile = async () => {
    const response = await getFile();
    adaptFile(response.data);
  }

  const handleUpdateBasic = async (property: string, value: string) => {
    try {
      await updateGroupingFile(file!.id, property, value);
      dispatch(setMessage(SuccessMessage("success.fileUpdatedSuccessfully", true)));
      reloadFile();
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.UpdatingFile", true)));
    }
  }

  const handleUpdateTags = async (tags: string[]): Promise<boolean> => { 
    try {
      await updateGroupingFileTags(file!.id, tags);
      dispatch(setMessage(SuccessMessage("success.fileUpdatedSuccessfully", true)));
      reloadFile();
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.UpdatingFile", true)));
      return false;
    }
  }

  const handleUpdateTypeCategory = async (type: string, category: string): Promise<boolean> => { 
    try {
      await updateGroupingFileTypeCategory(file!.id, type, category);
      dispatch(setMessage(SuccessMessage("success.fileUpdatedSuccessfully", true)));
      reloadFile();
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.UpdatingFile", true)));
      return false;
    }
  }

  const handleDismissModal = () => {
    setOpenModal(false);
  }

  const handleAcceptModal = async () => {
    if(file){
      deleteGroupingFile(file.id);
    }
  }

  useAsync(getFile, adaptFile, () => { }, () => { }, []);

  return (
    file !== null
    ?
    <Box
      className="grouping-file-edit-container"
    >
      {loading ? <LoadComponent /> : null}
      <Box
        className="grouping-file-edit-title-container"
        onClick={() => goBack()}
      >
        <ChevronLeftIcon /> 
        <Typography
          className="grouping-file-edit-title"
        >
          <FormattedMessage id="editDocument" />     
        </Typography>
      </Box>
      <GroupingFileEditForm 
        userGroupingId={userGroupingId}
        file={file}
        handleUpdateBasic={handleUpdateBasic}
        handleUpdateTags={handleUpdateTags}
        handleUpdateTypeCategory={handleUpdateTypeCategory}
      />
      <Box
        className="grouping-file-edit-button-container"
      >
        <Box
            className="grouping-file-edit-button-delete"
            onClick={() => setOpenModal(true)}
        >
            <FormattedMessage id="remove" />
        </Box>
      </Box>
      <ModalComponent
        isOpen={openModal}
        disMissText="remove"
        acceptText="btn.cancel"
        colorAcceptButton="red"
        handleDismiss={handleDismissModal}
        handleAccept={handleAcceptModal}
      >
        <Box>
          <ErrorIcon className="error-icon" />
        </Box>
        <Box
          className="grouping-file-modal-text"
        >
          <FormattedMessage id="deleteUserGroupingFile" values={{ value: <b>{file?.name}</b>}} />
        </Box>
      </ModalComponent>
    </Box>
    :
    null
  )
}

export default GroupingFileEdit;