import './grouping-card.scss';
import { FC, useState } from 'react';
import { GroupingProfile, UserGroupingBase } from 'src/models';
import { Box, Dialog, IconButton, Typography, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { FormattedDate } from 'react-intl';
import { adjustString, IsAdmin } from 'src/utilities';
import CloseIcon from '@mui/icons-material/Close';

type GroupingCardProps = {
    grouping: UserGroupingBase;
    color: 'primary' | 'secondary' | 'tertiary'
    handleSelectedGrouping: (value: UserGroupingBase) => void;
    handleClickGrouping: (groupingId: string) => void;
}

const GroupingCard: FC<GroupingCardProps> = ({ grouping, color, handleSelectedGrouping, handleClickGrouping }) => {

    const currentUser = useSelector(currentUserSelector);

    const matches = useMediaQuery('(max-width: 768px)');
    const [openModalUsers, setOpenModalUsers] = useState<boolean>(false);

    const handleCloseModal = () => {
        setOpenModalUsers(false);
    }

    const handleOpenModal = () => {
        if((currentUser.hmy && grouping.users.length > 0) || (!currentUser.hmy && grouping.hmyUsers.length > 0))
        {
            setOpenModalUsers(true);
        }
    }

    return (
        <Box
            className={`grouping-card-container grouping-card-${color}`}
        >
            <Box
                className="grouping-card-users"
            >
                <Box
                    className="grouping-card-users-icon"
                    onClick={() => handleOpenModal()}
                >
                    {currentUser.hmy
                        ?
                        (grouping.users.length === 0
                            ?
                                <PersonOffIcon fontSize={matches ? "medium" : "large"}/>
                            :
                            (grouping.users.length > 1
                                ?
                                <GroupsIcon fontSize={matches ? "medium" : "large"} />
                            :
                                <PersonIcon fontSize={matches ? "medium" : "large"} />
                            )                           
                        )
                        :
                        (grouping.hmyUsers.length === 0
                            ?
                                <PersonOffIcon fontSize={matches ? "medium" : "large"} />
                            :
                            (grouping.hmyUsers.length > 1
                                ?
                                <GroupsIcon fontSize={matches ? "medium" : "large"} />
                            :
                                <PersonIcon fontSize={matches ? "medium" : "large"} />
                            )                           
                        )
                    }
                </Box>
            </Box>
            <Box
                className="grouping-card-text"
                onClick={() => handleClickGrouping(grouping.id)}
            >
                <Typography align="center">
                    {adjustString( grouping.name, 30)}
                </Typography>
                <Typography>
                    <FormattedDate year="numeric" month="2-digit" day="2-digit" value={grouping.created} />
                </Typography>
            </Box>
            <Box
                className="grouping-card-delete"
            >
                {grouping.hmyUsers.find(x => x.profile.name === GroupingProfile.OWNER)?.id === currentUser.id || IsAdmin(currentUser)
                    ?
                    <Box
                        className="grouping-card-delete-icon"
                    >
                        <DeleteIcon 
                            onClick={() => handleSelectedGrouping(grouping)}
                            fontSize="inherit"
                        />
                    </Box>
                :
                    null
                }             
                <Box
                    className="grouping-card-documents-icon"
                    onClick={() => handleClickGrouping(grouping.id)}
                >
                    {grouping.files}
                </Box>      
            </Box>
            <Dialog 
                onClose={handleCloseModal} 
                open={openModalUsers}
            >
                <Box
                    className="grouping-card-modal-header"
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseModal}
                        aria-label="close"
                        className=""
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    className="grouping-card-modal-content"
                >
                    {currentUser.hmy
                        ?
                            grouping.users.map((user, index) => (
                                <Typography
                                    key={`${user}-${index}`}
                                >
                                    {user.fullName}
                                </Typography>
                            ))
                        :
                            grouping.hmyUsers.map((user, index) => (
                                <Typography
                                    key={`${user}-${index}`}
                                >
                                    {user.fullName}
                                </Typography>
                            ))
                    }
                </Box>         
            </Dialog>
        </Box>
    )
}

export default GroupingCard;