import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { BasicCatalogueLineModelService, FilterCatalagueModelService, catalogueServices } from 'src/services/catalogue';
import { useFetchAndLoad } from './use-fetch-load.hook';

export const CATEGORY_NONE = 'categoryNone';

export function useCatalogue(projectId: string | undefined, selectedCatalogues: string[]) {
  const { callEndpoint } = useFetchAndLoad();
  const [catalogueId, setCatalogueId] = useState<string>('');
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getCatalogues', projectId],
    queryFn: () => callEndpoint(catalogueServices.getCatalogues(projectId ?? '')),
    select: ({ data }) => {
      const filterData =
        selectedCatalogues.length > 0
          ? data.filter((catalogue: FilterCatalagueModelService) => selectedCatalogues.includes(catalogue.catalogueId))
          : data;
      if (filterData.length === 1 && catalogueId !== filterData[0].catalogueId) {
        setCatalogueId(filterData[0].catalogueId);
      }
      return filterData;
    },
    enabled: !!projectId,
  });

  const { data: catalogueData } = useQuery({
    queryKey: ['getCatalogue', projectId, catalogueId],
    queryFn: () => callEndpoint(catalogueServices.getCatalogue(projectId ?? '', catalogueId)),
    select: ({ data }) => {
      return data.catalogueLines.map((line: BasicCatalogueLineModelService) => ({ ...line, quantity: 0 }));
    },
    enabled: !!projectId && !!catalogueId,
  });

  return {
    data,
    isLoading: isLoading || isFetching,
    catalogueId,
    setCatalogueId,
    catalogueData: catalogueData,
    catalogueCategories: data
      ? data
          .reduce((total: string[], catalogue: FilterCatalagueModelService) => {
            if (
              catalogue.transportCategoryName === '' &&
              total.findIndex((category) => category === CATEGORY_NONE) === -1
            ) {
              return [...total, CATEGORY_NONE];
            } else if (total.findIndex((category) => category === catalogue.transportCategoryName) === -1) {
              return [...total, catalogue.transportCategoryName];
            }
            return total;
          }, [])
          .sort((a: string, b: string) => {
            // Si a o b son CATEGORY_NONE, ponlo primero
            if (a === CATEGORY_NONE) return -1;
            if (b === CATEGORY_NONE) return 1;

            // Ordena alfabéticamente si ninguno es CATEGORY_NONE
            return a.localeCompare(b);
          })
      : [],
  };
}
