import './profile-menu.scss'
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LANDING, ROUTE_PROFILE } from '../routes';
import { useDispatch } from 'react-redux';
import { logOut } from 'src/redux/states';
import { storageServices } from 'src/services';

const ProfileMenu: FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
 
     const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
         setAnchorEl(event.currentTarget);
     };
 
     const handleClose = () => {     
         setAnchorEl(null);
     };

     const handleCloseItem = (value: string) => {
        handleClose();
        if (typeof value === 'string') {
            if(value === ROUTE_PROFILE)
            {
                navigate(ROUTE_PROFILE);
            }
            else{
                storageServices.closeSession();
                dispatch(logOut());
                navigate(ROUTE_LANDING);
            }
        } 
     }

  return (
    <Box>
        <Button
        id="profile-button"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        >
            <AccountCircleRoundedIcon style={{fontSize: 30}} />
        </Button>
        <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'profile-button',
            }}
        >
            <MenuItem onClick={() => handleCloseItem(ROUTE_PROFILE)}>
                <span className="span-profile">
                    <FormattedMessage id="profile"/>
                </span>               
            </MenuItem>
            <MenuItem onClick={() => handleCloseItem('logOut')}>
                <span className="span-profile-red">
                    <FormattedMessage id="signOff"/>
                </span>
            </MenuItem>
        </Menu>
    </Box> 
  )
}

export default ProfileMenu;