import './hmy-table-row-landmark.scss';
import { FC } from 'react'
import { CompleteProject, Landmark, LandmarkDocument } from 'src/models';
import { Box } from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { EDIT_PROJECTFILES } from 'src/permissions/permissions';
import { HmyIconDocument, HmyIconImage, HmyIconVisibility } from 'src/components/hmy-icons';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';

type HmyTableRowLandmarkProps = {
    project: CompleteProject;
    landmark: Landmark;
    isLast: boolean;
    hanldeClickVisible: (fileId: string) => void;
    handleOpenDocument: (fileId: string, isVisible: boolean) => void;
    goToImages: (fileId: string) => void;
  };

const HmyTableRowLandmark: FC<HmyTableRowLandmarkProps> = ({project, landmark, isLast, hanldeClickVisible, handleOpenDocument, goToImages}) => {
    
    const currentUser = useSelector(currentUserSelector);
    
    const onlyUnique = (value: string, index: number, self: string[]) => {
        return self.indexOf(value) === index;
    }

    const getWorkOrders = (): string[] => {
        let workOrders = landmark.files.filter(x => !x.isCommercialDoc && x.workOrderDescription.trim() !== "").map(x => x.workOrderDescription);
        return workOrders.filter(onlyUnique);
    }

    const filesByWorkOrder = (workOrderDesc: string) : LandmarkDocument[] => {
        return landmark.files.filter(x => !x.isCommercialDoc && x.workOrderDescription === workOrderDesc && 
            (!IsCustomer(currentUser) || x.visible || x.visibles > 0)); 
    }

    const filesWithoutWorkOrder = () : LandmarkDocument[] => {
        return landmark.files.filter(x => !x.workOrderDescription || x.workOrderDescription.trim() === "");
    }

  return (
    <Box
        className="hmy-table-row-landmark-container"
        style={{borderBottomLeftRadius: isLast ? "15px" : "0px", borderBottomRightRadius: isLast ? "15px" : "0px"}}
    >
        <Box
          className="hmy-table-row-landmark-date"
          style={{width: "15%"}}
        >
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={landmark.date} />
        </Box>
        <Box className="hmy-table-row-landmark-lines">
            {getWorkOrders().map((workOrder, index) => (
                <Box
                    key={`${workOrder}-${index}`}
                    className="hmy-table-row-landmark-workorder"
                >
                    <Box
                        className="hmy-table-row-landmark-workorder-line"
                    >
                        <Box
                            className="hmy-table-row-landmark-workorder-desc"
                            style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 41 : 52}%`}}
                        >
                            <span>{workOrder}</span>
                        </Box>
                        <Box 
                            className="hmy-table-row-landmark-files"
                        >
                            {filesByWorkOrder(workOrder).map((file, index) => (
                                <Box
                                    key={`${file.blobId}-${index}`}
                                    className="hmy-table-row-landmark-file"
                                >
                                    <Box
                                        className="hmy-table-row-landmark-file-docType"
                                        style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 60 : 75}%`}}
                                    >
                                        <FormattedMessage id={file.documentType.toLocaleLowerCase()} />                                   
                                    </Box>
                                    <Box
                                        style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 20 : 25}%`, display: "flex", justifyContent: "center"}}
                                    >
                                        <Box
                                            className={file.images.length > 0 && (!IsCustomer(currentUser) || file.visible) ? "hmy-table-row-landmark-file-attachments" : "hmy-table-row-landmark-file-attachments-centered"}
                                            onClick={() => handleOpenDocument(file.blobId, file.visible)}
                                        >
                                            {!IsCustomer(currentUser) || file.visible
                                                ?
                                                <HmyIconDocument 
                                                    color="auto"
                                                    fontSize="inherit"
                                                    fileId={file.blobId}
                                                    isVisible={file.visible}
                                                    onClick={handleOpenDocument}
                                                />
                                                :
                                                null
                                            }                                        
                                            {(!IsCustomer(currentUser) && file.images.length > 0) || (IsCustomer(currentUser) && file.visibles > 0)
                                            ?
                                                <HmyIconImage
                                                    fontSize="inherit"
                                                    visibles={file.visibles}
                                                    total={file.total}
                                                    fileId={file.blobId}
                                                    isCustomer={IsCustomer(currentUser)}
                                                    onClick={goToImages}
                                                />
                                            :
                                                null
                                            }  
                                        </Box>                                                               
                                    </Box>
                                    {project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1
                                        ?
                                        <Box
                                            className="hmy-table-row-landmark-file-visibility"
                                            style={{width: `20%`}}
                                            onClick={() => hanldeClickVisible(file.blobId)}
                                        >
                                            <HmyIconVisibility 
                                                fontSize="inherit"
                                                isVisible={file.visible}
                                            />
                                        </Box>
                                        :
                                        null 
                                    }    
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            ))} 
            {filesWithoutWorkOrder().length > 0
            ?
                <Box              
                    className="hmy-table-row-landmark-workorder"
                >
                    {filesWithoutWorkOrder().map((file, index) => (       
                        !IsCustomer(currentUser) || file.visible
                            ?
                            <Box
                                key={`${file.blobId}-${index}`}
                                className="hmy-table-row-landmark-workorder-line"
                            >
                                <Box
                                    className="hmy-table-row-landmark-workorder-desc"
                                    style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 41 : 52}%`}}
                                >
                                    <span>{file.description ?? file.name}</span>
                                </Box>
                                <Box 
                                    className="hmy-table-row-landmark-files"
                                >
                                    <Box
                                        className="hmy-table-row-landmark-file"
                                    >
                                        <Box
                                            className="hmy-table-row-landmark-file-docType"
                                            style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 60 : 75}%`}}
                                        >
                                            <FormattedMessage id={file.documentType.toLocaleLowerCase()} />
                                        </Box>
                                        <Box
                                            style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 20 : 25}%`, display: "flex", justifyContent: "center"}}
                                        >
                                            <Box
                                                className="hmy-table-row-landmark-file-attachments-centered"
                                            >
                                                {!IsCustomer(currentUser) || file.visible
                                                    ?
                                                        <HmyIconDocument 
                                                            color="auto"
                                                            fontSize="small"
                                                            fileId={file.blobId}
                                                            isVisible={file.visible}
                                                            onClick={handleOpenDocument}
                                                        /> 
                                                    :
                                                        null
                                                }
                                            </Box>                                                               
                                        </Box>
                                        {project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1
                                            ?
                                            <Box
                                                className="hmy-table-row-landmark-file-visibility"
                                                style={{width: `20%`}}
                                                onClick={() => hanldeClickVisible(file.blobId)}
                                            >
                                                <HmyIconVisibility 
                                                    fontSize="small"
                                                    isVisible={file.visible}
                                                />
                                            </Box>
                                            :
                                            null 
                                        }    
                                    </Box>
                                </Box>
                            </Box>
                        :
                        null
                    ))}
                </Box>
            :
                null
            }
        </Box>
    </Box>
  )
}

export default HmyTableRowLandmark;