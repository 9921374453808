import './landing.scss'
import { FC, useEffect, useState } from 'react';
import LogoMiHmy from '../../assets/images/logo_mihmy_blanco.svg';
import { Box, Dialog, IconButton } from '@mui/material';
import { useNavigate, Location, useLocation } from 'react-router-dom';
import { loginMsal } from 'src/utilities';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector, sessionSelector, setLanguage } from '../../redux/states';
import { HmyFlip, ROUTE_INSPIRATION } from 'src/components';
import { authServices, storageServices } from 'src/services';
import { AppLanguages } from 'src/components/language-menu/languages';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { LoginForm } from '../login/login-form';
import LandingWelcome from './landing-welcome';
import LandingImages from './landing-images';
import LandingLabelEffect from './landing-label-effect';
import { useFetchAndLoad } from 'src/hooks';

const getUrl = (location: Location): string => {
    return `${location.pathname}${location.search}${location.hash}`
}

const Landing: FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const currentUser = useSelector(currentUserSelector);
    const { language } = useSelector(sessionSelector);
    const [openModelLogin, setOpenModelLogin] = useState<boolean>(false);
    const [isFlipped, setIsFlipped]= useState<boolean>(false);
    const { loading, callEndpoint } = useFetchAndLoad();

    const getSettingsMsal = async () => await callEndpoint(authServices.getSettingsMsal());

    const handleClickLogin = async () => {
        const settings = await getSettingsMsal();
        await loginMsal(settings.data, getUrl(location));
    }

    const handleClose = () => {
        setOpenModelLogin(false);
        setIsFlipped(false);
    };

    useEffect(() => {

        if(currentUser.name !== ""){
            if(currentUser.language !== language.name){
                storageServices.setLanguage(currentUser.language);
                const lang = (AppLanguages.filter(x => x.name === storageServices.getLanguage()))[0];
                dispatch(setLanguage(lang));
            }

            const pendingRoute = storageServices.getPendingRoute();
            if(pendingRoute && pendingRoute !== ''){
                storageServices.setPendingRoute('');
                navigate(pendingRoute);
            }else{
                navigate(ROUTE_INSPIRATION);
            }        
        }

    },[currentUser]);

    return (
        <Box className="landing-container">
            <Box 
                className="landing-topbar-container"
            >
                <Box className="landing-topbar-container-logo">
                    <img src={LogoMiHmy}  alt="logo" />
                </Box>
                <Box className="landing-topbar-container-button">
                    <Box
                        className="landing-topbar-container-button-btn"
                        onClick={() => setOpenModelLogin(true)}
                    >
                        <FormattedMessage id="login" />
                    </Box>
                </Box>
            </Box>
            <LandingWelcome />         
            <LandingLabelEffect />
            <LandingImages />
            <Dialog 
                onClose={handleClose} 
                open={openModelLogin}
                className="landing-dialog"
            >
                <Box
                    className="landing-dialog-topbar"
                >
                    <IconButton
                        onClick={() => isFlipped ? setIsFlipped(false) : handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>      
                </Box>
                <Box style={{maxWidth: "400px", minHeight: "450px", paddingBottom: "40px"}}>
                    <HmyFlip
                        direction="horizontal"
                        flipped={isFlipped}
                    >
                        <Box
                            className="landing-dialog-button-container"
                        >
                            <Box
                                className="landing-dialog-button"
                                onClick={() => setIsFlipped(true)}
                            >
                                <FormattedMessage id="customerAccess" />
                            </Box>
                            <Box
                                className="landing-dialog-button"
                                onClick={() => handleClickLogin()}
                            >
                                <FormattedMessage id="hmyAccess" />
                            </Box>
                        </Box> 
                        
                        <LoginForm 
                            isModal={true}
                            onClose={handleClose}
                        />
                    </HmyFlip>
                </Box>
            </Dialog>    
        </Box>
    )
}

export default Landing;