/************************PERMISOS BÁSICOS*********************/
export const READ_HOME = "READ_Home";
export const READ_PROJECTS = "READ_Projects";
export const DELETE_PROJECT = "DELETE_Project";
export const READ_CLOSED_PROJECTS = "READ_ClosedProjects";
export const READ_MANAGEMENT = "READ_Management";
export const READ_GROUPINGS = "READ_Groupings";
export const READ_PROFILE = "READ_Profile";
export const ADD_GROUPING = "ADD_Grouping";
export const ADD_PROJECTS = "ADD_Projects";
export const ADD_PROJECTS_SELECT_CUSTOMER = "ADD_ProjectsSelectCustomer";
export const READ_USERS = "READ_Users";
export const READ_HMYUSERS = "READ_HmyUsers";
export const READ_COMPANIES = "READ_Companies";
export const ADD_USERS = "ADD_Users";
export const ADD_HMYUSERS = "ADD_HmyUsers";
export const ADD_COMPANIES = "ADD_Companies";
export const DELETE_COMPANIES = "DELETE_Companies";
export const EDIT_COMPANIES = "EDIT_Companies";
export const DELETE_USERS = "DELETE_Users";
export const DELETE_HMYUSERS = "DELETE_HmyUsers";
export const ADD_REPORTS = "ADD_Reports";
export const READ_REPORTS = "READ_Reports";
export const READ_STATISTICS = "READ_Statistics";
export const READ_STATISTICS_INTERNAL = "READ_StatisticsInternal";
export const ADD_ROLE = "ADD_Role";
export const ADD_SUPERVISOR = "ADD_Supervisor";
export const READ_ORDERS = "READ_Orders";
export const ADD_ORDERS = "ADD_Orders";
export const EDIT_ORDERS = "EDIT_Orders";


/************************PERMISOS DE PROYECTOS*********************/
export const READ_PROJECT = "READ_Project";
export const READ_PROJECTINBOX = "READ_ProjectInbox";
export const READ_PROJECTFILES = "READ_ProjectFiles";
export const READ_PROJECTMANAGEMENT = "READ_ProjectManagement";
export const READ_PROJECTIMAGES = "READ_ProjectImages";
export const READ_PROJECTVSTORE = "READ_ProjectVStore";
export const ADD_PROJECTUSER = "ADD_ProjectUser";
export const DELETE_PROJECTUSER = "DELETE_ProjectUser";
export const ADD_PROJECTHMYUSER = "ADD_ProjectHmyUser";
export const DELETE_PROJECTHMYUSER = "DELETE_ProjectHmyUser";
export const EDIT_PROJECTNAME = "EDIT_ProjectName";
export const EDIT_PROJECTVSTORE = "EDIT_ProjectVStore";
export const ADD_PROJECTINBOXFILE = "ADD_ProjectInboxFile";
export const DELETE_PROJECTINBOXFILE = "DELETE_ProjectInboxFile";
export const EDIT_PROJECTINBOXFILE = "EDIT_ProjectInboxFile";
export const EDIT_PROJECTFILES = "EDIT_ProjectFiles";
export const EDIT_PROJECTIMAGES = "EDIT_ProjectImages";

/************************PERMISOS DE AGRUPACIONES*********************/
export const READ_GROUPING = "READ_Grouping";
export const DELETE_GROUPING = "DELETE_Grouping";
export const EDIT_GROUPING = "EDIT_Grouping";
export const READ_GROUPINGHMYUSER = "READ_GroupingHmyUser";
export const ADD_GROUPINGHMYUSER = "ADD_GroupingHmyUser";
export const DELETE_GROUPINGHMYUSER = "DELETE_GroupingHmyUser";
export const READ_GROUPINGCUSTOMERUSER = "READ_GroupingCustomerUser";
export const ADD_GROUPINGCUSTOMERUSER = "ADD_GroupingCustomerUser";
export const DELETE_GROUPINGCUSTOMERUSER = "DELETE_GroupingCustomerUser";
export const READ_GROUPINGFILES = "READ_GroupingFiles";
export const ADD_GROUPINGFILES = "ADD_GroupingFiles";
export const DELETE_GROUPINGFILES = "DELETE_GroupingFiles";
export const EDIT_GROUPINGFILES = "EDIT_GroupingFiles";