import './hmy-icon-image.scss';
import { FC } from 'react';
import { Box, Tooltip } from '@mui/material';
import imageIcon from '../../../assets/images/imageIcon.png';

type HmyIconImageProps = {
    fontSize: "small" | "inherit" | "large" | "medium";
    visibles: number;
    total: number;
    fileId: string;
    isCustomer: boolean;
    onClick: (fileId: string) => void;
};

const HmyIconImage: FC<HmyIconImageProps> = ({fontSize, visibles, total, fileId, isCustomer, onClick}) => {
  return (
    <Box
        className="hmy-icon-image"
        onClick={() => onClick(fileId)}
    >
      <Tooltip title={isCustomer ? `${visibles}` : `${visibles}/${total}`} placement="right">
        <img src={imageIcon} alt="image icon" />
      </Tooltip>        
    </Box>
  )
}

export { HmyIconImage };