import './drawing.scss';
import { FC, useState } from 'react'
import { CompleteProject, GeneralCollection, VstoreFile } from 'src/models';
import { Box } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { GetVstoreFilesDto } from 'src/dtos/projects/get-vstore-files.dto';
import { projectServices, VstoreFileModelService } from 'src/services';
import { vstoreFileAdapter } from 'src/adapters';
import { HmyBtn, HmyModalBasic, LoadComponent } from 'src/components';
import DrawingCard from './drawing-card';
import { EDIT_PROJECTVSTORE } from 'src/permissions/permissions';
import Iframe from 'react-iframe';

type DrawingProps = {
  project: CompleteProject;
};

const Drawing: FC<DrawingProps> = ({project}) => {
  const { loading, callEndpoint } = useFetchAndLoad();
  const [drawings, setDrawings] = useState<GeneralCollection<VstoreFile>>();
  const [openModalDrawing, setOpenModalDrawing]= useState<boolean>(false);
  const [selectedDrawing, setSelectedDrawing]= useState<string | null>(null);
  const [paginatorRequest, setPaginatorRequest] = useState<GetVstoreFilesDto>({
    projectId: project.projectId,
    page: 1,
    take: 10
});

  const getDrawings = async () => await callEndpoint(projectServices.getVstoreFiles(paginatorRequest));
  const doVisibleDrawing = async (projectId: string, fileUrn: string, visible: boolean) => await callEndpoint(projectServices.setVstoreFilevisibility(projectId, fileUrn, visible));

  const adaptVstoreFiles = (data: GeneralCollection<VstoreFileModelService>) => {

    let listVstoreFiles: VstoreFile[] = [];
    let newVstoreFiles = data.items.map((x: VstoreFileModelService) => { return vstoreFileAdapter(x) });
    if(paginatorRequest.page !== 1){
      listVstoreFiles = (drawings?.items ?? []).concat(newVstoreFiles);
    }else{
      listVstoreFiles = newVstoreFiles;
    }

    setDrawings({
      items: listVstoreFiles,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const loadMoreDrawings = () => {
    setPaginatorRequest({
      ... paginatorRequest,
      page: paginatorRequest.page + 1
    });
  }

  const hanldeClickVisible = async (fileId: string, visible: boolean) => {
    try {
        await doVisibleDrawing(project.projectId, fileId, visible);
        if(drawings){
          let drawingList: VstoreFile[] = [...drawings.items];
          const index = drawingList.findIndex(x => x.urn === fileId);
          drawingList[index].visible = visible;
          setDrawings({
            ... drawings,
            items: drawingList
          });
        }
      } 
      catch (error) 
      {
      }
  }

  const handleCloseDrawing = () => {
    setOpenModalDrawing(false);
    setSelectedDrawing(null);
  }

  const handleOpenDrawing = (source: string) => {
    setSelectedDrawing(source);
    setOpenModalDrawing(true);
  }

  useAsync(getDrawings, adaptVstoreFiles, () => { }, () => { }, [paginatorRequest]);

  return (
    <Box
      className="drawing-container"
    >
      {loading 
        ?
          <LoadComponent />
        :
          null
      }
      <Box
        className="drawing-list-container"
      >
        {drawings?.items.map((drawing, index) => (
          <DrawingCard 
            key={`${drawing.urn}-${index}`}
            drawing={drawing}
            canEdit={project.permissions.findIndex(x => x === EDIT_PROJECTVSTORE) > -1}
            handleOpenDrawing={handleOpenDrawing}
            hanldeClickVisible={hanldeClickVisible}
          />
        ))}
      </Box>
      {drawings && drawings?.currentPage < drawings?.pages
        ?
          <HmyBtn 
            title="seeMore" 
            width={0}
            fullwith={true}
            color="secondary"
            handleClick={loadMoreDrawings}
          />    
        :
          null
      }
      <HmyModalBasic
        isOpen={openModalDrawing}
        handleClose={handleCloseDrawing}    
      >
        <Box 
          className="drawing-iframe-container"
        >
          {selectedDrawing !== null
          ?
          <Iframe 
            url={selectedDrawing}
            id="drawing-Iframe"
            position="relative"
            scrolling="no"
            allowFullScreen={true}
            className="drawing-iframe"
          />
        :
        null
        }
        </Box>
      </HmyModalBasic>
    </Box>
  )
}

export default Drawing;