import { Autocomplete, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { FC, Fragment, KeyboardEvent } from 'react'
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

type BasicAutocompleteProps = {
    label: string;
    showLabel: boolean;
    fullWidth: boolean;
    value: string | null;
    options: string[];
    loading: boolean;
    helperText: string | null;
    inputValue: string;
    setValue: (value: string | null) => void;
    setInputValue: (value: string) => void;
};

const BasicAutocomplete: FC<BasicAutocompleteProps> = ({ label, showLabel, fullWidth, value, options, loading, helperText, inputValue, setValue, setInputValue}) => {
    
    const matches = useMediaQuery('(max-width: 768px)');

    return (
        <Autocomplete
            id="hmy-autocomplete"
            value={value}
            blurOnSelect
            onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            }}
            loading={loading}
            options={options}
            fullWidth={fullWidth}
            ListboxProps={{style: {fontSize: matches ? 18 : 20}}}
            sx={{
            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                fontSize: matches ? 18 : 20,
            },
            }}
            size={matches ? "small" : "medium"}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={showLabel ? <FormattedMessage id={label} /> : null} 
                    sx={{fontSize: matches ? "18px" : "20px"}}
                    variant="standard" 
                    color="primary"
                    fullWidth={fullWidth}
                    error={helperText !== null}
                    helperText={helperText !== null ? <FormattedMessage id={helperText} /> : null}
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <Fragment>
                        <InputAdornment 
                            position="end"
                            sx={{cursor: 'pointer', marginRight: "5px", fontSize: "30px"}}
                        >
                            {loading 
                                ? 
                                <CircularProgress color="primary" size={20} /> 
                                : 
                                <SearchIcon fontSize="inherit" color="primary" />
                            }
                        </InputAdornment>
                        
                        {params.InputProps.endAdornment}
                        </Fragment>
                    ),
                    }}
                />
            }
        />
    )
}

export default BasicAutocomplete