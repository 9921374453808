import './landmark-table.scss';
import { FC, ReactNode } from 'react'
import { Box } from '@mui/material';
import { CompleteProject } from 'src/models';
import { FormattedMessage } from 'react-intl';
import { EDIT_PROJECTFILES } from 'src/permissions/permissions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { GetDocumentsDto } from 'src/dtos';

type LandmarkTableProps = {
    children: ReactNode;
    project: CompleteProject;
    paginatorRequest: GetDocumentsDto;
    changeOrderDesc: () => void;
    onClickDocumentTypes: () => void;
};

const LandmarkTable: FC<LandmarkTableProps> = ({project, children, paginatorRequest, changeOrderDesc, onClickDocumentTypes}) => {

  return (
    <Box
      className="landmark-table"
    >
      <Box
        className="landmark-table-header"
      >
        <Box
          className="landmark-table-header-element separator clickable"
          style={{width: `15%`, justifyContent: "center"}}
          onClick={() => changeOrderDesc()}
        >
          <FormattedMessage id="date" />{paginatorRequest.orderDesc ? <SouthIcon className="landmark-table-icon" fontSize="inherit" /> : <NorthIcon className="landmark-table-icon" fontSize="inherit" />}<CalendarMonthIcon className="landmark-table-icon" fontSize="inherit" />
        </Box>
        <Box
          className="landmark-table-header-element separator"
          style={{width: `${project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? 35 : 45}%`, justifyContent: "center"}}
        >
          <FormattedMessage id="description" />
        </Box>
        <Box
          className="landmark-table-header-element separator clickable"
          style={{width: `30%`, justifyContent: "center"}}
          onClick={() => onClickDocumentTypes()}
        >
          <FormattedMessage id="documentType" />{paginatorRequest.typeDocument.length > 0 ? <FilterAltIcon className="landmark-table-icon" fontSize="inherit" /> : <FilterAltOutlinedIcon className="landmark-table-icon" fontSize="inherit" />}
        </Box>
        <Box
          className={project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? "landmark-table-header-element separator" : "landmark-table-header-element"}
          style={{width: `10%`, justifyContent: "center"}}
        >
          <FormattedMessage id="attachments" />
        </Box>
        {project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1
        ?
          <Box
            className="landmark-table-header-element"
            style={{width: `10%`, justifyContent: "center"}}
          >
            <FormattedMessage id="visibility" />
          </Box>
          :
          null 
        }     
      </Box>

      <Box
        className="landmark-table-body"
      >
        {children} 
      </Box>  
    </Box>
  )
}

export { LandmarkTable };