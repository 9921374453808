import { Box, Dialog, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchAndLoad } from 'src/hooks';
import { ProjectCommercialModelService, projectServices } from 'src/services';
import './hmy-btn-help.scss';

type HmyModalHelpProps = {
  open: boolean;
  close: () => void;
  projectId: string;
};

const HmyModalHelp: FC<HmyModalHelpProps> = ({ open, close, projectId }) => {
  const [commercialData, setCommercialData] = useState<ProjectCommercialModelService>();
  const { callEndpoint } = useFetchAndLoad();

  useEffect(() => {
    if (projectId) {
      callEndpoint(projectServices.getProjectCommercial(projectId)).then(
        ({ data }: { data: ProjectCommercialModelService }) => {
          setCommercialData(data);
        }
      );
    }
  }, [projectId]);

  if (!commercialData) {
    return null;
  }

  return (
    <Dialog open={open} className="hmy-modal-help" onClose={close}>
      <Box className="hmy-modal-help-container">
        <Typography className="hmy-modal-help-title">
          <FormattedMessage id="helpTitle" />
        </Typography>
        <Typography className="hmy-modal-help-text">
          <FormattedMessage id="helpText" />
        </Typography>

        <Typography className="hmy-modal-help-comercial">
          <FormattedMessage id="helpComercial" values={{ value: commercialData.name }} />
        </Typography>
        <a href={`mailto:${commercialData.email}`} target="_blank">
          {commercialData.email}
        </a>
        <a href={`tel:${commercialData.phoneNumber}`} target="_blank">
          {commercialData.phoneNumber}
        </a>
      </Box>
    </Dialog>
  );
};

export { HmyModalHelp };
