import './import-project-card.scss';
import { FC } from 'react'
import { Box } from '@mui/material';
import { adjustString, IsAdmin } from 'src/utilities';
import { CurrentUser, ImportProject } from 'src/models';
import { HmyCheckBox, StringMultiple } from 'src/components';
import { FormattedDate } from 'react-intl';

type ImportProjectCardProps = {
    project: ImportProject;
    selectedProjects: ImportProject[];
    currentUser: CurrentUser;
    handleCheckRow: (element: string) => void;
  };

const ImportProjectCard: FC<ImportProjectCardProps> = ({project, selectedProjects, currentUser, handleCheckRow}) => {
  return (
    <Box
      className="card-import-project-container"
    >
      <Box
        className="card-import-project-number"
      >
        <Box
          onClick={() => handleCheckRow(project.projectId)}
        >
          <HmyCheckBox
            color="primary"
            isChecked={selectedProjects.findIndex(x => x.projectId === project.projectId) > -1}
          />
          {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
        </Box>
        <Box>
          <FormattedDate year="numeric" month="2-digit" day="2-digit"  value={project.projectDate} />    
        </Box>
      </Box>

      <Box
        onClick={() => handleCheckRow(project.projectId)}
      >
        {adjustString(project.description, 35)}
      </Box>

      {IsAdmin(currentUser)
        ?
        <Box>
          <span>{project.customer.name}</span>
        </Box>
        :
          null
      }

      <Box>
        {IsAdmin(currentUser)
          ?
            <StringMultiple 
              elements={project.users}
              cutString={20}
            />
          :
            <span>{project.customer.name}</span>
        }
      </Box>
    </Box>
  )
}

export default ImportProjectCard;