import './report-card.scss';
import {FC} from 'react';
import { BasicReport } from 'src/models';
import { Box, Tooltip } from '@mui/material';
import imgReport from '../../../assets/images/reports_retail_tech.jpeg';
import { HmyIconLaunch } from 'src/components';
import { adjustString } from 'src/utilities';

type Props = {
    report: BasicReport;
    handleOpenReport: (report: BasicReport) => void;
}

const ReportCard: FC<Props> = ({report, handleOpenReport}) => {
  return (
    <Box
        className="report-card-container"
        onClick={() => handleOpenReport(report)}
    >
        <Box
            className="report-card"
        >   
            <img src={imgReport} alt="Chart logo" />     
        </Box>
        <Box
            className="report-card-name"
        >
            <Tooltip
                title={report.title}
            >
                <span>{adjustString(report.title, 17)}</span>
            </Tooltip>
        </Box>
    </Box>
  )
}

export default ReportCard;