import { useQuery } from '@tanstack/react-query';
import { BasicOrder } from 'src/models/orders';
import { getOrderPostTransport } from 'src/pages/order/get-order-post-transport.helpers';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { orderServices } from 'src/services/orders';
import { useFetchAndLoad } from './use-fetch-load.hook';

export function useTransport(orderData: BasicOrder | BasicShoppingOrder) {
  const { callEndpoint } = useFetchAndLoad();

  const request = getOrderPostTransport(orderData);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['postOrderTransport', request],
    queryFn: () => callEndpoint(orderServices.postOrderTransport(request)),
    select: ({ data }) => data,
  });

  return {
    deliveryDate: data?.deliveryDate,
    deliveryDays: data?.deliveryDays,
    transportCost: data?.transportCost,
    isLoading: isLoading || isFetching,
  };
}
