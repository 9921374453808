import './hmy-checkbox.scss';
import { FC } from 'react';
import { Checkbox } from '@mui/material';

type HmyCheckBoxProps = {
    color: "primary" | "secondary";
    isChecked: boolean;
};

const HmyCheckBox: FC<HmyCheckBoxProps> = ({color, isChecked}) => {
  return (
    <Checkbox
      checked={isChecked}
      color={color}
      size="small"
    />
  )
}

export { HmyCheckBox };