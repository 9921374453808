import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { TableHeaderElement } from 'src/models';
import './hmy-table-incidences.scss';

type HmyTableIncidencesProps = {
  children: ReactNode;
  headerELementList: TableHeaderElement[];
};

const HmyTableIncidences: FC<HmyTableIncidencesProps> = ({ headerELementList, children }) => {
  return (
    <Box className="hmy-table-incidences">
      <Box className="hmy-table-incidences-header">
        {headerELementList.map((element, index) => (
          <Box
            key={`${element.element}-${index}`}
            className={
              element.withBorder
                ? 'hmy-table-incidences-header-element separator'
                : 'hmy-table-incidences-header-element'
            }
            style={{
              width: `${element.width}%`,
              justifyContent: element.align,
              cursor: element.onClick !== null ? 'pointer' : 'default',
            }}
            onClick={() => (element.onClick !== null ? element.onClick() : null)}
          >
            {element.element}
          </Box>
        ))}
      </Box>

      <Box className="hmy-table-incidences-body">{children}</Box>
    </Box>
  );
};

export { HmyTableIncidences };
