import { DocumentModelService, DocumentPropsModelService, DocumentTypeModelService, LandmarkDocumentModelService, LandmarkModelService } from "src/services/blob";
import { BlobDocument, DocumentProps, TypeDocument, Landmark, LandmarkDocument } from "src/models/blob";
import { HmyModalDocumentBase } from "src/models";

const documentTypeAdapater = (documentType: DocumentTypeModelService): TypeDocument => ({
    documentTypeId: documentType.documentTypeId,
    name: documentType.name,
    alias: documentType.alias
});

export const documentPropsAdapter = (documentProps: DocumentPropsModelService): DocumentProps => {
    
    let documentTypes = documentProps.documentTypes.map((x: DocumentTypeModelService) => { return documentTypeAdapater(x) });

    return{
        documentTypes: documentTypes,
        extensions: documentProps.extensions,
        maxSize: documentProps.maxSize
    }
};

export const blobDocumentAdapter = (document: DocumentModelService): BlobDocument => {

    return{
        blobId: document.blobId,
        name: document.name,
        extension: document.extension,
        urlThumbnail: document.urlThumbnail,
        url: document.url,
        isImage: document.isImage,
        description: document.description,
        date: document.date,
        simpleDate: document.simpleDate,
        documentType: document.documentType,
        visible: document.visible
    }
};

export const modalDocumentAdapter = (document: BlobDocument) : HmyModalDocumentBase => {

    return{
        blobId: document.blobId,
        url: document.url,
        name: document.name,
        description: document.description,
        extension: document.extension,
        isImage: document.isImage,
        isVisible: document.visible
    }
}

export const landmarkAdapter = (landmark: LandmarkModelService): Landmark => {
    let landmarkDocuments = landmark.files.map((x: LandmarkDocumentModelService) => { return LandmarkDocumentAdapter(x) }); 

    return{
        date: landmark.date,
        files: landmarkDocuments
    }
}

export const LandmarkDocumentAdapter = (landmarkDocument: LandmarkDocumentModelService) : LandmarkDocument => {
    return{
        blobId: landmarkDocument.blobId,
        name: landmarkDocument.name,
        projectId: landmarkDocument.projectId,
        extension: landmarkDocument.extension,
        urlThumbnail: landmarkDocument.urlThumbnail,
        url: landmarkDocument.url,
        isImage: landmarkDocument.isImage,
        description: landmarkDocument.description,
        date: landmarkDocument.date,
        simpleDate: landmarkDocument.simpleDate,
        documentType: landmarkDocument.documentType,
        sourceTable: landmarkDocument.sourceTable,
        pkToString: landmarkDocument.pkToString,
        workOrder: landmarkDocument.workOrder,
        workOrderDescription: landmarkDocument.workOrderDescription,
        visible: landmarkDocument.visible,
        images: landmarkDocument.images,
        total: landmarkDocument.total,
        visibles: landmarkDocument.visibles,
        isCommercialDoc: landmarkDocument.isCommercialDoc
    }
}