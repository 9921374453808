import './cookies-component.scss';
import { useEffect, useState } from 'react';
import { Box, Grid, Link } from '@mui/material';
import { storageServices } from 'src/services/storage.service';
import logoHmy from '../../assets/images/HMY_logo_navy.svg'
import { FormattedMessage } from 'react-intl';
import LaunchIcon from '@mui/icons-material/Launch';
import { useDispatch } from 'react-redux';
import { setMatomoDisabled } from 'src/redux/states';

const CookiesComponent = () => {
  
    const dispatch = useDispatch();

    const [show, setShow] = useState(true); 

    const handleClick = (value: boolean) => {
        storageServices.setAcceptCookies(true);
        storageServices.setAcceptMatomo(value);
        dispatch(setMatomoDisabled(!value));
        setShow(false);
    }   

    useEffect(() => {    
        if(storageServices.getAcceptCookies()){
            if(!storageServices.existAcceptMatomo()){
                storageServices.setAcceptMatomo(true);
                dispatch(setMatomoDisabled(false));
            }
            setShow(false);
        }
        else
        {
            setShow(true);
        }
    },[]);  

  return (
    show
        ?
            <Box 
                className="cookies-container"
            >
                <Box 
                    className="hmy-logo-container"
                >
                    <Link href="https://www.hmy-group.com/" target="_blank" rel="noopener noreferrer">
                        <img src={logoHmy} alt="hmy-logo"/>
                    </Link>
                </Box>
                <Box 
                    className="message-container"
                >
                    <p className="message-title">
                        <FormattedMessage id="cookiesNotice" />                                     
                    </p>
                    <p className="message-text">
                        <FormattedMessage id="textCookies" />                                  
                    </p>
                    <Link href="https://www.hmy-group.com/es/aviso-legal-politica-de-privacidad-y-politica-de-cookies/#cookies" target="_blank" rel="noopener noreferrer">
                        <Grid container direction="row" alignItems="center">
                            <FormattedMessage id="moreInformation" /> ... <LaunchIcon style={{marginLeft: "0.5rem", fontSize: "18px", marginBottom: "0.2rem"}} />
                        </Grid>                           
                    </Link>                                      
                </Box>
                <Box
                    className="button-container"
                >
                    <Box
                        className="button-cookies-cancel"
                        onClick={() => handleClick(false)}
                    >
                        <FormattedMessage id="decline" />
                    </Box>
                    <Box
                        className="button-cookies"
                        onClick={() => handleClick(true)}
                    >
                        <FormattedMessage id="accept" />
                    </Box>      
                </Box>
            </Box>
        :
            null
  )
}

export {CookiesComponent};