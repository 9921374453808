import { useEffect, useState } from "react";

const getViewport = () => ({
  width: Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ),
  height: Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ),
});
const getVisualViewport = () => ({
  width: window.visualViewport!.width,
  height: window.visualViewport!.height,
  offsetTop: window.visualViewport!.offsetTop,
  offsetLeft: window.visualViewport!.offsetLeft
});

export const useVisualViewport = () => {
  const [viewport, setViewport] = useState(getViewport());
  const [visualViewport, setVisualViewport] = useState(getVisualViewport());
  useEffect(() => {
    const handleResize = () => {
      setVisualViewport(getVisualViewport());
      setViewport(getViewport());
    };
    window.visualViewport!.addEventListener("resize", handleResize);
    window.visualViewport!.addEventListener("scroll", handleResize);
    return () => {
      window.visualViewport!.removeEventListener("resize", handleResize);
      window.visualViewport!.removeEventListener("scroll", handleResize);
    }
      
  }, []);

  return { viewport, visualViewport };
}