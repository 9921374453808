import { BasicProject } from "./basic-project.model";

export interface CompleteProject extends BasicProject{
    vStore: VStore;
    permissions: string[];
}

export interface VStore {
    vStoreStatus: VStoreStatus;
    visibles: number;
}

export enum VStoreStatus {
    Unavailable = 0,
    Disabled = 1,
    Enabled = 2,
    Error = 99
}