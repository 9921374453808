import './hmy-modal-file-viewer.scss';
import { FC, ReactNode, useEffect } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HmyIconVisibility } from '../hmy-icons';
import SaveIcon from '@mui/icons-material/Save';
import { saveAs } from 'file-saver';
import { HmyModalDocumentBase } from 'src/models';
import { adjustString } from 'src/utilities';
import { useMatomo } from '@jonkoops/matomo-tracker-react'

type HmyModalFileViewerProps = {
    children: ReactNode;
    isOpen: boolean;
    canEdit: boolean;
    file: HmyModalDocumentBase;
    handleClose: () => void;
    hanldeClickVisible: (fileId: string) => void;
};

const HmyModalFileViewer: FC<HmyModalFileViewerProps> = ({children, isOpen, canEdit, file, handleClose, hanldeClickVisible}) => {

    const { trackEvent } = useMatomo();

    const handleDownloadDocument = () => {
        if(file !== null){
            saveAs(file.url, file.name);
            trackEvent({ category: 'Downloads', action: `${file.extension} download`, name: `${file.description} - ${file.name}` });
        }
    }

    useEffect(() => {  
        trackEvent({ category: 'onClick', action: `${file.extension} open`, name: `${file.description} - ${file.name}` });
      }, []);

    return (
    <Dialog fullScreen open={isOpen} onClose={() => handleClose()} sx={{borderRadius: "0px !important"}}>
        <Box
            className="hmy-modal-file-header"
        >
            <Box
                className="hmy-modal-file-header-text"
            >
                <span>
                    {adjustString(file?.description && file?.description !== "" ? file?.description : file?.name, 20)}
                </span>
            </Box>
            <Box
                className="hmy-modal-file-header-buttons"
            >
                {canEdit
                ?
                    <Box
                        onClick={() => hanldeClickVisible(file.blobId)}
                    >
                        <HmyIconVisibility 
                            fontSize="small"
                            isVisible={file.isVisible}
                        />
                    </Box>
                :
                    null
                }
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleDownloadDocument()}
                    aria-label="save"
                    className="hmy-modal-file-header-button"
                >
                    <SaveIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    className="hmy-modal-file-header-button"
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Box>
            
        </Box>
      <Box
        className="hmy-modal-file-container"
      >
        {children}
      </Box> 
    </Dialog>
  )
}

export { HmyModalFileViewer };