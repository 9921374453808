import './inbox-list-documents.scss';
import { FC } from 'react'
import { Box, FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import { BlobDocument, DocumentProps, Selectable } from 'src/models';
import { GetBlobsInboxDto, UpdateBlobDto } from 'src/dtos';
import { HmyInputText, HmySimpleSelectable } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { compareDate, imagesArr, isImageExtension } from 'src/utilities';
import { InboxDocumentCard } from '../inbox-document-card';
import { InboxEditFormValues } from '../inbox-document-card/inbox-edit-form';

type InboxListDocumentsProps = {
    documents: BlobDocument[];
    documentOptions: GetBlobsInboxDto;
    documentProperties: DocumentProps;
    searchText: string; 
    canEditDocuments: boolean;
    setSearchText: (value: string) => void;
    setDocumentOptions: (documentOptions: GetBlobsInboxDto) => void;
    updateDocument: (request: UpdateBlobDto) => Promise<boolean>;
    handleDeleteBlob: (documentId: string) => void;
    handleSelectDocument: (documentId: string)=> void;
  };

type DocumentDate = {
  id: string;
  date: Date;
}

const InboxListDocuments: FC<InboxListDocumentsProps> = ({documents, documentOptions, documentProperties, searchText, canEditDocuments, setSearchText, setDocumentOptions, updateDocument, handleDeleteBlob, handleSelectDocument}) => {

  const matches = useMediaQuery('(max-width: 768px)');
  const intl = useIntl();

  const handleEnterSearch = () => {
    setDocumentOptions({
      ... documentOptions,
      filter: searchText
    });
  }

  const handleChangeHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentOptions({
      ... documentOptions,
      seeHidden: event.target.checked
    });
  };

  const handleChangeDocumentType = (value: string) => {
    setDocumentOptions({
      ...documentOptions,
      documentType: value
    });
  }

  const handleChangeExtension = (value: string) => {
    if(value === "img"){
      setDocumentOptions({
        ...documentOptions,
        extension: imagesArr.join(",")
      });
    }
    else{
      setDocumentOptions({
        ...documentOptions,
        extension: value
      });
    }
  }

  const handleChangeDate = (value: string) => {
    if(value === ""){
      setDocumentOptions({
        ...documentOptions,
        date: null
      });
    }
    else{
      setDocumentOptions({
        ...documentOptions,
        date: documents.find(x => x.blobId === value)?.simpleDate ?? null
      });
    } 
  };

  const getDate = (documentDate: DocumentDate) : Selectable => ({
    id: documentDate.id,
    text: documentDate.date.toString()
  });

  const isDateInArray = (needle: DocumentDate, haystack : DocumentDate[]) => {
    for (var i = 0; i < haystack.length; i++) {
      if (needle.date.getTime() === haystack[i].date.getTime()) {
        return true;
      }
    }
    return false;
  }

  const getSelectablesDate = (): Selectable[] => {
    const dates: DocumentDate[] = documents.map(x => ({id: x.blobId, date: new Date(x.simpleDate)} as DocumentDate));
    
    let uniqueDates: DocumentDate[] = [];
    for (var i = 0; i < dates.length; i++) {
      if (!isDateInArray(dates[i], uniqueDates)) {
        uniqueDates.push(dates[i]);
      }
    }

    return uniqueDates.map(x => getDate(x));
  }

  const getSelectablesExtensions = (): Selectable[] => {
    let arrExtensions = documentProperties.extensions.filter(x => !isImageExtension(x)).map(x => ({id: x, text: x} as Selectable));
    arrExtensions.push(({id: "img", text: intl.formatMessage({id: "image.many"})} as Selectable))
    
    return arrExtensions;
  }

  const handleEdit = async (document: BlobDocument, resultForm: InboxEditFormValues): Promise<boolean> => {
    if (document.description !== resultForm.description || compareDate(document.date, resultForm.date) || document.documentType !== resultForm.documentType) {
      let request: UpdateBlobDto = {
          blobId: document.blobId,
          date: compareDate(document.date, resultForm.date) === true ? resultForm.date : null,
          description: document.description !== resultForm.description ? resultForm.description : null,
          documentType: document.documentType !== resultForm.documentType ? resultForm.documentType : null
      }

      return await updateDocument(request);

    }
    return false;
  }

  const handleDeleteDocument = (documentId: string) => {
    handleDeleteBlob(documentId)
  }
  
  return (
    <Box
      className="inbox-list-documents-container"
    >
      <Box
        className="inbox-list-documents-filter"
      >
        <Box 
          className="inbox-list-documents-search"
        >
          <HmyInputText
            title="search" 
            width={matches ? "100%" : 500}
            value={searchText}
            setValue={setSearchText}
            handleEnter={handleEnterSearch}
          />
        </Box>
        <Box
          className="inbox-list-documents-options"
        >
          <Box 
            className="inbox-list-documents-selects"
          >
            <HmySimpleSelectable 
              title="date"
              elements={getSelectablesDate()}
              value={documentOptions.date !== null ? documents.filter(x => x.simpleDate === documentOptions.date)[0].blobId : ""}
              isDate={true}
              isStringToTranslate={false}
              setValue={handleChangeDate}
            />
            <HmySimpleSelectable 
              title="type"
              elements={documentProperties.documentTypes.filter(x => x.alias !== "IMG").map(x => ({id: x.alias, text: x.alias.toLowerCase()} as Selectable))}
              value={documentOptions.documentType}
              isDate={false}
              isStringToTranslate={true}
              setValue={handleChangeDocumentType}
            />
            <HmySimpleSelectable 
              title="format"
              elements={getSelectablesExtensions()}
              value={documentOptions.extension.includes(",") ? "img" : documentOptions.extension}
              isDate={false}
              isStringToTranslate={false}
              setValue={handleChangeExtension}
            />
          </Box>
          <Box 
            className="inbox-list-documents-seeHidden"
          >
            <FormControlLabel
              control={<Switch
                checked={documentOptions.seeHidden}
                onChange={handleChangeHidden}
                color="secondary"
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />}
              className="inbox-switch-see-hidden"
              label={<FormattedMessage id="seeHidden"/>}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className="inbox-list-documents"
      >
        {documents.map(document => (
          <InboxDocumentCard 
            key={document.blobId}
            document={document}
            documentProperties={documentProperties}
            canEditDocument={canEditDocuments}
            handleDelete={handleDeleteDocument}
            handleEdit={handleEdit}
            handleSelectDocument={handleSelectDocument}
          />
        ))}
      </Box>
    </Box>
  )
}

export {InboxListDocuments};