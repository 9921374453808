import './documents.scss';
import { FC, useEffect, useState } from 'react'
import { CompleteProject, DocumentProps, GeneralCollection, HmyModalDocumentBase, Landmark, TypeDocument } from 'src/models';
import { FormattedMessage } from 'react-intl';
import { Box, Checkbox, Fab, FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFetchAndLoad, useAsync } from 'src/hooks';
import { currentUserSelector } from 'src/redux/states';
import { blobServices, DocumentPropsModelService, LandmarkModelService } from 'src/services';
import { documentPropsAdapter, landmarkAdapter, modalDocumentAdapter } from 'src/adapters';
import { GetDocumentsDto } from 'src/dtos';
import { EDIT_PROJECTFILES, READ_PROJECTFILES } from 'src/permissions/permissions';
import { HmyBtn, HmyImageViewer, HmyModalFileViewer, HmyPdfViewer, LoadComponent, ModalComponent } from 'src/components';
import { IsCustomer } from 'src/utilities';
import { LandmarkTable } from './landmark-table';
import NoLandmarks from './no-landmarks';
import HmyTableRowLandmark from './hmy-table-row-landmark';
import LandmarkCards from './landmark-cards';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

type DocumentsProps = {
  project: CompleteProject;
  goToImages: (fileId: string) => void;
};

const Documents: FC<DocumentsProps> = ({project, goToImages}) => {

  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModalDocumentTypes, setOpenModalDocumentTypes] = useState<boolean>(false);
  const [openDialogViewer, setOpenDialogViewer]= useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<HmyModalDocumentBase | null>(null);
  const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<TypeDocument[]>([]);
  const [landmarks, setLandmarks] = useState<GeneralCollection<Landmark>>();
  const [showButton, setShowButton] = useState<boolean>(true);
  const [paginatorRequest, setPaginatorRequest] = useState<GetDocumentsDto>({
      projectId: project.projectId,
      page: 1,
      take: 5,
      seeHidden: project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1,
      orderDesc: true,
      typeDocument: []
  });

  const getLandmarks = async () => await callEndpoint(blobServices.getLandmarksPaged(paginatorRequest));
  const getDocumentProps = async () => await callEndpoint(blobServices.getDocumentProps());
  const doVisible = async (documentId: string) => await callEndpoint(blobServices.doVisible(documentId));
  const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));

  const adaptListLandmark = (data: GeneralCollection<LandmarkModelService>) => {
    let listLandmark: Landmark[] = [];
    let newlandmarks = data.items.map((x: LandmarkModelService) => { return landmarkAdapter(x) });
    if(paginatorRequest.page !== 1){
      listLandmark = (landmarks?.items ?? []).concat(newlandmarks);
    }else{
      listLandmark = newlandmarks;
    }

    setLandmarks({
      items: listLandmark,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const adaptDocumentProps = (data: DocumentPropsModelService) => {

    let docProps = documentPropsAdapter(data);
    
    setDocumentProperties(docProps);
};

const handleChangeHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
  setPaginatorRequest({
    ... paginatorRequest,
    seeHidden: event.target.checked,
    page: 1
  });
};

const changeOrderDesc = () => {
  setPaginatorRequest({
    ... paginatorRequest,
    orderDesc: !paginatorRequest.orderDesc,
    page: 1
  });
}

const loadMoreLandmarks = () => {
  setPaginatorRequest({
    ... paginatorRequest,
    page: paginatorRequest.page + 1
  });
}

const hanldeClickVisible = async (fileId: string) => {
  try {
      await doVisible(fileId);
      setPaginatorRequest({
        ... paginatorRequest,
        page: 1
      }); 
    } 
    catch (error) 
    {
    }
}

const handleClickDocumentTypes = () => {
  if(openModalDocumentTypes){
    handleDismissModal();
  }
  else
  {
    setOpenModalDocumentTypes(true);
  }
}

const handleChangeDocumentType = (documentType: TypeDocument) => {
  let arr = [...selectedDocumentTypes];
  if(selectedDocumentTypes.findIndex(x => x.alias === documentType.alias) > -1){
    arr = selectedDocumentTypes.filter(x => x.alias !== documentType.alias);
  }
  else{
    arr.push(documentType);
  }
  setSelectedDocumentTypes(arr);
}

const handleDismissModal = () => {
  setOpenModalDocumentTypes(false);
  setSelectedDocumentTypes(paginatorRequest.typeDocument);
}

const handleAcceptModal = () => {
  setPaginatorRequest({
    ... paginatorRequest,
    typeDocument: selectedDocumentTypes,
    page: 1
  });
  setOpenModalDocumentTypes(false);
}

  //VISOR DOCS
  const handleSelectedDocumentToSee = async (documentId : string, isVisible: boolean) => {
    if(!IsCustomer(currentUser) || isVisible){
      try {
        const response = await getDocument(documentId);
        if(response.data.isImage || response.data.extension === "pdf"){
          setSelectedDocument(modalDocumentAdapter(response.data));
          setOpenDialogViewer(true);
        }
        else{
          window.open(response.data.url);
        }
      } catch (error) {
        
      }
    }
  }

  const handleCloseModalViewer = () => {
    setOpenDialogViewer(false);
    setSelectedDocument(null);
  }
  //FIN VISOR DOCS

  useEffect(() => {

    if(selectedDocument !== null){
      getDocument(selectedDocument.blobId).then((response) => {
        setSelectedDocument(modalDocumentAdapter(response.data));
      });      
    }

  },[landmarks])

  useAsync(getLandmarks, adaptListLandmark, () => { }, () => { }, [paginatorRequest]);

  useAsync(getDocumentProps, adaptDocumentProps, () => { }, () => { }, []);

  return (
    <Box
      className="documents-container"
    >
      {project.permissions.findIndex(x => x === READ_PROJECTFILES) > -1 && documentProperties && (
        !loading && 
        (landmarks?.items.length === 0 || (IsCustomer(currentUser) && (landmarks?.items.filter(x => (x.files.filter(f => f.visible || f.visibles > 0)).length > 0))?.length === 0)) && 
        paginatorRequest.typeDocument.length === 0
        ?
          <NoLandmarks 
            project={project}
          />
        :
          <>           
            {IsCustomer(currentUser) ? null 
              :
              <Box 
                className="landmarks-seeHidden"
              >  
                <span
                  className="landmarks-seeHidden-text" 
                >
                  <FormattedMessage id="seeHidden"/>
                </span>
                <Switch
                  checked={paginatorRequest.seeHidden}
                  onChange={handleChangeHidden}
                  color="secondary"
                  size="small"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            }             
            {matches
            ?
              <>
                {landmarks?.items.map((landmark, index) => (
                    !IsCustomer(currentUser) || (landmark.files.filter(x => x.visible || x.visibles > 0)).length > 0
                    ?
                    <LandmarkCards 
                      key={`${landmark.date.toString()}-${index}`}
                      project={project}
                      landmark={landmark}
                      handleOpenDocument={handleSelectedDocumentToSee}
                      goToImages={goToImages}
                    />
                    :
                    null
                  ))
                }
                {loading 
                  ?
                    <LoadComponent />
                  :
                    null
                }
              </>
            :
              <LandmarkTable
                project={project}
                paginatorRequest={paginatorRequest}
                changeOrderDesc={changeOrderDesc}
                onClickDocumentTypes={handleClickDocumentTypes}
              >
                  {landmarks?.items.map((landmark, index) => (
                    !IsCustomer(currentUser) || (landmark.files.filter(x => x.visible || x.visibles > 0)).length > 0
                    ?
                    <HmyTableRowLandmark 
                      key={`${landmark.date.toString()}-${index}`}
                      project={project}
                      landmark={landmark}
                      isLast={index === landmarks?.items.length - 1 && landmarks.currentPage === landmarks.pages}
                      hanldeClickVisible={hanldeClickVisible}
                      handleOpenDocument={handleSelectedDocumentToSee}
                      goToImages={goToImages}
                    />
                    : null
                  ))}  
                  {loading 
                    ?
                      <LoadComponent />
                    :
                      null
                  }
              </LandmarkTable>
            }
            {landmarks && landmarks?.currentPage < landmarks?.pages
              ?
                <HmyBtn 
                  title="seeMore" 
                  width={0}
                  fullwith={true}
                  color="secondary"
                  handleClick={loadMoreLandmarks}
                />    
              :
                null
            }
          </>
        )}
        <ModalComponent
          isOpen={openModalDocumentTypes}
          disMissText="accept"
          acceptText="btn.cancel"
          colorAcceptButton="green"
          handleDismiss={handleDismissModal}
          handleAccept={handleAcceptModal}      
        >
          <Box
            className="project-modal-document-types"
          >
            {documentProperties?.documentTypes.map((documentType, index) => (
              <FormControlLabel 
                key={`${documentType.alias}-${index}`}
                control={
                  <Checkbox
                    checked={selectedDocumentTypes.findIndex(x => x.alias === documentType.alias) > -1}
                    onChange={() => handleChangeDocumentType(documentType)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size="small"
                  />
              } 
                sx={{fontSize: "20px"}}
                label={<FormattedMessage id={documentType.alias.toLowerCase()} />}
                />
            ))}
          </Box>
        </ModalComponent>
        {selectedDocument !== null
          ?
            <HmyModalFileViewer
              isOpen={openDialogViewer}
              canEdit={project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1}
              file={selectedDocument}
              handleClose={handleCloseModalViewer}
              hanldeClickVisible={hanldeClickVisible}
            >
              {selectedDocument.isImage
                ?
                  <HmyImageViewer 
                    document={selectedDocument}
                  />
                :
                  <HmyPdfViewer 
                    base64String={selectedDocument.url}
                    pdfName={selectedDocument.description ?? selectedDocument.name}
                    zoomManagement={true}
                    rotationManagement={!matches}
                    closeManagement={false}
                    navBarHeight={50}
                    handleClose={handleCloseModalViewer}
                  />
              }
            </HmyModalFileViewer>
          :
            null
        }
        {showButton === true
        ?
          <Fab 
            color="primary" 
            onClick={() => setShowButton(false)}
            className="landmarks-buttons-absolute"
          >
            {paginatorRequest.typeDocument.length > 0 ? <FilterAltIcon fontSize="inherit" /> : <FilterAltOutlinedIcon fontSize="inherit" />}
          </Fab>
        :
          <Box 
            className="landmarks-buttons-absolute-container"
          >
              <Fab 
                color="primary"
                onClick={() => handleClickDocumentTypes()}
                className="landmarks-buttons-icons"
              >
                <DescriptionIcon fontSize="inherit" />
              </Fab>
              <Fab 
                color="primary"
                onClick={() => changeOrderDesc()}
                className="landmarks-buttons-icons"
              >
                {paginatorRequest.orderDesc ? <SouthIcon fontSize="inherit" /> : <NorthIcon fontSize="inherit" />}
              </Fab>
              <Fab 
                color="primary"
                onClick={() => setShowButton(true)}
                className="landmarks-buttons-icons"
              >
                <CloseIcon fontSize="inherit" />
              </Fab>
          </Box>
        }
      </Box>
  )
}

export default Documents;