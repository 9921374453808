import './hmy-input-password.scss';
import { FC, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ValidatorPassword from './validator-password';

type HmyInputPasswordProps = {
    withValidation: boolean;
    label: string;
    userName: string;
    email: string;
    password: string;
    setPassword: (value: string) => void;
    setIsValid: (value: boolean) => void;
};

const HmyInputPassword: FC<HmyInputPasswordProps> = ({ withValidation, label, userName, email, password, setPassword, setIsValid }) => {

    const matches = useMediaQuery('(max-width: 768px)');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
      };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            className="hmy-input-password-container"
        >
            <TextField
                type={showPassword ? 'text' : 'password'}
                color="primary"
                value={password}
                fullWidth
                label={<FormattedMessage id={label} />}
                variant="standard"
                onChange={handleChange}
                className="inputPassword"
                size={matches ? "small" : "medium"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment 
                            position="end"
                            onClick={handleClickShowPassword}
                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                color="primary"
                            >
                                {showPassword ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {withValidation &&
                <ValidatorPassword 
                    password={password}
                    userName={userName}
                    email={email}
                    setIsValid={setIsValid}
                />
            }
        </Box>
    )
}

export { HmyInputPassword };