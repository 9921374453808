import './landing-welcome.scss';
import { FC } from 'react';
import { Box, Link } from '@mui/material';
import FlechasVideo from '../../../assets/videos/flechasLanding.mp4';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage } from 'react-intl';

const LandingWelcome: FC = () => {
  return (
    <Box 
        className="landing-container-video"
    >
        <Box
            className="landing-welcome-video"
        >
            <video src={FlechasVideo} autoPlay muted />
            <Box
                className="landing-welcome-text"
            >
                <p className="imageText">
                    <FormattedMessage id="main.welcome" />
                </p>
            </Box>
            <Link
                href="#landing-label-effect-container"
            >
                <Box
                    className="landing-welcome-next"
                >
                    
                        <KeyboardArrowDownIcon 
                            fontSize="inherit" 
                            sx={{cursor: "pointer"}} 
                        />
                                
                </Box>
            </Link> 
        </Box>
    </Box>
  )
}

export default LandingWelcome;