import './landing-label-effect.scss';
import { FC } from 'react';
import { Box, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const LandingLabelEffect: FC = () => {
  return (
    <Box
        id="landing-label-effect-container"
        className="landing-label-effect-container"
    >
        <Box
            className="landing-label-effect"
        >
            <p>
                <FormattedMessage id="main.description" />
            </p>
            
        </Box>
        <Box
            className="landing-label-effect-moving-container"
        >
            <Box
                className="landing-label-effect-moving"
            >
                <p>
                    <FormattedMessage id="inspirationGallery" />
                </p>
                <p className="landing-label-effect-moving-bullet">&#8226;</p>
                <p>
                    <FormattedMessage id="trendsObservatory" />
                </p>
                <p className="landing-label-effect-moving-bullet">&#8226;</p>
                <p>
                    MateriaLab
                </p>
                <p className="landing-label-effect-moving-bullet">&#8226;</p>
                <p>
                    Showroom
                </p>
            </Box>
        </Box>
        <Link
            href="#landing-images-container"
        >
            <Box
                className="landing-label-effect-next"
            >
                
                    <KeyboardArrowDownIcon 
                        fontSize="inherit" 
                        sx={{cursor: "pointer"}} 
                    />
                            
            </Box>
        </Link> 
    </Box>
  )
}

export default LandingLabelEffect;