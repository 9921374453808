import './string-multiple.scss'
import { FC, useState } from 'react'
import { Box, Dialog, DialogTitle, IconButton, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { adjustString } from 'src/utilities';

type StringMultipleProps = {
    elements: string[];
    cutString: number;//0 no se ajusta el string
};

const StringMultiple: FC<StringMultipleProps> = ({elements, cutString}) => {

    const [openModel, setOpenModel] = useState<boolean>(false);

   const handleClick = () => {
    if(elements.length > 1){
      setOpenModel(true);
    }
   }

   interface SimpleDialogProps {
    open: boolean;
    onClose: (value: boolean) => void;
  }
  
  const SimpleDialog = (props: SimpleDialogProps) => {
    const { onClose, open } = props;
  
    const handleClose = () => {
      onClose(false);
    };
  
    return (
      <Dialog 
        onClose={handleClose} 
        open={open}
      >
        <DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <List sx={{padding: "30px"}}>
          {elements.map((element) => (
            <ListItem key={element}>
              <ListItemText primary={element} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

  return (
    <Box>
        <span
            onClick={() => handleClick()}
            className={elements.length > 1 ? "multiple-string" : ""}
        >
          {cutString > 0 ? adjustString(elements[0], cutString) : elements[0]}
        </span>
        <SimpleDialog
        open={openModel}
        onClose={setOpenModel}
      />
    </Box>   
  )
}

export { StringMultiple };