import { Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { HmyInfiniteScroll } from 'src/components';
import { TableHeaderElement } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { currentUserSelector } from 'src/redux/states';
import { HmyTableIncidences } from './hmy-table-incidences';
import HmyTableRowIncidences from './hmy-table-row-incidences';
import HmyCardIncidences from './hmy-card-incidences';

type OrderIncidencesProps = {
  orderData: BasicOrder;
};
const OrderIncidences: FC<OrderIncidencesProps> = ({ orderData }) => {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  const incidenceIdText = intl.formatMessage({ id: 'incidenceId' });
  const incidenceDateText = intl.formatMessage({ id: 'date' });
  const incidenceStateText = intl.formatMessage({ id: 'status' });
  const incidenceOptionsText = intl.formatMessage({ id: 'options' });
  const matches = useMediaQuery('(max-width: 768px)');

  const headerELementList: TableHeaderElement[] = [
    {
      element: incidenceIdText,
      width: 30 - (currentUser.hmy ? 4 : 0),
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: incidenceDateText,
      width: 30 - (currentUser.hmy ? 4 : 0),
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: incidenceStateText,
      width: 40 - (currentUser.hmy ? 4 : 0),
      withBorder: currentUser.hmy,
      align: 'center',
      onClick: null,
    },
  ];

  if (currentUser.hmy) {
    headerELementList.push({
      element: incidenceOptionsText,
      width: 12,
      withBorder: false,
      align: 'center',
      onClick: null,
    });
  }

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="orderedIncidences" />
      </Typography>
      <HmyTableIncidences headerELementList={headerELementList}>
        {orderData.incidences?.map((incidence, index) => (
          <HmyTableRowIncidences // version pc
            key={index}
            isFirst={index === 0}
            isLast={orderData.lines ? index === orderData.lines.length - 1 : true}
            incidence={incidence}
            isDeletable={currentUser.hmy}
          />
        ))}
      </HmyTableIncidences>
      {matches ? (
        <HmyInfiniteScroll
          currentPage={1}
          pages={0}
          dataLength={orderData.lines?.length ?? 0}
          handleChangePage={() => {}}
        >
          {orderData.incidences?.map((incidence, index) => (
            <HmyCardIncidences key={index} incidence={incidence} isDeletable={currentUser.hmy} /> //version movile
          ))}
        </HmyInfiniteScroll>
      ) : null}
    </>
  );
};

export default OrderIncidences;
