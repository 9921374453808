export const formatPrice = (price: number) => {
  if (typeof price === 'number') {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
    }).format(price);
  } else {
    return price;
  }
};
