import './hmy-modal-basic.scss';
import { FC, ReactNode } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type HmyModalBasicProps = {
    children: ReactNode;
    isOpen: boolean;
    handleClose: () => void;
  };

const HmyModalBasic: FC<HmyModalBasicProps> = ({children, isOpen, handleClose}) => {
  return (
    <Dialog fullScreen open={isOpen} onClose={() => handleClose()} sx={{borderRadius: "0px !important"}}>
        <Box
            className="hmy-modal-basic-header"
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="hmy-modal-basic-header-button"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      <Box
        className="hmy-modal-basic-container"
      >
        {children}
      </Box> 
    </Dialog>
  )
}

export { HmyModalBasic };