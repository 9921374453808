import './images.scss';
import { FC, useEffect, useState } from 'react'
import { BlobDocument, CompleteProject, GeneralCollection, HmyModalDocumentBase } from 'src/models';
import { Box, Switch, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFetchAndLoad } from 'src/hooks';
import { currentUserSelector } from 'src/redux/states';
import { useSearchParams } from 'react-router-dom';
import { blobServices, DocumentModelService } from 'src/services';
import { GetProjectImagesDto } from 'src/dtos';
import { EDIT_PROJECTFILES, EDIT_PROJECTIMAGES } from 'src/permissions/permissions';
import { blobDocumentAdapter, modalDocumentAdapter } from 'src/adapters';
import { HmyBtn, HmyImageListViewer, HmyModalFileViewer, LoadComponent } from 'src/components';
import { IsCustomer } from 'src/utilities';
import { FormattedMessage } from 'react-intl';
import { HmyIconVisibility, HmyIconLaunch } from 'src/components/hmy-icons';

type ImagesProps = {
  project: CompleteProject;
};

const Images: FC<ImagesProps> = ({project}) => {

  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [searchParams] = useSearchParams();
  const [imageList, setImageList] = useState<GeneralCollection<BlobDocument>>();
  const [rows, setRows] = useState<[BlobDocument[]]>();
  //const [containerToken, setContainerToken]= useState<string>('');
  const [openDialog, setOpenDialog]= useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<HmyModalDocumentBase | null>(null);
  const [paginatorRequest, setPaginatorRequest] = useState<GetProjectImagesDto>({
    fileId: "",
    projectId: project.projectId,
    page: 0,
    take: 12,
    seeHidden: project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1
});

const convertArrayToBidimensional = (list : BlobDocument[], elementsPerSubArray: number) => {
  let newArray: [BlobDocument[]] = [[]], i, k;  
  for (i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
          k++;
          newArray[k] = [];
      }

      newArray[k].push(list[i]);
  }
  return newArray;
}

  const getImages = async () => await callEndpoint(blobServices.getProjectImgBlob(paginatorRequest));
  //const getContainerToken = async () => await callEndpoint(blobServices.getContainerToken());
  const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));
  const doVisible = async (documentId: string) => await callEndpoint(blobServices.doVisible(documentId));

  const adaptImages = (data: GeneralCollection<DocumentModelService>) => {
    
    let listImages: BlobDocument[] = [];
    let newImages = data.items.map((x: DocumentModelService) => { return blobDocumentAdapter(x) });
    
    if(paginatorRequest.page !== 1){
      listImages = (imageList?.items ?? []).concat(newImages);
    }else{
      listImages = newImages;
    }

    setImageList({
      items: listImages,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const handleChangeHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ... paginatorRequest,
      seeHidden: event.target.checked,
      page: 1
    });
  };

   const handleChangePage = () => {
    setPaginatorRequest({
      ... paginatorRequest,
      page: paginatorRequest.page + 1
    });
  }

  const doVisibleBlob = (fileId: string) => {
    if(imageList){
      let newImageList: BlobDocument[] = [...imageList.items];
      const index = newImageList.findIndex(x => x.blobId === fileId);
      newImageList[index].visible = !newImageList[index].visible;
      setImageList({
        ... imageList,
        items: newImageList
      });
    }
  }

  const hanldeClickVisible = async (fileId: string) => {
    try {
        await doVisible(fileId);
        doVisibleBlob(fileId);
        if(selectedDocument !== null){
          setSelectedDocument({
              ...selectedDocument,
              isVisible: !selectedDocument.isVisible
          });
        }
      } 
      catch (error) 
      {
      }
  }

  const handleSelectedDocumentToSee = async (documentId : string) => {
    try {
      const response = await getDocument(documentId);
      setSelectedDocument(modalDocumentAdapter(response.data));
      setOpenDialog(true);
    } catch (error) {
      
    }
  }

  const handleCloseModal = () => {
    setOpenDialog(false);
    setSelectedDocument(null);
  }

  const handleClickNext = async () => {
    if(imageList && selectedDocument !== null){
      const index = imageList.items.findIndex(x => x.blobId === selectedDocument.blobId);
      try {
        const response = await getDocument(imageList.items[index + 1].blobId);
        setSelectedDocument(modalDocumentAdapter(response.data));
      } catch (error) {   
      }
    }   
  }

  const handleClickPrevious = async () => {
    if(imageList && selectedDocument !== null){
      const index = imageList.items.findIndex(x => x.blobId === selectedDocument.blobId);
      try {
        const response = await getDocument(imageList.items[index - 1].blobId);
        setSelectedDocument(modalDocumentAdapter(response.data));
      } catch (error) {     
      }
    } 
  }

  useEffect(() => {
    const documentId = searchParams.get("documentId");
    if(documentId){
      setPaginatorRequest({
        ... paginatorRequest,
        fileId: documentId,
        page: 1
      });
    }else{
      setPaginatorRequest({
        ... paginatorRequest,
        page: 1
      });
    }
  },[])

  useEffect(() => {
    if(paginatorRequest.page > 0){
      getImages()
        .then(response => adaptImages(response.data))
        .catch(error => {})
    }
  },[paginatorRequest])

  useEffect(() => {
    if(imageList && imageList.items?.length > 0){

        setRows(convertArrayToBidimensional(imageList.items, matches ? 3 : 4));
    }else{
        setRows(undefined);
    }                      
    // eslint-disable-next-line
  },[imageList]);

  //TODO: utilizar en el futuro para privatizar contenedores de thumbs
  //useAsync(getContainerToken, setContainerToken, () => { }, () => { }, []);

  return (
    <Box
      className="images-container"
    >
      {IsCustomer(currentUser) ? null 
        :
        <Box 
          className="images-seeHidden"
        >
          
          <span
            className="images-seeHidden-text" 
          >
            <FormattedMessage id="seeHidden"/>
          </span>
          <Switch
            checked={paginatorRequest.seeHidden}
            onChange={handleChangeHidden}
            color="secondary"
            size="small"
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      }

      {loading 
        ?
          <LoadComponent />
        :
          null
      }
      <>
        {rows && rows.map((row, index) => (
          <Box
            key={`imagesRow-${index}`}
            className="project-image-line"
          >
            {row.map((imageBlob, index) => (
              <Box
                key={`${imageBlob.blobId}`}
                className={matches ? "project-image-small" : "project-image-image"}
                style={{backgroundImage: `url(${imageBlob.urlThumbnail})`,backgroundSize: "cover",backgroundRepeat: "no-repeat"}}
              >
                {!imageBlob.visible
                ?
                  <Box
                    className="project-image-card-overlay"
                  >
                  </Box>
                  :
                  null
                }
                {project.permissions.findIndex(x => x === EDIT_PROJECTIMAGES) > -1
                  ?
                    <Box
                      className="project-image-visibility"
                      onClick={() => hanldeClickVisible(imageBlob.blobId)}
                    >
                      <HmyIconVisibility 
                          fontSize="small"
                          isVisible={imageBlob.visible}
                      />
                    </Box>
                  :
                    null
                }
                <Box
                  className="project-image-launch"
                  onClick={() => handleSelectedDocumentToSee(imageBlob.blobId)}
                >
                  <HmyIconLaunch 
                      fontSize="small"
                  />
                </Box>  
              </Box>
            ))}
          </Box>
        ))}

        {imageList && imageList?.currentPage < imageList?.pages
          ?
            <Box
              className="project-image-seeMore"
            >
              <HmyBtn 
                title="seeMore" 
                width={0}
                fullwith={true}
                color="secondary"
                handleClick={handleChangePage}
              /> 
            </Box>           
          :
            null
        }

        {selectedDocument !== null
        ?
          <HmyModalFileViewer
            isOpen={openDialog}
            canEdit={project.permissions.findIndex(x => x === EDIT_PROJECTIMAGES) > -1}
            file={selectedDocument}
            handleClose={handleCloseModal}
            hanldeClickVisible={hanldeClickVisible}
          >
            <HmyImageListViewer 
              document={selectedDocument}
              hasNext={imageList!.items.findIndex(x => x.blobId === selectedDocument.blobId) < imageList!.items.length - 1}
              hasPrevious={imageList!.items.findIndex(x => x.blobId === selectedDocument.blobId) > 0}
              handleClickNext={handleClickNext}
              handleClickPrevious={handleClickPrevious}
            />
          </HmyModalFileViewer>
        :
          null
        }
      </>
    </Box>
  )
}

export default Images;