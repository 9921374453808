import { combineReducers } from '@reduxjs/toolkit'
import SessionReducer from './states/session.slice';
import CurrentUserReducer from './states/current-user.slice';
import MessageReducer from './states/message.slice';

const rootReducer = combineReducers({
    session: SessionReducer,
    currentUser: CurrentUserReducer,
    message: MessageReducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;