import './grouping-files.scss';
import { FC, useState } from 'react'
import { CompleteGrouping, DocumentProps, ErrorMessage, GeneralCollection, SuccessMessage, UserGroupingFile } from 'src/models';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Fab, Link, useMediaQuery } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { setMessage } from 'src/redux/states';
import { LoadComponent } from 'src/components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { DELETE_GROUPINGFILES } from 'src/permissions/permissions';
import { blobServices, DocumentPropsModelService, groupingServices, UserGroupingFileModelService } from 'src/services';
import { GetGroupingFilesDto } from 'src/dtos';
import { documentPropsAdapter, groupingFileAdapter } from 'src/adapters';
import { isImageExtension } from 'src/utilities';
import GroupingFilesList from './grouping-files-list';
import GroupingFileAdd from './grouping-file-add';
import GroupingFileEdit from './grouping-file-edit';

type GroupingFileScreenType = "AddFile" | "EditFile" | "ListFile";

type GroupingFilesProps = {
    grouping: CompleteGrouping;
};

const GroupingFiles: FC<GroupingFilesProps> = ({ grouping }) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width: 768px)');
    const { loading, callEndpoint } = useFetchAndLoad();
    const [currentScreen, setCurrentScreen] = useState<GroupingFileScreenType>("ListFile");
    const [documentTypes, setDocumentTypes] = useState<string[]>([]);
    const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
    const [editFile, setEditFile] = useState<string | null>(null);
    const [groupingFiles, setGroupingFiles] = useState<GeneralCollection<UserGroupingFile>>();
    const [paginatorRequest, setPaginatorRequest] = useState<GetGroupingFilesDto>({
      userGroupingId: grouping.id,
      page: 1,
      take: matches ? 10 : 5,
      columnOrder: "Created",
      typeOrder: "DESC",
      filter: "",
      kinds: [],
      categories: [],
      tags: [],
      typeDocuments: []
    });

    const images = intl.formatMessage({id: "image.many"});
  
    const getFiles = async () => await callEndpoint(groupingServices.getFiles(paginatorRequest));
    const deleteGroupingFile = async (fileId: string) => await callEndpoint(groupingServices.deleteFile(fileId));
    const getDocumentProps = async () => await callEndpoint(blobServices.getDocumentProps());
  
    const adaptGroupingFiles = (data: GeneralCollection<UserGroupingFileModelService>) => {
  
      let listFiles: UserGroupingFile[] = [];
      let newFiles = data.items.map((x: UserGroupingFileModelService) => { return groupingFileAdapter(x) });
      if(matches && paginatorRequest.page !== 1){
        listFiles = (groupingFiles?.items ?? []).concat(newFiles);
      }else{
        listFiles = newFiles;
      }
  
      setGroupingFiles({
        items: listFiles,
        total: data.total,
        currentPage: data.currentPage,
        pages: data.pages
      });
  
    };

    const adaptDocumentProps = (data: DocumentPropsModelService) => {
    
      let docProps = documentPropsAdapter(data);
      setDocumentProperties(docProps);

      let arrExtensions = docProps.extensions.filter(x => !isImageExtension(x));
      arrExtensions.push(images);
      setDocumentTypes(arrExtensions);
    };

    const handleGoToAddGroupingFile = () => {
      setCurrentScreen("AddFile");
    }

    const handleGoToEditGroupingFile = (value: string) => {
      setEditFile(value);
      setCurrentScreen("EditFile");
    }

    const handleGoToGroupingFileList = async () => {
      setCurrentScreen("ListFile");
      setEditFile(null);
      try {
        const response = await getFiles();
        adaptGroupingFiles(response.data);
      } catch (error) {
      }
    }

    const handleDeleteGroupingFile = async (fileId: string) => {
      try {
        await deleteGroupingFile(fileId);
        handleGoToGroupingFileList();
        dispatch(setMessage(SuccessMessage("success.fileDeletedSuccessfully", true)));
      } catch (error) {
        dispatch(setMessage(ErrorMessage("error.errorDeletingFile", true)))
      }
    }
  
    useAsync(getFiles, adaptGroupingFiles, () => { }, () => { }, [paginatorRequest]);

    useAsync(getDocumentProps, adaptDocumentProps, () => { }, () => { }, []);

    return (
      <Box 
        className="grouping-file-container"
      >
        {loading ?<LoadComponent /> : null }
        <Box
          className="grouping-file-main"
        >
          {currentScreen === "AddFile"
            ?
            documentProperties && <GroupingFileAdd 
                userGroupingId={grouping.id}
                documentOptions={documentProperties}
                goBack={handleGoToGroupingFileList}
              />
            :
              (currentScreen === "EditFile" && editFile !== null
              ?
                <GroupingFileEdit 
                  userGroupingId={grouping.id}
                  fileId={editFile}
                  canDelete={grouping.permissions.includes(DELETE_GROUPINGFILES)}
                  goBack={handleGoToGroupingFileList}
                  deleteGroupingFile={handleDeleteGroupingFile}
                />
              :
                groupingFiles &&
                <GroupingFilesList 
                  grouping={grouping}
                  documentTypes={documentTypes}
                  groupingFiles={groupingFiles}
                  paginatorRequest={paginatorRequest}
                  setPaginatorRequest={setPaginatorRequest}
                  handleEditFile={handleGoToEditGroupingFile}
                  handleAddGroupingFile={handleGoToAddGroupingFile}
                /> 
              )
          }
        </Box>
        <Fab className="grouping-file-btn-up">
          <Link
            href="#"       
          >
            <ArrowUpwardIcon sx={{fontSize: '40px'}} />
          </Link>    
        </Fab> 
      </Box>
    )
}

export default GroupingFiles;