import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { completeProjectAdapter } from 'src/adapters';
import { HmyBtn, LoadComponent } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { useFetchAndLoad } from 'src/hooks';
import { useUpdateIncidenceStatus } from 'src/hooks/use-incidence-states.hook';
import { useCreateComment, useIncidence } from 'src/hooks/use-incidence.hook';
import { CompleteProject, ErrorMessage, SuccessMessage } from 'src/models';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { projectServices } from 'src/services';
import { IsAdmin } from 'src/utilities';
import { IncidenceStatusText } from '../order/incidences/order-incidences/incidence-status-text';
import IncidenceComment from './incidence-comment';
import IncidenceStatus from './incidence-status';
import './incidence.scss';

const Incidence: FC = () => {
  const [newComment, setNewComment] = useState<string>('');
  const [isEditable, setIsEditable] = useState<boolean>();
  const [project, setProject] = useState<CompleteProject>();
  const currentUser = useSelector(currentUserSelector);

  const { incidenceid } = useParams();
  const { mutationCreateComment } = useCreateComment();
  const { mutationUpdateIncidenceStatus } = useUpdateIncidenceStatus(incidenceid);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { callEndpoint } = useFetchAndLoad();
  const matches = useMediaQuery('(max-width: 768px)');

  const { data: incidence, isLoading } = useIncidence(incidenceid ?? '');
  const getProject = async (projectid: string) => await callEndpoint(projectServices.getProject(projectid));

  useEffect(() => {
    if (incidence) {
      getProject(incidence.projectId).then((response) => {
        if (response.status === 200) {
          setProject(completeProjectAdapter(response.data));
        }
      });
    }
  }, [incidence]);

  // Set order editable to select profiles (Commercial, Admin and hmy user)
  useEffect(() => {
    if (currentUser && project) {
      const projectUsersEditable = project.users.filter((user) => user.profileName.toLowerCase() === 'commercial');
      setIsEditable(
        projectUsersEditable.findIndex((user) => user.id === currentUser.id) !== -1 ||
          IsAdmin(currentUser) ||
          currentUser.hmy
      );
    }
  }, [currentUser, project]);

  const handleSendComment = () => {
    if (incidenceid && newComment.trim() !== '') {
      mutationCreateComment.mutate(
        { incidenceId: incidenceid, comment: newComment },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CreateComment', true)));
            setNewComment('');
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CreateComment', true)));
          },
        }
      );
    }
  };

  const handleUpdateIncidenceStatus = (incidenceStatusId: string) => {
    if (incidenceid && incidenceStatusId && isEditable) {
      mutationUpdateIncidenceStatus.mutate({ incidenceId: incidenceid, incidenceStatusId });
    }
  };

  if (isLoading) {
    return <LoadComponent />;
  }

  if (!incidence) {
    return null;
  }

  return (
    <Box className="incidence-container">
      <Box style={{ display: 'flex', justifyContent: 'space-between', columnGap: '20px', width: '85%' }}>
        <Box className="incidence-title" onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize="inherit" />
          <Typography className="incidence-title-text">
            {matches ? null : <FormattedMessage id="incidence" />}
            <span className="incidence-title-text-incidence">{incidence.number}</span>
          </Typography>
        </Box>
      </Box>
      <Box className="incidence-body">
        <Box className="incidence-main">
          <span>
            <FormattedMessage id="orderIncidenceSubtitle" />
          </span>
          <div className="incidence-description">{incidence.description}</div>

          <span>
            <FormattedMessage id="comments" />
          </span>
          <div className="incidence-comments">
            <div className="incidence-comments-add">
              <HmyInputTextarea value={newComment} setValue={setNewComment} title="addComment" maxLength={500} />
              <div className="incidence-comments-add-buttons">
                <HmyBtn
                  color="primary"
                  title="btn.save"
                  fullwith={false}
                  filled
                  disabled={newComment.trim() === ''}
                  width={200}
                  handleClick={handleSendComment}
                />
                <HmyBtn
                  color="secondary"
                  title="btn.cancel"
                  fullwith={false}
                  disabled={newComment === ''}
                  width={200}
                  handleClick={() => setNewComment('')}
                />
              </div>
            </div>
            {incidence.comments.map((comment, index) => (
              <IncidenceComment data={comment} odd={index % 2 === 0} />
            ))}
          </div>
        </Box>
        <Box className="incidence-info">
          <span>
            <FormattedMessage id="status" />
          </span>
          {isEditable ? (
            <IncidenceStatus
              handleUpdateIncidenceStatus={handleUpdateIncidenceStatus}
              statusNumber={incidence ? parseInt(incidence.statusNumber) : 0}
            />
          ) : (
            <IncidenceStatusText statusNumber={incidence.statusNumber} />
          )}
          {incidence.lines.length > 0 && (
            <>
              <span className="incidence-modal-section-text">
                <FormattedMessage id="orderIncidenceArticles" />
              </span>
              <List className="incidence-articles-list">
                {incidence.lines.map(({ saleOrderLineId, name }) => (
                  <ListItem key={saleOrderLineId}>
                    <ListItemIcon sx={{ minWidth: '20px', height: '20px', width: '20px' }}>
                      <CheckBoxOutlinedIcon
                        sx={{ height: '20px', width: '20px', '& .MuiSvgIcon-root': { fontSize: '20px' } }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={name} sx={{ '& .MuiListItemText-primary': { fontSize: '16px' } }} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Incidence;
