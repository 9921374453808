import './project.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ProjectTopBar from './project-top-bar';
import { useParams, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { CompleteProject } from 'src/models';
import { CompleteProjectModelService, projectServices } from 'src/services';
import { completeProjectAdapter } from 'src/adapters';
import {
  LoadComponent,
  projectSubRoutes,
  SUBROUTE_IMAGES,
  SUBROUTE_INBOX,
  SUBROUTE_LANDMARKS,
  SUBROUTE_ORDERS,
  SUBROUTE_SETTINGS_PROJECT,
  SUBROUTE_VSTORE,
} from 'src/components';
import Inbox from './inbox';
import Documents from './documents';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormattedMessage } from 'react-intl';
import { NotFound } from '../not-found';
import Drawing from './drawing';
import Images from './images';
import Settings from './settings';
import {
  READ_ORDERS,
  READ_PROJECTFILES,
  READ_PROJECTIMAGES,
  READ_PROJECTINBOX,
  READ_PROJECTMANAGEMENT,
  READ_PROJECTVSTORE,
} from 'src/permissions/permissions';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Orders } from '../orders';

const Project: FC = () => {
  const { projectid } = useParams();
  const matches = useMediaQuery('(max-width: 768px)');
  const { loading, callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const [project, setProject] = useState<CompleteProject>();
  const [optionSelected, setOptionSelected] = useState<projectSubRoutes>(SUBROUTE_INBOX);

  const getProject = async () => await callEndpoint(projectServices.getProject(projectid ?? ''));

  const adaptProject = (data: CompleteProjectModelService) => {
    let newProjects = completeProjectAdapter(data);

    setProject(newProjects);

    trackPageView({
      documentTitle: `Project - ${newProjects.projectName ?? newProjects.description}`,
    });
  };

  const handleClickElement = (value: projectSubRoutes) => {
    navigate(value);
  };

  /**
   * Marca el elemento seleccionado en el menu
   */
  const manageActiveElement = () => {
    const urlLocation = location.pathname.split(project!.projectId);
    let elementActive = optionSelected;
    switch (urlLocation[1].slice(1)) {
      case SUBROUTE_INBOX:
        elementActive = SUBROUTE_INBOX;
        break;
      case SUBROUTE_LANDMARKS:
        elementActive = SUBROUTE_LANDMARKS;
        break;
      case SUBROUTE_IMAGES:
        elementActive = SUBROUTE_IMAGES;
        break;
      case SUBROUTE_VSTORE:
        elementActive = SUBROUTE_VSTORE;
        break;
      case SUBROUTE_SETTINGS_PROJECT:
        elementActive = SUBROUTE_SETTINGS_PROJECT;
        break;
      case SUBROUTE_ORDERS:
        elementActive = SUBROUTE_ORDERS;
        break;
      default:
        elementActive = SUBROUTE_LANDMARKS;
        break;
    }

    if (elementActive !== optionSelected) {
      setOptionSelected(elementActive);
    }
  };

  useEffect(() => {
    if (project) {
      manageActiveElement();
    }
  }, [location.pathname, project]);

  const goToImages = (fileId: string) => {
    navigate(`${SUBROUTE_IMAGES}?documentId=${fileId}`);
  };

  const reloadProject = () => {
    getProject().then((response) => {
      if (response.status === 200) {
        adaptProject(response.data);
      }
    });
  };

  useAsync(
    getProject,
    adaptProject,
    () => {},
    () => {},
    [projectid]
  );

  return (
    <Box className="project-container">
      {loading ? (
        <LoadComponent />
      ) : project !== undefined ? (
        <Box className="project-main">
          <Box className="project-title" onClick={() => navigate(-1)}>
            <ChevronLeftIcon fontSize="inherit" />
            <Typography className="project-title-text">
              {matches ? null : <FormattedMessage id="project" />}
              <span className="project-title-text-project">{project.projectName ?? project.description}</span>
            </Typography>
          </Box>
          <ProjectTopBar project={project} selected={optionSelected} handleClickElement={handleClickElement} />
          <Box className="content-box-projects">
            <Routes>
              {!project.permissions.includes(READ_PROJECTFILES) ? null : (
                <Route index element={<Documents project={project} goToImages={goToImages} />} />
              )}
              {!project.permissions.includes(READ_PROJECTINBOX) ? null : (
                <Route path={SUBROUTE_INBOX} element={<Inbox project={project} />} />
              )}
              {!project.permissions.includes(READ_PROJECTFILES) ? null : (
                <Route path={SUBROUTE_LANDMARKS} element={<Documents project={project} goToImages={goToImages} />} />
              )}
              {!project.permissions.includes(READ_PROJECTIMAGES) ? null : (
                <Route path={SUBROUTE_IMAGES} element={<Images project={project} />} />
              )}
              {!project.permissions.includes(READ_PROJECTVSTORE) ? null : (
                <Route path={SUBROUTE_VSTORE} element={<Drawing project={project} />} />
              )}
              {!project.permissions.includes(READ_PROJECTMANAGEMENT) ? null : (
                <Route
                  path={SUBROUTE_SETTINGS_PROJECT}
                  element={<Settings project={project} reloadProject={reloadProject} />}
                />
              )}
              {/* TODO: ADD READ_ORDERS */}
              {!project.permissions.includes(READ_PROJECTIMAGES) ? null : (
                <Route path={SUBROUTE_ORDERS} element={<Orders project={project} />} />
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default Project;
