import './grouping-top-bar.scss';
import { FC } from 'react'
import { groupingSubRoutes, SUBROUTE_GROUPING_FILES, SUBROUTE_GROUPING_INFORMATION } from 'src/components';
import { useIntl } from 'react-intl';
import { Box, useMediaQuery } from '@mui/material';
import { READ_GROUPING, READ_GROUPINGFILES } from 'src/permissions/permissions';
import filesIcon from '../../../assets/images/archivos.png';
import infoIcon from '../../../assets/images/info.png';
import { CompleteGrouping } from 'src/models';

type GroupingTopBarProps = {
    grouping: CompleteGrouping;
    selected: groupingSubRoutes;
    handleClickElement: (value: groupingSubRoutes) => void;
};

const GroupingTopBar: FC<GroupingTopBarProps> = ({ grouping, selected, handleClickElement }) => {

    const intl = useIntl();
    const matches = useMediaQuery('(max-width: 768px)');
    
    const filesText = intl.formatMessage({id: "files"});
    const informationText = intl.formatMessage({id: "info"});

    return (
        <Box className={`grouping-navigation`}>
      
            {grouping.permissions.includes(READ_GROUPINGFILES) && (
            <Box 
                className={(selected === SUBROUTE_GROUPING_FILES)  ? "grouping-navigation-element active" : "grouping-navigation-element"}
                onClick={() => handleClickElement(SUBROUTE_GROUPING_FILES)}
            >
                <Box className='grouping-navigation-element-icon'>
                    <img src={filesIcon} alt="icon-inbox"/>
                    {matches ? null : <Box>{filesText.toUpperCase()}</Box>}
                </Box>
            </Box>
            )}

            {grouping.permissions.includes(READ_GROUPING) && (
            <Box 
                className={selected === SUBROUTE_GROUPING_INFORMATION ? "grouping-navigation-element active" : "grouping-navigation-element"}
                onClick={() => handleClickElement(SUBROUTE_GROUPING_INFORMATION)}
            >
                <Box className='grouping-navigation-element-icon'>
                    <img src={infoIcon} alt="icon-inbox"/>
                    {matches ? null : <Box>{informationText.toUpperCase()}</Box>}
                </Box>
            </Box>
            )}

        </Box>
    )
}

export default GroupingTopBar;