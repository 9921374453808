import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { Message } from 'src/models';

export interface messageState extends Message {
}

const initialState: messageState = {
  severity: undefined,
  message: "",
  isOpen: false
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, { payload }: PayloadAction<Message>) => state = { ...payload },
    setOpen: (state, { payload }: PayloadAction<boolean>) => { state.isOpen = payload }
  },
});

export const { setMessage, setOpen } = messageSlice.actions;
export const messageSelector = (state: RootState) => state.message;
export default messageSlice.reducer;