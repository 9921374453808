// FILES MESSAGES
import messages_es from './es.json';
import messages_en from './en.json';
import messages_fr from './fr.json';
import messages_pt from './pt.json';
import messages_tr from './tr.json';

    // TRANSLATIONS MESSAGES
 const  messages = {
    'es': messages_es,
    'en': messages_en,
    'fr': messages_fr,
    'pt': messages_pt,
    'tr': messages_tr
}

const getMessages = (language: string) => {
    switch(language){
        case 'es':
            return messages.es;
        case 'en':
            return messages.en;
        case 'fr':
            return messages.fr;
        case 'pt':
            return messages.pt;
        case 'tr':
            return messages.tr;
        default:
            return messages.es;
    }
}

export  default getMessages;