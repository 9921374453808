import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { CurrentUser } from "src/models";
import store from "src/redux/store";

const ability = new Ability();

type Actions = 'ADD' | 'READ' | 'EDIT' | 'DELETE';

export type AppAbilityType = Ability<[Actions, string]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

const CAN = (perm: string) => {

  let checkPerm = perm.split("_");
  return ability.can(checkPerm[0], checkPerm[1]);
};

store.subscribe(() => {
    let auth = store.getState().currentUser;
    ability.update(defineRulesFor(auth));
});

const defineRulesFor = (auth: CurrentUser) => {
    const permissions = auth.permissions;
    const { can, rules } = new AbilityBuilder(AppAbility);
  
    // This logic depends on how the
    // server sends you the permissions array
    if (permissions) {
      permissions.forEach((p) => {
        let per = p.split("_");
        can(per[0] as Actions, per[1]);
      });
    }
  
    return rules;
};

export default CAN;