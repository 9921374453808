import './no-grouping-files.scss';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ADD_GROUPINGFILES } from 'src/permissions/permissions';
import { CompleteGrouping } from 'src/models';

type NoGroupingFilesProps = {
    grouping: CompleteGrouping;
}

const NoGroupingFiles: FC<NoGroupingFilesProps> = ({ grouping }) => {

    return (
        <Box
            className="no-groupings-container"
        >
            <Box
                className="no-groupings-text"
            >
                
                {grouping.permissions.includes(ADD_GROUPINGFILES) 
                ?
                    <Typography className="text-title" ><FormattedMessage id="userGroupingNoDocsAdd"/></Typography>
                :
                    <Typography className="text-title" ><FormattedMessage id="userGroupingNoDocs"/></Typography>
                }
            </Box>  
        </Box>
    )
}

export default NoGroupingFiles;