import { TextField, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HmyFormElement, HmyFormSection } from 'src/components';
import { CreateGroupingFileProps } from 'src/dtos';

export type GroupingNewFileForm = {
  finalName: string;
  nameDescription: string;
  type: string;
  category: string;
  tags: string[];
  date: string;
  description: string;
};

type GroupingFileAddFormFileProps = {
  value: CreateGroupingFileProps;
  setValue: (value: CreateGroupingFileProps) => void;
};

const GroupingFileAddFormFile: FC<GroupingFileAddFormFileProps> = ({ value, setValue }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [nameError, setNameError] = useState<string | null>(null);

  const validateName = (name: string): number => {
    if (name === null || name.trim() === '') {
      setNameError('error.required');
      return 1;
    } else {
      setNameError(null);
      return 0;
    }
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError !== null) {
      validateName(event.target.value);
    }
    setValue({
      ...value,
      nameDescription: event.target.value,
    });
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      description: event.target.value,
    });
  };

  return (
    <HmyFormSection>
      <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="name">
        <TextField
          label=""
          variant="standard"
          value={value.nameDescription}
          onChange={handleChangeName}
          onBlur={(e) => validateName(value.nameDescription)}
          error={nameError !== null}
          helperText={nameError !== null ? <FormattedMessage id={nameError} /> : null}
          fullWidth
          inputProps={{ style: { fontSize: matches ? 16 : 20 } }}
        />
      </HmyFormElement>

      <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="description">
        <TextField
          label=""
          variant="standard"
          type="text"
          value={value.description}
          onChange={handleChangeDescription}
          multiline={true}
          fullWidth
          inputProps={{ style: { fontSize: matches ? 16 : 20 } }}
        />
      </HmyFormElement>
    </HmyFormSection>
  );
};

export default GroupingFileAddFormFile;
