import DeleteIcon from '@mui/icons-material/Delete';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Badge, Box } from '@mui/material';
import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router';
import { ROUTE_PROJECT, StringMultiple } from 'src/components';
import { BasicProject, CurrentUser } from 'src/models';
import { IsAdmin, adjustString } from 'src/utilities';
import './hmy-card-project.scss';

type HmyCardProjectProps = {
  project: BasicProject;
  currentUser: CurrentUser;
  handleClickProject: (projectId: string) => void;
  setSelectedProject: (project: BasicProject) => void;
};

const HmyCardProject: FC<HmyCardProjectProps> = ({ project, currentUser, handleClickProject, setSelectedProject }) => {
  const navigate = useNavigate();
  return (
    <Box
      className={
        project.isClosed
          ? 'card-project-container card-project-isClosed'
          : project.isDeleted
          ? 'card-project-container isDeleted'
          : 'card-project-container'
      }
    >
      <Box className="card-project-number">
        <Box onClick={() => handleClickProject(project.projectId)}>
          {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
        </Box>
        <Box>
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={project.date} />
        </Box>
      </Box>

      <Box onClick={() => handleClickProject(project.projectId)}>
        {adjustString(project.projectName ?? project.description, 25)}
        {project.totalIncidences > 0 && (
          <Badge className="orders-incidence-badge" badgeContent={project.totalIncidences}>
            <FeedbackIcon
              style={{ width: '20px', color: 'grey', cursor: 'pointer' }}
              onClick={() => navigate(ROUTE_PROJECT.replace(':projectid', project.projectId) + '/orders')}
            />
          </Badge>
        )}
      </Box>

      <Box className=".card-project-users">
        {currentUser.hmy && project.users.filter((x) => x.profileName.toLowerCase() === 'customer').length > 0 ? (
          <StringMultiple
            elements={project.users.filter((x) => x.profileName.toLowerCase() === 'customer').map((x) => x.fullName)}
            cutString={0}
          />
        ) : !currentUser.hmy ? (
          project.users.filter((x) => x.profileName.toLowerCase() === 'Commercial')[0]?.fullName
        ) : null}
        <Box>
          {IsAdmin(currentUser) ? null : project.isDeleted ? (
            <SettingsBackupRestoreIcon
              className="delete-icon"
              fontSize="small"
              onClick={() => setSelectedProject(project)}
            />
          ) : (
            <DeleteIcon className="delete-icon" fontSize="small" onClick={() => setSelectedProject(project)} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HmyCardProject;
