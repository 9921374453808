import './grouping-files-list.scss';
import { FC, ReactNode, useState } from 'react';
import { Box, Checkbox, FormControlLabel, useMediaQuery } from '@mui/material';
import { HmyBtn, HmyImageViewer, HmyInfiniteScroll, HmyInputText, HmyModalFileViewer, HmyPdfViewer, HmyTable, ModalComponent } from 'src/components';
import GroupinFileRow from './groupin-file-row';
import GroupingFileCard from './grouping-file-card';
import { CompleteGrouping, GeneralCollection, HmyModalDocumentBase, TableHeaderElement, UserGroupingCategoryBase, UserGroupingFile, UserGroupingTag, UserGroupingTypeBase } from 'src/models';
import { ADD_GROUPINGFILES, EDIT_GROUPINGFILES } from 'src/permissions/permissions';
import { blobServices } from 'src/services';
import { useIntl } from 'react-intl';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useFetchAndLoad } from 'src/hooks';
import { modalDocumentAdapter } from 'src/adapters';
import { GetGroupingFilesDto } from 'src/dtos';
import { imagesArr } from 'src/utilities';
import NoGroupingFiles from '../no-grouping-files';

type ModalType = "Kind" | "Category" | "Tag" | "File";

type GroupingFilesListProps = {
    grouping: CompleteGrouping;
    documentTypes: string[];
    groupingFiles: GeneralCollection<UserGroupingFile>;
    paginatorRequest: GetGroupingFilesDto;
    setPaginatorRequest: (value: GetGroupingFilesDto) => void;
    handleEditFile: (fileId: string) => void;
    handleAddGroupingFile: () => void;
};

const GroupingFilesList: FC<GroupingFilesListProps> = ({ grouping, groupingFiles, documentTypes, paginatorRequest, setPaginatorRequest, handleEditFile, handleAddGroupingFile }) => {

    const intl = useIntl();
    const matches = useMediaQuery('(max-width: 768px)');
    const { loading ,callEndpoint } = useFetchAndLoad();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalTypes, setOpenModalTypes] = useState<boolean>(false);
    const [selectedModalType, setSelectedModalType] = useState<ModalType | null>(null);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>([]);
    const [selectedKinds, setSelectedKinds] = useState<UserGroupingTypeBase[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<UserGroupingCategoryBase[]>([]);
    const [selectedTags, setSelectedTags] = useState<UserGroupingTag[]>([]);
    const [selectedFile, setSelectedFile] = useState<HmyModalDocumentBase | null>(null);
    const [searchText, setSearchText] = useState<string>("");

    const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));

    const dateText = intl.formatMessage({id: "date"});
    const nameText = intl.formatMessage({id: "name"});
    const categoryText = intl.formatMessage({id: "category"});
    const kindText = intl.formatMessage({id: "kind"});
    const tagsText = intl.formatMessage({id: "tag.many"});
    const filesText = intl.formatMessage({id: "files"});
    const editText = intl.formatMessage({id: "edit"});
    const images = intl.formatMessage({id: "image.many"});

    const handleEnterSearch = () => {
      setPaginatorRequest({
        ... paginatorRequest,
        filter: searchText,
        page: 1
      });
    }
  
    const handleChangePage = (page: number) => {
      setPaginatorRequest({
        ... paginatorRequest,
        page: page
      });
    }

    const handleChangeRowsPerPage = (pages: number) => {
      setPaginatorRequest({
        ...paginatorRequest,
        take: pages,
        page: 1
      });
    }
  
    const handleChangeOrder = () => {
      const order: string = paginatorRequest.typeOrder === "DESC" ? "ASC" : "DESC";
      setPaginatorRequest({
        ... paginatorRequest,
        typeOrder: order,
        page: 1
      });
    }
  
    const handleSelectedKind = () => {
      setSelectedModalType("Kind");
      setOpenModalTypes(true);
    }
  
    const handleSelectedCategory = () => {
      setSelectedModalType("Category");
      setOpenModalTypes(true);
    }
  
    const handleSelectedTag = () => {
      setSelectedModalType("Tag");
      setOpenModalTypes(true);
    }
  
    const handleSelectedFile = () => {
      setSelectedModalType("File");
      setOpenModalTypes(true);
    }

    const handleSelectedDocumentToSee = async (documentId : string) => {
        try {
          const response = await getDocument(documentId);
          if(response.data.isImage || response.data.extension === "pdf"){
            setSelectedFile(modalDocumentAdapter(response.data));
            setOpenModal(true);
          }
          else{
            window.open(response.data.url);
          }
        } catch (error) {
          
        }
      }
  
      const handleAcceptModal = () => {
        setPaginatorRequest({
          ... paginatorRequest,
          typeDocuments: selectedDocumentTypes,
          kinds: selectedKinds.map(x => x.id),
          categories: selectedCategories.map(x => x.id),
          tags: selectedTags.map(x => x.id),
          page: 1
        });
        setOpenModalTypes(false);
      }
  
      const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedFile(null);
      }
  
      const handleCloseModalTypes = () => {
        setOpenModalTypes(false);
        setSelectedModalType(null);
        setSelectedDocumentTypes(paginatorRequest.typeDocuments);
        setSelectedKinds(grouping.kinds.filter(x => paginatorRequest.kinds.includes(x.id)))
        setSelectedCategories(grouping.categories.filter(x => paginatorRequest.categories.includes(x.id)))
        setSelectedTags(grouping.tags.filter(x => paginatorRequest.tags.includes(x.id)))
      }
  
      const handleChangeDocumentType = (extension: string) => {
        let arr = [...selectedDocumentTypes];
        if(selectedDocumentTypes.findIndex(x => x === (extension === images ? "jpg" : extension)) > -1){
          if(extension === images)
          {
            arr = selectedDocumentTypes.filter(x => !imagesArr.includes(x));
          }else
          {
            arr = selectedDocumentTypes.filter(x => x !== extension);
          }
        }
        else{
          if(extension === images)
          {
            arr = arr.concat(imagesArr);
          }else
          {
            arr.push(extension);
          }       
        }
        setSelectedDocumentTypes(arr);
      }
  
      const handleChangeKind = (kind: UserGroupingTypeBase) => {
        let arr = [...selectedKinds];
        if(selectedKinds.findIndex(x => x.id === kind.id) > -1){
          arr = selectedKinds.filter(x => x.id !== kind.id);
        }
        else{
          arr.push(kind);
        }
        setSelectedKinds(arr);
      }
  
      const handleChangeCategories = (category: UserGroupingCategoryBase) => {
        let arr = [...selectedCategories];
        if(selectedCategories.findIndex(x => x.id === category.id) > -1){
          arr = selectedCategories.filter(x => x.id !== category.id);
        }
        else{
          arr.push(category);
        }
        setSelectedCategories(arr);
      }
  
      const handleChangeTags = (tag: UserGroupingTag) => {
        let arr = [...selectedTags];
        if(selectedTags.findIndex(x => x.id === tag.id) > -1){
          arr = selectedTags.filter(x => x.id !== tag.id);
        }
        else{
          arr.push(tag);
        }
        setSelectedTags(arr);
      }
  
      const headerElementList: TableHeaderElement[] = [
        {
          element: 
          <Box 
            sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
          >
            <p>{dateText}</p>
            {paginatorRequest.typeOrder.toLowerCase().includes("asc") ? <NorthIcon fontSize="inherit" />  : <SouthIcon fontSize="inherit" />}
            <CalendarMonthIcon fontSize="inherit" />
          </Box>,
          width: 15,
          withBorder: true,
          align: "center",
          onClick: handleChangeOrder
        },
        {
          element: nameText,
          width: grouping.permissions.includes(EDIT_GROUPINGFILES) ? 25 : 35,
          withBorder: true,
          align: "center",
          onClick: null
        },
        {
          element: 
          <Box 
            sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
          >
            <p>{kindText}</p>
            {paginatorRequest.kinds.length > 0 ? <FilterAltIcon fontSize="inherit" /> : <FilterAltOutlinedIcon fontSize="inherit" />}
          </Box>   
          ,
          width: 15,
          withBorder: true,
          align: "center",
          onClick: handleSelectedKind
        },
        {
          element: 
          <Box 
            sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
          >
            <p>{categoryText}</p>
            {paginatorRequest.categories.length > 0 ? <FilterAltIcon fontSize="inherit" /> : <FilterAltOutlinedIcon fontSize="inherit" />}
          </Box> 
          ,
          width: 13,
          withBorder: true,
          align: "center",
          onClick: handleSelectedCategory
        },
        {
          element: 
          <Box 
            sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
          >
            <p>{tagsText}</p>
            {paginatorRequest.tags.length > 0 ? <FilterAltIcon fontSize="inherit" /> : <FilterAltOutlinedIcon fontSize="inherit" />}
          </Box> 
          ,
          width: 12,
          withBorder: true,
          align: "center",
          onClick: handleSelectedTag
        },
        {
          element: 
          <Box 
            sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
          >
            <p>{filesText}</p>
            {paginatorRequest.typeDocuments.length > 0 ? <FilterAltIcon fontSize="inherit" /> : <FilterAltOutlinedIcon fontSize="inherit" />}
          </Box> 
          ,
          width: 10,
          withBorder: grouping.permissions.includes(EDIT_GROUPINGFILES) ? true : false,
          align: "center",
          onClick: handleSelectedFile
        },
        {
          element: editText,
          width: 10,
          withBorder: false,
          align: "center",
          onClick: null
        }
      ]
  
    const getModalTypesContent = () : ReactNode => {
      switch (selectedModalType) {
        case "Kind":
          return <Box
            className="grouping-files-types"
          >
            {grouping.kinds.map((kind, index) => (
                <FormControlLabel 
                  key={`${kind.id}-${index}`}
                  control={
                    <Checkbox
                      checked={selectedKinds.findIndex(x => x.id === kind.id) > -1}
                      onChange={() => handleChangeKind(kind)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                } 
                  label={kind.name}
                  />
              ))}
          </Box>
        case "Category":
          return <Box
            className="grouping-files-types"
          >
            {grouping.categories.map((category, index) => (
                <FormControlLabel 
                  key={`${category.id}-${index}`}
                  control={
                    <Checkbox
                      checked={selectedCategories.findIndex(x => x.id === category.id) > -1}
                      onChange={() => handleChangeCategories(category)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                } 
                  label={category.name}
                  />
              ))}
          </Box>
        case "Tag":
          return <Box
            className="grouping-files-types"
          >
            {grouping.tags.map((tag, index) => (
                <FormControlLabel 
                  key={`${tag.id}-${index}`}
                  control={
                    <Checkbox
                      checked={selectedTags.findIndex(x => x.id === tag.id) > -1}
                      onChange={() => handleChangeTags(tag)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                } 
                  label={tag.name}
                  />
              ))}
          </Box>
        case "File":
          return <Box
            className="grouping-files-types"
          >
            {documentTypes?.map((extension, index) => (
                <FormControlLabel 
                  key={`${extension}-${index}`}
                  control={
                    <Checkbox
                      checked={extension === images ? selectedDocumentTypes.filter(x => imagesArr.includes(x)).length > 0 : selectedDocumentTypes.findIndex(x => x === extension) > -1}
                      onChange={() => handleChangeDocumentType(extension)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                } 
                  label={extension.toUpperCase()}
                  />
              ))}
          </Box>
        default:
          return null;
      }
    }

    return (
        <>
          <Box
            className="grouping-file-search"
          >
            <HmyInputText
                title="search" 
                width={matches ? "100%" : 500}
                value={searchText}
                setValue={setSearchText}
                handleEnter={handleEnterSearch}
            />
            {grouping.permissions.includes(ADD_GROUPINGFILES) && (
                <HmyBtn 
                  title="userGroupingAddFile"
                  fullwith={false}
                  width={matches ? 230 : 300}
                  color="primary"
                  handleClick={handleAddGroupingFile}
                />
            )}
            </Box>
            {!loading && groupingFiles.items.length === 0 && paginatorRequest.filter === ""
            ?
              <NoGroupingFiles
                grouping={grouping}
              />
            :
            <>
              <HmyTable
                  headerELementList={grouping.permissions.includes(EDIT_GROUPINGFILES) ? headerElementList : headerElementList.filter(x => x.element !== editText)}
                  currentPage={groupingFiles?.currentPage ?? 1}
                  pages={groupingFiles?.pages ?? 0}
                  rowsPerPage={paginatorRequest.take}
                  isChekable={false}
                  isChecked={false}
                  handleCheck={() => (false)}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                  {groupingFiles?.items.map((file, index) => (
                  <GroupinFileRow 
                      key={`${file.id}-${index}`}
                      file={file}
                      isFirst={index === 0}
                      isLast={index === groupingFiles?.items.length - 1}
                      canEdit={grouping.permissions.includes(EDIT_GROUPINGFILES)}
                      setSelectedFile={handleSelectedDocumentToSee}
                      setEditFile={handleEditFile}
                  />
                  ))}  
              </HmyTable>
              {matches
              ?
              <HmyInfiniteScroll
                  currentPage={groupingFiles?.currentPage ?? 1}
                  pages={groupingFiles?.pages ?? 0}
                  dataLength={groupingFiles?.items.length ?? 0}
                  handleChangePage={handleChangePage}
              >
                  {groupingFiles?.items.map((file, index) => (
                  <GroupingFileCard 
                      key={`${file.id}-${index}`}
                      file={file}
                      canEdit={grouping.permissions.includes(EDIT_GROUPINGFILES)}
                      setSelectedFile={handleSelectedDocumentToSee}
                      setEditFile={handleEditFile}
                  />
                  ))}
              </HmyInfiniteScroll>
              :
              null
              }
            </>
            }
            <ModalComponent
              isOpen={openModalTypes}
              disMissText="accept"
              acceptText="btn.cancel"
              colorAcceptButton="green"
              handleDismiss={handleCloseModalTypes}
              handleAccept={handleAcceptModal}      
            >
            {getModalTypesContent()}
            </ModalComponent>
            {selectedFile !== null
            ?
            <HmyModalFileViewer
                isOpen={openModal}
                canEdit={false}
                file={selectedFile}
                handleClose={handleCloseModal}
                hanldeClickVisible={() => console.log()}
            >
                {selectedFile.isImage
                ?
                    <HmyImageViewer 
                    document={selectedFile}
                    />
                :
                    <HmyPdfViewer 
                    base64String={selectedFile.url}
                    pdfName={selectedFile.description ?? selectedFile.name}
                    zoomManagement={true}
                    rotationManagement={!matches}
                    closeManagement={false}
                    navBarHeight={50}
                    handleClose={handleCloseModal}
                    />
                }
            </HmyModalFileViewer>
            :
            null
            }
        </>
    )
}

export default GroupingFilesList