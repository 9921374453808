import './hmy-infinite-scroll.scss';
import { FC, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type HmyInfiniteScrollProps = {
    children: ReactNode;
    currentPage: number;
    pages: number;
    dataLength: number;
    handleChangePage: (page:number) => void;
};

const HmyInfiniteScroll: FC<HmyInfiniteScrollProps> = ({currentPage, pages, dataLength, handleChangePage, children}) => {
  return (
    <InfiniteScroll
        dataLength={dataLength}
        next={async () => await handleChangePage(currentPage + 1)}
        hasMore={currentPage < pages}
        loader={null}
        className="hmy-infinite-scroll"
    >
        {children}
    </InfiniteScroll>
  )
}

export { HmyInfiniteScroll };