import './users.scss';
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box, Fab, FormControlLabel, Link, Switch, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { ErrorMessage, GeneralCollection, SuccessMessage, TableHeaderElement, User } from 'src/models';
import { HmyBtn, HmyInfiniteScroll, HmyInputText, HmyTable, LoadComponent, ModalComponent, ROUTE_PROJECTS } from 'src/components';
import { GetUsersDto } from 'src/dtos';
import CAN from '../../../permissions';
import { ADD_USERS, READ_HMYUSERS } from 'src/permissions/permissions';
import { authServices, UserModelService, userServices } from 'src/services';
import { userAdapter } from 'src/adapters/user.adapter';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HmyTableRowUser from './hmy-table-row-user';
import { IsAdmin } from 'src/utilities';
import { tokenAdapter } from 'src/adapters';
import HmyCardUser from './hmy-card-user';

type UsersProps = {
  handleClickUser: (userId: string) => void;
  handleClickAddUser: () => void;
};

const Users: FC<UsersProps> = ({ handleClickUser, handleClickAddUser }) => {

  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [users, setUsers] = useState<GeneralCollection<User>>();
  const [searchText, setSearchText] = useState<string>("");
  const [paginatorRequest, setPaginatorRequest] = useState<GetUsersDto>({
    page: 1,
    take: matches ? 10 : 5,
    columnOrder: "Name",
    typeOrder: "ASC",
    filter: "",
    hmy: false,
    showPendingUsers: true,
    showDeletedUsers: false
  });

  const name = intl.formatMessage({id: "name"});
  const email = intl.formatMessage({id: "email"});
  const projects = intl.formatMessage({id: "projects"});
  const remove = intl.formatMessage({id: "remove"});
  const status = intl.formatMessage({id: "status"});
  const see = intl.formatMessage({id: "see"});

  const headerElementList: TableHeaderElement[] = [
    {
      element: name,
      width: 20,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: email,
      width: IsAdmin(currentUser) && paginatorRequest.hmy ? 40 : 50,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: projects,
      width: 10,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: remove,
      width: 10,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: status,
      width: 10,
      withBorder: IsAdmin(currentUser) && paginatorRequest.hmy,
      align: "center",
      onClick: null
    },
    {
      element: see,
      width: IsAdmin(currentUser) && paginatorRequest.hmy ? 10 : 0,
      withBorder: false,
      align: "center",
      onClick: null
    }
  ]

  const goToProjects = (userEmail: string) => {
    navigate(`${ROUTE_PROJECTS}?filter=${userEmail}&page=1&take=${matches ? 10 : 5}&typeOrder=DESC`)
  }

  const getListUsers = async () => await callEndpoint(userServices.getUsers(paginatorRequest));
  const deleteUser = async (userId: string) => await callEndpoint(userServices.deleteUser(userId));
  const resendRegistrationToken = async (userId: string) => await callEndpoint(authServices.resendRegistrationToken(userId));
  const impersonateUser = async (userId: string) => await callEndpoint(authServices.impersonateUser(userId));

  const adaptListUsers = (data: GeneralCollection<UserModelService>) => {
    let listUser: User[] = [];
    let newUsers = data.items.map((x: UserModelService) => { return userAdapter(x) });
    if(matches && paginatorRequest.page !== 1){
      listUser = (users?.items ?? []).concat(newUsers);
    }else{
      listUser = newUsers;
    }

    setUsers({
      items: listUser,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const handleEnterSearch = () => {
    setPaginatorRequest({
      ... paginatorRequest,
      filter: searchText,
      page: 1
    });
  }

  const handleChangeDeleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ... paginatorRequest,
      showDeletedUsers: event.target.checked,
      page: 1
    });
  };

  const handleChangePending = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ... paginatorRequest,
      showPendingUsers: event.target.checked,
      page: 1
    });
  };

  const handleChangeHmyUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ... paginatorRequest,
      hmy: event.target.checked,
      page: 1
    });
  };

  const handleDismissModal = () => {
    setOpenModal(false);
    setSelectedUser(undefined);
  }

  const handleAcceptModal = async () => {
    try {
      await deleteUser(selectedUser!.id);
      dispatch(setMessage(SuccessMessage(selectedUser?.active ? "success.userDeleted" : "success.userRecover", true)));
      setPaginatorRequest({
        ... paginatorRequest,
        page: 1
      });
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedUser?.active ? "error.deletingUser" : "error.userRecover", true)))
    } finally{
      setOpenModal(false);
      setSelectedUser(undefined);
    }
  }

  const handleResendRegistrationEmail = async (user: User) => {
    try {
      await resendRegistrationToken(user.id);
      dispatch(setMessage(SuccessMessage("success.resendRegistrationRequest", true)));
      setPaginatorRequest({
        ... paginatorRequest,
        page: 1
      });
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.resendingRegistration", true)))
    } 
  }

  const handleImpersonate = async (user: User) => {
    try {
      const response = await impersonateUser(user.id);
      if(response.status === 200){
        tokenAdapter(response.data);
        navigate("/");
        document.location.reload();
      }
    } catch (error) {
    }
  }

  const handleChangePage = (page: number) => {
    setPaginatorRequest({
      ... paginatorRequest,
      page: page
    });
  }

  const handleChangeRowsPerPage = (pages: number) => {
    setPaginatorRequest({
      ...paginatorRequest,
      take: pages,
      page: 1
    });
  }

  useEffect(() => {
    if(selectedUser !== undefined){
      setOpenModal(true);
    }
  },[selectedUser])

  useAsync(getListUsers, adaptListUsers, () => { }, () => { }, [paginatorRequest]);

  return (
    <Box 
        className="users-container"
      >
        {loading ? <LoadComponent /> : null}
        <Box
          className="users-main"
        >
          <Box
            className="users-search"
          >
            <HmyInputText
              title="searchUser" 
              width={matches ? "100%" : 500}
              value={searchText}
              setValue={setSearchText}
              handleEnter={handleEnterSearch}
            />
            {CAN(ADD_USERS) && (
              <HmyBtn 
                title="createUser"
                fullwith={false}
                width={matches ? 230 : 300}
                color="primary"
                handleClick={handleClickAddUser}
              />
            )}
          </Box>
          <Box
            className="users-switch-container"
          >
            <FormControlLabel
              control={<Switch
                checked={paginatorRequest.showDeletedUsers}
                onChange={handleChangeDeleted}
                color="secondary"
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />}
              label={<FormattedMessage id={matches ? "deleted" : "PROJECT_FILTER_SeeDeleted"}/>}
            />
            <FormControlLabel
              control={<Switch
                checked={paginatorRequest.showPendingUsers}
                onChange={handleChangePending}
                color="secondary"
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />}
              label={<FormattedMessage id="USER_FILTER_SeePending"/>}
            />
              {CAN(READ_HMYUSERS) && (
                <FormControlLabel
                  control={<Switch
                    checked={paginatorRequest.hmy}
                    onChange={handleChangeHmyUsers}
                    color="secondary"
                    inputProps={{ 'aria-label': 'controlled' }}
                    size="small"
                  />}
                  label="HMY"
                />
              )}
          </Box>
          <HmyTable
            headerELementList={IsAdmin(currentUser) && paginatorRequest.hmy ? headerElementList : headerElementList.filter(x => x.element !== see)}
            currentPage={users?.currentPage ?? 1}
            pages={users?.pages ?? 0}
            rowsPerPage={paginatorRequest.take}
            isChekable={false}
            isChecked={false}
            handleCheck={() => (false)}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {users?.items.map((user, index) => (
              <HmyTableRowUser 
                key={`${user.id}-${index}`}
                isFirst={index === 0}
                isLast={index === users?.items.length - 1}
                user={user}
                currentUser={currentUser}
                handleClickUser={handleClickUser}
                goToProjects={goToProjects}
                setSelectedUser={setSelectedUser}
                handleResendEmail={handleResendRegistrationEmail}
                onClickImpersonate={handleImpersonate}
              />
            ))} 
          </HmyTable>
          {matches
          ?
            <HmyInfiniteScroll
              currentPage={users?.currentPage ?? 1}
              pages={users?.pages ?? 0}
              dataLength={users?.items.length ?? 0}
              handleChangePage={handleChangePage}
            >
              {users?.items.map((user, index) => (
                  <HmyCardUser 
                    key={`${user.id}-${index}`}
                    user={user}
                    handleClickUser={handleClickUser}
                    goToProjects={goToProjects}
                    setSelectedUser={setSelectedUser}
                    handleResendEmail={handleResendRegistrationEmail}
                  />
                ))
              }
            </HmyInfiniteScroll>
          :
          null
          }
        </Box>
        <ModalComponent
          isOpen={openModal}
          disMissText={selectedUser?.active ? "remove" : "recover"}
          acceptText="btn.cancel"
          colorAcceptButton={selectedUser?.active ? "red" : "green"}
          handleDismiss={handleDismissModal}
          handleAccept={handleAcceptModal}
        >
          <Box>
            <ErrorIcon className="error-icon" />
          </Box>
          <Box
            className="users-modal-text"
          >
            {selectedUser?.active
            ?
              <FormattedMessage id="deleteUser" values={{ value: <b>{selectedUser?.fullName}</b>}} />
            :
              <FormattedMessage id="reactivateUser" values={{ value: <b>{selectedUser?.fullName}</b>}} />
            }
          </Box>
        </ModalComponent>
        <Fab className="users-btn-up">
          <Link
            href="#"       
          >
            <ArrowUpwardIcon sx={{fontSize: '40px'}} />
          </Link>    
        </Fab> 
      </Box>
  )
}

export default Users;