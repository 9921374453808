import './language-menu.scss';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector, sessionSelector, setCurrentUserLanguage, setLanguage } from '../../redux/states';
import { AppLanguages } from './languages';
import { Language } from 'src/models';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFetchAndLoad } from 'src/hooks';
import { storageServices, authServices } from '../../services';
import { UpdateLanguageDto } from 'src/dtos/auth/update-language.dto';

const LanguageMenu = () => {

    const dispatch = useDispatch();

    const { language } = useSelector(sessionSelector);
    const currentUser = useSelector(currentUserSelector);
    const { callEndpoint } = useFetchAndLoad();

    const updateLangUser = async (value: UpdateLanguageDto) => await callEndpoint(authServices.updateLanguageUser(value));

    const [anchorLanguage, setAnchorLanguage] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorLanguage);
 
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorLanguage(event.currentTarget);
    };
 
    const handleClose = () => {     
        setAnchorLanguage(null);
    };

    const handleCloseItem = (value: Language) => {
        if(language.name !== value.name)
        {
            if(currentUser.language !== value.name){
                const request: UpdateLanguageDto = {
                    userId: currentUser.id,
                    language: value.name
                };
                updateLangUser(request).then(response => {
                    if(response.status === 200){
                        storageServices.setLanguage(value.name);
                    }
                });
            }
            dispatch(setCurrentUserLanguage(value));
            dispatch(setLanguage(value));
        }
       
       handleClose();
    }

  return (
    <Box>
        <Button
            id="language-button"
            aria-controls={open ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            color="inherit"
            className="language-container"
        >
            <span className="btn-language">
                <img src={language.icon} className="flag-icon" />
                <KeyboardArrowDownIcon className="icon-font-size" />
            </span>
        </Button>
        <Menu
            id="language-menu"
            anchorEl={anchorLanguage}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'language-button',
            }}
        >
            {AppLanguages.map((AppLanguage) => (
                <MenuItem
                    key={`tab-${AppLanguage.name}`} 
                    onClick={() => handleCloseItem(AppLanguage)}
                >
                    <span className="span-language">
                        <img src={AppLanguage.icon} className="flag-icon" />
                        <FormattedMessage id={AppLanguage.translate}/>
                    </span>               
                </MenuItem>           
            ))}
        </Menu>
    </Box> 
  )
}

export default LanguageMenu;