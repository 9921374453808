import { useEffect, useRef } from "react";
/**
 * Custom hook para comparar valores actuales y antiguos en los useEffect
 * @param value 
 * @returns 
 */
 export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};