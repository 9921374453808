import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { STATUS_INCIDENCE, useIncidenceStates } from 'src/hooks/use-incidence-states.hook';
import './incidence-status.scss';
import { FormattedMessage } from 'react-intl';

type IncidenceStatusProps = {
  statusNumber: number | undefined;
  handleUpdateIncidenceStatus: (incidenceStatusId: string) => void;
};

const IncidenceStatus: FC<IncidenceStatusProps> = ({ statusNumber, handleUpdateIncidenceStatus }) => {
  const matches = useMediaQuery('(max-width: 768px)');

  const { getStateId } = useIncidenceStates();

  const handleChangeState = (newStateNumber: any) => {
    if (statusNumber !== newStateNumber) {
      const incidenceStatusId = getStateId(newStateNumber);
      handleUpdateIncidenceStatus(incidenceStatusId);
    }
  };

  if (typeof statusNumber === 'undefined') {
    return null;
  }

  return (
    <div className="incidence-status">
      {Object.values(STATUS_INCIDENCE).map((stateNumber) => {
        if (typeof stateNumber === 'number') {
          return (
            <div
              key={stateNumber}
              className={`incidence-status-button state-${stateNumber} ${stateNumber == statusNumber && 'selected'}`}
              onClick={() => handleChangeState(stateNumber)}
            >
              <FormattedMessage id={`status-incidence-${stateNumber}`} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default IncidenceStatus;
