import { Box } from '@mui/material';
import { FC, useState } from 'react';
import './hmy-btn-help.scss';
import { HmyModalHelp } from './hym-modal-help';

type HmyBtnHelpProps = {
  projectId: string;
};

const HmyBtnHelp: FC<HmyBtnHelpProps> = ({ projectId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => setIsOpen(true);
  return (
    <>
      <Box onClick={handleClick} className="hmy-btn-help">
        ?
      </Box>
      {isOpen && <HmyModalHelp open={isOpen} close={() => setIsOpen(false)} projectId={projectId} />}
    </>
  );
};

export { HmyBtnHelp };
