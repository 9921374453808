import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import './incidence-status-text.scss';

type IncidenceStatusTextProps = {
  statusNumber: string | number;
};
const IncidenceStatusText: FC<IncidenceStatusTextProps> = ({ statusNumber }) => {
  return (
    <div className={`incidence-status-text status-${statusNumber}`}>
      <div className="status-badge" />
      <FormattedMessage id={`status-incidence-${statusNumber}`} />
    </div>
  );
};

export { IncidenceStatusText };
