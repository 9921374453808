import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FC } from 'react';
import { BasicOrder } from 'src/models/orders';
import './incidence-articles-list.scss';

type IncidenceArticlesListProps = {
  orderData: BasicOrder;
  checkedArticles: string[];
  setCheckedArticles: (saleOrderLineIds: string[]) => void;
};

const IncidenceArticlesList: FC<IncidenceArticlesListProps> = ({ orderData, checkedArticles, setCheckedArticles }) => {
  const handleToggle = (id: string) => () => {
    const currentIndex = checkedArticles.indexOf(id);
    const newChecked = [...checkedArticles];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedArticles(newChecked);
  };

  return (
    <List className="incidence-articles-list">
      {orderData.lines?.map((item) => (
        <ListItem key={item.saleOrderLineId} onClick={handleToggle(item.saleOrderLineId)}>
          <ListItemIcon sx={{ minWidth: '24px', height: '24px', width: '24px' }}>
            <Checkbox
              edge="start"
              checked={checkedArticles.indexOf(item.saleOrderLineId) !== -1}
              tabIndex={-1}
              disableRipple
              sx={{ height: '24px', width: '24px', '& .MuiSvgIcon-root': { fontSize: '24px' } }}
            />
          </ListItemIcon>
          <ListItemText primary={item.referenceDesc} sx={{ '& .MuiListItemText-primary': { fontSize: '14px' } }} />
        </ListItem>
      ))}
    </List>
  );
};

export default IncidenceArticlesList;
