import './grouping-file-edit.scss';
import { FC, useEffect, useState } from 'react';
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { groupingCategoryAdapter, groupingTagAdapter, groupingTypeAdapter } from 'src/adapters';
import { HmyAutocompleteAdd, HmyFormSection, HmySingleForm } from 'src/components';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { GeneralCollection, UserGroupingCategory, UserGroupingFile, UserGroupingTag, UserGroupingType } from 'src/models';
import { groupingServices, UserGroupingCategoryModelService, UserGroupingTagModelService, UserGroupingTypeModelService } from 'src/services';
import { formatDate } from 'src/utilities';
import DeleteIcon from '@mui/icons-material/Delete';

type GroupingFileEditFormProps = {
    userGroupingId: string;
    file: UserGroupingFile;
    handleUpdateBasic: (property: string, value: string) => void;
    handleUpdateTags: (tags: string[]) => Promise<boolean>;
    handleUpdateTypeCategory: (type: string, category: string) => Promise<boolean>;
  };

const GroupingFileEditForm: FC<GroupingFileEditFormProps> = ({ userGroupingId, file, handleUpdateBasic, handleUpdateTags, handleUpdateTypeCategory }) => {

    const intl = useIntl();
    const matches = useMediaQuery('(max-width: 768px)');
    
    const newElement = intl.formatMessage({id: "new"});
    const { loading, callEndpoint } = useFetchAndLoad();
    
    const [types, setTypes] = useState<UserGroupingType[]>([]);
    const [categories, setCategories] = useState<UserGroupingCategory[]>([]);
    const [tags, setTags] = useState<UserGroupingTag[]>([]);

    const [typeSelected, setTypeSelected] = useState<string>(file.kind.name);
    const [categorySelected, setCategorySelected] = useState<string>(file.category.name);
    const [tagsSelected, setTagsSelected] = useState<string[]>(file.tags.map(x => x.name));

    const [newName, setNewName] = useState<string>(file.name);
    const [errorNewName, setErrorNewName] = useState<string | null>(null);
    const [newDate, setNewDate] = useState<string>(formatDate(new Date(file.date)));
    const [errorNewDate, setErrorNewDate] = useState<string | null>(null);
    const [newDescription, setNewDescription] = useState<string>(file.description);
    const [newType, setNewType] = useState<string | null>(null);
    const [newCategory, setNewCategory] = useState<string | null>(null);
    const [newTags, setNewTags] = useState<string[]>([]);

    const getTypes = async () => await callEndpoint(groupingServices.getTypes({
      userGroupingId: userGroupingId,
      categoryName: ""
    }));

    const getCategories = async () => await callEndpoint(groupingServices.getCategories({
      userGroupingId: userGroupingId,
      userGroupingTypeId: types.find(x => x.name === typeSelected)?.id ?? ""
    }));

    const getTags = async () => await callEndpoint(groupingServices.getTags({
      userGroupingId: userGroupingId
    }));

    const adaptTypes = (data: GeneralCollection<UserGroupingTypeModelService>) => {

      let typeList =  data.items.map(x => groupingTypeAdapter(x));
      
      setTypes(typeList);

    };

    const adaptCategories = (data: GeneralCollection<UserGroupingCategoryModelService>) => {

      let categoryList =  data.items.map(x => groupingCategoryAdapter(x));
      
      setCategories(categoryList);

    };

    const adaptTags = (data: GeneralCollection<UserGroupingTagModelService>) => {

      let tagList =  data.items.map(x => groupingTagAdapter(x));
      
      setTags(tagList);

    };

    const adaptNewString = (value: string) : string => {
        return value.includes(`(${newElement})`) ? value.replace(`(${newElement})`, "").trimEnd() : value;
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.value === null || event.target.value.trim().length === 0){
          setErrorNewName("error.required")
      }else{
          setErrorNewName(null)
      }     
      setNewName(event.target.value);
    };

    const handleEditName = async () => {
        await handleUpdateBasic("name", newName);
    }

    const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (new Date(event.target.value) > new Date(2021, 1, 1)){
          setErrorNewDate(null);
        }else{
          setErrorNewDate("error.dateGreater")
        }
        setNewDate(formatDate(new Date(event.target.value)));
    }

    const handleEditDate = async () => {
        await handleUpdateBasic("date", newDate);
    }

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {  
        setNewDescription(event.target.value);
    };

    const handleEditDescription = async () => {
        await handleUpdateBasic("description", newDescription);
    }

    const handleEditTypeCategory = async () => {
        if(await handleUpdateTypeCategory(adaptNewString(typeSelected), adaptNewString(categorySelected))){
            handleCancelForm();
        }
    }

    const handleEditTags = async () => {
        if(await handleUpdateTags(tagsSelected.map(x => adaptNewString(x)))){
            handleCancelForm();
        }
    }

    const handleCancelForm = () => {
      setNewName(file.name);
      setErrorNewName(null);
      setNewDate(formatDate(new Date(file.date)));
      setErrorNewDate(null);
      setNewDescription(file.description ?? "")
      setTypeSelected(file.kind.name);
      setCategorySelected(file.category.name);
      setTagsSelected(file.tags.map(x => x.name));
      setNewType(null);
      setNewCategory(null);
      setNewTags([]);
    }

    const handleLoadTypes = async () => {
      const response = await getTypes();
      adaptTypes(response.data);
    }

    const handleSetType = (value: string | null) => {
      setTypeSelected(value ?? "");   
      setCategorySelected("");
    }

    const handleLoadCategories = async () => {
        const response = await getCategories();
        adaptCategories(response.data);
    }

    const handleSetCategory = (value: string | null) => {
      setCategorySelected(value ?? "")  
    }

    const handleSetTag = (value: string | null) => {
        if(value !== null){
            let tagList = [...tagsSelected];
            tagList.push(value);
            setTagsSelected(tagList);
        }  
    }

    const handleCancelElementTag = (value: string) => {
        let newTags = [...tagsSelected.filter(x => x !== value)];
        setTagsSelected(newTags);
    }

    const handleLoadTags = async (filter: string) => {
        const response = await getTags();
        adaptTags(response.data);
    }

    const handleNewType = (value: string) => {
      const newValue = `${value} (${newElement})`;
      if(newType === null || newType !== newValue){
        setNewType(newValue);
        handleSetType(newValue);
      }
    }

    const handleNewCategory = (value: string) => {
      const newValue = `${value} (${newElement})`;
      if(newCategory === null || newCategory !== newValue){
        setNewCategory(newValue);
        handleSetCategory(newValue);
      }
    }

    const handleNewTag = (value: string) => {
      const newValue = `${value} (${newElement})`;
      if(!newTags.includes(newValue)){
        let newTagList = [... newTags];
        newTagList.push(newValue);
        setNewTags(newTagList);
      }
      
      if(!tagsSelected.includes(newValue)){
        handleSetTag(newValue);
      }
      
    }

    useEffect(() => {
        setTypeSelected(file.kind.name);
        setCategorySelected(file.category.name);
        setTagsSelected(file.tags.map(x => x.name));
    },[file])

    useAsync(getTypes, adaptTypes, () => { }, () => { }, []);
    useAsync(getCategories, adaptCategories, () => { }, () => { }, [typeSelected]);
    useAsync(getTags, adaptTags, () => { }, () => { }, []);

    return (
        <>
            <HmyFormSection>
                <HmySingleForm
                    isMultiline={false}
                    isFullWidth={false}
                    showTitle={true}
                    title="name"
                    textValues={[file.name]}
                    canEdit={true}
                    canDelete={false}
                    hasErrors={errorNewName !== null}
                    onClickSubmitForm={handleEditName}
                    onClickCancelForm={handleCancelForm}
                    onDeleteForm={() => console.log()}
                >
                    <TextField 
                        label="" 
                        variant="standard" 
                        value={newName}
                        onChange={handleChangeName}
                        error={errorNewName !== null}
                        helperText={errorNewName !== null ? <FormattedMessage id={errorNewName} /> : null}
                        fullWidth
                        inputProps={{style: {fontSize: matches ? 22 : 26}}}
                    />
                </HmySingleForm>
                <HmySingleForm
                    isMultiline={false}
                    isFullWidth={false}
                    showTitle={true}
                    title="date"
                    textValues={[formatDate(new Date(file.date))]}
                    canEdit={true}
                    canDelete={false}
                    hasErrors={errorNewDate !== null}
                    onClickSubmitForm={handleEditDate}
                    onClickCancelForm={handleCancelForm}
                    onDeleteForm={() => console.log()}
                >
                    <TextField 
                        label="" 
                        variant="standard"
                        type="date" 
                        value={newDate}
                        onChange={handleChangeDate}
                        error={errorNewDate !== null}
                        helperText={errorNewDate !== null ? <FormattedMessage id={errorNewDate} /> : null}
                        fullWidth
                        inputProps={{style: {fontSize: matches ? 22 : 26}}}
                    />
                </HmySingleForm>
            </HmyFormSection>
            <HmyFormSection>
                <HmySingleForm
                    isMultiline={false}
                    isFullWidth={true}
                    showTitle={false}
                    title="typeCategory"
                    textValues={[`${intl.formatMessage({id: "kind"})}: ${file.kind.name}`,
                    `${intl.formatMessage({id: "category"})}: ${file.category.name}`]}
                    canEdit={true}
                    canDelete={false}
                    hasErrors={typeSelected === "" || categorySelected === ""}
                    onClickSubmitForm={handleEditTypeCategory}
                    onClickCancelForm={handleCancelForm}
                    onDeleteForm={() => console.log()}
                >
                    <Box
                        className="grouping-file-edit-type-container"
                    >
                        <Box
                            className="grouping-file-edit-type-element"
                        >
                            <HmyAutocompleteAdd 
                                label="type"
                                showLabel={true}
                                fullWidth={true}
                                value={typeSelected}
                                loading={loading}
                                options={newType !== null ? types.map(x => x.name).concat([newType]) : types.map(x => x.name)}
                                helperText={null}
                                handleNewElement={handleNewType}
                                setValue={handleSetType}
                                loadResults={handleLoadTypes}
                            />
                        </Box>
                        <Box
                            className="grouping-file-edit-type-element"
                        >
                            <HmyAutocompleteAdd 
                                label="category"
                                showLabel={true}
                                fullWidth={true}
                                value={categorySelected}
                                loading={loading}
                                options={newCategory !== null ? categories.map(x => x.name).concat([newCategory]) : categories.map(x => x.name)}
                                helperText={null}
                                handleNewElement={handleNewCategory}
                                setValue={handleSetCategory}
                                loadResults={handleLoadCategories}
                            />
                        </Box>
                    </Box>
                </HmySingleForm>
            </HmyFormSection>
            <HmyFormSection>
                <HmySingleForm
                    isMultiline={true}
                    isFullWidth={false}
                    showTitle={true}
                    title="tag.many"
                    textValues={file.tags.map(x => x.name)}
                    canEdit={true}
                    canDelete={false}
                    hasErrors={false}
                    onClickSubmitForm={handleEditTags}
                    onClickCancelForm={handleCancelForm}
                    onDeleteForm={() => console.log()}
                >
                    {tagsSelected.map((tag, index) => (
                        <Box
                            key={`${tag}-${index}`}
                            className="grouping-file-edit-tags-content"
                        >
                            <Typography fontSize={matches ? 20 : 24} >{tag}</Typography>
                            <DeleteIcon 
                                onClick={() => handleCancelElementTag(tag)}
                                color="error"
                                style={{cursor: "pointer"}}
                            />
                        </Box>
                    ))}
                    <HmyAutocompleteAdd 
                        label="tag.many"
                        showLabel={false}
                        fullWidth={true}
                        value={null}
                        loading={loading}
                        options={(tags.filter(x => !tagsSelected.includes(x.name)).map(x => x.name)).concat(newTags)}
                        helperText={null}
                        handleNewElement={handleNewTag}
                        setValue={handleSetTag}
                        loadResults={handleLoadTags}
                    />
                </HmySingleForm>
                <HmySingleForm
                    isMultiline={false}
                    isFullWidth={false}
                    showTitle={true}
                    title="description"
                    textValues={[file.description]}
                    canEdit={true}
                    canDelete={false}
                    hasErrors={false}
                    onClickSubmitForm={handleEditDescription}
                    onClickCancelForm={handleCancelForm}
                    onDeleteForm={() => console.log()}
                >
                    <TextField 
                        label="" 
                        variant="standard" 
                        type="text"
                        value={newDescription}
                        onChange={handleChangeDescription}
                        multiline={true}
                        fullWidth
                        inputProps={{style: {fontSize: matches ? 22 : 26}}}
                    />
                </HmySingleForm>
            </HmyFormSection>
        </>
    )
}

export default GroupingFileEditForm;