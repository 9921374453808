import "swiper/css";
import './hmy-pdf-viewer.scss';
import { FC, useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { usePrevious, useVisualViewport } from "../../hooks";
import { HmyPdfViewerNavBar } from './hmy-pdf-viewer-nav';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

type PdfViewerProps = {
  pdfName: string,
  base64String: string,
  zoomManagement: boolean,
  rotationManagement: boolean,
  closeManagement: boolean,
  navBarHeight: number,
  handleClose: () => void
};

const NotPdfFound: FC = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <SentimentVeryDissatisfiedIcon
        fontSize="large"
      />
      <Typography
        style={{
          color: "white"
        }}
        variant="h6"
      >
        <FormattedMessage id="noPdfFound" />
      </Typography>
    </Box>
  )
}

const HmyPdfViewer: FC<PdfViewerProps> = ({ pdfName, base64String, zoomManagement, rotationManagement, closeManagement, navBarHeight, handleClose }) => {

  //Mobile
  const [isMobile, setIsMobile] = useState<boolean>(false); //TODO: Hacer esto responsivo
  const [swiper, setSwiper] = useState<any>();
  const { viewport, visualViewport } = useVisualViewport();

  //Pdf viewerState
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(90);
  const [scale, setScale] = useState<number>(100);

  //Swiper State
  const [allowTouch, setAllowTouch] = useState<boolean>(viewport.width !== Math.round(visualViewport.width) ? false : true);
  const prevAllowTouch: boolean = usePrevious(allowTouch) ?? false;

  const onDocumentLoadSuccess = (e: any) => {
    setNumPages(e.numPages);
  }

  //Pagination
  //Se gestiona el comportamiento de swiper aquí para evitar efectos secundarios raros.
  const handleNextPage = () => {
    if (currentPage < numPages) {
      if (isMobile)
        swiper.slideTo(currentPage);
      setCurrentPage(currentPage + 1);
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      if (isMobile)
        swiper.slideTo(currentPage - 2);
      setCurrentPage(currentPage - 1);
    }
  }

  const handleSwiperChange = (slide: number) => {
    setCurrentPage(slide + 1);
  }

  //Zooming
  const handleOnZoomOut = () => {
    if (!isMobile) {
      if (scale > 60)  //TODO: Parametrizar esto (min scale)
        setScale(scale - 20);
    }
  }

  const handleOnZoomIn = () => {
    if (!isMobile) {
      if (scale < 400) //TODO: Parametrizar esto (max scale)
        setScale(scale + 20); //TODO: Parametrizar saltos en el zoom
    }
  }

  //Rotating
  const handleOnRotateClockWise = () => {
    if (rotation == 270) {
      setRotation(0);
    } else {
      setRotation(rotation + 90);
    }
  }

  const handleOnRotateAntiClockWise = () => {
    if (rotation === 0) {
      setRotation(270);
    } else {
      setRotation(rotation - 90);
    }
  }

  useEffect(() => {
    if (isMobile) {
      if (swiper !== undefined) {
        if (Math.round(visualViewport.width) !== viewport.width) {
          setAllowTouch(false);

        } else {
          setAllowTouch(true);
        }
      }
    }
  }, [visualViewport.width])

  useEffect(() => {
    if (isMobile) {
      if (swiper !== undefined) {
        if (prevAllowTouch !== allowTouch) {
          //De esta forma bloqueamos los controles táctiles
          (swiper as SwiperCore).wrapperEl.classList.toggle("block-swiper");
        }
      }
    }
  }, [allowTouch])

  useEffect(() => {
    if (window.screen.width <= 768) { //TODO:Añadir a la condición la función del userAgent! (De momento esto funciona) 
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [])

  return (
    <Box 
      className="hmy-pdf-viewer-container"
    >
      <HmyPdfViewerNavBar
        viewportRatio={(visualViewport.height / viewport.height)}
        pdfName={pdfName}
        navBarHeight={navBarHeight}
        managePagination={isMobile ? allowTouch : true}
        manageZoom={zoomManagement}
        manageRotation={rotationManagement}
        manageClose={isMobile ? allowTouch : closeManagement}
        currentPage={currentPage}
        totalPages={numPages}
        scale={scale}
        isMobile={isMobile}
        handleOnClose={handleClose}
        handleOnNextPage={handleNextPage}
        handleOnPreviousPage={handlePreviousPage}
        handleOnZoomOut={zoomManagement ? handleOnZoomOut : undefined}
        handleOnZoomIn={zoomManagement ? handleOnZoomIn : undefined}
        handleOnRotateClockWise={rotationManagement ? handleOnRotateClockWise : undefined}
        handleOnRotateAntiClockWise={rotationManagement ? handleOnRotateAntiClockWise : undefined}
      />
      <Box
        className={`hmy-pdf-viewer-pdfContainer ${(!isMobile ? "desktop" : "")}`}
        style={{
          marginTop: navBarHeight,
        }}
      >
        <Document
          file={base64String}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="svg"
          className={!isMobile ? "desktop" : ""}
          noData={<NotPdfFound />}
          onLoadError={() => <NotPdfFound />}
          onSourceError={() => <NotPdfFound />}
          error={<NotPdfFound />}
        >
          {
            isMobile
              ?
              <Swiper
                slidesPerView={1}
                width={viewport.width}
                onSwiper={setSwiper}
                onSlideChange={(e: any) => handleSwiperChange(e.activeIndex)}
                preventInteractionOnTransition={true}
                touchMoveStopPropagation={true}
                cssMode={true}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <SwiperSlide>
                    <Page
                      key={`page_${index + 1}`}
                      width={viewport.width * 0.9000000}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      style={{
                        maxWidth: viewport.width,
                        maxHeight: viewport.height - navBarHeight,
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              :
              <Page
                pageNumber={currentPage}
                scale={scale / 100}
                rotate={rotation}
              />
          }
        </Document>
      </Box>
    </Box>
  );
}

export { HmyPdfViewer }