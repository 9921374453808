import './hmy-simple-selectable.scss';
import { FC } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material';
import { Selectable } from 'src/models';
import { FormattedDate, FormattedMessage } from 'react-intl';

type HmySimpleSelectableProps = {
    title: string; //Esto tiene que ser el id de la traducción
    elements: Selectable[];
    value: string;
    isDate: boolean;
    isStringToTranslate: boolean;
    setValue: (value: string) => void;
  };

const HmySimpleSelectable: FC<HmySimpleSelectableProps> = ({title, elements, value, isDate, isStringToTranslate, setValue}) => {

  const matches = useMediaQuery('(max-width: 768px)');

  const handleChange = (event: SelectChangeEvent) => {
      setValue(event.target.value);
  };

  return (
    <FormControl>
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          variant="standard"
          className="hmy-simple-selectable"
          fullWidth={matches}
        >
          <MenuItem value="" >
            <Typography className="hmy-simple-selectable-option-default"><FormattedMessage id={title} /></Typography>
          </MenuItem>
          {elements.map((element, index) => (
            <MenuItem 
                value={element.id}
                key={`${element.id}-${index}`}
                className="hmy-simple-selectable-option"
            >
                {isDate ? <FormattedDate year="numeric" month="2-digit" day="2-digit" value={element.text} /> : (isStringToTranslate ? <FormattedMessage id={element.text} /> : element.text)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}

export { HmySimpleSelectable }