import { Box, Dialog, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { HmyBtn, ROUTE_NEW_ORDER, ROUTE_PROJECTS } from 'src/components';
import { useShoppingList } from 'src/hooks/use-shopping-list.hook';
import { calculeOrderPrice } from 'src/pages/order/calcule-order-price.helpers';
import { formatPrice } from 'src/pages/order/format-price.helpers';
import ImageArticle from 'src/pages/order/hmy-modal-order/image-article';
import { BasicCatalogueLineModelService } from 'src/services/catalogue';
import './shopping-menu-modal.scss';
import PdfArticle from 'src/pages/order/hmy-modal-order/pdf-article';

type ShoppingMenuModalProps = {
  isOpen: boolean;
  close: () => void;
};

const ShoppingMenuModal: FC<ShoppingMenuModalProps> = ({ isOpen, close }) => {
  const matches = useMediaQuery('(max-width: 768px)');

  const { shoppingList } = useShoppingList();

  const navigate = useNavigate();

  return (
    <Dialog open={isOpen} onClose={close} className="shopping-menu-modal" fullScreen={matches}>
      <Box className="shopping-menu-modal-container">
        {shoppingList && shoppingList.lines && shoppingList.lines.length > 0 ? (
          <>
            {shoppingList.lines.map((line: BasicCatalogueLineModelService) => {
              return (
                <Box className="shopping-menu-modal-catalogue-line">
                  {line.articleFiles[0].isImage ? (
                    <ImageArticle thumbnail={line.articleFiles[0].urlThumbnail} imageId={line.articleFiles[0].fileId} />
                  ) : (
                    <PdfArticle article={line.articleFiles[0]} />
                  )}
                  <Box className="shopping-menu-modal-catalogue-line-description">
                    <div className="shopping-menu-modal-catalogue-line-description-item">{line.referenceDesc}</div>
                    <div>{line.colorDesc}</div>
                    <div>{line.reference}</div>
                    <div>{line.color}</div>
                  </Box>
                  <Box className="shopping-menu-modal-catalogue-line-price">
                    <div>{formatPrice(line.salePrice)}</div>
                    <Box className="shopping-menu-modal-catalogue-line-price-controls">{line.quantity}</Box>
                  </Box>
                </Box>
              );
            })}
            <Box className="shopping-menu-modal-total">
              <FormattedMessage
                id="totalMaterialPrice"
                values={{ value: formatPrice(calculeOrderPrice(shoppingList).subTotal) }}
              />
            </Box>
            <Box className="shopping-menu-modal-controls">
              {matches && (
                <HmyBtn
                  title="btn.comeBack"
                  width={100}
                  filled
                  fullwith={false}
                  color="secondary"
                  handleClick={close}
                />
              )}
              <HmyBtn
                title="btn.continueOrder"
                width={250}
                fullwith={false}
                filled
                color="primary"
                handleClick={() => {
                  navigate(`${ROUTE_NEW_ORDER.replace(':projectid', shoppingList.projectId)}`);
                  close();
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <FormattedMessage id="emptyShoppingCart" />
            <Box className="shopping-menu-modal-controls">
              {matches && (
                <HmyBtn
                  title="btn.comeBack"
                  width={100}
                  filled
                  fullwith={false}
                  color="secondary"
                  handleClick={close}
                />
              )}
              <HmyBtn
                title="projects"
                width={250}
                fullwith={false}
                filled
                color="primary"
                handleClick={() => {
                  navigate(ROUTE_PROJECTS);
                  close();
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default ShoppingMenuModal;
