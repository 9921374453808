/**
 * Convierte un fichero a base64.
 */
 export const getBase64 = async(file: File) => {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", function() {
            // convert image file to base64 string
            resolve(reader.result);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    })
}