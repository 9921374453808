import './hmy-table.scss';
import { FC, ReactNode } from 'react'
import { Box } from '@mui/material';
import { TableHeaderElement } from 'src/models';
import { HmyCheckBox } from '../hmy-checkbox';
import { HmyPaginator } from '../hmy-paginator';

type HmyTableProps = {
    children: ReactNode;
    headerELementList: TableHeaderElement[];
    currentPage: number;
    pages: number;
    rowsPerPage: number;
    isChekable: boolean;
    isChecked: boolean;
    handleCheck: (value: boolean) => void;
    handleChangePage: (page:number) => void;
    handleChangeRowsPerPage: (value: number) => void;
};

const HmyTable: FC<HmyTableProps> = ({headerELementList, children, currentPage, pages, rowsPerPage, isChekable, isChecked, handleCheck, handleChangePage, handleChangeRowsPerPage}) => {

  return (
    <Box
      className="hmy-table"
    >
      <Box
        className="hmy-table-header"
      >
        {isChekable
          ?
            <Box
              key="checkable-header"
              className="hmy-table-header-element separator"
              style={{width: "5%", justifyContent: "center"}}
              onClick={() => handleCheck(!isChecked)}
            >
               <HmyCheckBox
                color="secondary"
                isChecked={isChecked}
              />
            </Box>
          :
            null
        }
        {headerELementList.map((element, index) => (
          <Box
            key={`${element.element}-${index}`}
            className={element.withBorder ? "hmy-table-header-element separator" : "hmy-table-header-element"}
            style={{width: `${element.width}%`, justifyContent: element.align, cursor: element.onClick !== null ? "pointer" : "default"}}
            onClick={() => element.onClick !== null ? element.onClick() : null}
          >
            {element.element}
          </Box>
        ))}        
      </Box>

      <Box
        className="hmy-table-body"
      >
        {children} 
      </Box>

      <HmyPaginator 
        currentPage={currentPage}
        pages={pages}
        rowsPerPage={rowsPerPage}
        showTablePaginator={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

    </Box>
  )
}

export { HmyTable };