import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { ROUTE_LANDING } from './routes';
import { storageServices } from 'src/services';

export type ProtectedRouteProps = {
    isAllowed: boolean;
    children: JSX.Element;
  };
  
  export default function ProtectedRoute({isAllowed, children}: ProtectedRouteProps) {

    const location = useLocation();
    const savePendingRoute = () => {
      //Nos guardamos la ruta pendiente del usuario para redireccionar despues de que haga login
      storageServices.setPendingRoute(location.pathname);
      return false
    }

    if (!isAllowed) {
      savePendingRoute();
      return <Navigate to={ROUTE_LANDING} replace />;
    } 
    else {
      return children ? children : <Outlet />;
    }
  }