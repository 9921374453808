import { PublicClientApplication } from '@azure/msal-browser';
import { SettingsMsalModelService } from "src/services";

export const loginMsal = async (settings: SettingsMsalModelService, location: string | null) => {
    let redirect_uri = `${window.location.origin}/oauth2-callback/msal/msal`;
    let client = new PublicClientApplication({
      auth: {
        clientId: settings.clientId,
        authority: settings.authority,
        redirectUri: redirect_uri,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    });

    await client.handleRedirectPromise();

    let accounts = client.getAllAccounts();
    if (accounts.length === 0) {
      client.loginRedirect({
        scopes: ["User.Read", "openid", "profile"],
        redirectUri: redirect_uri,
        state: `${location}`
      })
        .catch(e => {
          console.debug(e);
        });
    }
}

export const grantAccessCode = (clientId: string, authority: string, scope: string, redirectUri: string | URL, state: string | null = null) => {
    let URI = `${authority}/oauth2/v2.0/authorize`
      .concat("?response_type=code")
      .concat("&response_mode=query")
      .concat(`&client_id=${clientId}`)
      .concat(`&scope=${encodeURI(scope)}`)
      .concat(`&redirect_uri=${encodeURIComponent(redirectUri.toString())}`
      );

    if (state) {
      URI = URI.concat(`&state=${encodeURIComponent(state)}`)
    }
    window.location.replace(URI);
};