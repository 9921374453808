import DeleteIcon from '@mui/icons-material/Delete';
import './grouping-file-add.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { HmyForm, HmyInboxFileZone, HMY_INPUT_FILE_ID, LoadComponent, LoadComponentProgress } from 'src/components';
import { DocumentProps, ErrorMessage, SuccessMessage } from 'src/models';
import { getBase64 } from 'src/utilities/get-base64';
import {
  CreateGroupingFileDto,
  CreateGroupingFileProps,
  CreateLargeGroupingFileDto,
  CreateLargeInboxBlobDto,
} from 'src/dtos';
import { useDispatch } from 'react-redux';
import { useFetchAndLoad } from 'src/hooks';
import { CHUNK_SIZE, MAX_SIZE, blobServices, groupingServices } from 'src/services';
import { setMessage } from 'src/redux/states';
import { formatDate } from 'src/utilities';
import GroupingFileAddForm, { GroupingNewFileForm } from './grouping-file-add-form';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface GroupingFilesAddValuesProps {
  files: CreateGroupingFileProps[];
  type: string;
  date: string;
  category: string;
  tags: string[];
}

const EMPTY_GROUPING_DATA: GroupingFilesAddValuesProps = {
  files: [],
  type: '',
  category: '',
  date: formatDate(new Date()),
  tags: [],
};

type GroupingFileAddProps = {
  userGroupingId: string;
  documentOptions: DocumentProps;
  goBack: () => void;
};

const GroupingFileAdd: FC<GroupingFileAddProps> = ({ userGroupingId, documentOptions, goBack }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const newElement = intl.formatMessage({ id: 'new' });
  const { loading, callEndpoint } = useFetchAndLoad();

  const [files, setFiles] = useState<File[]>([]);
  const [values, setValues] = useState<GroupingFilesAddValuesProps>(EMPTY_GROUPING_DATA);

  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));
  const uploadChunks = async (request: FormData) => await callEndpoint(blobServices.uploadChunks(request));
  const createInboxBlob = async (request: CreateGroupingFileDto[]) =>
    await callEndpoint(groupingServices.createGroupingBlob(request));
  const createInboxLargeBlob = async (request: CreateLargeGroupingFileDto) =>
    await callEndpoint(groupingServices.createGroupingLargeBlob(request));

  // Helper function to process each file and create a DTO
  const processFile = async (file: CreateGroupingFileDto) => {
    const base64 = await getBase64(file.file!); // Get base64 asynchronously
    return {
      ...file,
      data: (base64 as string).split(',')[1],
    };
  };

  // Helper function to upload chunks of a large file
  const handleFileChunkUpload = async (file: CreateGroupingFileDto) => {
    let beginingOfTheChunk = 0;
    let endOfTheChunk = CHUNK_SIZE;
    if (file.file) {
      for (let counter = 1; counter <= file.totalCount; counter++) {
        let chunk = file!.file.slice(beginingOfTheChunk, endOfTheChunk);
        const formData = new FormData();
        formData.append('id', counter.toString());
        formData.append('fileName', file.name);
        formData.append('formFile', chunk);

        try {
          await uploadChunks(formData);
          beginingOfTheChunk = endOfTheChunk;
          endOfTheChunk += CHUNK_SIZE;

          if (counter === file.totalCount) {
            const request: CreateLargeGroupingFileDto = {
              userGroupingId,
              originalName: file.name,
              name: file.name,
              description: file.description,
              nameDescription: file.description,
              data: file.data,
              type: values.type,
              category: values.category,
              tags: values.tags,
              date: values.date,
            };
            await createInboxLargeBlob(request);
          }
        } catch (error) {
          dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
        }
      }
    }
  };

  // Helper function to process big files
  const processBigFiles = async (bigFiles: CreateGroupingFileDto[]) => {
    for (const file of bigFiles) {
      await handleFileChunkUpload(file);
    }
  };

  // Helper function for final successful upload handling
  const handleFinalUpload = async () => {
    dispatch(setMessage(SuccessMessage('success.fileUploadedSuccessfully', true)));
  };

  const createNewInboxBlob = async (files: CreateGroupingFileDto[]): Promise<boolean> => {
    try {
      // Process files asynchronously and create DTOs
      const definitiveRequest: CreateGroupingFileDto[] = await Promise.all(files.map((file) => processFile(file)));

      // Filter normal and big files
      const normalFiles = definitiveRequest.filter((file) => !file.isBigFile);
      const bigFiles = definitiveRequest.filter((file) => file.isBigFile);

      // Upload normal files if any
      if (normalFiles.length > 0) {
        await createInboxBlob(normalFiles);
      }

      // Process and upload big files if any
      if (bigFiles.length > 0) {
        await processBigFiles(bigFiles);
      }

      // Handle final successful upload
      await handleFinalUpload();
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
      return false;
    }
  };

  const handleAddDocument = async () => {
    const filesToAdd = await files.reduce(async (totalPromise: Promise<CreateGroupingFileDto[]>, file) => {
      // Resolve the accumulated value (total)
      const total = await totalPromise;

      const currentValue = values.files.find((value) => value.originalName === file.name);
      if (currentValue) {
        try {
          const response = await getValidName(file.name);
          if (response.status === 200) {
            total.push({
              file,
              userGroupingId,
              name: response.data,
              originalName: currentValue.originalName,
              data: currentValue.data,
              description: currentValue.description,
              date: values.date,
              type: values.type,
              category: values.category,
              tags: values.tags,
              isBigFile: file.size > MAX_SIZE,
              totalCount:
                file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1,
            });
          }
        } catch (error) {
          // handle error
        }
      }

      return total;
    }, Promise.resolve([])); // Initial value is a resolved promise with an empty array

    if (filesToAdd.length > 0) {
      try {
        await createNewInboxBlob(filesToAdd);
        setFiles([]);
        goBack();
      } catch (error) {
        // handle error
      }
    }
  };

  const adaptNewString = (value: string): string => {
    return value.includes(`(${newElement})`) ? value.replace(`(${newElement})`, '').trimEnd() : value;
  };

  const handleResetForm = () => {
    setValues(EMPTY_GROUPING_DATA);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setValues({
        ...EMPTY_GROUPING_DATA,
        files: files.map((file) => ({
          userGroupingId,
          data: '',
          name: file.name,
          originalName: file.name,
          size: file.size,
          description: '',
          nameDescription: '',
          totalCount: 0,
          isBigFile: false,
        })),
      });
    }
  }, [files]);
  console.log(files, values);
  return loading ? (
    <LoadComponent />
  ) : (
    <Box className="grouping-file-add-container">
      <Box className="grouping-file-title" onClick={() => goBack()}>
        <ChevronLeftIcon />
        <Typography className="grouping-file-new-title">
          <FormattedMessage id="userGroupingAddFile" />
        </Typography>
      </Box>
      {<HmyInboxFileZone documentProperties={documentOptions} files={files} setFiles={setFiles} />}
      {files.length > 0 && values.files.length > 0 ? (
        <HmyForm
          submitFormDisabled={values.files.some((file) => file.description === '')}
          handleResetForm={handleResetForm}
          handleSubmitForm={handleAddDocument}
          handleCancelForm={goBack}
        >
          <Box className="hmy-inbox-file-zone-selected">
            <GroupingFileAddForm userGroupingId={userGroupingId} values={values} setValues={setValues} />
          </Box>
        </HmyForm>
      ) : null}
    </Box>
  );
};

export default GroupingFileAdd;
