import './management-top-bar.scss';
import { FC } from 'react'
import { managementSubRoutes, SUBROUTE_COMPANIES, SUBROUTE_REPORTS, SUBROUTE_STATISTICS, SUBROUTE_USER, SUBROUTE_USERS } from 'src/components';
import { useIntl } from 'react-intl';
import { Box, useMediaQuery } from '@mui/material';
import CAN from '../../../permissions';
import { READ_COMPANIES, READ_REPORTS, READ_STATISTICS, READ_USERS } from 'src/permissions/permissions';
import usersIcon from '../../../assets/images/usuarios.png';
import statisticsIcon from '../../../assets/images/estadisticas.png';
import companiesIcon from '../../../assets/images/empresas.png';

type ManagementTopBarProps = {
    selected: managementSubRoutes;
    handleClickElement: (value: managementSubRoutes) => void;
};

const ManagementTopBar: FC<ManagementTopBarProps> = ({ selected, handleClickElement }) => {

    const intl = useIntl();
    const matches = useMediaQuery('(max-width: 768px)');
    
    const usersText = intl.formatMessage({id: "user.many"});
    const statisticsText = intl.formatMessage({id: "statistics"});
    const reportsText = intl.formatMessage({id: "reports"});
    const companiesText = intl.formatMessage({id: "companies"});

  return (
    <Box className={`management-navigation`}>
      
        {CAN(READ_USERS) && (
          <Box 
            className={(selected === SUBROUTE_USERS || selected === SUBROUTE_USER)  ? "management-navigation-element active" : "management-navigation-element"}
            onClick={() => handleClickElement(SUBROUTE_USERS)}
          >
            <Box className='management-navigation-element-icon'>
                <img src={usersIcon} alt="icon-inbox"/>
                {matches ? null : <Box>{usersText.toUpperCase()}</Box>}
            </Box>
          </Box>
        )}

        {CAN(READ_REPORTS) && (
          <Box 
            className={selected === SUBROUTE_REPORTS ? "management-navigation-element active" : "management-navigation-element"}
            onClick={() => handleClickElement(SUBROUTE_REPORTS)}
          >
            <Box className='management-navigation-element-icon'>
                <img src={statisticsIcon} alt="icon-inbox"/>
                {matches ? null : <Box>{reportsText.toUpperCase()}</Box>}
            </Box>
          </Box>
        )}

        {/* {CAN(READ_STATISTICS) && (
          <Box 
            className={selected === SUBROUTE_STATISTICS ? "management-navigation-element active" : "management-navigation-element"}
            onClick={() => handleClickElement(SUBROUTE_STATISTICS)}
          >
            <Box className='management-navigation-element-icon'>
                <img src={statisticsIcon} alt="icon-inbox"/>
                {matches ? null : <Box>{statisticsText.toUpperCase()}</Box>}
            </Box>
          </Box>
        )}

        {CAN(READ_COMPANIES) && (
          <Box 
            className={selected === SUBROUTE_COMPANIES ? "management-navigation-element active" : "management-navigation-element"}
            onClick={() => handleClickElement(SUBROUTE_COMPANIES)}
          >
            <Box className='management-navigation-element-icon'>
                <img src={companiesIcon} alt="icon-inbox"/>
                {matches ? null : <Box>{companiesText.toUpperCase()}</Box>}
            </Box>
          </Box>
        )} */}
     
    </Box>
  )
}

export default ManagementTopBar;