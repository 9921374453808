import './hmy-input-text.scss';
import { FC, KeyboardEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';

type HmyInputTextProps = {
    title: string; //Esto tiene que ser el id de la traducción
    width: number | string;
    value: string;
    setValue: (value: string) => void;
    handleEnter: () => void;
  };

const HmyInputText: FC<HmyInputTextProps> = ({title, width, value, setValue, handleEnter}) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({id: title});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const onClickEnter = (e: KeyboardEvent<HTMLDivElement>): void => {
      if(e.key === "Enter"){
        handleEnter();
      }
    }

  return (
    <TextField 
        label={placeholder} 
        variant="outlined" 
        value={value}
        onChange={handleChange}
        style={{ width: width === "100%" ? width : (width > 0 ? `${width}px` : "auto")}}
        className="inputRounded"
        onKeyPress={(e) => onClickEnter(e)}
        InputProps={{
            endAdornment: (
                <InputAdornment 
                    position="end"
                    className="hover-icon"
                    onClick={() => handleEnter()}
                >
                    <SearchIcon fontSize="inherit" color="primary" />
                </InputAdornment>
            )
          }}
    />
  )
}

export { HmyInputText };