import './inbox-document-card.scss';
import { FC, useState } from 'react'
import { BlobDocument, DocumentProps } from 'src/models';
import { Box } from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { HmyFlip } from 'src/components';
import InboxEditForm, { InboxEditFormValues } from './inbox-edit-form';
import { HmyIconDocument } from 'src/components/hmy-icons';

type InboxDocumentCardProps = {
  document: BlobDocument;
  documentProperties: DocumentProps;
  canEditDocument: boolean;
  handleDelete: (documentId: string) => void;
  handleEdit: (document: BlobDocument, resultForm: InboxEditFormValues) => Promise<boolean>;
  handleSelectDocument: (documentId: string)=> void;
};

const InboxDocumentCard: FC<InboxDocumentCardProps> = ({document, documentProperties, canEditDocument, handleDelete, handleEdit, handleSelectDocument}) => {

  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  const handleSelected = () => {
    handleSelectDocument(document.blobId);
  }
  
  const handleCancel = () => {
    setIsFlipped(false);
  }

  const handleEditDocument = async (resultForm: InboxEditFormValues) => {
    if(await handleEdit(document, resultForm)){
      handleCancel();
    }
  }

  const handleDeleteDocument = () => {
    handleDelete(document.blobId);
  }

  return (
    <Box
      className="inbox-document-card-container"
    >
      <HmyFlip
        direction="horizontal"
        flipped={isFlipped}
      >
        <Box
          className="inbox-document-card"
        >
          <Box 
            className="inbox-document-card-row date"
          >
            <FormattedDate year="numeric" month="2-digit" day="2-digit" value={document.simpleDate} />
            {canEditDocument && <Box
                className="icon-edit"
                onClick={() => setIsFlipped(true)}
              >
                <DriveFileRenameOutlineIcon fontSize="inherit" />
              </Box>
            }          
          </Box>
          <Box 
            className="inbox-document-card-row description"
          >
            {document.description}
          </Box>
          <Box 
            className="inbox-document-card-row"
          >
            <FormattedMessage id={document.documentType.toLowerCase()} />
          </Box>
          <Box 
            className="inbox-document-card-row extension"
          >
            <HmyIconDocument 
                color={document.visible ? "visible" : "no-visible"}
                fontSize="small"
                fileId={document.blobId}
                isVisible={document.visible}
                onClick={handleSelected}
            />
            <Box
              className="icon-document-text"
            >
              {document.extension.toUpperCase()}
            </Box>    
          </Box>
        </Box>

        <Box
          className="inbox-document-card-edit"
        >
          <InboxEditForm 
            document={document}
            documentProperties={documentProperties}
            handleEditDocument={handleEditDocument}
            handleCancel={handleCancel}
            handleDeleteDocument={handleDeleteDocument}
          />
        </Box>
      </HmyFlip>
    </Box>
  )
}

export { InboxDocumentCard }