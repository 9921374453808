import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { HmySelectable } from 'src/components';
import { HmyStatusText } from 'src/components/hmy-status-text';
import { useFetchAndLoad } from 'src/hooks';
import { useOrderUpdateStatus } from 'src/hooks/use-order.hook';
import { useTransport } from 'src/hooks/use-transport.hook';
import { ErrorMessage, Selectable, SuccessMessage } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { OrderStateModelService, orderServices } from 'src/services/orders';
import { formatDate } from 'src/utilities';
import '../order.scss';
import TogglerFastShipment from './components/TogglerFastShipment';

type OrderHeaderProps = {
  orderData: BasicOrder;
  editableStatus: boolean;
  editable: boolean;
  currentState: string;
  setCurrentState: (statusId: string) => void;
  orderEdited: boolean;
  changeUrgent?: (urgent: boolean) => void;
};

const OrderHeader: FC<OrderHeaderProps> = ({
  orderData,
  editableStatus,
  editable,
  currentState,
  setCurrentState,
  orderEdited,
  changeUrgent,
}) => {
  const [orderStates, setOrderStates] = useState<Selectable[]>([]);
  const [deliveryDateView, setDeliveryDateView] = useState(new Date());
  const [urgent, setUrgent] = useState<boolean>(orderData.urgent);

  const currentUser = useSelector(currentUserSelector);
  const { callEndpoint } = useFetchAndLoad();
  const getListStates = async () => await callEndpoint(orderServices.getOrderStates());
  const { deliveryDate } = useTransport(orderData);
  const { mutationUpdateOrderStatus } = useOrderUpdateStatus(orderData.saleOrderId);
  const dispatch = useDispatch();

  useEffect(() => {
    getListStates().then((response: { data: OrderStateModelService[] }) =>
      setOrderStates(
        response.data.map((state) => ({
          id: state.saleOrderStatusId,
          text: `status-${state.number}`,
        }))
      )
    );
  }, []);

  useEffect(() => {
    setDeliveryDateView(orderEdited ? deliveryDate : orderData.deliveryDate);
  }, [orderEdited, deliveryDate]);

  useEffect(() => {
    if (changeUrgent) {
      changeUrgent(urgent);
    }
  }, [urgent]);

  const handleUpdateStatus = (status: string) => {
    if (orderData.statusId !== status) {
      mutationUpdateOrderStatus.mutate(
        { saleOrderId: orderData.saleOrderId, statusId: status },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.UpdateOrderStatus', true)));
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.UpdateOrderStatus', true)));
          },
        }
      );
    }
  };

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="generalInformation" />
      </Typography>
      <Box className="order-header">
        <div>
          <div style={{ display: 'flex', rowGap: '6px', flexDirection: 'column' }}>
            <TogglerFastShipment isUrgent={urgent} editable={editable} setUrgent={(urgent) => setUrgent(urgent)} />
            {orderData.number && <span>{orderData.number}</span>}
          </div>
          <span>{orderData.projectName}</span>
        </div>
        {orderData.statusName && (
          <div className="order-header-inline">
            {editableStatus ? (
              <HmySelectable
                value={currentState}
                elements={orderStates}
                setValue={setCurrentState}
                isFormattedValue={true}
                defaultValue=""
                title="status"
                onChange={handleUpdateStatus}
              />
            ) : (
              <>
                <FormattedMessage id="status" />:
                <HmyStatusText id={orderData.statusId} />
              </>
            )}
          </div>
        )}
        <div>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="purchaseDate" />
            </span>
            <span>
              <FormattedDate value={orderData.date ? formatDate(new Date(orderData.date)) : formatDate(new Date())} />
            </span>
          </span>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="deliveryDate" />
            </span>
            <span>
              <FormattedDate value={deliveryDateView} />
            </span>
          </span>
          <span>
            <FormattedMessage id="createdBy" />: {orderData.createdByEmail}
          </span>
        </div>
        <div className="order-header-inline">{/* <FormattedMessage id="deliveryAddress" /> {orderData.address} */}</div>
        {orderData.statusName === '' && (
          <div>
            <span>
              <FormattedMessage id="phone" />
              {`: ${currentUser.phoneNumber}`}
            </span>
            <span>
              <FormattedMessage id="email" />
              {`: ${currentUser.email}`}
            </span>
          </div>
        )}
      </Box>
    </>
  );
};

export default OrderHeader;
