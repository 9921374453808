enum Severity  {
    ERROR = "error",
    INFO = "info",
    SUCCESS = "success",
    WARN = "warning"
  }
  
  export interface Message {
    severity : Severity | undefined,
    message: string
    isOpen: boolean
  }
  
  export const InfoMessage = (message: string, isOpen: boolean) : Message => ({
    severity: Severity.INFO,
    message,
    isOpen
  });
  
  export const SuccessMessage = (message: string, isOpen: boolean) : Message => ({
    severity: Severity.SUCCESS,
    message,
    isOpen
  });
  
  export const WarningMessage = (message: string, isOpen: boolean) : Message => ({
    severity: Severity.WARN,
    message,
    isOpen
  });
  
  export const ErrorMessage = (message: string, isOpen: boolean) : Message => ({
    severity: Severity.ERROR,
    message,
    isOpen
  });