import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IncidenceStatusModelService, incidenceServices } from 'src/services/incidence';
import { useFetchAndLoad } from './use-fetch-load.hook';

export enum STATUS_INCIDENCE {
  OPEN = 1,
  SOLVED = 2,
  CANCELLED = 3,
}

export function useIncidenceStates() {
  const { callEndpoint } = useFetchAndLoad();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getOrderStates'],
    queryFn: () => callEndpoint(incidenceServices.getIncidenceStates()),
    select: ({ data }) => data,
  });

  const getStateNumber = (id: string) => {
    return data ? data.find((state: IncidenceStatusModelService) => state.incidenceStatusId === id).number : -1;
  };
  const getStateId = (number: number) => {
    return data ? data.find((state: IncidenceStatusModelService) => state.number == number).incidenceStatusId : '';
  };

  return { data, isLoading: isLoading || isFetching, getStateNumber, getStateId };
}

export function useUpdateIncidenceStatus(incidenceId: string | undefined) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const queryClient = useQueryClient();

  const mutationUpdateIncidenceStatus = useMutation({
    mutationFn: (incidence: { incidenceId: string; incidenceStatusId: string }) =>
      callEndpoint(incidenceServices.putIncidenceStatus(incidence)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getIncidence', incidenceId] });
    },
  });

  return { mutationUpdateIncidenceStatus };
}
