import './modal-component.scss';
import { FC, ReactNode } from 'react';
import { Box, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type ModalComponentProps = {
  children: ReactNode;
  isOpen: boolean;
  disMissText: string; //id traducción
  acceptText: string; //id traducción
  colorAcceptButton: 'red' | 'green';
  handleDismiss: () => void;
  handleAccept: () => void;
};

const ModalComponent: FC<ModalComponentProps> = ({ children, isOpen, disMissText, acceptText, colorAcceptButton, handleDismiss, handleAccept }) => {

  return (
    <Dialog open={isOpen} onClose={() => handleDismiss()} sx={{borderRadius: "25px !important"}}>
      <Box
        className="modal-container"
      >
        {children}
        <Box
          className="modal-buttons"
        >
          <Box
            className={colorAcceptButton === "green" ? "modal-buttons-cancel" : "modal-buttons-accept"}
            onClick={() => handleAccept()}
          >
            <FormattedMessage id={disMissText} />
          </Box>
          <Box
            className={colorAcceptButton === "green" ? "modal-buttons-accept" : "modal-buttons-cancel"}
            onClick={() => handleDismiss()}
          >
            <FormattedMessage id={acceptText} />
          </Box>
        </Box>
      </Box> 
    </Dialog>
  );
}

export {ModalComponent};