import { Language } from "src/models";
import Spanish from '../../assets/images/flags/es.png';
import English from '../../assets/images/flags/en.png';
import French from '../../assets/images/flags/fr.png';
import Portuguese from '../../assets/images/flags/pt.png';
import Turkish from '../../assets/images/flags/tr.png';

export const AppLanguages: Language[] = [
    {
        name: "es",
        translate: "language.spanish",
        icon: Spanish
    },
    {
        name: "en",
        translate: "language.english",
        icon: English
    },
    {
        name: "fr",
        translate: "language.french",
        icon: French
    },
    {
        name: "pt",
        translate: "language.portuguese",
        icon: Portuguese
    },
    {
        name: "tr",
        translate: "language.turkish",
        icon: Turkish
    }
];