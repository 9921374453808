import './login-form-recovery.scss';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { sessionSelector } from 'src/redux/states';
import { Box, Link, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Captcha } from 'src/components';

type LoginFormRecoveryProps = {
    isVerified: boolean;
    resetCaptcha: boolean;
    handleOpenRecoverPass: () => void;
    setIsVerified: (value: boolean) => void;
    setRecaptchaShowed: (value: boolean) => void;
    handleSubmitRecovery: (email: string) => void;
}

const LoginFormRecovery: FC<LoginFormRecoveryProps> = ({ isVerified, resetCaptcha, handleOpenRecoverPass, setIsVerified, setRecaptchaShowed, handleSubmitRecovery }) => {

    const { language } = useSelector(sessionSelector);
    
    const [email, setEmail] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState<string | null>(null);

    const validateEmail = (value: string) => {
        const regex = RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[^@]+.[a-zA-Z]{2,}$");
        if(value === null || value.trim() === ""){
          setErrorEmail("error.required");
        }
        else if(!regex.test(value.trim()) || value.trim().endsWith('@hmy-group.com')){
            setErrorEmail("error.email");
        }
        else{
            setErrorEmail(null);
        }
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(errorEmail !== null){
            validateEmail(event.target.value);
        }
        setEmail(event.target.value);
    };

    const handleSubmitLogin = () => {
        if(isVerified && email.trim().length > 0 &&  errorEmail === null){
            handleSubmitRecovery(email);
        }
    }

    return (
        <Box
            className="login-form-recovery-content"
        >
            <Box
                className="login-form-recovery-inputs"
            >
                <TextField 
                    label={<FormattedMessage id="email" />}
                    variant="standard" 
                    value={email}
                    onChange={handleChangeEmail}
                    onBlur={e => validateEmail(email)}
                    error={errorEmail !== null}
                    helperText={errorEmail !== null ? <FormattedMessage id={errorEmail} /> : null}
                    fullWidth
                    inputProps={{style: {fontSize: 22}}}
                    className="recovery-inputEmail"
                />
                </Box>
            <Box
                className="login-form-recovery-buttons"
            >
                <Captcha 
                    setIsVerified={setIsVerified}
                    setRecaptchaShowed={setRecaptchaShowed}
                    language={language.name}
                    resetCaptcha={resetCaptcha}
                />
                <Box
                    className={isVerified && email.trim().length > 0 &&  errorEmail === null ? "login-form-recovery-send" : "login-form-recovery-send-disabled"}
                    onClick={() => handleSubmitLogin()}
                >
                    <FormattedMessage id="restorePassword" />
                </Box>
                <Box>
                    <Typography 
                        align="center"
                        className="login-form-recovery-text"
                    >
                        <FormattedMessage id="restorePassword.explication" />
                    </Typography>
                </Box>
                <Box
                    className="login-form-recovery-goback"
                >
                    <Link 
                        component="button" 
                        variant="body2" 
                        onClick={() => handleOpenRecoverPass()} 
                        color="primary" 
                        fontSize={20}
                    >
                        <FormattedMessage id="comeBack"/>
                    </Link>
                </Box>
            </Box>           
        </Box>
    )
}

export default LoginFormRecovery;