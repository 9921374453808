import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { FaTruckFast } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';
import { Selectable } from 'src/models';
import './hmy-selectable-autocomplete.scss';

type AutocompleteCatalogueProps = {
  title: string; //Esto tiene que ser el id de la traducción
  defaultValue: string;
  elements: Selectable[];
  value: string;
  isFormattedValue: boolean;
  setValue: (value: string) => void;
};

const AutocompleteCatalogue: FC<AutocompleteCatalogueProps> = ({ title, value, elements, setValue }) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  useEffect(() => {
    if (value && elements.length > 0) {
      setSelectedValue(elements.find((element) => value === element.id) || null);
    }
  }, [value, elements]);
  const handleChange = (event: React.SyntheticEvent, value: Selectable | null) => {
    if (value) {
      setValue(value.id);
    } else {
      setValue('');
    }
  };
  const options = elements.map((element) => ({
    ...element,
    text: element.text,
  }));

  if (value === null) {
    return null;
  }

  return (
    <FormControl fullWidth color="primary" sx={{ backgroundColor: 'white', borderRadius: '50px' }}>
      <Autocomplete
        className="hmy-selectable-autocomplete"
        id="hmy-select"
        options={options}
        getOptionLabel={(option) => option.text}
        clearIcon={<></>}
        renderInput={(params) => (
          <TextField
            className="hmy-selectable-autocomplete-field"
            {...params}
            style={{ width: '100%' }}
            sx={{ '& input': { border: 'none' } }}
            label={<FormattedMessage id={title} />}
          />
        )}
        disableClearable
        onChange={handleChange}
        value={selectedValue}
        renderOption={(props: any, option: any) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              className="hmy-selectable-autocomplete-text"
              key={key}
              style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}
              {...optionProps}
            >
              <span style={{ fontSize: '16px' }}>{option.text}</span>
              {option.urgent && <FaTruckFast style={{ fontSize: '14px' }} />}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export { AutocompleteCatalogue };
