import './section-matterport.scss';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MatterportVideo from '../../../assets/videos/TechGreenok.mp4';
import BetterWorldImg from '../../../assets/images/betterWorld.png';
import Book from '../../../assets/images/TGbook.png';
import MovilImage from '../../../assets/images/movil.png';
import { HmyBtn } from 'src/components';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

type SectionMatterportProps = {
  handleOpenMatterport: (source: string) => void;
}

const SectionMatterport: FC<SectionMatterportProps> = ({ handleOpenMatterport }) => {

  const { trackEvent } = useMatomo();

  const handleClick = () => {
    handleOpenMatterport("https://my.matterport.com/show/?m=hpo6V9ei7aA");
    trackEvent({ category: 'onClick', action: 'matterport access', name: 'Cariñena matterport access' });
  }

  return (
    <Box className="section-matterport" id="sectionMatterport">
      <Box
        className="matterport-container"
      >
        <Box
          className="matterport-text-container"
        >
          <Typography className="matterport-text-title">
            <FormattedMessage id="visitShowroom" />
          </Typography>
          <Typography sx={{ fontSize: { md: '20px', xs: '18px' }, paddingLeft: {md: '0px', xs: '10px'}, paddingRight: {md: '0px', xs: '10px'}}} align="left" >
            <FormattedMessage 
              id="matterportText1"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
            <span> </span>
            <FormattedMessage 
              id="matterportText2"
              values={{
                b: (chunks) => <strong>{chunks}</strong>
              }}
            />
          </Typography>    
          <Box
            className="btn-matterport-access"
          >
            <HmyBtn 
              title="accessVirtualTour"
              width={300}
              fullwith={false}
              handleClick={handleClick}
              color="primary"
            />
          </Box>
        </Box> 
        <Box
          className="matterport-video-container"
        >
          <video className="matterport-movil-video" src={MatterportVideo} autoPlay muted loop />
          <img className="matterport-movil-image" src={MovilImage} alt="img-movil" />
        </Box> 

        <Box
          className="matterport-notext-container"
        >         
          <Box
            className="matterport-image-container"
          >
            <Box
              className="matterport-image-main"
            >
              <img src={BetterWorldImg} alt="img-BetterWorld" />
            </Box>
            <Box
              className="matterport-image-main2"
            >
              <img className="matterport-book" src={Book} alt="img-Book" />
            </Box>       
          </Box>
        </Box>
        
        <Box
          className="btn-matterport-access-small"
        >
          <HmyBtn 
            title="accessVirtualTour"
            width={270}
            fullwith={false}
            handleClick={handleClick}
            color="primary"
          />
        </Box>

      </Box>       
    </Box>
  )
}

export default SectionMatterport;