import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import getMessages from './translations';
import { storageServices } from './services/storage.service';
import { useSelector, useDispatch } from 'react-redux';
import { sessionSelector, setCurrentUser, setLanguage, setMatomoDisabled } from './redux/states';
import { AppRoutes } from './components';
import { AppLanguages } from './components/language-menu/languages';
import { authServices } from './services';
import { useFetchAndLoad } from './hooks';
import { currentUserAdapter } from './adapters';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import MatomoTracker from '@jonkoops/matomo-tracker';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const App = () => {
  const dispatch = useDispatch();
  const { language, matomoDisabled } = useSelector(sessionSelector);
  const { callEndpoint } = useFetchAndLoad();

  const getUser = async () => await callEndpoint(authServices.getCurrentUser());
  const [matomoInstance, setMatomoInstance] = useState<MatomoTracker>();

  useEffect(() => {
    const token = storageServices.getMiHmyAccessToken();
    if (token) {
      getUser().then((res) => {
        dispatch(setCurrentUser(currentUserAdapter(res.data)));
        storageServices.setLanguage(res.data.language);
        const lang = AppLanguages.filter((x) => x.name === storageServices.getLanguage())[0];
        dispatch(setLanguage(lang));
      });
    } else {
      const lang = AppLanguages.filter((x) => x.name === storageServices.getLanguage())[0];
      dispatch(setLanguage(lang ?? ''));
    }

    if (storageServices.getAcceptCookies()) {
      dispatch(setMatomoDisabled(!storageServices.getAcceptMatomo()));
    }
  }, []);

  useEffect(() => {
    setMatomoInstance(
      createInstance({
        urlBase: process.env.REACT_APP_MATOMO_URL ?? '',
        siteId: Number(process.env.REACT_APP_MATOMO_ID),
        disabled: matomoDisabled,
      })
    );
  }, [matomoDisabled]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return language.name !== '' ? (
    <IntlProvider locale={language.name} messages={getMessages(language.name)}>
      {matomoInstance ? (
        <MatomoProvider value={matomoInstance}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </MatomoProvider>
      ) : null}
    </IntlProvider>
  ) : null;
};

export default App;
