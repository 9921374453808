import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BasicOrder } from 'src/models/orders';
import { orderServices } from '../services/orders';
import { useFetchAndLoad } from './use-fetch-load.hook';
import { useShoppingList } from './use-shopping-list.hook';

export function useOrder(orderId: string | undefined, orderData?: BasicOrder) {
  const { callEndpoint } = useFetchAndLoad();

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getOrder', orderId],
    queryFn: () => callEndpoint(orderServices.getOrder(orderId)),
    select: ({ data }) => data,
    enabled: !!orderId && !orderData,
  });

  const mutationUpdateOrder = useMutation({
    mutationFn: (newData: BasicOrder) => callEndpoint(orderServices.putOrder(newData)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getOrder'] }),
  });

  return { data: orderData ? orderData : data, isLoading: isLoading || isFetching, mutationUpdateOrder };
}

export function useCreateOrder() {
  const queryClient = useQueryClient();
  const { loading, callEndpoint } = useFetchAndLoad();
  const { clearList } = useShoppingList();
  const mutationCreateOrder = useMutation({
    mutationFn: (shoppingList: any) => callEndpoint(orderServices.postOrder(shoppingList)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getOrders'] });
      clearList();
    },
  });

  return { mutationCreateOrder };
}

export function useOrderUpdateStatus(orderId: string) {
  const queryClient = useQueryClient();
  const { loading, callEndpoint } = useFetchAndLoad();
  const mutationUpdateOrderStatus = useMutation({
    mutationFn: ({ saleOrderId, statusId }: { saleOrderId: string; statusId: string }) =>
      callEndpoint(orderServices.putOrderStatus({ saleOrderId, statusId })),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getOrder', orderId] }),
  });

  return { mutationUpdateOrderStatus };
}
