import { FC, useState } from 'react';
import { HmyFormElement, HmyFormSection, HmySelectableSmart } from 'src/components';
import { TextField, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'src/utilities';
import { Selectable, TypeDocument } from 'src/models';

export type InboxNewFileForm = {
  finalName: string;
  description: string;
  date: string;
  documentType: string;
  size: number;
  isBigFile: boolean;
};

type InboxFileAddFormProps = {
  documentTypes: TypeDocument[];
  values: InboxNewFileForm;
  setValues: (values: InboxNewFileForm) => void;
};

const InboxFileAddForm: FC<InboxFileAddFormProps> = ({ documentTypes, values, setValues }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [nameError, setNameError] = useState<string | null>(null);

  const validateName = (name: string): number => {
    if (name === null || name.trim() === '') {
      setNameError('error.required');
      return 1;
    } else {
      setNameError(null);
      return 0;
    }
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError !== null) {
      validateName(event.target.value);
    }
    setValues({
      ...values,
      description: event.target.value,
    });
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      date: formatDate(new Date(event.target.value)),
    });
  };

  const handleChangeDocumentType = (documentType: string) => {
    setValues({
      ...values,
      documentType: documentType,
    });
  };

  const adaptDocumentTypeToSelectable = (value: TypeDocument): Selectable => {
    return {
      id: value.alias,
      text: value.alias.toLowerCase(),
    };
  };

  return (
    <>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="description">
          <TextField
            label=""
            variant="standard"
            value={values.description}
            onChange={handleChangeDescription}
            onBlur={(e) => validateName(values.description)}
            error={nameError !== null}
            helperText={nameError !== null ? <FormattedMessage id={nameError} /> : null}
            fullWidth
            inputProps={{ style: { fontSize: matches ? 16 : 20 } }}
          />
        </HmyFormElement>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="date">
          <TextField
            label=""
            variant="standard"
            type="date"
            value={values.date}
            onChange={handleChangeDate}
            fullWidth
            inputProps={{ style: { fontSize: matches ? 16 : 20 } }}
          />
        </HmyFormElement>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="type">
          <HmySelectableSmart
            title="type"
            showTitle={false}
            defaultValue=""
            elements={documentTypes.filter((x) => x.alias !== 'IMG').map((x) => adaptDocumentTypeToSelectable(x))}
            value={values.documentType}
            isFormattedValue={true}
            fullWidth={true}
            setValue={handleChangeDocumentType}
          />
        </HmyFormElement>
      </HmyFormSection>
    </>
  );
};

export default InboxFileAddForm;
