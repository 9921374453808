import './project-top-bar.scss';
import { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import inboxIcon from '../../../assets/images/inbox.png';
import hitosIcon from '../../../assets/images/hitos.png';
import imagesIcon from '../../../assets/images/imagenes.png';
import vstoreIcon from '../../../assets/images/virtual_store.png';
import configIcon from '../../../assets/images/configuracion.png';
import ordersIcon from '../../../assets/images/orders.png';
import { CompleteProject, VStoreStatus } from 'src/models';
import {
  READ_ORDERS,
  READ_PROJECTFILES,
  READ_PROJECTIMAGES,
  READ_PROJECTINBOX,
  READ_PROJECTMANAGEMENT,
  READ_PROJECTVSTORE,
} from 'src/permissions/permissions';
import { useIntl } from 'react-intl';
import {
  projectSubRoutes,
  SUBROUTE_IMAGES,
  SUBROUTE_INBOX,
  SUBROUTE_LANDMARKS,
  SUBROUTE_ORDERS,
  SUBROUTE_SETTINGS_PROJECT,
  SUBROUTE_VSTORE,
} from 'src/components';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';

type ProjectTopBarProps = {
  project: CompleteProject;
  selected: projectSubRoutes;
  handleClickElement: (value: projectSubRoutes) => void;
};

const ProjectTopBar: FC<ProjectTopBarProps> = ({ project, selected, handleClickElement }) => {
  const intl = useIntl();
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);

  const inboxText = intl.formatMessage({ id: 'inbox' });
  const hitosText = intl.formatMessage({ id: 'landmarks' });
  const imagesText = intl.formatMessage({ id: 'image.many' });
  const vstoreText = intl.formatMessage({ id: 'virtualStore' });
  const settingsText = intl.formatMessage({ id: 'setting' });
  const ordersText = intl.formatMessage({ id: 'orders' });

  const hasRightsVstore = (): boolean => {
    if (IsCustomer(currentUser)) {
      return project.vStore.vStoreStatus === VStoreStatus.Enabled && project.vStore.visibles > 0;
    } else {
      return project.vStore.vStoreStatus === VStoreStatus.Enabled;
    }
  };

  const getNumberTabs = (): number => {
    let total: number = 0;
    if (project.permissions.includes(READ_PROJECTINBOX)) {
      total = total + 1;
    }
    if (project.permissions.includes(READ_PROJECTFILES)) {
      total = total + 1;
    }
    if (project.permissions.includes(READ_PROJECTIMAGES)) {
      total = total + 1;
    }
    if (project.permissions.includes(READ_PROJECTVSTORE) && hasRightsVstore()) {
      total = total + 1;
    }
    if (project.permissions.includes(READ_PROJECTMANAGEMENT)) {
      total = total + 1;
    }
    if (project.permissions.includes(READ_ORDERS)) {
      total = total + 1;
    }
    return total;
  };

  return (
    <Box className={`project-navigation-${getNumberTabs()}`}>
      {project.permissions.includes(READ_PROJECTINBOX) ? (
        <Box
          className={selected === SUBROUTE_INBOX ? 'project-navigation-element active' : 'project-navigation-element'}
          onClick={() => handleClickElement(SUBROUTE_INBOX)}
        >
          <Box className="project-navigation-element-icon">
            <img src={inboxIcon} alt="icon-inbox" />
            {matches ? null : <Box>{inboxText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}

      {project.permissions.includes(READ_PROJECTFILES) ? (
        <Box
          className={
            selected === SUBROUTE_LANDMARKS ? 'project-navigation-element active' : 'project-navigation-element'
          }
          onClick={() => handleClickElement(SUBROUTE_LANDMARKS)}
        >
          <Box className="project-navigation-element-icon">
            <img src={hitosIcon} alt="icon-landmarks" />
            {matches ? null : <Box>{hitosText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}

      {project.permissions.includes(READ_PROJECTIMAGES) ? (
        <Box
          className={selected === SUBROUTE_IMAGES ? 'project-navigation-element active' : 'project-navigation-element'}
          onClick={() => handleClickElement(SUBROUTE_IMAGES)}
        >
          <Box className="project-navigation-element-icon">
            <img src={imagesIcon} alt="icon-images" />
            {matches ? null : <Box>{imagesText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}

      {project.permissions.includes(READ_PROJECTVSTORE) && hasRightsVstore() ? (
        <Box
          className={selected === SUBROUTE_VSTORE ? 'project-navigation-element active' : 'project-navigation-element'}
          onClick={() => handleClickElement(SUBROUTE_VSTORE)}
        >
          <Box className="project-navigation-element-icon">
            <img src={vstoreIcon} alt="icon-vstore" />
            {matches ? null : <Box>{vstoreText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}

      {project.permissions.includes(READ_PROJECTMANAGEMENT) ? (
        <Box
          className={
            selected === SUBROUTE_SETTINGS_PROJECT ? 'project-navigation-element active' : 'project-navigation-element'
          }
          onClick={() => handleClickElement(SUBROUTE_SETTINGS_PROJECT)}
        >
          <Box className="project-navigation-element-icon">
            <img src={configIcon} alt="icon-settings" />
            {matches ? null : <Box>{settingsText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}

      {project.permissions.includes(READ_PROJECTIMAGES) && project.saleOrderEnabled ? (
        <Box
          className={selected === SUBROUTE_ORDERS ? 'project-navigation-element active' : 'project-navigation-element'}
          onClick={() => handleClickElement(SUBROUTE_ORDERS)}
        >
          <Box className="project-navigation-element-icon">
            <img src={ordersIcon} alt="icon-settings" />
            {matches ? null : <Box>{ordersText.toUpperCase()}</Box>}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ProjectTopBar;
