import './landmark-cards.scss';
import { FC } from 'react'
import { CompleteProject, Landmark } from 'src/models';
import LandmarkCard from '../landmark-card';
import { Box, useMediaQuery } from '@mui/material';
import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';

type LandmarkCardsProps = {
    project: CompleteProject;
    landmark: Landmark;
    handleOpenDocument: (fileId: string, isVisible: boolean) => void;
    goToImages: (fileId: string) => void;
};

const LandmarkCards: FC<LandmarkCardsProps> = ({project, landmark, handleOpenDocument, goToImages}) => {
  
    const matches = useMediaQuery('(max-width: 768px)');
    const currentUser = useSelector(currentUserSelector);

    return (
    <Box 
        className="landmark-cards-container"
        sx={{marginTop: IsCustomer(currentUser) && matches ? "30px" : "0px"}}
    >
        <Box
            className="landmark-cards-date-container"
        >
            <Box
                className="landmark-cards-date"
            >
                <FormattedDate year="numeric" month="2-digit" day="2-digit" value={landmark.date} />
            </Box>    
        </Box>
        {landmark.files.map((file, index) => (
            !IsCustomer(currentUser) || file.visible || file.visibles > 0
            ?
            <LandmarkCard 
                key={`${file.blobId}-${index}`}
                project={project}
                file={file}
                handleOpenDocument={handleOpenDocument}
                goToImages={goToImages}
            />
            :
            null
        ))}
    </Box>
  )
}

export default LandmarkCards;