import './project-settings-users.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorMessage, ProjectProfile, SuccessMessage, UserProject } from 'src/models';
import ProjectSettingsUsersListForm from './project-settings-users-list-form';
import { useFetchAndLoad } from 'src/hooks';
import { projectServices } from 'src/services';
import { SetUserProjectDto, SetUsersProjectDto } from 'src/dtos';
import { LoadComponent } from 'src/components';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';

type ProjectSettingsUsersProps = {
    projectId: string;
    title: string;
    canEdit: boolean;
    openForm: boolean; 
    projectProfiles: ProjectProfile[];
    users: UserProject[];
    isHmy: boolean;
    setOpenForm: (value: boolean) => void;
    reloadProject: () => void;
  };

const ProjectSettingsUsers: FC<ProjectSettingsUsersProps> = ({projectId, title, canEdit, openForm, projectProfiles, users, isHmy, setOpenForm, reloadProject}) => {
  
    const dispatch = useDispatch();
    const { loading, callEndpoint } = useFetchAndLoad();
    
    const [usersCopy, setUsersCopy] = useState<UserProject[]>([]);

    const setUsersProject = async (request: SetUsersProjectDto) => await callEndpoint(projectServices.setProjectUsers(request));

    const handleCancelChanges = () => {
        setUsersCopy(JSON.parse(JSON.stringify(users)));
    }

    const handleCloseForm = () => {
        if(openForm){
            setOpenForm(false);
            handleCancelChanges();
        }
        else{
            setOpenForm(true);
        }
    }

    const handleSetUsers = async (addUsers: SetUserProjectDto[], removeUsers: SetUserProjectDto[]) => {
        let request: SetUsersProjectDto = {
            project: projectId,
            isHmy: isHmy,
            add: addUsers ?? [],
            remove: removeUsers ?? []
        };

        try {
            await setUsersProject(request);
            handleCloseForm();
            dispatch(setMessage(SuccessMessage("success.projectUpdated", true)));
            reloadProject();
          } 
          catch (error) {
            dispatch(setMessage(ErrorMessage("error.updatingProject", true)));
          }
    }

    useEffect(() => {
        handleCancelChanges();
    }, [])

    return (
    <Box
      className="project-settings-users"
    >
        <Box
            className="project-settings-users-title-container"
        >
            <Typography
                className="project-settings-users-title"
            >
                <FormattedMessage id={title} />
            </Typography> 
            {canEdit
            ?
                <Box
                    className="project-settings-icon-edit"
                    onClick={() => handleCloseForm()}
                >
                    {openForm
                    ?
                        <CloseIcon fontSize="inherit" />
                    :
                        <DriveFileRenameOutlineIcon fontSize="inherit" />
                    }
                </Box>
            :
                null
            }
        </Box>
        {loading ? <LoadComponent /> : null}
        {openForm
        ?
            <ProjectSettingsUsersListForm 
                users={users}
                usersCopy={usersCopy}
                isHmy={isHmy}
                projectProfiles={projectProfiles}
                setUsersCopy={setUsersCopy}
                handleCancelChanges={handleCancelChanges}
                handleSetUsers={handleSetUsers}
            />
        :
            <Box
                className="project-settings-users-list-container"
            >
                {users.map(user => (
                    <Box
                        key={user.id}
                    >
                        <Box
                            className="project-settings-user-name"
                        >
                            {user.fullName}
                        </Box>
                        {isHmy
                            ?
                            <Box
                                className="project-settings-user-profile"
                            >
                                <FormattedMessage id={user.profileName.toLowerCase()} />
                            </Box>
                        :
                            null
                        }
                    </Box>
                ))}
            </Box>
        }
    </Box>
  )
}

export default ProjectSettingsUsers