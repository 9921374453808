import { GetOrdersDto } from 'src/dtos/orders';
import { GeneralCollection } from 'src/models';
import { EncodeGetURI } from 'src/utilities';
import { http } from '../http-client';
import { BasicOrderModelService, OrderStateModelService, OrderTransportRequestModel } from './model-service';
import { BasicOrder } from 'src/models/orders';

const getOrders = (request: GetOrdersDto) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<BasicOrderModelService>>(EncodeGetURI('saleOrder/all', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const getOrderStates = () => {
  const controller = new AbortController();

  return {
    call: http.get<OrderStateModelService>('saleOrder/states', {
      signal: controller.signal,
    }),
    controller,
  };
};

const getOrder = (orderId: string | undefined) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<BasicOrder>>(`saleOrder/${orderId}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

const postOrder = (request: any) => {
  const controller = new AbortController();

  return {
    call: http.post(`saleOrder`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const putOrder = (request: any) => {
  const controller = new AbortController();

  return {
    call: http.put(`saleOrder`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const putOrderStatus = (request: { saleOrderId: string; statusId: string }) => {
  const controller = new AbortController();

  return {
    call: http.put(`saleOrder/${request.saleOrderId}/status`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const postOrderExcel = (request: { saleOrderId: string }) => {
  const controller = new AbortController();

  return {
    call: http.post(`saleOrder/excel/detail`, request, {
      signal: controller.signal,
      responseType: 'blob',
    }),
    controller,
  };
};

const postOrdersExcel = (request: { saleOrderIds: string[] }) => {
  const controller = new AbortController();

  return {
    call: http.post(`saleOrder/excel`, request, {
      signal: controller.signal,
      responseType: 'blob',
    }),
    controller,
  };
};

const postOrderTransport = (request: OrderTransportRequestModel) => {
  const controller = new AbortController();

  return {
    call: http.post(`saleOrder/transport`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const orderServices = {
  getOrders,
  getOrderStates,
  getOrder,
  postOrder,
  putOrder,
  putOrderStatus,
  postOrderExcel,
  postOrdersExcel,
  postOrderTransport,
};
