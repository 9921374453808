import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ADD_ORDERS } from 'src/permissions/permissions';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';
import Gatete from '../../../assets/images/GATETESORPRENDIDO.png';
import Light from '../../../assets/images/bombilla.png';
import CAN from '../../../permissions';
import HmyModalOrder from '../../order/hmy-modal-order';
import './no-orders.scss';

const NoOrders: FC = () => {
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);

  return (
    <Box className="no-orders-container">
      {true || IsCustomer(currentUser) ? (
        <>
          <Box className="no-orders-image-customer">
            <Box className="no-orders-image-customer-container">
              <Box className="no-orders-image-customer-circle"></Box>
              <img src={Light} alt="light" />
            </Box>
          </Box>
          <Box className="no-orders-text-customer">
            <Typography className="customer-text-title">
              <FormattedMessage id="customerNoOrders" />
            </Typography>
            <Typography className="customer-text-body">
              <FormattedMessage
                id="customerNoOrdersBody"
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </Typography>
            {/*CAN(ADD_ORDERS) && <HmyModalOrder />*/}
          </Box>
        </>
      ) : (
        <>
          <Box className="no-orders-image">
            <img src={Gatete} alt="surprised-cat" />
          </Box>
          <Box className="no-orders-text">{/*CAN(ADD_ORDERS) && <HmyModalOrder />*/}</Box>
        </>
      )}
    </Box>
  );
};

export default NoOrders;
