import { TextareaAutosize } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import './hmy-input-textarea.scss';

type HmyInputTextareaProps = {
  title?: string; //Esto tiene que ser el id de la traducción
  value: string;
  setValue: (value: string) => void;
  minRows?: number;
  maxLength?: number;
  readOnly?: boolean;
};

const HmyInputTextarea: FC<HmyInputTextareaProps> = ({
  title,
  value,
  setValue,
  minRows = 4,
  maxLength,
  readOnly = false,
}) => {
  const intl = useIntl();
  const placeholder = title ? intl.formatMessage({ id: title }) : '';

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className="textarea-container">
      <TextareaAutosize
        minRows={minRows}
        maxLength={maxLength}
        className={`inputTextArea ${readOnly ? 'readOnly' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
      />
      {maxLength && <div className="textarea-length">{`(${value.length}/${maxLength})`}</div>}
    </div>
  );
};

export { HmyInputTextarea };
