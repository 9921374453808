import './user-customer.scss';
import { Box, Checkbox, FormControlLabel, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { basicUserAdapater } from 'src/adapters/user.adapter';
import { HmyAutocomplete, HmyAutocompleteReload, HmyFormSection, HmySelectableSmart, HmySingleForm } from 'src/components';
import { AppLanguages } from 'src/components/language-menu/languages';
import { GetSelectableCustomersDto, GetSelectableUsersDto } from 'src/dtos';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { BasicUser, CompleteUser, Language, Product, Roles, Sector, Selectable } from 'src/models';
import { currentUserSelector } from 'src/redux/states';
import { BasicUserModelService, inspirationServices, ProductModelService, SectorModelService, userServices } from 'src/services';
import { IsCommercial } from 'src/utilities';
import { customerProjectAdapater, productAdapter, sectorAdapter } from 'src/adapters';
import { TinyCustomer } from 'src/models/customer';
import { TinyCustomerModelService } from 'src/services/customer';
import DeleteIcon from '@mui/icons-material/Delete';

type InspirationZone = {
    showMateriaLab: boolean;
    showRoomSpain: boolean;
    showRoomFrance: boolean;
    showRoomEuroshop: boolean;
}

type UserCustomerProps = {
    user: CompleteUser;
    adaptLanguageToSelectable: (value: Language) => Selectable;
    handleUpdateLanguage: (language: string) => void;
    handleUpdateBasic: (name: string, surname: string, notifications: boolean) => void;
    handleUpdateInspirationZone: (showMaterial: boolean, showroomSpain: boolean, showroomFrance: boolean, showRoomEuroshop: boolean, products: number[], sectors: number[]) => void;
    handleUpdateSharedCustomers: (hmyUserList: BasicUser[]) => void;
    handleUpdateCommercial: (hmyUser: BasicUser | null) => void;
    handleUpdateCustomers: (customerList: TinyCustomer[]) => void;
    handleGetAccessObservatory: () => void;
};

const UserCustomer: FC<UserCustomerProps> = ({ user, adaptLanguageToSelectable, handleUpdateLanguage, handleUpdateBasic, handleUpdateInspirationZone, handleUpdateSharedCustomers, handleUpdateCommercial, handleUpdateCustomers, handleGetAccessObservatory}) => {

    const matches = useMediaQuery('(max-width: 768px)');
    const intl = useIntl();
    const currentUser = useSelector(currentUserSelector);
    const { loading, callEndpoint } = useFetchAndLoad();
    const [newName, setNewName] = useState<string>(user.name);
    const [errorNewName, setErrorNewName] = useState<string | null>(null);
    const [newSurname, setNewSurname] = useState<string>(user.surName);
    const [errorNewSurname, setErrorNewSurname] = useState<string | null>(null);
    const [newLanguage, setNewLanguage] = useState<string>(user.language);
    const [newShareCustomer, setNewShareCustomer] = useState<BasicUser[]>(user.sharedCustomers);
    const [sharedUsers, setSharedUsers] = useState<BasicUser[]>([]);
    const [customers, setCustomers] = useState<TinyCustomer[]>([]);
    const [newCustomers, setNewCustomers] = useState<TinyCustomer[]>(user.customers);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [newSectors, setNewSectors] = useState<number[]>(user.sectors);
    const [newProducts, setNewProducts] = useState<number[]>(user.products);
    const [commercials, setCommercials] = useState<BasicUser[]>([]);
    const [newCommercial, setNewCommercial]= useState<BasicUser | null>(user.boss);
    const [newInspirationZone, setNewInspirationZone]= useState<InspirationZone>({
        showMateriaLab: user.showMateriaLab,
        showRoomSpain: user.showRoomSpain,
        showRoomFrance: user.showRoomFrance,
        showRoomEuroshop: user.showRoomEuroshop
    });

    const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));
    const getSelectableCustomers = async (request: GetSelectableCustomersDto) => await callEndpoint(userServices.getSelectableCustomers(request));
    const getSectors = async () => await callEndpoint(inspirationServices.getSectors());
    const getProducts = async () => await callEndpoint(inspirationServices.getProducts());

    const adaptBasicUsers = (data: BasicUserModelService[]): BasicUser[] => {
        return data.map(x => basicUserAdapater(x));
    };

    const adaptCustomers = (data: TinyCustomerModelService[]): TinyCustomer[] => {

        return data.map(x => customerProjectAdapater(x));
    };

    const adaptSectors = (data: SectorModelService[]) => {

        setSectors(data.map(x => sectorAdapter(x)));
    };

    const adaptProducts = (data: ProductModelService[]) => {

        setProducts(data.map(x => productAdapter(x)));
    };

    const handleEditName = () => {
        handleUpdateBasic(newName, user.surName, user.notificationsActive);
    }

    const handleEditSurname = () => {
        handleUpdateBasic(user.name, newSurname, user.notificationsActive);
    }

    const handleEditLanguage = () => {
        handleUpdateLanguage(newLanguage);
    }

    const handleEditShareClient = () => {
        handleUpdateSharedCustomers(newShareCustomer);
    }

    const handleEditSectors = () => {
        handleUpdateInspirationZone(user.showMateriaLab, user.showRoomSpain, user.showRoomFrance, user.showRoomEuroshop, user.products, newSectors);
    }

    const handleEditProducts = () => {
        handleUpdateInspirationZone(user.showMateriaLab, user.showRoomSpain, user.showRoomFrance, user.showRoomEuroshop, newProducts, user.sectors);
    }

    const handleEditInspiration = () => {
        handleUpdateInspirationZone(newInspirationZone.showMateriaLab, newInspirationZone.showRoomSpain, newInspirationZone.showRoomFrance, newInspirationZone.showRoomEuroshop, user.products, user.sectors);
    }

    const handleEditCustomers = () => {
        handleUpdateCustomers(newCustomers);
    }

    const handleEditCommercial = () => {
        handleUpdateCommercial(newCommercial);
      }

    const handleCancelElementUser = (value: BasicUser) => {
        let newUsers = [...newShareCustomer.filter(x => x.id !== value.id)];
        setNewShareCustomer(newUsers);
    }

    const handleCancelElementCustomer = (value: TinyCustomer) => {
        let newClients = [...newCustomers.filter(x => x.customerId !== value.customerId)];
        setNewCustomers(newClients);
    }

    const loadSharedUsers = async (excludedUsers: string[]) => {
        try {
            let request: GetSelectableUsersDto = {
                roleName: Roles.COMMERCIAL,
                usersDiscard: excludedUsers,
                withoutSupervisor: false
            };
    
            const response = await getSelectableUsers(request);
            setSharedUsers(adaptBasicUsers(response.data));
    
        } catch (error) {
        }
    }

    const handleLoadSharedUsers = () => {
        let users = [...newShareCustomer.map(x => x.id)];
        if(IsCommercial(currentUser)){
            users.push(currentUser.id);
        }
        loadSharedUsers(users);
    }

    const handleSetSharedUsers = (value: string | null) => {
        if(value !== null){
            let newUsers = [...newShareCustomer];
            let newUser = sharedUsers.find(x => x.email === value);
            newUsers.push(newUser!);
            setNewShareCustomer(newUsers);
        }  
    }

    const handleLoadCustomers = async (filter: string) => {
        try {
            let request: GetSelectableCustomersDto = {
                take: 15,
                filter: filter,
                includeEtl: true
            };
    
            const response = await getSelectableCustomers(request);
            setCustomers(adaptCustomers(response.data));
    
        } catch (error) {
        }
    }

    const handleSetCustomers = (value: string | null) => {
        if(value !== null){
            let newClients = [...newCustomers];
            let newCustomer = customers.find(x => x.displayValue === value || x.name === value);
            newClients.push(newCustomer!);
            setNewCustomers(newClients);
        }  
    }

    const handleSetCommercial = (value: string | null) => {
        if(value !== null){
          let newCommercial = commercials.find(x => x.email === value);
          setNewCommercial(newCommercial!);
        }
        else{
            setNewCommercial(null);
        }
      }
  
      const handleLoadCommercials = async () => {
        try {
            let request: GetSelectableUsersDto = {
                roleName: Roles.COMMERCIAL,
                usersDiscard: [],
                withoutSupervisor: false
            };
    
            const response = await getSelectableUsers(request);
            setCommercials(adaptBasicUsers(response.data));
    
        } catch (error) {
        }
      }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === null || event.target.value.trim().length === 0){
            setErrorNewName("error.required")
        }else{
            setErrorNewName(null)
        }     
        setNewName(event.target.value);
    };

    const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === null || event.target.value.trim().length === 0){
            setErrorNewSurname("error.required")
        }else{
            setErrorNewSurname(null)
        }     
        setNewSurname(event.target.value);
    };

    const handleChangeLanguage = (value: string) => {
        setNewLanguage(value);
    }

    const handleChangeCheckboxSector = (event: React.ChangeEvent<HTMLInputElement>) => {
        let sectorNumber: number = +event.target.name;       
        if(event.target.checked){
            let newArr: number[] =  [...newSectors];
            newArr.push(sectorNumber);

            setNewSectors(newArr);
        }
        else{
            setNewSectors(newSectors.filter(x => x !== sectorNumber));
        }
    };

    const handleChangeCheckboxProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
        let productNumber: number = +event.target.name;       
        if(event.target.checked){
            let newArr: number[] =  [...newProducts];
            newArr.push(productNumber);

            setNewProducts(newArr);
        }
        else{
            setNewProducts(newProducts.filter(x => x !== productNumber));
        }
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewInspirationZone({
          ...newInspirationZone,
          [event.target.name]: event.target.checked,
        });
    };

    const handleCancelForm = () => {
        setNewName(user.name);
        setErrorNewName(null);
        setNewSurname(user.surName);
        setErrorNewSurname(null);
        setNewCommercial(user.boss);
    }

    useAsync(getSectors, adaptSectors, () => { }, () => { }, []);
    useAsync(getProducts, adaptProducts, () => { }, () => { }, []);

  return (
    <>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="email"
                textValues={[user.userName]}
                canEdit={false}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={() => console.log()}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
            </HmySingleForm>
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="comercial"
                textValues={[newCommercial?.fullName ?? ""]}
                canEdit={currentUser.roles.findIndex(x => x === Roles.ADMIN || x === Roles.ROOT) > -1}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={() => handleEditCommercial()}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <HmyAutocomplete 
                  label="supervisor"
                  showLabel={false}
                  fullWidth={true}
                  value={newCommercial?.email ?? null}
                  loading={loading}
                  options={commercials.map(x => x.email)}
                  helperText={null}
                  setValue={handleSetCommercial}
                  loadResults={handleLoadCommercials}
              />
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="name"
                textValues={[user.name]}
                canEdit={true}
                canDelete={false}
                hasErrors={errorNewName !== null}
                onClickSubmitForm={handleEditName}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <TextField 
                    label="" 
                    variant="standard" 
                    value={newName}
                    onChange={handleChangeName}
                    error={errorNewName !== null}
                    helperText={errorNewName !== null ? <FormattedMessage id={errorNewName} /> : null}
                    fullWidth
                    inputProps={{style: {fontSize: 22}}}
                />
            </HmySingleForm>
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="surnames"
                textValues={[user.surName]}
                canEdit={true}
                canDelete={false}
                hasErrors={errorNewSurname !== null}
                onClickSubmitForm={handleEditSurname}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <TextField 
                    label="" 
                    variant="standard" 
                    value={newSurname}
                    onChange={handleChangeSurname}
                    error={errorNewSurname !== null}
                    helperText={errorNewSurname !== null ? <FormattedMessage id={errorNewSurname} /> : null}
                    fullWidth
                    inputProps={{style: {fontSize: 22}}}
                />
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="language"
                textValues={[intl.formatMessage({id: AppLanguages.find(x => x.name === user.language)?.translate})]}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditLanguage}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <HmySelectableSmart 
                    title="language"   
                    showTitle={false}       
                    defaultValue=""
                    elements={AppLanguages.map(x => adaptLanguageToSelectable(x))}
                    value={newLanguage}
                    isFormattedValue={true}
                    fullWidth={true}
                    setValue={handleChangeLanguage}
                />
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={true}
                isFullWidth={true}
                showTitle={true}
                title="shareClient"
                textValues={user.sharedCustomers.map(x => x.fullName)}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditShareClient}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                {newShareCustomer.map((user, index) => (
                    <Box
                        key={`${user.id}-${index}`}
                        className="user-edit-form-element-content-item-loaded"
                    >
                        <Typography fontSize={matches ? 20 : 22} >{user.fullName}</Typography>
                        <DeleteIcon 
                            onClick={() => handleCancelElementUser(user)}
                            color="error"
                            style={{cursor: "pointer"}}
                        />
                    </Box>
                ))}
                <HmyAutocomplete 
                    label="shareClient"
                    showLabel={false}
                    fullWidth={true}
                    value={null}
                    loading={loading}
                    options={sharedUsers.filter(x => !newShareCustomer.map(u => u.id).includes(x.id)).map(x => x.email)}
                    helperText={null}
                    setValue={handleSetSharedUsers}
                    loadResults={handleLoadSharedUsers}
                />
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={true}
                isFullWidth={true}
                showTitle={true}
                title="sectors"
                textValues={sectors.filter(x => user.sectors.includes(x.sectorId)).map(x => x.sectorName)}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditSectors}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <Box
                    className="user-edit-form-element-content-checkboxes"
                >
                    {sectors.map((sector, index) => (
                        <FormControlLabel 
                            key={`${sector}-${index}`}                         
                            control={
                                <Checkbox 
                                    checked={newSectors.findIndex(x => x === sector.sectorId) > -1}
                                    onChange={handleChangeCheckboxSector} 
                                    name={sector.sectorId.toString()}
                                    size={matches ? "small" : "medium"}
                                />
                            } 
                            label={<Typography fontSize={22} >{sector.sectorName}</Typography>} 
                        />
                    ))} 
                </Box>  
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={true}
                isFullWidth={true}
                showTitle={true}
                title="products"
                textValues={products.filter(x => user.products.includes(x.productoId)).map(x => x.productName)}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditProducts}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <Box
                    className="user-edit-form-element-content-checkboxes"
                >
                    {products.map((product, index) => (
                        <FormControlLabel 
                            key={`${product}-${index}`}                         
                            control={
                                <Checkbox 
                                    checked={newProducts.findIndex(x => x === product.productoId) > -1}
                                    onChange={handleChangeCheckboxProduct} 
                                    name={product.productoId.toString()}
                                    size={matches ? "small" : "medium"}
                                />
                            } 
                            label={<Typography fontSize={22} >{product.productName}</Typography>} 
                        />
                    ))} 
                </Box>  
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={true}
                isFullWidth={true}
                showTitle={true}
                title="inspiration"
                textValues={[
                    `${intl.formatMessage({id: "observatoryAndMateriaLab"})} (${intl.formatMessage({id: newInspirationZone.showMateriaLab ? "activated" : "disabled"})})`,
                    `Showroom Cariñena (${intl.formatMessage({id: newInspirationZone.showRoomSpain ? "activated" : "disabled"})})`,
                    `Showroom Monéteau (${intl.formatMessage({id: newInspirationZone.showRoomFrance ? "activated" : "disabled"})})`,
                    `Showroom Euroshop (${intl.formatMessage({id: newInspirationZone.showRoomEuroshop ? "activated" : "disabled"})})`
                ]}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditInspiration}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                <FormControlLabel 
                    control={
                        <Switch 
                            name="showMateriaLab"
                            color="secondary"
                            checked={newInspirationZone.showMateriaLab}
                            onChange={handleChangeSwitch} 
                            size="small"
                        />
                    } 
                    label={<Typography fontSize={matches ? 20 : 22} ><FormattedMessage id="observatoryAndMateriaLab" /></Typography>} 
                />
                <FormControlLabel 
                    control={
                        <Switch 
                            name="showRoomSpain"
                            color="secondary"
                            checked={newInspirationZone.showRoomSpain}
                            onChange={handleChangeSwitch} 
                            size="small"
                        />
                    } 
                    label={<Typography fontSize={matches ? 20 : 22} >Showroom Cariñena</Typography>} 
                />
                <FormControlLabel 
                    control={
                        <Switch 
                            name="showRoomFrance"
                            color="secondary"
                            checked={newInspirationZone.showRoomFrance}
                            onChange={handleChangeSwitch} 
                            size="small"
                        />
                    } 
                    label={<Typography fontSize={matches ? 20 : 22} >Showroom Monéteau</Typography>} 
                />  
                <FormControlLabel 
                    control={
                        <Switch 
                            name="showRoomEuroshop"
                            color="secondary"
                            checked={newInspirationZone.showRoomEuroshop}
                            onChange={handleChangeSwitch} 
                            size="small"
                        />
                    } 
                    label={<Typography fontSize={matches ? 20 : 22} >Showroom Euroshop</Typography>} 
                />
            </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
            <HmySingleForm
                isMultiline={true}
                isFullWidth={false}
                showTitle={true}
                title="customers"
                textValues={newCustomers.map(x => x.displayValue.trim() !== "" ? x.displayValue : x.name)}
                canEdit={true}
                canDelete={false}
                hasErrors={false}
                onClickSubmitForm={handleEditCustomers}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => console.log()}
            >
                {newCustomers.map((customer, index) => (
                    <Box
                        key={`${customer.customerId}-${index}`}
                        className="user-edit-form-element-content-item-loaded"
                    >
                        <Typography fontSize={matches ? 20 : 24} >{customer.displayValue.trim() !== "" ? customer.displayValue : customer.name}</Typography>
                        <DeleteIcon 
                            onClick={() => handleCancelElementCustomer(customer)}
                            color="error"
                            style={{cursor: "pointer"}}
                        />
                    </Box>
                ))}
                <HmyAutocompleteReload 
                    label="customers"
                    showLabel={false}
                    fullWidth={true}
                    value={null}
                    loading={loading}
                    options={customers.filter(x => !newCustomers.map(u => u.customerId).includes(x.customerId)).map(x => x.displayValue.trim() !== "" ? x.displayValue : x.name)}
                    helperText={null}
                    setValue={handleSetCustomers}
                    loadResults={handleLoadCustomers}
                />
            </HmySingleForm>
            {user.observatoryAccess === null
            ?
                <Box
                    className="user-edit-form-observatoryaccess-container"
                >
                    <Box
                        className="user-edit-form-observatoryaccess-button"
                        onClick={() => handleGetAccessObservatory()}
                    >
                        <FormattedMessage id="getAccessObservatory" />
                    </Box>
                </Box>
            :
                null
            }
        </HmyFormSection>
    </>
  )
}

export default UserCustomer;