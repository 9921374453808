import './hmy-form.scss';
import { FC, ReactNode } from 'react'
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type HmyFormProps = {
    children: ReactNode;
    submitFormDisabled: boolean;
    handleResetForm: () => void;
    handleSubmitForm: () => void;
    handleCancelForm: () => void;
};

const HmyForm: FC<HmyFormProps> = ({ children, submitFormDisabled, handleResetForm, handleSubmitForm, handleCancelForm }) => {

    const onClickSubmitForm = () => {
        if(!submitFormDisabled){
            handleSubmitForm();
        }
    }

    return (
        <Box 
            className="hmy-form-container"
        >
            {children}
            <Box className="hmy-form-button-container" >
                <Box
                    className="hmy-form-button-reset"
                    onClick={() => handleResetForm()}
                >
                    <FormattedMessage id="btn.clear" />
                </Box>
                <Box
                    className={submitFormDisabled ? "hmy-form-button-save-disabled" : "hmy-form-button-save"}
                    onClick={() => onClickSubmitForm()}
                >
                    <FormattedMessage id="save" />
                </Box>
                <Box
                    className="hmy-form-button-cancel"
                    onClick={() => handleCancelForm()}
                >
                    <FormattedMessage id="btn.cancel" />
                </Box>
            </Box>
        </Box>
    )
}

export { HmyForm };