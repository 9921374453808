import './hmy-image-list-viewer.scss';
import { FC } from 'react'
import { HmyImageViewer } from '../hmy-image-viewer';
import { HmyModalDocumentBase } from 'src/models';
import { Box, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type HmyImageListViewerProps = {
    document: HmyModalDocumentBase;
    hasNext: boolean;
    hasPrevious: boolean;
    handleClickNext: () => void;
    handleClickPrevious: () => void;
};

const HmyImageListViewer: FC<HmyImageListViewerProps> = ({document, hasNext, hasPrevious, handleClickNext, handleClickPrevious}) => {
  return (
    <Box
        className="hmy-image-list-viewer-container"
    >
        <Box
            className="hmy-image-list-viewer-button"
        >
            {hasPrevious
                ?
                    <IconButton
                        className="hmy-image-list-viewer-button-btn"
                        onClick={() => handleClickPrevious()}
                    >
                        <ArrowBackIosIcon fontSize="medium" htmlColor="#000" />
                    </IconButton>
                :
                null
            } 
        </Box>
        <Box
            className="hmy-image-list-viewer-content"
        >
            <HmyImageViewer 
                document={document}
            />
        </Box>
        <Box
            className="hmy-image-list-viewer-button"
        >
            {hasNext
                ?
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="next"
                        className="hmy-image-list-viewer-button-btn"
                        onClick={() => handleClickNext()}
                    >
                        <ArrowForwardIosIcon fontSize="medium" htmlColor="#000" />
                    </IconButton>
                :
                null
            }
        </Box>
    </Box>
  )
}

export { HmyImageListViewer }