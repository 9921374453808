import './reports-page.scss';
import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { BasicReportModelService, reportServices } from 'src/services';
import { BasicReport } from 'src/models';
import { basicReportAdapater } from 'src/adapters';
import { HmyModalBasic, LoadComponent } from 'src/components';
import Iframe from 'react-iframe';
import ReportCard from './report-card';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';

const ReportsPage: FC = () => {
  const { loading, callEndpoint } = useFetchAndLoad();
  const [reports, setReports] = useState<BasicReport[]>([]);
  const [openModalReport, setOpenModalReport] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<BasicReport>();
  const currentUser = useSelector(currentUserSelector);
  const { trackPageView, pushInstruction } = useMatomo();

  const getReports = async () => await callEndpoint(reportServices.getReports());

  const adaptReports = (data: BasicReportModelService[]) => {
    let newReports = data.map((x) => basicReportAdapater(x));

    setReports(newReports);
  };

  const handleCloseReport = () => {
    setOpenModalReport(false);
    setSelectedReport(undefined);
  };

  const handleOpenReport = (report: BasicReport) => {
    setSelectedReport(report);
    setOpenModalReport(true);

    pushInstruction('setUserId', currentUser.email);

    trackPageView({
      documentTitle: report.title,
    });
  };

  useAsync(
    getReports,
    adaptReports,
    () => {},
    () => {},
    []
  );

  return (
    <Box className="report-container">
      {loading ? <LoadComponent /> : null}
      <Box className="report-list-container">
        {reports.map((report) => (
          <ReportCard key={report.reportId} report={report} handleOpenReport={handleOpenReport} />
        ))}
      </Box>
      <HmyModalBasic isOpen={openModalReport} handleClose={handleCloseReport}>
        <Box className="report-iframe-container">
          {selectedReport ? (
            <Iframe
              url={selectedReport.link}
              id="drawing-Iframe"
              position="relative"
              scrolling="no"
              allowFullScreen={true}
              className="report-iframe"
            />
          ) : null}
        </Box>
      </HmyModalBasic>
    </Box>
  );
};

export default ReportsPage;
