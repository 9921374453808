import './section-lab.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import LabComponent from './lab-component';
import ObsComponent from './obs-component';

const SectionLab: FC = () => {
  return (
    <Box className="section-lab" id="sectionLab">
        <ObsComponent />
        <LabComponent showSmallEnabled={false} />          
    </Box>
  )
}

export default SectionLab;