import { CreateReportDto, SetUsersReportDto } from "src/dtos";
import { http } from "../http-client";
import { BasicReportModelService } from "./model-service";

const getReports = () => {
    const controller = new AbortController();

    return { call: http.get<BasicReportModelService[]>('report', { signal: controller.signal }), controller };
}

const createReport = (request: CreateReportDto) => {
    const controller = new AbortController();

    return { call: http.post('report', request, { signal: controller.signal }), controller };
}

const setReportUsers = (request: SetUsersReportDto) => {
    const controller = new AbortController();

    return { call: http.post('report/setreportusers', request, { signal: controller.signal }), controller };
}

const deleteReport = (reportId: string) => {
    const controller = new AbortController();

    return { call: http.delete(`report/${reportId}`, { signal: controller.signal }), controller };
}

export const reportServices = {
    getReports,
    createReport,
    setReportUsers,
    deleteReport
}