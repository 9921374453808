import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { Language, Session } from 'src/models';

export interface sessionState extends Session {
}

const initialState: sessionState = {
    language: {
        name: "es",
        translate: "language.spanish",
        icon: ""
    },
    matomoDisabled: true
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setLanguage: (state, { payload }: PayloadAction<Language>) => {
            state.language = payload
        },
        setMatomoDisabled: (state, { payload }: PayloadAction<boolean>) => {
            state.matomoDisabled = payload
        }
    },
});

export const { setLanguage, setMatomoDisabled } = sessionSlice.actions;
export const sessionSelector = (state: RootState) => state.session;
export default sessionSlice.reducer;