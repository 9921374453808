import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const ObsComponent: FC = () => {

  const currentUser = useSelector(currentUserSelector);

  const { trackEvent } = useMatomo();

  const handleClick = () => {
    window.open(`https://observatorio-tendencias.hmy-bus.app/?lang=${currentUser.language}&logtoken=${currentUser.observatoryAccess}&mihmy=${currentUser.id}`);
    trackEvent({ category: 'onClick', action: 'observatory access', name: 'observatory access' });
  }

  return (
    <Box
      className={"section-lab-observatorio"}
      onClick={() => handleClick()}
    >
      <h2>
        <FormattedMessage id="titleObs1"/><br /><FormattedMessage id="titleObs2"/>
      </h2>  
      <Typography className="section-lab-laboratorio-text">
        <FormattedMessage id="textObs"/>
      </Typography>
      <Typography className="section-lab-laboratorio-text">
        <FormattedMessage id="click"/> <u><FormattedMessage id="here"/></u>
      </Typography>
    </Box> 
  )
}

export default ObsComponent;