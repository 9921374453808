import './user-form.scss';
import { FC } from 'react';
import { CreateUserDto } from 'src/dtos';
import { BasicUser, Language, Roles, Selectable } from 'src/models';
import { HmyAutocomplete, HmyFormElement, HmyFormSection, HmySelectableSmart } from 'src/components';
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { AppLanguages } from 'src/components/language-menu/languages';
import CAN from '../../../../permissions';
import { ADD_SUPERVISOR } from 'src/permissions/permissions';
import { FormattedMessage } from 'react-intl';
import { userHmyError } from '..';
import DeleteIcon from '@mui/icons-material/Delete';

type UserHmyFormProps = {
  values: CreateUserDto;
  loading: boolean;
  supervisors: BasicUser[];
  supervised: BasicUser[];
  uerHmyErrors: userHmyError;
  setValues: (value: CreateUserDto) => void;
  adaptLanguageToSelectable: (value: Language) => Selectable;
  loadSupervisors: (isSupervisorBoss: boolean) => void;
  loadSupervised: (excludedUsers: string[]) => void;
  validateEmail: (value: string) => void;
};

const UserHmyForm: FC<UserHmyFormProps> = ({
  values,
  loading,
  supervisors,
  supervised,
  uerHmyErrors,
  setValues,
  adaptLanguageToSelectable,
  loadSupervisors,
  loadSupervised,
  validateEmail,
}) => {
  const matches = useMediaQuery('(max-width: 768px)');

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uerHmyErrors.userName !== null) {
      validateEmail(event.target.value);
    }
    setValues({
      ...values,
      userName: event.target.value,
    });
  };

  const handleUpdateLanguage = (value: string) => {
    setValues({
      ...values,
      language: value,
    });
  };

  const handleLoadSupervisors = () => {
    loadSupervisors(values.role === Roles.SUPERVISOR);
  };

  const handleSetSupervisor = (value: string | null) => {
    if (value !== null) {
      let newSupervisor = supervisors.find((x) => x.email === value);
      setValues({
        ...values,
        boss: newSupervisor!,
      });
    } else {
      setValues({
        ...values,
        boss: value,
      });
    }
  };

  const handleLoadSupervised = () => {
    loadSupervised(values.users.map((x) => x.id));
  };

  const handleSetSupervised = (value: string | null) => {
    if (value !== null) {
      let newUser = supervised.find((x) => x.email === value);
      let newUsers = [...values.users];
      newUsers.push(newUser!);
      setValues({
        ...values,
        users: newUsers,
      });
    }
  };

  const handleCancelElementUser = (value: BasicUser) => {
    let newUsers = [...values.users.filter((x) => x.id !== value.id)];
    setValues({
      ...values,
      users: newUsers,
    });
  };

  return (
    <>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="email">
          <Box className="user-form-element-content-email">
            <Box className="user-form-element-email">
              <TextField
                label=""
                variant="standard"
                value={values.userName}
                onChange={handleChangeEmail}
                onBlur={(e) => validateEmail(values.userName)}
                error={uerHmyErrors.userName !== null}
                helperText={uerHmyErrors.userName !== null ? <FormattedMessage id={uerHmyErrors.userName} /> : null}
                fullWidth
                inputProps={{ style: { fontSize: matches ? 18 : 22 } }}
              />
            </Box>
            <Box className="user-form-element-span">@hmy-group.com</Box>
          </Box>
        </HmyFormElement>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="language">
          <HmySelectableSmart
            title="language"
            showTitle={false}
            defaultValue=""
            elements={AppLanguages.map((x) => adaptLanguageToSelectable(x))}
            value={values.language}
            isFormattedValue={true}
            fullWidth={true}
            setValue={handleUpdateLanguage}
          />
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        {CAN(ADD_SUPERVISOR) && (values.role === Roles.COMMERCIAL || values.role === Roles.SUPERVISOR) && (
          <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="supervisor">
            <HmyAutocomplete
              label="supervisor"
              showLabel={false}
              fullWidth={true}
              value={values.boss?.email ?? null}
              loading={loading}
              options={supervisors.map((x) => x.email)}
              helperText={null}
              setValue={handleSetSupervisor}
              loadResults={handleLoadSupervisors}
            />
          </HmyFormElement>
        )}
        {(values.role === Roles.SUPERVISOR || values.role === Roles.BOSSSUPERVISOR) && (
          <HmyFormElement isMultiline={true} isFullWidth={false} showTitle={true} title="supervised">
            {values.users.map((user, index) => (
              <Box key={`${user}-${index}`} className="user-form-element-content-item-loaded">
                <Typography fontSize={matches ? 18 : 20}>{user.email}</Typography>
                <DeleteIcon
                  onClick={() => handleCancelElementUser(user)}
                  color="error"
                  style={{ cursor: 'pointer' }}
                  fontSize="inherit"
                />
              </Box>
            ))}
            <HmyAutocomplete
              label="supervised"
              showLabel={false}
              fullWidth={true}
              value={null}
              loading={loading}
              options={supervised.filter((x) => !values.users.map((u) => u.id).includes(x.id)).map((x) => x.email)}
              helperText={null}
              setValue={handleSetSupervised}
              loadResults={handleLoadSupervised}
            />
          </HmyFormElement>
        )}
      </HmyFormSection>
    </>
  );
};

export { UserHmyForm };
