import './grouping-information.scss';
import { FC, useState } from 'react'
import { BasicUser, CompleteGrouping, ErrorMessage, Roles, SuccessMessage } from 'src/models';
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { HmyAutocomplete, HmyFormSection, HmySingleForm } from 'src/components';
import { FormattedMessage } from 'react-intl';
import { useFetchAndLoad } from 'src/hooks';
import { BasicUserModelService, groupingServices, userServices } from 'src/services';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { basicUserAdapater, basicUserUGUserAdapater } from 'src/adapters/user.adapter';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetSelectableUsersDto, UpdateGroupingUsersDto } from 'src/dtos';
import { ADD_GROUPINGCUSTOMERUSER, ADD_GROUPINGHMYUSER, EDIT_GROUPING } from 'src/permissions/permissions';

type GroupingInformationProps = {
    grouping: CompleteGrouping;
    reloadGrouping: () => void;
};

const GroupingInformation: FC<GroupingInformationProps> = ({ grouping, reloadGrouping }) => {

  const matches = useMediaQuery('(max-width: 768px)');
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  
  const [newName, setNewName] = useState<string>(grouping.name);
  const [errorNewName, setErrorNewName] = useState<string | null>(null);
  const [newHmyUsers, setNewHmyUsers] = useState<BasicUser[]>(grouping.hmyUsers.map(x => basicUserUGUserAdapater(x)));
  const [hmyUsers, setHmyUsers] = useState<BasicUser[]>([]);
  const [newUsers, setNewUsers] = useState<BasicUser[]>(grouping.users.map(x => basicUserUGUserAdapater(x)));
  const [users, setUsers] = useState<BasicUser[]>([]);

  const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));
  const updateGroupingName = async (name: string) => await callEndpoint(groupingServices.updateGroupingName(grouping.id, name));
  const updateGroupingUsers = async (request: UpdateGroupingUsersDto) => await callEndpoint(groupingServices.updateGroupingUsers(request));

  const adaptBasicUsers = (data: BasicUserModelService[]): BasicUser[] => {
    return data.map(x => basicUserAdapater(x));
  };

  const handleEditName = async () => {
    try {
      await updateGroupingName(newName);
      reloadGrouping();
      dispatch(setMessage(SuccessMessage("success.groupingUpdated", true)));
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.updateUserGrouping", true)));
    }
  }

  const handleEditUsers = async (isHmy: boolean) => {
    let request: UpdateGroupingUsersDto = {
      groupId: grouping.id,
      isHmy: isHmy,
      usersId: isHmy ? newHmyUsers.map(x => x.id) : newUsers.map(x => x.id)
    };
    try {
      await updateGroupingUsers(request);
      reloadGrouping();
      dispatch(setMessage(SuccessMessage("success.groupingUpdated", true)));
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.updateUserGrouping", true)));
    }
    
  }

  const handleEditHmyUsers = () => {
    handleEditUsers(true);
  }

  const handleEditCustomerUsers = () => {
    handleEditUsers(false);
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === null || event.target.value.trim().length === 0){
        setErrorNewName("error.required")
    }else{
        setErrorNewName(null)
    }     
    setNewName(event.target.value);
  };

  const handleCancelElementUser = (value: BasicUser, isHmy: boolean) => {
    if(isHmy){
      let hmyUserList = [...newHmyUsers.filter(x => x.id !== value.id)];
      setNewHmyUsers(hmyUserList);
    }else{
      let userList = [...newUsers.filter(x => x.id !== value.id)];
      setNewUsers(userList);
    }  
  }

  const handleLoadHmyUsers = async () => {
    try {
        let request: GetSelectableUsersDto = {
            roleName: Roles.COMMERCIAL,
            usersDiscard: newHmyUsers.map(x => x.id),
            withoutSupervisor: false
        };

        const response = await getSelectableUsers(request);
        setHmyUsers(adaptBasicUsers(response.data));

    } catch (error) {
    }
  }

  const handleLoadUsers = async () => {
    try {
        let request: GetSelectableUsersDto = {
            roleName: Roles.CUSTOMER,
            usersDiscard: newUsers.map(x => x.id),
            withoutSupervisor: false
        };

        const response = await getSelectableUsers(request);
        setUsers(adaptBasicUsers(response.data));

    } catch (error) {
    }
  }

  const handleSetHmyUsers = (value: string | null) => {
    if(value !== null){
        let newHmyUserList = [...newHmyUsers];
        let newHmyUser = hmyUsers.find(x => x.email === value);
        newHmyUserList.push(newHmyUser!);
        setNewHmyUsers(newHmyUserList);
    }  
  }

  const handleSetUsers = (value: string | null) => {
    if(value !== null){
        let newUserList = [...newUsers];
        let newUser = users.find(x => x.email === value);
        newUserList.push(newUser!);
        setNewUsers(newUserList);
    }  
  }

  const handleCancelForm = () => {
    setNewName(grouping.name);
    setErrorNewName(null);
  }

  return (
    <Box
      className="user-grouping-edit-container"
    >
      <HmyFormSection>
        <HmySingleForm
          isMultiline={false}
          isFullWidth={true}
          showTitle={true}
          title="groupName"
          textValues={[grouping.name]}
          canEdit={grouping.permissions.includes(EDIT_GROUPING)}
          canDelete={false}
          hasErrors={errorNewName !== null}
          onClickSubmitForm={handleEditName}
          onClickCancelForm={handleCancelForm}
          onDeleteForm={() => console.log()}
        >
          <TextField 
            label="" 
            variant="standard" 
            value={newName}
            onChange={handleChangeName}
            error={errorNewName !== null}
            helperText={errorNewName !== null ? <FormattedMessage id={errorNewName} /> : null}
            fullWidth
            inputProps={{style: {fontSize: matches ? 18 : 22}}}
          />
        </HmySingleForm>
      </HmyFormSection>
      <HmyFormSection>
        <HmySingleForm
          isMultiline={true}
          isFullWidth={false}
          showTitle={true}
          title="hmyAccounts"
          textValues={grouping.hmyUsers.map(x => x.fullName)}
          canEdit={grouping.permissions.includes(ADD_GROUPINGHMYUSER)}
          canDelete={false}
          hasErrors={false}
          onClickSubmitForm={handleEditHmyUsers}
          onClickCancelForm={handleCancelForm}
          onDeleteForm={() => console.log()}
        >
            {newHmyUsers.map((hmyUser, index) => (
              <Box
                  key={`${hmyUser.id}-${index}`}
                  className="user-grouping-edit-content-item-loaded"
              >
                  <Typography fontSize={matches ? 18 : 22} >{hmyUser.email}</Typography>
                  {hmyUser.id !== grouping.hmyUsers.find(x => x.profile.name === "Owner")?.id &&
                    <DeleteIcon 
                        onClick={() => handleCancelElementUser(hmyUser, true)}
                        color="error"
                        style={{cursor: "pointer"}}
                        fontSize="inherit"
                    />
                  }
                  
              </Box>
            ))}
            <HmyAutocomplete 
              label="hmyAccounts"
              showLabel={false}
              fullWidth={true}
              value={null}
              loading={loading}
              options={hmyUsers.filter(x => !newHmyUsers.map(u => u.id).includes(x.id)).map(x => x.email)}
              helperText={null}
              setValue={handleSetHmyUsers}
              loadResults={handleLoadHmyUsers}
            />
        </HmySingleForm>
        <HmySingleForm
            isMultiline={true}
            isFullWidth={false}
            showTitle={true}
            title="customerAccounts"
            textValues={grouping.users.map(x => x.fullName)}
            canEdit={grouping.permissions.includes(ADD_GROUPINGCUSTOMERUSER)}
            canDelete={false}
            hasErrors={false}
            onClickSubmitForm={handleEditCustomerUsers}
            onClickCancelForm={handleCancelForm}
            onDeleteForm={() => console.log()}
        >
            {newUsers.map((user, index) => (
                <Box
                    key={`${user.id}-${index}`}
                    className="user-grouping-edit-content-item-loaded"
                >
                    <Typography fontSize={matches ? 18 : 22} >{user.email}</Typography>
                    <DeleteIcon 
                        onClick={() => handleCancelElementUser(user, false)}
                        color="error"
                        style={{cursor: "pointer"}}
                        fontSize="inherit"
                    />
                </Box>
            ))}
            <HmyAutocomplete 
                label="customerAccounts"
                showLabel={false}
                fullWidth={true}
                value={null}
                loading={loading}
                options={users.filter(x => !newUsers.map(u => u.id).includes(x.id)).map(x => x.email)}
                helperText={null}
                setValue={handleSetUsers}
                loadResults={handleLoadUsers}
            />
        </HmySingleForm>
      </HmyFormSection>
    </Box>
  )
}

export default GroupingInformation;