import './hmy-card-user.scss'
import { FC } from 'react'
import { CurrentUser, User } from 'src/models';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CAN from '../../../../permissions';
import { DELETE_USERS } from 'src/permissions/permissions';
import { adjustString } from 'src/utilities';

type HmyCardUserProps = {
    user: User;
    handleClickUser: (userId: string) => void;
    goToProjects: (userEmail: string) => void;
    setSelectedUser: (user: User) => void;
    handleResendEmail: (user: User) => void;
  };

const HmyCardUser: FC<HmyCardUserProps> = ({user, handleClickUser, goToProjects, setSelectedUser, handleResendEmail}) => {
  
    const isExpired = (value: Date | null) : boolean => {
        if(value !== null)
        {
            const now = new Date(Date.now());
            const expires = new Date(value);
            return now > expires;
        }
        else{
            return true;
        }     
    };

    const handleGoToProjects = () => {
        if(user.active && user.projects > 0){
            goToProjects(user.email);
        }
    }

    return (
    <Box  
      className={user.active ? "card-user-container" : "card-user-container card-user-isDeleted"}
    >
        <Box
            className="card-user-main"
        >
            <Box
                className="card-user-email"
                onClick={() => handleClickUser(user.id)}
            >
                <Box>
                    {user.name}
                </Box>
                <Box>
                    {adjustString(user.email, 27)}
                </Box>
            </Box>
            <Box
                className="card-user-status"
            >
                {(user.active && user.emailConfirmed) || user.hmy
            ?
                <CheckIcon
                    className="check-icon"
                    fontSize="inherit"
                /> 
            :
                (!user.active
                ?
                    <CloseIcon
                        className="card-user-isDeleted"
                        fontSize="inherit"
                    />   
                :
                    isExpired(user.emailConfirmExpired)
                    ?
                        <EmailIcon
                            className="resend-icon"
                            onClick={() => handleResendEmail(user)}
                            fontSize="inherit"
                        /> 
                    :
                        <AccessTimeIcon 
                            className="resend-icon"
                            fontSize="inherit"
                        />
                )
            } 
            </Box>    
        </Box>
        <Box
            className="card-user-actions"
        >
            <Box
                className={user.active ? "users-card-projects" : "users-card-projects users-card-delete"}
                onClick={() => handleGoToProjects()}
            >
                {user.projects}
            </Box>
            <Box>
                {CAN(DELETE_USERS) && user.isRemovable && user.emailConfirmed
                    ?
                        (user.active
                        ?
                            <DeleteIcon 
                                className="delete-icon"
                                onClick={() => setSelectedUser(user)}
                                fontSize="inherit"
                            />          
                        :

                            <SettingsBackupRestoreIcon
                                className="card-user-isDeleted"
                                sx={{cursor: "pointer"}}
                                onClick={() => setSelectedUser(user)}
                                fontSize="inherit"
                            />
                        )
                    :
                    null
                }  
            </Box>
        </Box>
    </Box>
  )
}

export default HmyCardUser;