import './section-euroshop-matterport.scss';
import {FC} from 'react'
import { Box, useMediaQuery } from '@mui/material';
import { HmyBtn } from 'src/components';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';

type SectionEuroshopMatterportProps = {
    handleOpenMatterport: (source: string) => void;
  }
const euroshopEN : string = 'https://my.matterport.com/show/?m=MvUC9jVEUxG';
const euroshopES : string = 'https://my.matterport.com/show/?m=f6sU4SsGFYt';

const SectionEuroshopMatterport: FC<SectionEuroshopMatterportProps> = ({handleOpenMatterport}) => {

    const matches = useMediaQuery('(max-width: 768px)');

    const currentUser = useSelector(currentUserSelector);

    const getUrl = () : string => {
        switch(currentUser.language) { 
          case 'es': { 
            return euroshopES;
          } 
          case 'en': { 
            return euroshopEN;
          } 
          default: {
            return euroshopEN;
          }       
       } 
      }
    
    const { trackEvent } = useMatomo();
    
    const handleClick = () => {
        handleOpenMatterport(getUrl());
        trackEvent({ category: 'onClick', action: 'matterport access', name: 'EuroShop matterport access' });
    }

  return (
    <Box className="section-euroshop-matterport" id="sectionEuroshopMatterport">
        <Box
            className="matterport-euroshop-container"
        >
            <Box
                className="matterport-euroshop-container-1"
            >
            </Box>
            <Box
                className="matterport-euroshop-container-2"
            >
                <Box
                    className="matterport-euroshop-container-2-text"
                >
                    <Box>
                        <p className='matterport-euroshop-container-2-text-1'>
                            <span>
                                <b>H</b>appy to
                            </span>
                            <br/>
                            <span>
                                <b>M</b>eet
                            </span>
                            <br/>
                            <span className='alignLeftYou'>
                                <b>Y</b>ou
                            </span>
                        </p>                    
                    </Box>
                    <Box>
                        <p className='matterport-euroshop-container-2-text-2'>
                            <FormattedMessage 
                            id="matterportEuroshopText"
                            values={{
                                b: (chunks) => <strong>{chunks}</strong>
                            }}
                            />
                        </p>
                    </Box>
                    <Box
                        className="btn-euroshop-matterport-access"
                    >
                        <HmyBtn 
                            title="accessVirtualTour"
                            width={300}
                            fullwith={false}
                            handleClick={handleClick}
                            color={matches ? "secondary" : "primary"}
                        />
                    </Box>
                </Box>
                <Box
                className="matterport-euroshop-container-2-lines"
                >
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default SectionEuroshopMatterport;