import './home.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Fab, Link } from '@mui/material';
import SectionWelcome from './section-welcome';
import SectionCarousel from './section-carousel';
import SectionLab from './section-lab';
import SectionMatterport from './section-matterport';
import SingleSectionLab from './section-lab/single-section-lab';
import { Footer, HmyModalBasic } from 'src/components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import SectionFranceMatterport from './section-france-matterport';
import Iframe from 'react-iframe';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import SectionEuroshopMatterport from './section-euroshop-matterport';

const Home: FC = () => {

  const currentUser = useSelector(currentUserSelector);
  
  const [showUpButton, setShowUpButton] = useState<number>(0);
  const [openModalMatterport, setOpenModalMatterport]= useState<boolean>(false);
  const [selectedMatterport, setSelectedMatterport]= useState<string | null>(null);
  const { trackPageView, pushInstruction } = useMatomo();

  const onScroll: EventListener = (event: Event) => {    
    setShowUpButton(window.scrollY);
  };

  const handleCloseMatterport = () => {
    setOpenModalMatterport(false);
    setSelectedMatterport(null);
  }

  const handleOpenMatterport = (source: string) => {
    setSelectedMatterport(source);
    setOpenModalMatterport(true);
  }

  useEffect(() => {
    const win: Window = window;
    win.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    pushInstruction('setUserId', currentUser.email);

    trackPageView({
      documentTitle: 'Inspiration'
    });
  }, []);

  return (
    <Box 
        className="home-container"
    >
      <SectionWelcome />
      <SectionCarousel />
      {currentUser.showMateriaLab && currentUser.observatoryAccess !== null
      ?
        <>
          <SectionLab />
          <SingleSectionLab />
        </>
      :
        null
      }
      {currentUser.showRoomEuroshop
      ?
        <SectionEuroshopMatterport 
          handleOpenMatterport={handleOpenMatterport}
        />
      :
        null
      }
      {currentUser.showRoomSpain
      ?
        <SectionMatterport 
          handleOpenMatterport={handleOpenMatterport}
        />
      :
        null
      }  
      {currentUser.showRoomFrance
      ?
        <SectionFranceMatterport 
          handleOpenMatterport={handleOpenMatterport}
        />
      :
        null
      } 
      <Fab className={showUpButton > 100 ? 'btn-up' : 'btn-up-hidden'} >
        <Link
          href="#sectionWelcome"       
        >
          <ArrowUpwardIcon sx={{fontSize: '35px'}} />
        </Link>    
      </Fab>            
      <Footer 
        showEnabled={true}
      />
      <HmyModalBasic
        isOpen={openModalMatterport}
        handleClose={handleCloseMatterport}    
      >
        <Box 
          className="matterport-iframe-container"
        >
          {selectedMatterport !== null
          ?
          <Iframe 
            url={selectedMatterport}
            id="drawing-Iframe"
            position="relative"
            scrolling="no"
            allowFullScreen={true}
            className="drawing-iframe"
          />
        :
        null
        }
        </Box>
      </HmyModalBasic>
    </Box>
  )
}

export default Home;