import './static-password.scss';
import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LogoMiHmy from '../../assets/images/logo_mihmy_azul.svg';
import { FormattedMessage } from 'react-intl';
import { HmyInputPassword } from 'src/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, StaticUser, SuccessMessage } from 'src/models';
import { authServices, StaticUserModelService } from 'src/services';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { RegistrationUserDto } from 'src/dtos';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';

type StaticPasswordProps = {
  isRecoverPass: boolean;
};

const StaticPassword: FC<StaticPasswordProps> = ({ isRecoverPass }) => {

  const { token } = useParams();
  const { callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [user, setUser] = useState<StaticUser | null>(null);

  const getStaticUser = async () => await callEndpoint(authServices.getStaticUser({token: token ?? "", type: isRecoverPass ? "RecoveryPassToken" : "RegistrationToken"}));
  const completeRegistrationUser = async (value: RegistrationUserDto) => await callEndpoint(authServices.completeRegistrationUser(value));
  const recoveryPassword = async (value: RegistrationUserDto) => await callEndpoint(authServices.recoveryPassword(value));
  
  const adaptStaticUser = (data: StaticUserModelService) => {

    setUser({
      name: data.name,
      email: data.email
    });

  };

  const handleSubmit = async () => {
    if(isValid && password === password2){
      const request: RegistrationUserDto = {
        token: token ?? "",
        password: password
      };
      try {
        if(isRecoverPass){
          await recoveryPassword(request);
          dispatch(setMessage(SuccessMessage("success.recoverPassRedirect", true)));
        }else{
          await completeRegistrationUser(request);
          dispatch(setMessage(SuccessMessage("success.completeRegistration", true)));
        }   
        navigate("/");
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.errorOccurred", true)));
      }
    }

  }

  useAsync(getStaticUser, adaptStaticUser, () => { }, () => { }, [token]);

  return (
    <Box
      className="static-password-container"
    >
      <Box
          className="static-password-logo-container"
        >
          <Box
            className="static-password-logo"
          >
            <img src={LogoMiHmy} alt="logo" />
          </Box>
      </Box>
      <Box
        className="static-password-main"
      >
        <Typography className="static-password-text" align="center">
            <FormattedMessage id={user !== null ? (isRecoverPass ? "enterPassword" : "passwordWelcome") : "notHavePermission"} />
        </Typography>
        {user !== null
          ?
            <Box
              className="static-password-content"
            >
              <HmyInputPassword 
                withValidation={true}
                label={isRecoverPass ? "newPassword" : "password"}
                userName={user.name}
                email={user.email}
                password={password}
                setPassword={setPassword}
                setIsValid={setIsValid}
              />
              <HmyInputPassword 
                withValidation={false}
                label="repeatNewPassword"
                userName={user.name}
                email={user.email}
                password={password2}
                setPassword={setPassword2}
                setIsValid={setIsValid}
              />
              <Box
                className="static-password-button-container"
              >
                <Box 
                  className={isValid && password === password2 ? "static-password-button" : "static-password-button-disabled"}
                  onClick={() => handleSubmit()}
                >
                  <FormattedMessage id="send" />
                </Box>
              </Box>
            </Box>
          :
            null
        }       
      </Box>
    </Box>   
  )
}

export default StaticPassword;