export const combineUrl = (values: string[]) : string => {
    let rdo = '';

    values.forEach(value => {
        if (rdo.length > 0 && rdo.slice(-1) !== '/') {
            rdo += '/';
        }
        if (value.slice(0, 1) === '/' && rdo.length > 0) {
            value = value.slice(1) ;
        }
        if (value) {
            rdo += value;
        }
    });
    return rdo;
}

export const formatDate = (date: Date) => {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();

    return (`${yyyy}-${mm}-${dd}`);
}

export const compareDate = (date1: Date, date2: string): boolean => {
    let today = new Date(date1);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let today2 = new Date(date2);
    let dd2 = String(today2.getDate()).padStart(2, '0');
    let mm2 = String(today2.getMonth() + 1).padStart(2, '0');
    let yyyy2 = today2.getFullYear();

    return dd !== dd2 || mm !== mm2 || yyyy !== yyyy2;
}