import './user-form.scss';
import { FC } from 'react';
import {
  HmyAutocomplete,
  HmyAutocompleteReload,
  HmyFormElement,
  HmyFormSection,
  HmySelectableSmart,
} from 'src/components';
import { Box, Checkbox, FormControlLabel, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { AppLanguages } from 'src/components/language-menu/languages';
import { CreateUserDto } from 'src/dtos';
import { BasicUser, Language, Product, Sector, Selectable } from 'src/models';
import { FormattedMessage } from 'react-intl';
import { userCustomerError } from '..';
import { TinyCustomer } from 'src/models/customer';
import DeleteIcon from '@mui/icons-material/Delete';
import { IsAdmin } from 'src/utilities';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';

type UserCustomersFormProps = {
  values: CreateUserDto;
  loading: boolean;
  sharedUsers: BasicUser[];
  customers: TinyCustomer[];
  sectors: Sector[];
  products: Product[];
  commercials: BasicUser[];
  customerErrors: userCustomerError;
  setValues: (value: CreateUserDto) => void;
  adaptLanguageToSelectable: (value: Language) => Selectable;
  loadSharedUsers: (excludedUsers: string[]) => void;
  loadCommercials: (filter: string) => void;
  loadCustomers: (filter: string) => void;
  validateEmail: (value: string) => void;
  validateName: (value: string) => void;
  validateSurname: (value: string) => void;
  validateCommercial: (value: BasicUser | null) => void;
};

const UserCustomersForm: FC<UserCustomersFormProps> = ({
  values,
  loading,
  sharedUsers,
  customers,
  sectors,
  products,
  commercials,
  customerErrors,
  setValues,
  adaptLanguageToSelectable,
  loadSharedUsers,
  loadCommercials,
  loadCustomers,
  validateEmail,
  validateName,
  validateSurname,
  validateCommercial,
}) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.userName !== null) {
      validateEmail(event.target.value);
    }
    setValues({
      ...values,
      userName: event.target.value,
    });
  };

  const handleUpdateLanguage = (value: string) => {
    setValues({
      ...values,
      language: value,
    });
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.name !== null) {
      validateName(event.target.value);
    }
    setValues({
      ...values,
      name: event.target.value,
    });
  };

  const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.surname !== null) {
      validateSurname(event.target.value);
    }
    setValues({
      ...values,
      surname: event.target.value,
    });
  };

  const handleCancelElementUser = (value: BasicUser) => {
    let newUsers = [...values.users.filter((x) => x.id !== value.id)];
    setValues({
      ...values,
      users: newUsers,
    });
  };

  const handleLoadSharedUsers = () => {
    loadSharedUsers(values.users.map((x) => x.id));
  };

  const handleSetSharedUsers = (value: string | null) => {
    if (value !== null) {
      let newUser = sharedUsers.find((x) => x.email === value);
      let newUsers = [...values.users];
      newUsers.push(newUser!);
      setValues({
        ...values,
        users: newUsers,
      });
    }
  };

  const handleChangeCheckboxSector = (event: React.ChangeEvent<HTMLInputElement>) => {
    let sectorNumber: number = +event.target.name;
    if (event.target.checked) {
      let newArr: number[] = [...values.sectors];
      newArr.push(sectorNumber);

      setValues({
        ...values,
        sectors: newArr,
      });
    } else {
      setValues({
        ...values,
        sectors: values.sectors.filter((x) => x !== sectorNumber),
      });
    }
  };

  const handleChangeCheckboxProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    let productNumber: number = +event.target.name;
    if (event.target.checked) {
      let newArr: number[] = [...values.products];
      newArr.push(productNumber);

      setValues({
        ...values,
        products: newArr,
      });
    } else {
      setValues({
        ...values,
        products: values.products.filter((x) => x !== productNumber),
      });
    }
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const handleLoadCommercials = (filter: string) => {
    loadCommercials(filter);
  };

  const handleSetCommercial = (value: string | null) => {
    if (value !== null && value.length > 0) {
      let newCommercial = commercials.find((x) => x.email === value);
      if (customerErrors.commercial !== null) {
        validateCommercial(newCommercial ?? null);
      }

      if (newCommercial) {
        setValues({
          ...values,
          commercial: newCommercial,
        });
      } else {
        setValues({
          ...values,
          commercial: null,
        });
      }
    } else {
      setValues({
        ...values,
        commercial: null,
      });
    }
  };

  const handleLoadCustomers = (filter: string) => {
    loadCustomers(filter);
  };

  const handleSetCustomer = (value: string | null) => {
    if (value !== null && value.length > 0) {
      let newCustomer = customers.find((x) => x.displayValue === value);
      if (newCustomer) {
        setValues({
          ...values,
          customer: newCustomer,
        });
      } else {
        setValues({
          ...values,
          customer: null,
        });
      }
    } else {
      setValues({
        ...values,
        customer: null,
      });
    }
  };

  return (
    <>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="email">
          <Box className="user-form-element-content-email">
            <Box className="user-form-element-email">
              <TextField
                label=""
                variant="standard"
                value={values.userName}
                onChange={handleChangeEmail}
                onBlur={(e) => validateEmail(values.userName)}
                error={customerErrors.userName !== null}
                helperText={customerErrors.userName !== null ? <FormattedMessage id={customerErrors.userName} /> : null}
                fullWidth
                inputProps={{ style: { fontSize: matches ? 18 : 20 } }}
              />
            </Box>
          </Box>
        </HmyFormElement>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="language">
          <HmySelectableSmart
            title="language"
            showTitle={false}
            defaultValue=""
            elements={AppLanguages.map((x) => adaptLanguageToSelectable(x))}
            value={values.language}
            isFormattedValue={true}
            fullWidth={true}
            setValue={handleUpdateLanguage}
          />
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="name">
          <TextField
            label=""
            variant="standard"
            value={values.name}
            onChange={handleChangeName}
            onBlur={(e) => validateName(values.name)}
            error={customerErrors.name !== null}
            helperText={customerErrors.name !== null ? <FormattedMessage id={customerErrors.name} /> : null}
            fullWidth
            inputProps={{ style: { fontSize: matches ? 18 : 20 } }}
          />
        </HmyFormElement>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="surnames">
          <TextField
            label=""
            variant="standard"
            value={values.surname}
            onChange={handleChangeSurname}
            onBlur={(e) => validateSurname(values.surname)}
            error={customerErrors.surname !== null}
            helperText={customerErrors.surname !== null ? <FormattedMessage id={customerErrors.surname} /> : null}
            fullWidth
            inputProps={{ style: { fontSize: matches ? 18 : 20 } }}
          />
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement isMultiline={true} isFullWidth={true} showTitle={true} title="shareClient">
          {values.users.map((user, index) => (
            <Box key={`${user}-${index}`} className="user-form-element-content-item-loaded">
              <Typography fontSize={matches ? 18 : 20}>{user.email}</Typography>
              <DeleteIcon
                onClick={() => handleCancelElementUser(user)}
                color="error"
                style={{ cursor: 'pointer' }}
                fontSize="inherit"
              />
            </Box>
          ))}
          <HmyAutocomplete
            label="shareClient"
            showLabel={false}
            fullWidth={true}
            value={null}
            loading={loading}
            options={sharedUsers.filter((x) => !values.users.map((u) => u.id).includes(x.id)).map((x) => x.email)}
            helperText={null}
            setValue={handleSetSharedUsers}
            loadResults={handleLoadSharedUsers}
          />
        </HmyFormElement>
      </HmyFormSection>
      <Box className="user-form-inspiration-title">
        <Typography className="user-form-title">
          <FormattedMessage id="inspiration" />:
        </Typography>
      </Box>
      <HmyFormSection>
        <HmyFormElement isMultiline={true} isFullWidth={true} showTitle={true} title="sector">
          <Box className="user-form-element-content-checkboxes">
            {sectors.map((sector, index) => (
              <FormControlLabel
                key={`${sector}-${index}`}
                control={
                  <Checkbox
                    checked={values.sectors.findIndex((x) => x === sector.sectorId) > -1}
                    onChange={handleChangeCheckboxSector}
                    name={sector.sectorId.toString()}
                    size="small"
                  />
                }
                label={<Typography fontSize={matches ? 18 : 20}>{sector.sectorName}</Typography>}
              />
            ))}
          </Box>
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement isMultiline={true} isFullWidth={true} showTitle={true} title="product">
          <Box className="user-form-element-content-checkboxes">
            {products.map((product, index) => (
              <FormControlLabel
                key={`${product}-${index}`}
                control={
                  <Checkbox
                    checked={values.products.findIndex((x) => x === product.productoId) > -1}
                    onChange={handleChangeCheckboxProduct}
                    name={product.productoId.toString()}
                    size="small"
                  />
                }
                label={<Typography fontSize={matches ? 18 : 20}>{product.productName}</Typography>}
              />
            ))}
          </Box>
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement isMultiline={true} isFullWidth={true} showTitle={false} title={null}>
          <FormControlLabel
            control={
              <Switch
                name="showMateriaLab"
                color="secondary"
                checked={values.showMateriaLab}
                onChange={handleChangeSwitch}
                size="small"
              />
            }
            label={
              <Typography fontSize={matches ? 18 : 20}>
                <FormattedMessage id="observatoryAndMateriaLab" />
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Switch
                name="showRoomSpain"
                color="secondary"
                checked={values.showRoomSpain}
                onChange={handleChangeSwitch}
                size="small"
              />
            }
            label={<Typography fontSize={matches ? 18 : 20}>Showroom Cariñena</Typography>}
          />
          <FormControlLabel
            control={
              <Switch
                name="showRoomFrance"
                color="secondary"
                checked={values.showRoomFrance}
                onChange={handleChangeSwitch}
                size="small"
              />
            }
            label={<Typography fontSize={matches ? 18 : 20}>Showroom Monéteau</Typography>}
          />
          <FormControlLabel
            control={
              <Switch
                name="showRoomEuroshop"
                color="secondary"
                checked={values.showRoomEuroshop}
                onChange={handleChangeSwitch}
                size="small"
              />
            }
            label={<Typography fontSize={matches ? 18 : 20}>Showroom Euroshop</Typography>}
          />
        </HmyFormElement>
      </HmyFormSection>
      <Box className="user-form-inspiration-title">
        <Typography className="user-form-title">
          <FormattedMessage id="customer" />:
        </Typography>
      </Box>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={false} title={null}>
          <HmyAutocompleteReload
            label="customers"
            showLabel={false}
            fullWidth={true}
            value={values.customer?.displayValue ?? ''}
            loading={loading}
            options={customers.map((x) => x.displayValue)}
            helperText={null}
            setValue={handleSetCustomer}
            loadResults={handleLoadCustomers}
          />
        </HmyFormElement>
      </HmyFormSection>
      {IsAdmin(currentUser) ? (
        <>
          <Box className="user-form-inspiration-title">
            <Typography className="user-form-title">
              <FormattedMessage id="commercial" />:
            </Typography>
          </Box>
          <HmyFormSection>
            <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={false} title={null}>
              <HmyAutocomplete
                label="commercial"
                showLabel={false}
                fullWidth={true}
                value={values.commercial?.email ?? ''}
                loading={loading}
                options={commercials.map((x) => x.email)}
                helperText={customerErrors.commercial}
                setValue={handleSetCommercial}
                loadResults={handleLoadCommercials}
              />
            </HmyFormElement>
          </HmyFormSection>
        </>
      ) : null}
    </>
  );
};

export { UserCustomersForm };
