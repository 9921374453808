import './user.scss';
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { BasicUser, CompleteUser, ErrorMessage, Language, Selectable, SuccessMessage } from 'src/models';
import { authServices, CompleteUserModelService, inspirationServices, userServices } from 'src/services';
import { completeUserAdapter } from 'src/adapters/user.adapter';
import { LoadComponent } from 'src/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UserHmy from './user-hmy';
import UserCustomer from './user-customer';
import { UpdateCustomersDto, UpdateInspirationZoneDto, UpdateLanguageDto, UpdateBasicsDto, UpdateRoleDto, UpdateSharedCustomersDto, UpdateSupervisedDto, UpdateSupervisorDto, UpdateCommercialDto } from 'src/dtos';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { TinyCustomer } from 'src/models/customer';

type UserProps = {
  goToUsers: () => void;
};

const User: FC<UserProps> = ({ goToUsers }) => {

  const { userid } = useParams();
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  
  const [user, setUser] = useState<CompleteUser>();

  const getUser = async () => await callEndpoint(userServices.getUser(userid ?? ""));
  const updateLangUser = async (value: UpdateLanguageDto) => await callEndpoint(authServices.updateLanguageUser(value));
  const updateRole = async (value: UpdateRoleDto) => await callEndpoint(userServices.updateRole(value));
  const updateSupervisor = async (value: UpdateSupervisorDto) => await callEndpoint(userServices.updateSupervisor(value));
  const updateSupervised = async (value: UpdateSupervisedDto) => await callEndpoint(userServices.updateSupervised(value));
  const updateCommercial = async (value: UpdateCommercialDto) => await callEndpoint(userServices.updateCommercial(value));
  const updateBasics = async (value: UpdateBasicsDto) => await callEndpoint(userServices.updateBasics(value));
  const updateInspirationZone = async (value: UpdateInspirationZoneDto) => await callEndpoint(userServices.updateInspirationZone(value));
  const updateSharedCustomers = async (value: UpdateSharedCustomersDto) => await callEndpoint(userServices.updateSharedCustomers(value));
  const updateCustomers = async (value: UpdateCustomersDto) => await callEndpoint(userServices.updateCustomers(value));
  const updateObservatoryAccess = async (email: string) => await callEndpoint(inspirationServices.getObservatoryAccess(email));

  const adaptUser = (data: CompleteUserModelService) => {

    let newUser = completeUserAdapter(data);
    setUser(newUser);

  };

  const adaptLanguageToSelectable = (value: Language): Selectable => {
    return {
        id: value.name,
        text: value.translate
    }
  }

  const handleUpdateLanguage = async (language: string) => {
    if(user && language !== null && language !== user.language){
     
      const request: UpdateLanguageDto = {
        userId: user.id,
        language: language
      };
      try {
        await updateLangUser(request);
        setUser({
          ... user,
          language: language
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error) {
        dispatch(setMessage(ErrorMessage("error.updatingLanguage", true)));
      }
    }
  }

  const reloadUser = async () => {
    try {
      const response = await getUser();
      adaptUser(response.data);
    } catch (error) {
    }
  }

  const handleUpdateRole = async (role: string) => {
    if(user && role !== null && role !== user.roles[0]){
     
      const request: UpdateRoleDto = {
        id: user.id,
        role: role
      };
      try {
        await updateRole(request);
        reloadUser();
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateSupervisor = async (supervisor: BasicUser | null) => {
    if(user){
     
      const request: UpdateSupervisorDto = {
        id: user.id,
        supervisorId: supervisor === null ? null : supervisor.id
      };
      try {
        await updateSupervisor(request);
        setUser({
          ... user,
          boss: supervisor
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateSupervised = async (supervisedList: BasicUser[]) => {
    if(user && (supervisedList.filter(x => !user.supervisedUsers.map(u => u.id).includes(x.id)).length > 0 ||
    user.supervisedUsers.filter(x => !supervisedList.map(u => u.id).includes(x.id)).length > 0)){
     
      const request: UpdateSupervisedDto = {
        id: user.id,
        supervisedId: supervisedList.length > 0 ? supervisedList.map(x => x.id) : []
      };
      try {
        await updateSupervised(request);
        setUser({
          ... user,
          supervisedUsers: supervisedList
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateCommercial = async (commercial: BasicUser | null) => {
    if(user && commercial !== null){
     
      const request: UpdateCommercialDto = {
        id: user.id,
        commercialId: commercial.id
      };
      try {
        await updateCommercial(request);
        setUser({
          ... user,
          boss: commercial
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateBasic = async (name: string, surname: string, notifications: boolean) => {
    if((user && name.trim() !== user.name) || (user && surname.trim() !== user.surName) || (user && notifications !== user.notificationsActive))
    {
     
      const request: UpdateBasicsDto = {
        id: user.id,
        name: name,
        surName: surname,
        notifications: notifications
      };
      try {
        await updateBasics(request);
        if(name.trim() !== user.name){
          setUser({
            ... user,
            name: name
          });
        }
        else if(surname.trim() !== user.surName){
          setUser({
            ... user,
            surName: surname
          });
        }
        else{
          setUser({
            ... user,
            notificationsActive: notifications
          });
        }
        
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateInspirationZone = async (showMaterial: boolean, showroomSpain: boolean, showroomFrance: boolean, showRoomEuroshop: boolean, products: number[], sectors: number[]) => {
    if(user && (showMaterial !== user.showMateriaLab || 
      showroomSpain !== user.showRoomSpain || 
      showroomFrance !== user.showRoomFrance || 
      showRoomEuroshop !== user.showRoomEuroshop ||
      products !== user.products || sectors !== user.sectors))
    {
     
      const request: UpdateInspirationZoneDto = {
        id: user.id,
        showMateriaLab: showMaterial,
        showRoomSpain: showroomSpain,
        showRoomFrance: showroomFrance,
        showRoomEuroshop: showRoomEuroshop,
        products: products,
        sectors: sectors
      };
      try {
        await updateInspirationZone(request);
        setUser({
          ... user,
          showMateriaLab: showMaterial,
          showRoomSpain: showroomSpain,
          showRoomFrance: showroomFrance,
          showRoomEuroshop: showRoomEuroshop,
          products: products,
          sectors: sectors
        });
        
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }
  
  const handleUpdateSharedCustomers = async (hmyUserList: BasicUser[]) =>
  {
     
    if(user && (hmyUserList.filter(x => !user.sharedCustomers.map(u => u.id).includes(x.id)).length > 0 ||
    user.sharedCustomers.filter(x => !hmyUserList.map(u => u.id).includes(x.id)).length > 0)){
     
      const request: UpdateSharedCustomersDto = {
        id: user.id,
        hmyUsersId: hmyUserList.length > 0 ? hmyUserList.map(x => x.id) : []
      };
      try {
        await updateSharedCustomers(request);
        setUser({
          ... user,
          sharedCustomers: hmyUserList
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleUpdateCustomers = async (customerList: TinyCustomer[]) =>
  {
     
    if(user && (customerList.filter(x => !user.customers.map(u => u.customerId).includes(x.customerId)).length > 0 ||
    user.customers.filter(x => !customerList.map(u => u.customerId).includes(x.customerId)).length > 0)){
     
      const request: UpdateCustomersDto = {
        id: user.id,
        customersId: customerList.length > 0 ? customerList.map(x => x.customerId) : []
      };
      try {
        await updateCustomers(request);
        setUser({
          ... user,
          customers: customerList
        });
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  const handleGetAccessObservatory = async () => {
    if(user){
      try {
        const response = await updateObservatoryAccess(user.userName);
        setUser({
          ... user,
          observatoryAccess: response.data
        });
        
        dispatch(setMessage(SuccessMessage("success.userUpdated", true)));
      } 
      catch (error: any) {
        dispatch(setMessage(ErrorMessage("error.updateUser", true)));
      }
    }
  }

  useAsync(getUser, adaptUser, () => { }, () => { }, [userid]);

  return (
    <Box
      className="user-edit-container"
    >
      {loading ? <LoadComponent /> : null}
      {user
      ?
        <>
          <Box
            className="user-title"
            onClick={() => goToUsers()}
          >
            <ChevronLeftIcon fontSize="inherit" /> 
            <Typography className="user-edit-text">
              {user.fullName}
            </Typography>
          </Box>
          {user.hmy
            ?
              <UserHmy 
                user={user}
                adaptLanguageToSelectable={adaptLanguageToSelectable}
                handleUpdateLanguage={handleUpdateLanguage}
                handleUpdateRole={handleUpdateRole}
                handleUpdateSupervisor={handleUpdateSupervisor}
                handleUpdateSupervised={handleUpdateSupervised}
                handleGetAccessObservatory={handleGetAccessObservatory}
              />
            :
              <UserCustomer 
                user={user}
                adaptLanguageToSelectable={adaptLanguageToSelectable}
                handleUpdateLanguage={handleUpdateLanguage}
                handleUpdateBasic={handleUpdateBasic}
                handleUpdateInspirationZone={handleUpdateInspirationZone}
                handleUpdateSharedCustomers={handleUpdateSharedCustomers}
                handleUpdateCustomers={handleUpdateCustomers}
                handleUpdateCommercial={handleUpdateCommercial}
                handleGetAccessObservatory={handleGetAccessObservatory}
              />
          }
        </>
      :
        null
      }  
    </Box>
  )
}

export default User;