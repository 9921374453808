import { Selectable } from "src/models";
import { TinyCustomer } from "src/models/customer";
import { TinyCustomerModelService } from "src/services/customer";

export const customerProjectAdapater = (customer: TinyCustomerModelService): TinyCustomer => ({
    customerId: customer.customerId,
    sourceId: customer.sourceId,
    customerNumber: customer.customerNumber,
    name: customer.name,
    commercialName: customer.commercialName,
    displayValue: customer.commercialName ?? ""
});

export const customerSelectableAdapater = (customer: TinyCustomerModelService): Selectable => ({
    id: customer.customerId,
    text: customer.name
});