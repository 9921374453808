import {
  CreateInboxBlobDto,
  CreateLargeInboxBlobDto,
  GetBlobsInboxDto,
  GetDocumentsDto,
  GetProjectImagesDto,
  UpdateBlobDto,
} from 'src/dtos';
import { GeneralCollection } from 'src/models';
import { EncodeGetURI } from 'src/utilities';
import { http } from '../http-client';
import { DocumentModelService, DocumentPropsModelService } from './model-service';

const getDocumentProps = () => {
  const controller = new AbortController();

  return {
    call: http.get<DocumentPropsModelService>('blob/getAllowedDocumentProps', { signal: controller.signal }),
    controller,
  };
};

const getBlob = (id: string) => {
  const controller = new AbortController();

  return { call: http.get<DocumentModelService>(`blob/${id}`, { signal: controller.signal }), controller };
};

const downloadBlob = (id: string) => {
  const controller = new AbortController();

  return {
    call: http.get<DocumentModelService>(`blob/download/${id}`, { responseType: 'blob', signal: controller.signal }),
    controller,
  };
};

const getValidName = (fileName: string) => {
  const controller = new AbortController();

  return {
    call: http.get<string>(`blob/getValidName/${encodeURIComponent(fileName)}`, { signal: controller.signal }),
    controller,
  };
};

const getContainerToken = () => {
  const controller = new AbortController();

  return { call: http.get<string>(`blob/getContainerToken`, { signal: controller.signal }), controller };
};

const getBlobsInbox = (request: GetBlobsInboxDto) => {
  const controller = new AbortController();

  return {
    call: http.get<DocumentModelService[]>(EncodeGetURI('blob/getBlobsInbox', request), { signal: controller.signal }),
    controller,
  };
};

const getLandmarksPaged = (request: GetDocumentsDto) => {
  const controller = new AbortController();

  return {
    call: http.post<GetDocumentsDto>('blob/getLandmarksPagedAsync', request, { signal: controller.signal }),
    controller,
  };
};

const getProjectImgBlob = (request: GetProjectImagesDto) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<DocumentModelService>>(EncodeGetURI('blob/getProjectImgBlobsAsync', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const doVisible = (blobId: string) => {
  const controller = new AbortController();

  return { call: http.post(`Blob/doBlobVisibleAsync/${blobId}`, { signal: controller.signal }), controller };
};

const createInboxBlob = (request: CreateInboxBlobDto[]) => {
  const controller = new AbortController();

  return { call: http.post('blob/createInboxBlob', { blobs: request }, { signal: controller.signal }), controller };
};

const createInboxLargeBlob = (request: CreateLargeInboxBlobDto) => {
  const controller = new AbortController();

  return { call: http.post('blob/createInboxLargeBlob', request, { signal: controller.signal }), controller };
};

const uploadChunks = (request: FormData) => {
  const controller = new AbortController();

  return { call: http.post('blob/uploadChunks', request, { signal: controller.signal }), controller };
};

const updateBlob = (request: UpdateBlobDto) => {
  const controller = new AbortController();

  return { call: http.post('blob/updateBlob', request, { signal: controller.signal }), controller };
};

const deleteBlob = (documentId: string) => {
  const controller = new AbortController();

  return { call: http.delete(`Blob/${documentId}`, { signal: controller.signal }), controller };
};

export const blobServices = {
  getDocumentProps,
  getBlob,
  downloadBlob,
  getValidName,
  getContainerToken,
  getBlobsInbox,
  getLandmarksPaged,
  getProjectImgBlob,
  doVisible,
  createInboxBlob,
  createInboxLargeBlob,
  uploadChunks,
  updateBlob,
  deleteBlob,
};
