import './arrow-icon.scss';
import { FC } from 'react';
import { Box, Link } from '@mui/material';


type ArrowIconProps = {
  href: string;
};

const ArrowIcon: FC<ArrowIconProps> = ({ href }) => {
  return (
    <Link className="arrow-link" href={href}>
      <Box className="arrow">
          <span></span>
          <span></span>
      </Box>
    </Link>
  )
}

export {ArrowIcon};