import './landmark-card.scss';
import { FC } from 'react'
import { CompleteProject, LandmarkDocument } from 'src/models';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HmyIconDocument, HmyIconImage} from 'src/components/hmy-icons';
import { EDIT_PROJECTFILES } from 'src/permissions/permissions';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer } from 'src/utilities';

type LandmarkCardProps = {
    project: CompleteProject;
    file: LandmarkDocument;
    handleOpenDocument: (fileId: string, isVisible: boolean) => void;
    goToImages: (fileId: string) => void;
};

const LandmarkCard: FC<LandmarkCardProps> = ({project, file, handleOpenDocument, goToImages}) => {

    const currentUser = useSelector(currentUserSelector);

  return (
    <Box 
        className="landmark-card-container"
    >
        <Box
            className="landmark-card-file-description"
        >
            {file.workOrderDescription ?? file.description}
        </Box>
        <Box
            className="landmark-card-file-documentType-container"
        >
            <Box
                className="landmark-card-file-documentType"
            >
                <FormattedMessage id={file.documentType.toLocaleLowerCase()} />              
            </Box>
        </Box>
        <Box
            style={{width: `30%`, display: "flex", justifyContent: "center"}}
        >
            <Box
                className="landmark-card-file-attachments"
            >
                {!IsCustomer(currentUser) || file.visible
                    ?
                        <HmyIconDocument 
                            color={project.permissions.findIndex(x => x === EDIT_PROJECTFILES) > -1 ? (file.visible ? "visible" : "no-visible") : "auto"}
                            fontSize="small"
                            fileId={file.blobId}
                            isVisible={file.visible}
                            onClick={handleOpenDocument}
                        />
                    :
                        null
                }               
                {file.images?.length > 0
                ?
                    <HmyIconImage
                        fontSize="small"
                        visibles={file.visibles}
                        total={file.total}
                        fileId={file.blobId}
                        isCustomer={IsCustomer(currentUser)}
                        onClick={goToImages}
                    />
                :
                    null
                }  
            </Box>                                                               
        </Box>
    </Box>
  )
}

export default LandmarkCard;