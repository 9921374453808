import './project-settings-users-list-form.scss';
import { FC, useEffect, useState } from 'react'
import { BasicUser, ProjectProfile, Roles, SelectableDefaultValue, UserProject } from 'src/models';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HmyAutocomplete, HmySelectableSmart } from 'src/components';
import { projectProfileSelectableAdapater, setUserProjectAdapater } from 'src/adapters';
import { IsAdmin } from 'src/utilities';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { useFetchAndLoad } from 'src/hooks';
import { BasicUserModelService, userServices } from 'src/services';
import { GetSelectableUsersDto, SetUserProjectDto } from 'src/dtos';
import { basicUserAdapater } from 'src/adapters/user.adapter';
import ProjectSettingsUserListFormElement from '../project-settings-user-list-form-element';

type ProjectSettingsUsersListFormProps = {
    users: UserProject[];
    usersCopy: UserProject[];
    isHmy: boolean;
    projectProfiles: ProjectProfile[];
    setUsersCopy: (value: UserProject[]) => void;
    handleCancelChanges: () => void;
    handleSetUsers: (addUsers: SetUserProjectDto[], removeUsers: SetUserProjectDto[]) => void;
  };

const ProjectSettingsUsersListForm: FC<ProjectSettingsUsersListFormProps> = ({ users, usersCopy, isHmy, projectProfiles, setUsersCopy, handleCancelChanges, handleSetUsers }) => {

    const currentUser = useSelector(currentUserSelector);
    const { loading, callEndpoint } = useFetchAndLoad();
    const [newUser, setNewUser] = useState<string | null>(null);
    const [optionsUser, setOptionsUser] = useState<BasicUser[]>([]);


    const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));

    const adaptBasicUsers = (data: BasicUserModelService[]) => {

        let newBasicUsers = data.map(x => basicUserAdapater(x));

        setOptionsUser(newBasicUsers);
    };

    const isCommercialProject = (): boolean => {
        return users.findIndex(x => x.email === currentUser.email && x.profileName.toLowerCase() === "commercial") > -1;
    }

    const getAvailableProfiles = (profileActiveId: string | null): ProjectProfile[] => {
        let activeProfiles = usersCopy.map(x => x.profileId);
        
        if(profileActiveId !== null){
            const index = activeProfiles.findIndex(x => x === profileActiveId);
            activeProfiles.splice(index, 1);
        }
        
        return projectProfiles.filter(x => activeProfiles.findIndex(ap => ap === x.projectProfileId) === -1 || 
        (activeProfiles.findIndex(ap => ap === x.projectProfileId) > -1 && !x.isSingle))
    }

    const canEditProfile = (profileName: string): boolean => {

        const requiresPrivileges: boolean = projectProfiles.find(x => x.name === profileName)?.requiresHighPrivileges ?? false;
        if(requiresPrivileges)
        {
            return IsAdmin(currentUser) || isCommercialProject();
        }
        else{
            return true;
        }
    }

    const canDeleteUser = (profileName: string, userId: string): boolean => {
        return canEditProfile(profileName) && currentUser.id !== userId;
    }

    const handleDeleteUser = (userId: string, profileId: string) => {
        
        const usersCopyIndex = usersCopy.findIndex(x => x.id === userId && x.profileId === profileId);
            let copyArr = [...usersCopy];
            copyArr.splice(usersCopyIndex, 1);
            setUsersCopy(copyArr);
    }

    const handleNewUser = (value: string | null) => {
        if(!isHmy && value !== null){
            handleUpdateProfile(value, projectProfiles.find(x => x.name === "Customer")!.projectProfileId);
        }
        else{
            setNewUser(value);
        }    
    }

    const handleNewProfile = (profileId: string) => {
        if(newUser !== null){
            handleUpdateProfile(newUser, profileId);
        } 
    }

    const handleUpdateProfile = (email: string, profileId: string) => {
        let arr = [...usersCopy];

        if(usersCopy.findIndex(x => x.email === email) === -1)
        {//Es nuevo
            const userNew = optionsUser.find(x => x.email === email);
            const profileNew = projectProfiles.find(x => x.projectProfileId === profileId);
            if(userNew && profileNew){
                arr.push({
                    id: userNew.id,
                    email: userNew.email,
                    fullName: userNew.fullName,
                    profileId: profileId,
                    profileName: profileNew.name,
                    isDeleted: false
                });

                setUsersCopy(arr);
                handleNewUser(null);
            }   
        }
        else if(usersCopy.findIndex(x => x.profileId === profileId) === -1)
        {//Editamos perfil de elemento existente
            const index = arr.findIndex(x => x.email === email);
            const profileNew = projectProfiles.find(x => x.projectProfileId === profileId);
            arr[index].profileId = profileId;
            arr[index].profileName = profileNew!.name;

            setUsersCopy(arr);
        }
    }

    const handleLoadUsers = async () => {
        try {
            let request: GetSelectableUsersDto = {
                roleName: isHmy ? Roles.COMMERCIAL : Roles.CUSTOMER,
                usersDiscard: usersCopy.map(x => x.id),
                withoutSupervisor: false
            };

            const response = await getSelectableUsers(request);
            adaptBasicUsers(response.data);

        } catch (error) {
        }
    }

    const hasChanges = (): boolean => {
        let arr: UserProject[] = usersCopy.filter(x => users.findIndex(u => u.id === x.id && u.profileId === x.profileId) === -1);
        return arr.length > 0 || usersCopy.length !== users.length;
    }

    const handleSaveChanges = () => {
        const deleteUsers = users.filter(x => usersCopy.findIndex(u => u.id === x.id && u.profileId === x.profileId) === -1);
        const addUsers = usersCopy.filter(x => users.findIndex(u => u.id === x.id && u.profileId === x.profileId) === -1);

        handleSetUsers(addUsers.map(x => setUserProjectAdapater(x)), deleteUsers.map(x => setUserProjectAdapater(x)));
    }

    useEffect(() => {
        handleLoadUsers();
      },[usersCopy]);

  return (
    <Box
        className="project-settings-users-list-form-container"
    >
        {usersCopy.map((user, index) => (
            <ProjectSettingsUserListFormElement 
                key={`${user.id}-${index}`}
                user={user}
                isHmy={isHmy}
                canEditProfile={canEditProfile}
                canDeleteUser={canDeleteUser}
                getAvailableProfiles={getAvailableProfiles}
                onUpdateProfile={handleUpdateProfile}
                handleDeleteUser={handleDeleteUser}
            />
        ))}
        <Box
            className="project-settings-add-form"
        >
            <HmyAutocomplete 
                label="searchUser"
                showLabel={true}
                fullWidth={true}
                value={newUser}
                loading={loading}
                options={optionsUser.map(x => x.email)}
                helperText={null}
                setValue={handleNewUser}
                loadResults={handleLoadUsers}
            />
            {newUser !== null && isHmy
                ?
                    <HmySelectableSmart 
                        title="profile"   
                        showTitle={true}       
                        defaultValue="selectProfile"
                        elements={getAvailableProfiles(null).map(x => projectProfileSelectableAdapater(x))}
                        value={SelectableDefaultValue}
                        isFormattedValue={true}
                        fullWidth={false}
                        setValue={handleNewProfile}
                    />
                :
                null
            }
        </Box>      
        {hasChanges()
            ?
            <Box
                className="project-settings-user-form-buttons"
            >
                <Box
                    onClick={() => handleSaveChanges()}
                    className="project-settings-user-form-button"
                >
                    <FormattedMessage id="save"/>
                </Box> 
                <Box
                    onClick={() => handleCancelChanges()}
                    className="project-settings-user-form-button-cancel"
                >
                    <FormattedMessage id="btn.cancel"/>
                </Box>
            </Box>
            :
            null
        }
    </Box>
  )
}

export default ProjectSettingsUsersListForm