export const EncodeGetURI = (endpoint: string, request: any): string => {
    let query = "";
    
      if (request) {
          for(let parameter in request) {
              let val = request[parameter];
    
              if (val){
                  query += (query?"&":"?") + `${parameter}=${encodeURIComponent(val)}`;
              }
          };
      }
      return `${endpoint}${query}`;
};