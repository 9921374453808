import './forbidden.scss';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ImageError from '../../assets/images/forbidden.png'
import { FormattedMessage } from 'react-intl';
import { HmyBtn } from 'src/components';
import { useNavigate } from 'react-router-dom';

const Forbidden: FC = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }

    return (
        <Box
            className="forbidden-container"
        >
            <Box
                className="forbidden-image-container"
            >
                <img src={ImageError} alt="forbidden-image"/> 
            </Box>
            <Box
                className="forbidden-text"
            >
                <Typography className="forbidden-text-title" ><FormattedMessage id="error"/><span style={{marginLeft: "15px"}}>403</span></Typography>
                <Typography className="forbidden-text-body" >
                    <FormattedMessage 
                        id="error403"
                    />
                </Typography>
                <Box
                    className="forbidden-btn"
                >
                <HmyBtn 
                    title="btn.comeBack"
                    width={300}
                    fullwith={false}
                    handleClick={handleClick}
                    color="secondary"
                />
                </Box>
            </Box>
        </Box>
    )
}

export default Forbidden;