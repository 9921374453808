import { FC } from 'react';
import './hmy-status-text.scss';
import { FormattedMessage } from 'react-intl';
import { useOrderStates } from 'src/hooks/use-order-states.hook';

type HmyStatusTextProps = {
  id: string;
};
const HmyStatusText: FC<HmyStatusTextProps> = ({ id }) => {
  const { getStateNumber } = useOrderStates();
  const statusNumber = getStateNumber(id);
  return (
    <div className={`status-text status-${statusNumber}`}>
      <div className="status-badge" />
      <FormattedMessage id={`status-${statusNumber}`} />
    </div>
  );
};

export { HmyStatusText };
