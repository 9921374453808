import { useQuery } from '@tanstack/react-query';
import { OrderStateModelService, orderServices } from '../services/orders';
import { useFetchAndLoad } from './use-fetch-load.hook';

export enum STATUS_ORDER {
  REGISTERED = 1,
  IN_PROGRESS = 2,
  SENT = 3,
  DELIVERED = 4,
  CANCELLED = 5,
}

export function useOrderStates() {
  const { callEndpoint } = useFetchAndLoad();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getOrderStates'],
    queryFn: () => callEndpoint(orderServices.getOrderStates()),
    select: ({ data }) => data,
  });

  const getStateNumber = (id: string) => {
    return data ? data.find((state: OrderStateModelService) => state.saleOrderStatusId === id)?.number : -1;
  };

  const getStateId = (number: number) => {
    return data ? data.find((state: OrderStateModelService) => state.number === number)?.saleOrderStatusId : -1;
  };

  return { data, isLoading: isLoading || isFetching, getStateNumber, getStateId };
}
