import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableHeaderElement } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { calculeOrderPrice } from '../../calcule-order-price.helpers';
import { formatPrice } from '../../format-price.helpers';
import './hmy-table-articles.scss';

type HmyTableArticlesProps = {
  children: ReactNode;
  headerELementList: TableHeaderElement[];
  orderData: BasicOrder | BasicShoppingOrder;
};

const HmyTableArticles: FC<HmyTableArticlesProps> = ({ headerELementList, children, orderData }) => {
  return (
    <Box className="hmy-table-articles">
      <Box className="hmy-table-articles-header">
        {headerELementList.map((element, index) => (
          <Box
            key={`${element.element}-${index}`}
            className={
              element.withBorder ? 'hmy-table-articles-header-element separator' : 'hmy-table-articles-header-element'
            }
            style={{
              width: `${element.width}%`,
              justifyContent: element.align,
              cursor: element.onClick !== null ? 'pointer' : 'default',
            }}
            onClick={() => (element.onClick !== null ? element.onClick() : null)}
          >
            {element.element}
          </Box>
        ))}
      </Box>

      <Box className="hmy-table-articles-body">{children}</Box>
      <Box className="hmy-table-articles-footer">
        <FormattedMessage
          id="totalMaterialPrice"
          values={{ value: orderData && formatPrice(calculeOrderPrice(orderData).subTotal) }}
        />
      </Box>
    </Box>
  );
};

export { HmyTableArticles };
