import './import-project-row.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import { HmyCheckBox, HmyTableRow, StringMultiple } from 'src/components';
import { CurrentUser, ImportProject } from 'src/models';
import { adjustString, IsAdmin } from 'src/utilities';
import { FormattedDate } from 'react-intl';

type ImportProjectRowProps = {
    isFirst: boolean;
    isLast: boolean;
    project: ImportProject;
    selectedProjects: ImportProject[];
    currentUser: CurrentUser;
    handleCheckRow: (element: string) => void;
  };

const ImportProjectRow: FC<ImportProjectRowProps> = ({isFirst, isLast, project, selectedProjects, currentUser, handleCheckRow}) => {
  return (
    <HmyTableRow
      isFirst={isFirst}
      isLast={isLast}
    >
      <Box
        className="import-projects-table-row-element"
        style={{width: "5%", justifyContent: "flex-center", cursor: "pointer"}}
        onClick={() => handleCheckRow(project.projectId)}
      >
        <HmyCheckBox
          color="primary"
          isChecked={selectedProjects.findIndex(x => x.projectId === project.projectId) > -1}
        />
      </Box>
      <Box
        className="import-projects-table-row-element"
        style={{width: "40%", justifyContent: "flex-start", paddingLeft: 20, cursor: "pointer"}}
        onClick={() => handleCheckRow(project.projectId)}
      >
        {adjustString(project.description, 35)}
      </Box>
      <Box
        className="import-projects-table-row-element"
        style={{width: "15%", justifyContent: "flex-start", cursor: "pointer"}}
        onClick={() => handleCheckRow(project.projectId)}
      >
        {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
      </Box>  
      
      <Box
        className="import-projects-table-row-element"
        style={{width: "25%", justifyContent: "center"}}
      >
        {IsAdmin(currentUser)
        ?
          <StringMultiple 
            elements={project.users}
            cutString={17}
          />
        :
          <span>{project.customer.name}</span>
        }
      </Box>
      <Box
        className="import-projects-table-row-element"
        style={{width: "15%", justifyContent: "center"}}
      >
        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={project.projectDate} />    
      </Box>
    </HmyTableRow>
  )
}

export default ImportProjectRow;