import CommentIcon from '@mui/icons-material/Comment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Badge, Box } from '@mui/material';
import { FC, useState } from 'react';
import { FaTruckFast } from 'react-icons/fa6';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { HmyCheckBox } from 'src/components';
import { HmyStatusText } from 'src/components/hmy-status-text';
import { STATUS_ORDER, useOrderStates } from 'src/hooks/use-order-states.hook';
import { CurrentUser } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import IncidenceModal from 'src/pages/order/incidences/incidence-modal';
import PdfOrder from 'src/pages/order/pdf-order';
import { adjustString } from 'src/utilities';
import './hmy-card-order.scss';
import IncidenceButton from '../incidence-button';

type HmyCardOrderProps = {
  order: BasicOrder;
  currentUser: CurrentUser;
  selectedOrders: BasicOrder[];
  handleClickOrder: (projectId: string, orderId: string) => void;
  handleCheckRow: (saleOrderId: string) => void;
};

const HmyCardOrder: FC<HmyCardOrderProps> = ({
  order,
  currentUser,
  selectedOrders,
  handleClickOrder,
  handleCheckRow,
}) => {
  const [printOrderId, setPrintOrderId] = useState<string>('');
  const [printOrderLoading, setPrintOrderLoading] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>();
  const [openIncidence, setOpenIncidence] = useState<boolean>(false);

  const { getStateNumber } = useOrderStates();

  const handleClosePdf = () => {
    setPrintOrderId('');
    setPrintOrderLoading(false);
  };

  const handlePrint = () => {
    setCurrentDate(new Date());
    setPrintOrderId(order.saleOrderId);
    setPrintOrderLoading(true);
  };
  return (
    <Box className="card-order-container">
      <Box className="card-order-number">
        <Box onClick={() => handleCheckRow(order.saleOrderId)}>
          <HmyCheckBox
            color="primary"
            isChecked={selectedOrders.findIndex((x) => x.saleOrderId === order.saleOrderId) > -1}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
        >
          <Box style={{ justifyContent: 'start', alignItems: 'center', display: 'flex', gap: '10px' }}>
            {order.urgent && <FaTruckFast />}
            {order.openIncidences > 0 && (
              <>
                <Badge className="orders-incidence-badge" badgeContent={order.openIncidences}>
                  <FeedbackIcon style={{ width: '20px', color: 'grey' }} />
                </Badge>
                {order.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
              </>
            )}
          </Box>
          {order.number}
        </Box>
      </Box>
      <Box className="card-order-number" onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}>
        <Box>{adjustString(order.projectName, 25)}</Box>
        <Box>
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={order.date} />
        </Box>
      </Box>

      <Box className="card-order-status">
        <HmyStatusText id={order.statusId} />

        <Box className="card-order-buttons">
          <Box className="orders-button orders-button-view">
            {(getStateNumber(order.statusId) === STATUS_ORDER.DELIVERED ||
              getStateNumber(order.statusId) === STATUS_ORDER.SENT) && <IncidenceButton orderId={order.saleOrderId} />}
          </Box>
          <Box className="orders-button" onClick={handlePrint}>
            {printOrderLoading ? (
              <>
                <FormattedMessage id="loading" />
                ...
              </>
            ) : (
              <>
                <InsertDriveFileOutlinedIcon className="orders-button-icon" />
                <FormattedMessage id="PDF" />
              </>
            )}
          </Box>
          <Box className="orders-button">
            <TableViewOutlinedIcon className="orders-button-icon" />
            <FormattedMessage id="excel" />
          </Box>
        </Box>
        {printOrderId && currentDate && (
          <PdfOrder
            orderId={order.saleOrderId}
            title={`${order.projectName}-${order.number}`}
            close={handleClosePdf}
            date={currentDate}
          />
        )}
      </Box>
    </Box>
  );
};

export default HmyCardOrder;
