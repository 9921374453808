import './landing-images.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import Image1 from '../../../assets/images/landing_image_1.png';
import Image2 from '../../../assets/images/landing_image_2.png';
import Image3 from '../../../assets/images/landing_image_3.png';
import { FormattedMessage } from 'react-intl';

const LandingImages: FC = () => {
  return (
    <Box
        id="landing-images-container"
        className="landing-images-container"
    >
      <Box
        className="landing-image-container"
      >
        <img src={Image1} alt="Image1" />
        <p><FormattedMessage id="landing-image-text-1" /></p>
      </Box>
      <Box
        className="landing-image-container"
      >
        <img src={Image2} alt="Image2" />
        <p>
          <FormattedMessage id="landing-image-text-2" />
        </p>
      </Box>
      <Box
        className="landing-image-container"
      >
        <img src={Image3} alt="Image3" />
        <p>
          <FormattedMessage id="landing-image-text-3" />
        </p>
      </Box>
    </Box>
  )
}

export default LandingImages;