import './grouping.scss';
import { FC, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import {
  groupingSubRoutes,
  LoadComponent,
  ROUTE_USER_GROUPINGS,
  SUBROUTE_GROUPING_FILES,
  SUBROUTE_GROUPING_INFORMATION,
} from 'src/components';
import { CompleteGrouping } from 'src/models';
import { NotFound } from '../not-found';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormattedMessage } from 'react-intl';
import GroupingTopBar from './grouping-top-bar';
import { CompleteGroupingModelService, groupingServices } from 'src/services';
import { READ_GROUPING, READ_GROUPINGFILES } from 'src/permissions/permissions';
import { completeGroupingAdapter } from 'src/adapters';
import GroupingFiles from './grouping-files';
import GroupingInformation from './grouping-information';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Grouping: FC = () => {
  const { groupingid } = useParams();
  const { loading, callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const [grouping, setGrouping] = useState<CompleteGrouping>();
  const [optionSelected, setOptionSelected] = useState<groupingSubRoutes>(SUBROUTE_GROUPING_FILES);

  const getGrouping = async () => await callEndpoint(groupingServices.getGrouping(groupingid ?? ''));

  const adaptGrouping = (data: CompleteGroupingModelService) => {
    let newGrouping = completeGroupingAdapter(data);

    setGrouping(newGrouping);

    trackPageView({
      documentTitle: `Grouping - ${newGrouping.name}`,
    });
  };

  const handleClickElement = (value: groupingSubRoutes) => {
    navigate(value);
  };

  /**
   * Marca el elemento seleccionado en el menu
   */
  const manageActiveElement = () => {
    const urlLocation = location.pathname.split(grouping!.id);
    let elementActive = optionSelected;
    switch (urlLocation[1].slice(1)) {
      case SUBROUTE_GROUPING_FILES:
        elementActive = SUBROUTE_GROUPING_FILES;
        break;
      case SUBROUTE_GROUPING_INFORMATION:
        elementActive = SUBROUTE_GROUPING_INFORMATION;
        break;
      default:
        elementActive = SUBROUTE_GROUPING_FILES;
        break;
    }

    if (elementActive !== optionSelected) {
      setOptionSelected(elementActive);
    }
  };

  useEffect(() => {
    if (grouping) {
      manageActiveElement();
    }
  }, [location.pathname, grouping]);

  const reloadGrouping = () => {
    getGrouping().then((response) => {
      if (response.status === 200) {
        adaptGrouping(response.data);
      }
    });
  };

  useAsync(
    getGrouping,
    adaptGrouping,
    () => {},
    () => {},
    [groupingid]
  );

  return (
    <Box className="grouping-container">
      {loading ? <LoadComponent /> : null}
      {grouping !== undefined ? (
        <Box className="grouping-main">
          <Box className="grouping-title" onClick={() => navigate(ROUTE_USER_GROUPINGS)}>
            <ChevronLeftIcon />
            <Typography className="grouping-title-text">
              <FormattedMessage id="btn.comeBack" />
              <span className="grouping-title-text-grouping">{grouping.name}</span>
            </Typography>
          </Box>
          <GroupingTopBar grouping={grouping} selected={optionSelected} handleClickElement={handleClickElement} />
          <Box className="content-box-grouping">
            <Routes>
              {!grouping.permissions.includes(READ_GROUPINGFILES) ? null : (
                <Route index element={<GroupingFiles grouping={grouping} />} />
              )}
              {!grouping.permissions.includes(READ_GROUPING) ? null : (
                <Route
                  path={SUBROUTE_GROUPING_INFORMATION}
                  element={<GroupingInformation grouping={grouping} reloadGrouping={reloadGrouping} />}
                />
              )}
              {!grouping.permissions.includes(READ_GROUPINGFILES) ? null : (
                <Route path={SUBROUTE_GROUPING_FILES} element={<GroupingFiles grouping={grouping} />} />
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default Grouping;
