import './hmy-card-articles.scss';
import { FC } from 'react';
import { BasicProject, CurrentUser } from 'src/models';
import { Box, TextField } from '@mui/material';
import { adjustString, IsAdmin } from 'src/utilities';
import { StringMultiple } from 'src/components';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { FormattedDate } from 'react-intl';
import { BasicOrderLines } from 'src/models/orders';
import { BasicCatalogueLineModelService } from 'src/services/catalogue';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { formatPrice } from '../../format-price.helpers';
import ImageArticle from '../../hmy-modal-order/image-article';
type HmyCardArticlesProps = {
  article: BasicOrderLines | BasicCatalogueLineModelService;
  currentUser: CurrentUser;
  editable?: boolean;
  updateOrderData: (article: BasicOrderLines | BasicCatalogueLineModelService) => void;
  removeArticle: (id: string, color: string) => void;
};

const HmyCardArticles: FC<HmyCardArticlesProps> = ({
  article,
  currentUser,
  editable,
  updateOrderData,
  removeArticle,
}) => {
  return (
    <Box className="card-articles-container">
      <Box className="card-articles-description">
        <Box>
          {article.reference} {article.color}
        </Box>
        {article.articleFiles && article.articleFiles.length > 0 && article.articleFiles[0].isImage && (
          <ImageArticle thumbnail={article.articleFiles[0].urlThumbnail} imageId={article.articleFiles[0].fileId} />
        )}
        <Box>{article.referenceDesc}</Box>
        <Box>{article.colorDesc}</Box>
        <Box>{formatPrice(article.salePrice)}</Box>
      </Box>
      <Box className="card-articles-quantity">
        {editable ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              columnGap: '10px',
              alignItems: 'center',
            }}
          >
            <Box
              onClick={() =>
                article.quantity > 0 &&
                updateOrderData({
                  ...article,
                  quantity: article.quantity - 1,
                })
              }
            >
              <RemoveIcon style={{ fontSize: '1.4em' }} />
            </Box>
            <TextField
              value={article.quantity}
              variant="filled"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: '0', step: '1' } }}
              size="small"
              style={{ width: '100px' }}
              sx={{ '& input': { paddingTop: '4px', fontSize: '0.65em', textAlign: 'center' } }}
              onChange={(e) =>
                updateOrderData({
                  ...article,
                  quantity: isNaN(Number(e.target.value)) ? article.quantity : Number(e.target.value),
                })
              }
            />

            <Box
              onClick={() =>
                updateOrderData({
                  ...article,
                  quantity: article.quantity + 1,
                })
              }
            >
              <AddIcon style={{ fontSize: '1.4em' }} />
            </Box>
          </Box>
        ) : (
          <Box>{article.quantity}</Box>
        )}
        <Box>{formatPrice(article.salePrice * article.quantity)}</Box>
        {editable && (
          <Box
            sx={{ position: 'absolute', right: 0, bottom: 0, cursor: 'pointer' }}
            onClick={() => removeArticle(article.reference, article.color)}
          >
            <CloseIcon style={{ fontSize: '16px' }} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HmyCardArticles;
