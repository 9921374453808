import './section-welcome.scss';
import { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { ArrowIcon } from 'src/components';
import { FormattedMessage } from 'react-intl';
import GreetingGirl from '../../../assets/images/chica_inspiracion.png';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';

const SectionWelcome: FC = () => {

  const currentUser = useSelector(currentUserSelector);

  return (
    <Box className="section-welcome-container" id="sectionWelcome">
      <Box
        className="section-welcome-image-container"
      >
        <Box
          className="section-welcome-image"
        >
          <img src={GreetingGirl} alt="girl-greeting"/>      
        </Box>
        <Box
          className="section-welcome-greeting"
        >
            <Typography className="text-title" ><FormattedMessage id="greet2"/> {currentUser.name}!</Typography>
            <Typography className="text-body" ><FormattedMessage id="alittle"/><span><FormattedMessage id="inspiration2"/></span>?</Typography>
            <Box
              className="arrow-btn-transitioned"
            >
              <ArrowIcon href="#sectionCarousel" />
            </Box> 
        </Box>
      </Box>       
    </Box>
  )
}

export default SectionWelcome;