import './selected-project-alert.scss';
import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type SelectedProjectAlertProps = {
    selectedProjects: number;
    handleImport: () => void;
    handleCheck: (value: boolean) => void;
};

const SelectedProjectAlert: FC<SelectedProjectAlertProps> = ({selectedProjects, handleImport, handleCheck}) => {
  return (
    <Box 
      className="import-projects-selected-box"
    >
      <Typography
        className="import-projects-selected-box-text"
      >
        <span style={{marginRight: "10px"}}>{selectedProjects}</span><FormattedMessage id={selectedProjects === 1 ? "selected.one" : "selected.many"}/> 
      </Typography>
      <Box
        className="import-projects-selected-box-import"
        onClick={() => handleImport()}
      >
        <FormattedMessage id="import"/>
      </Box>
      <Box
        className="import-projects-selected-box-cancel"
        onClick={() => handleCheck(false)}
      >
        <FormattedMessage id="btn.cancel"/>
      </Box>
    </Box>
  )
}

export default SelectedProjectAlert;