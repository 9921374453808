import './login-form-user.scss';
import { FC, useState } from 'react';
import { Box, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Captcha } from 'src/components';
import { useSelector } from 'react-redux';
import { sessionSelector } from 'src/redux/states';

type LoginFormUserProps = {
    isVerified: boolean;
    isModal: boolean;
    handleClose: () => void;
    handleOpenRecoverPass: () => void;
    setIsVerified: (value: boolean) => void;
    setRecaptchaShowed: (value: boolean) => void;
    handleSubmit: (email: string, password: string) => void;
}

const LoginFormUser: FC<LoginFormUserProps> = ({ isVerified, isModal, handleClose, handleOpenRecoverPass, setIsVerified, setRecaptchaShowed, handleSubmit }) => {

    const { language } = useSelector(sessionSelector);
    
    const [email, setEmail] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
      };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateEmail = (value: string) => {
        const regex = RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[^@]+.[a-zA-Z]{2,}$");
        if(value === null || value.trim() === ""){
          setErrorEmail("error.required");
        }
        else if(!regex.test(value.trim()) || value.trim().endsWith('@hmy-group.com')){
            setErrorEmail("error.email");
        }
        else{
            setErrorEmail(null);
        }
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(errorEmail !== null){
            validateEmail(event.target.value);
        }
        setEmail(event.target.value);
    };

    const handleSubmitLogin = () => {
        if(isVerified && email.trim().length > 0 && password.trim().length > 0 &&  errorEmail === null){
            handleSubmit(email, password);
        }
    }

    return (
        <Box
            className="login-form-content"
        >
            <Box
                className="login-form-inputs"
            >
                <TextField 
                    label={<FormattedMessage id="email" />}
                    variant="standard" 
                    value={email}
                    onChange={handleChangeEmail}
                    onBlur={e => validateEmail(email)}
                    error={errorEmail !== null}
                    helperText={errorEmail !== null ? <FormattedMessage id={errorEmail} /> : null}
                    fullWidth
                    className="login-inputEmail"
                />
                <TextField
                    id="hmy-input-password"
                    type={showPassword ? 'text' : 'password'}
                    color="primary"
                    value={password}
                    fullWidth
                    label={<FormattedMessage id="password" />}
                    variant="standard"
                    onChange={handleChange}
                    className="login-inputPassword"
                    size="medium"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment 
                                position="end"
                                onClick={handleClickShowPassword}
                            >
                                <IconButton
                                    aria-label="toggle password visibility"
                                    color="primary"
                                >
                                    {showPassword ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                </Box>
            <Box
                className="login-form-buttons"
            >
                <Captcha 
                    setIsVerified={setIsVerified}
                    setRecaptchaShowed={setRecaptchaShowed}
                    language={language.name}
                    resetCaptcha={false}
                />
                <Box
                    className={isVerified && email.trim().length > 0 && password.trim().length > 0 &&  errorEmail === null ? "login-form-send" : "login-form-send-disabled"}
                    onClick={() => handleSubmitLogin()}
                >
                    <FormattedMessage id="login" />
                </Box>
                <Link fontSize={20} component="button" variant="body2" onClick={() => handleOpenRecoverPass()} color="primary" ><FormattedMessage id="login.forgotPassword"/></Link>
                    {!isModal &&
                        <Box
                            className="login-form-goback"
                        >
                            <Link 
                                component="button" 
                                variant="body2" 
                                onClick={() => handleClose()} 
                                color="primary" 
                                fontSize={20}
                            >
                                <FormattedMessage id="comeBack"/>
                            </Link>
                        </Box>
                    }
            </Box>           
        </Box>
    )
}

export default LoginFormUser;