import './drawing-card.scss';
import { FC } from 'react'
import { VstoreFile } from 'src/models';
import { Box } from '@mui/material';
import { HmyIconVisibility, HmyIconLaunch } from 'src/components/hmy-icons';

type DrawingCardProps = {
    drawing: VstoreFile;
    canEdit: boolean;
    handleOpenDrawing: (source: string) => void;
    hanldeClickVisible: (fileId: string, visible: boolean) => void;
};

const DrawingCard : FC<DrawingCardProps> = ({drawing, canEdit, handleOpenDrawing, hanldeClickVisible}) => {

  return (
    <Box
        className="drawing-card-container"
    >
        <Box
            className="drawing-card"
            style={{backgroundImage: `url(data:image/png;base64,${drawing.thumbnail})`,backgroundSize: "cover",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}
        >
            {!drawing.visible
            ?
              <Box
                className="drawing-card-overlay"
              >
              </Box>
              :
              null
            }
            {canEdit
                ?
                <Box
                    className="drawing-card-visibility"
                    onClick={() => hanldeClickVisible(drawing.urn, !drawing.visible)}
                >
                    <HmyIconVisibility 
                        fontSize="small"
                        isVisible={drawing.visible}
                    />
                </Box>
                :
                null
            }   
            <Box
                className="drawing-card-launch"
                onClick={() => handleOpenDrawing(drawing.source)}
            >
                <HmyIconLaunch 
                    fontSize="small"
                />
            </Box>      
        </Box>
        <Box
            className="drawing-card-name"
        >
            <span>{drawing.name}</span>
        </Box>
    </Box>
  )
}

export default DrawingCard;