import "swiper/css";
import "swiper/css/navigation";
import './hmy-horizontal-infinite-scroll.scss';
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { Box } from '@mui/material';

type HmyHorizontalInfiniteScrollProps = {
    children: JSX.Element[],
    slidesPerView: number,
  }

const HmyHorizontalInfiniteScroll: FC<HmyHorizontalInfiniteScrollProps> = ({ children, slidesPerView }) => {

    const [swiper, setSwiper] = useState<SwiperCore>();

    return (
        <Box
            className="horizontal-scroll-component-container"
        >
            <Swiper 
                navigation={true} 
                allowTouchMove={true}
                slidesPerView={slidesPerView}
                onSwiper={setSwiper}
                modules={[Navigation]} 
                className="mySwiper"
            >
                {children.map((e, index) => (
                    <SwiperSlide
                        key={`horizontal-scroll-${index}`}
                        className="horizontal-scroll-slide-container"
                    >
                        {e}
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    )
}

export { HmyHorizontalInfiniteScroll };