import './footer.scss';
import { FC } from 'react';
import { Box, Link, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import logoHmy from '../../assets/images/HMY_logo_navy.svg';
import InstagramIcon from '../../assets/images/instagram.png';
import LinkedInIcon from '../../assets/images/linkedin.png';
import YouTubeIcon from '../../assets/images/youtube.png';

type FooterProps = {
  showEnabled: boolean;
};

const Footer: FC<FooterProps> = ({showEnabled}) => {

  const matches = useMediaQuery('(max-width: 768px)');
  
  return (
    showEnabled
    ?
    <Box className="footer" >
      <Box className="footer-container" >
        <Box
          className="footer-logo"
        >
          <Link href="https://www.hmy-group.com" target="_blank" rel="noopener noreferrer">
            <img src={logoHmy} alt="logo" />
          </Link>
        </Box>
        <Box className="generalReferences">
          <ul>
              <li><Link href="https://www.hmy-group.com/es/aviso-legal-politica-de-privacidad-y-politica-de-cookies/#privacidad" target="_blank" rel="noopener noreferrer"><FormattedMessage id="normative" /></Link></li>
              <span>|</span>
              <li><Link href="https://www.hmy-group.com/es/aviso-legal-politica-de-privacidad-y-politica-de-cookies/#cookies" target="_blank" rel="noopener noreferrer"><FormattedMessage id="cookiesPolicy" /></Link></li>
              <span>|</span>
              <li><Link href="https://www.hmy-group.com/es/aviso-legal-politica-de-privacidad-y-politica-de-cookies/#aviso" target="_blank" rel="noopener noreferrer"><FormattedMessage id="legalWarning" /></Link></li>
              {matches ? null
              :
                <>
                  <span>|</span>
                  <li>{process.env.REACT_APP_VERSION}</li>
                </>
              }
              
          </ul>
        </Box>
        <Box className="iconsReferences">
          <span>
            hmy-group.com
          </span>
          <ul>
              <li>
                <Link href="https://www.linkedin.com/company/hmy" target="_blank" rel="noopener noreferrer">
                  <img src={LinkedInIcon} alt="linkedin-icon" className="footer-icon-style" />
                </Link>
              </li>
              <li>
                <Link href="https://www.instagram.com/hmy_group/" target="_blank" rel="noopener noreferrer">
                  <img src={InstagramIcon} alt="instagram-icon" className="footer-icon-style" />
                </Link>
              </li>
              <li>
                <Link href="https://www.youtube.com/user/HMYGROUP" target="_blank" rel="noopener noreferrer">
                  <img src={YouTubeIcon} alt="youtube-icon" className="footer-icon-style" />
                </Link>
              </li>
          </ul>
        </Box>
      </Box>
    </Box>
    :
    null
  )
}

export {Footer};