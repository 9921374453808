import './hmy-form-section.scss'
import { FC, ReactNode } from 'react'
import { Box } from '@mui/material';

type HmyFormSectionProps = {
    children: ReactNode;
};

const HmyFormSection: FC<HmyFormSectionProps> = ({ children }) => {
  return (
    <Box
        className="hmy-form-section"
    >
        {children}
    </Box>
  )
}

export { HmyFormSection };