import './user-hmy.scss';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { basicUserAdapater } from 'src/adapters/user.adapter';
import { HmyAutocomplete, HmyFormSection, HmySelectableSmart, HmySingleForm } from 'src/components';
import { AppLanguages } from 'src/components/language-menu/languages';
import { GetSelectableUsersDto } from 'src/dtos';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { BasicUser, CompleteUser, Language, Roles, Selectable } from 'src/models';
import { BasicUserModelService, userServices } from 'src/services';
import DeleteIcon from '@mui/icons-material/Delete';

type UserHmyProps = {
  user: CompleteUser;
  adaptLanguageToSelectable: (value: Language) => Selectable;
  handleUpdateLanguage: (language: string) => void;
  handleUpdateRole: (role: string) => void;
  handleUpdateSupervisor: (supervisor: BasicUser | null) => void;
  handleUpdateSupervised: (supervised: BasicUser[]) => void;
  handleGetAccessObservatory: () => void;
};

const UserHmy: FC<UserHmyProps> = ({ user, adaptLanguageToSelectable, handleUpdateLanguage, handleUpdateRole, handleUpdateSupervisor, handleUpdateSupervised, handleGetAccessObservatory }) => {

    const intl = useIntl();
    const { loading, callEndpoint } = useFetchAndLoad();

    const [roles, setRoles] = useState<string[]>([]);
    const [supervisors, setSupervisors] = useState<BasicUser[]>([]);
    const [supervised, setSupervised] = useState<BasicUser[]>([]);
    const [newRole, setNewRole] = useState<string>(user.roles[0]);
    const [newLanguage, setNewLanguage] = useState<string>(user.language);
    const [newSupervisor, setNewSupervisor]= useState<BasicUser | null>(user.boss);
    const [newSupervised, setNewSupervised]= useState<BasicUser[]>(user.supervisedUsers);

    const getListRoles = async () => await callEndpoint(userServices.getListRoles());
    const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));

    const adaptListRoles = (data: string[]) => {
      setRoles(data);
  
    };

    const adaptRoleToSelectable = (value: string) : Selectable => {
      return {
          id: value,
          text: value.toLowerCase()
      }
    }

    const adaptBasicUsers = (data: BasicUserModelService[]): BasicUser[] => {
      return data.map(x => basicUserAdapater(x));
    };

    const handleEditLanguage = () => {
      handleUpdateLanguage(newLanguage);
    }

    const handleEditRole = () => {
      handleUpdateRole(newRole);  
    }

    const handleEditSupervisor = () => {
      handleUpdateSupervisor(newSupervisor);
    }

    const handleCancelSupervisor = () => {
      setNewSupervisor(null);
      handleUpdateSupervisor(null);
    }

    const handleEditSupervised = () => {
      handleUpdateSupervised(newSupervised);
    }

    const handleSetSupervisor = (value: string | null) => {
      if(value !== null){
        let newSupervisor = supervisors.find(x => x.email === value);
        setNewSupervisor(newSupervisor!);
      }
      else{
        setNewSupervisor(null);
      }
    }

    const handleLoadSupervisors = async () => {
      try {
          let request: GetSelectableUsersDto = {
              roleName: user.roles.findIndex(x => x === Roles.SUPERVISOR) > -1 ? Roles.BOSSSUPERVISOR : Roles.SUPERVISOR,
              usersDiscard: [],
              withoutSupervisor: false
          };
  
          const response = await getSelectableUsers(request);
          setSupervisors(adaptBasicUsers(response.data));
  
      } catch (error) {
      }
    }

    const handleSetSupervised = (value: string | null) => {
      if(value !== null){
        let newSupers = [...newSupervised];
        let supervise = supervised.find(x => x.email === value);
        newSupers.push(supervise!);
        setNewSupervised(newSupers!);
      }
    }

    const handleLoadSupervised = async () => {
      try {
          let request: GetSelectableUsersDto = {
              roleName: user.roles.findIndex(x => x === Roles.SUPERVISOR) > -1 ? Roles.COMMERCIAL : Roles.SUPERVISOR,
              usersDiscard: newSupervised.map(x => x.id),
              withoutSupervisor: true
          };
  
          const response = await getSelectableUsers(request);
          setSupervised(adaptBasicUsers(response.data));
  
      } catch (error) {
      }
    }
    
    const handleCancelElementUser = (value: BasicUser) => {
      let newUsers = [...newSupervised.filter(x => x.id !== value.id)];
      setNewSupervised(newUsers);
    }

    const handleChangeLanguage = (value: string) => {
      setNewLanguage(value);
    }

    const handleOnChangeRole = (value: string) => {
      setNewRole(value);
    }

    const handleCancelForm = () => {
      setNewLanguage(user.language);
      setNewSupervised(user.supervisedUsers);
      setNewSupervisor(user.boss);
      setNewRole(user.roles[0]);
    }

    useAsync(getListRoles, adaptListRoles, () => { }, () => { }, []);

    return (
      <>
        <HmyFormSection>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={false}
              showTitle={true}
              title="name"
              textValues={[user.name]}
              canEdit={false}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={() => console.log()}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
          >
          </HmySingleForm>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={false}
              showTitle={true}
              title="surnames"
              textValues={[user.surName]}
              canEdit={false}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={() => console.log()}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
          >
          </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={false}
              showTitle={true}
              title="email"
              textValues={[user.userName]}
              canEdit={false}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={() => console.log()}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
          >
          </HmySingleForm>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={false}
              showTitle={true}
              title="language"
              textValues={[intl.formatMessage({id: AppLanguages.find(x => x.name === user.language)?.translate})]}
              canEdit={true}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={handleEditLanguage}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
          >
              <HmySelectableSmart 
                  title="language"   
                  showTitle={false}       
                  defaultValue=""
                  elements={AppLanguages.map(x => adaptLanguageToSelectable(x))}
                  value={newLanguage}
                  isFormattedValue={true}
                  fullWidth={true}
                  setValue={handleChangeLanguage}
              />
          </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
          {user.roles.findIndex(x => x === Roles.ROOT) === -1
          ?
            <HmySingleForm
              isMultiline={false}
              isFullWidth={false}
              showTitle={true}
              title="type"
              textValues={[intl.formatMessage({id: newRole.toLowerCase()})]}
              canEdit={true}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={handleEditRole}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
            >
              <HmySelectableSmart 
                title="type"   
                showTitle={false}       
                defaultValue=""
                elements={roles.filter(x => x !== Roles.ROOT && x !== Roles.CUSTOMER).map(x => adaptRoleToSelectable(x))}
                value={newRole}
                isFormattedValue={true}
                fullWidth={true}
                setValue={handleOnChangeRole}
              />   
            </HmySingleForm>
          :
          null
          }
          {user.roles.findIndex(x => x === Roles.COMMERCIAL || x === Roles.SUPERVISOR) > -1
          ?
            <HmySingleForm
                isMultiline={false}
                isFullWidth={false}
                showTitle={true}
                title="supervisor"
                textValues={[newSupervisor?.fullName ?? ""]}
                canEdit={true}
                canDelete={newSupervisor !== null}
                hasErrors={false}
                onClickSubmitForm={() => handleEditSupervisor()}
                onClickCancelForm={handleCancelForm}
                onDeleteForm={() => handleCancelSupervisor()}
            >
              <HmyAutocomplete 
                  label="supervisor"
                  showLabel={false}
                  fullWidth={true}
                  value={newSupervisor?.email ?? null}
                  loading={loading}
                  options={supervisors.map(x => x.email)}
                  helperText={null}
                  setValue={handleSetSupervisor}
                  loadResults={handleLoadSupervisors}
              />
            </HmySingleForm>
          :
            null
          }
        </HmyFormSection>
        <HmyFormSection>
          {user.roles.findIndex(x => x === Roles.SUPERVISOR || x === Roles.BOSSSUPERVISOR) > -1
          ?
            <HmySingleForm
              isMultiline={true}
              isFullWidth={false}
              showTitle={true}
              title="supervised"
              textValues={newSupervised.map(x => x.fullName)}
              canEdit={true}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={handleEditSupervised}
              onClickCancelForm={handleCancelForm}
              onDeleteForm={() => console.log()}
            >
              {newSupervised.map((supervised, index) => (
                  <Box
                      key={`${supervised.id}-${index}`}
                      className="hmy-user-edit-form-element-content-item-loaded"
                  >
                      <Typography>{supervised.email}</Typography>
                      <DeleteIcon 
                          onClick={() => handleCancelElementUser(supervised)}
                          color="error"
                          style={{cursor: "pointer"}}
                      />
                  </Box>
              ))}
              <HmyAutocomplete 
                  label="supervised"
                  showLabel={false}
                  fullWidth={true}
                  value={null}
                  loading={loading}
                  options={supervised.filter(x => !newSupervised.map(u => u.id).includes(x.id)).map(x => x.email)}
                  helperText={null}
                  setValue={handleSetSupervised}
                  loadResults={handleLoadSupervised}
              /> 
            </HmySingleForm>
          :
            null
          }
          {user.observatoryAccess === null
            ?
                <Box
                    className="hmy-user-edit-form-observatoryaccess-container"
                >
                    <Box
                        className="hmy-user-edit-form-observatoryaccess-button"
                        onClick={() => handleGetAccessObservatory()}
                    >
                        <FormattedMessage id="getAccessObservatory" />
                    </Box>
                </Box>
            :
                null
          }
        </HmyFormSection>
      </>
    )
}

export default UserHmy;