import './no-landmarks.scss';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SUBROUTE_INBOX } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { ADD_PROJECTINBOXFILE } from 'src/permissions/permissions';
import { CompleteProject } from 'src/models';
import inboxIcon from '../../../../assets/images/inbox.png';

type NoLandmarksProps = {
    project: CompleteProject;
  };

const NoLandmarks: FC<NoLandmarksProps> = ({project}) => {

    const navigate = useNavigate();

    const handleClickInbox = () => {
        navigate(SUBROUTE_INBOX)
    }

  return (
    <Box
        className="no-landmarks-container"
    >
        <Box
            className="no-landmarks-text"
        >
            <Typography className="text-title" ><FormattedMessage id="noLandmarks"/></Typography>
            {project.permissions.findIndex(x => x === ADD_PROJECTINBOXFILE) > -1 && (
                <Typography className="text-body" >
                    <FormattedMessage id="noLandmarksTxt"/>
                    <span
                        className="text-body-navigate"
                        onClick={() => handleClickInbox()}
                    >
                        <FormattedMessage id="inbox"/>
                    </span>
                    <img src={inboxIcon} alt="icon-inbox" />
                </Typography>
            )}
        </Box>  
    </Box>
  )
}

export default NoLandmarks;