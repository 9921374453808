import { GeneralCollection } from 'src/models';
import { http } from '../http-client';
import {
  IncidenceModelService,
  IncidenceStatusModelService,
  PostIncidenceCommentModelService,
  PostIncidenceModelService,
} from './model-service';

const getIncidence = (incidenceId: string | undefined) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<IncidenceModelService>>(`incidence/${incidenceId}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

const getIncidenceStates = () => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<IncidenceStatusModelService>>('incidence/states', {
      signal: controller.signal,
    }),
    controller,
  };
};

const postIncidence = (request: PostIncidenceModelService) => {
  const controller = new AbortController();

  return {
    call: http.post('incidence', request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const postIncidenceComment = (request: PostIncidenceCommentModelService) => {
  const controller = new AbortController();

  return {
    call: http.post(`incidence/${request.incidenceId}/comments`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const putIncidenceStatus = (request: { incidenceId: string; incidenceStatusId: string }) => {
  const controller = new AbortController();

  return {
    call: http.put('incidence/status', request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const deleteIncidence = (incidenceId: string) => {
  const controller = new AbortController();

  return {
    call: http.delete(`incidence/${incidenceId}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const incidenceServices = {
  getIncidence,
  getIncidenceStates,
  postIncidence,
  postIncidenceComment,
  putIncidenceStatus,
  deleteIncidence,
};
