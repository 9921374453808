import './login-form.scss';
import { FC, useState } from 'react';
import { Alert, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LoginFormUser from './login-form-user';
import { authServices } from 'src/services';
import { useFetchAndLoad } from 'src/hooks';
import { useDispatch } from 'react-redux';
import { UserLoginDto } from 'src/dtos';
import { currentUserAdapter, tokenAdapter } from 'src/adapters';
import { setCurrentUser, setMessage } from 'src/redux/states';
import LoginFormRecovery from './login-form-recovery';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { useNavigate } from 'react-router-dom';
import { ROUTE_INSPIRATION } from 'src/components';

type LoginFormProps = {
    isModal: boolean;
    onClose: () => void;
}

const LoginForm: FC<LoginFormProps> = ({ isModal, onClose }) => {

    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const navigate = useNavigate();
    
    const [openRecoverPass, setOpenRecoverPass]= useState<boolean>(false);
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [recaptchaShowed, setRecaptchaShowed]= useState<boolean>(false);
    const [isVerifiedRecovery, setIsVerifiedRecovery] = useState<boolean>(false);
    const [recaptchaShowedRecovery, setRecaptchaShowedRecovery] = useState<boolean>(false);
    const [resetCaptcha, setResetCaptcha]= useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertCaptcha, setOpenAlertCaptcha] = useState(false);

    const getAccessToken = async (request: UserLoginDto) => await callEndpoint(authServices.loginUser(request));
    const getUser = async () => await callEndpoint(authServices.getCurrentUser());
    const getEmailToRecoveryPassword = async (email: string) => await callEndpoint(authServices.getEmailToRecoveryPassword(email));

    const handleOpenRecoverPass = () => {
        setOpenRecoverPass(!openRecoverPass);
    }
    
    const handleClose = () => {
        onClose();
        setOpenRecoverPass(false);
    }

    const  handleSubmit = async (email: string, password: string) => {
        // LogIn user
        if(recaptchaShowed && !isVerified){
            setOpenAlertCaptcha(true);
            setOpenAlert(false);         
        }else{
            if(recaptchaShowed && isVerified){
                setOpenAlertCaptcha(false);
            }

            const request: UserLoginDto = {
                userName: email,
                password: password
            };

            try {
                const token = await getAccessToken(request);
                
                if(token.status === 200){
                    tokenAdapter(token.data);
                    getUser().then((res) => {
                        dispatch(setCurrentUser(currentUserAdapter(res.data)));
                        if(!isModal){
                            navigate(ROUTE_INSPIRATION);
                        }
                    });
                }
                else{
                    setOpenAlert(true);
                }
            } catch (error) {
                setOpenAlert(true);         
            }
        }
    }

    const handleSubmitRecovery = (email: string) => {

        if(!isVerifiedRecovery){
            setOpenAlertCaptcha(true);
            setOpenAlert(false);          
        }else{
            if(isVerifiedRecovery){
                setOpenAlertCaptcha(false);
            }
            
            getEmailToRecoveryPassword(email).then(response => {
                if(response.status === 200){
                    dispatch(setMessage(SuccessMessage("success.recoverPass", true)));
                    setResetCaptcha(true);
                    handleClose();
                }else{
                    dispatch(setMessage(ErrorMessage("error.recoverPass", true)));
                    setResetCaptcha(true);
                }         
            }).catch(error => {
            });
        }

    }

    return (
        <Box
            className="login-form-container"
        >
            {openAlert ?
                <Alert severity="error" style={{fontSize: "20px"}}>
                    <FormattedMessage id="incorrectPassword"/>
                </Alert>
                :
                null
            }
            {openAlertCaptcha
                ?
                <Alert severity="warning" style={{fontSize: "20px"}}>
                    <FormattedMessage id="verifyHuman"/>
                </Alert>
                :
                null
            }
            {!openRecoverPass
                ?
                    <LoginFormUser 
                        isVerified={isVerified}
                        isModal={isModal}
                        handleClose={onClose}
                        handleOpenRecoverPass={handleOpenRecoverPass}
                        setIsVerified={setIsVerified}
                        setRecaptchaShowed={setRecaptchaShowed}
                        handleSubmit={handleSubmit}
                    />
                :
                    <LoginFormRecovery 
                        isVerified={isVerifiedRecovery}
                        resetCaptcha={resetCaptcha}
                        handleOpenRecoverPass={handleOpenRecoverPass}
                        setIsVerified={setIsVerifiedRecovery}
                        setRecaptchaShowed={setRecaptchaShowedRecovery}
                        handleSubmitRecovery={handleSubmitRecovery}
                    />
            }
        </Box>
    )
}

export { LoginForm }