import './hmy-images-viewer.scss';
import { FC } from 'react'
import { Box } from '@mui/material';
import { HmyModalDocumentBase } from 'src/models';

type HmyImagesViewerProps = {
    document: HmyModalDocumentBase;
};

const HmyImageViewer: FC<HmyImagesViewerProps> = ({document}) => {
  return (
    <Box 
      className="hmy-images-viewer-container"
    >
        <img 
            src={document.url} 
            alt={document.name} 
        />
    </Box>
  )
}

export { HmyImageViewer }