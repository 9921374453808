import { Dialog, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { modalDocumentAdapter } from 'src/adapters';
import { HmyModalFileViewer, HmyPdfViewer } from 'src/components';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices } from 'src/services';
import { BasicCatalogueLineArticleModelService } from 'src/services/catalogue';
import './hmy-modal-order.scss';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
type PdfArticleProps = {
  article: BasicCatalogueLineArticleModelService;
};

const PdfArticle: FC<PdfArticleProps> = ({ article }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const [document, setDocument] = useState<any>();
  const { callEndpoint } = useFetchAndLoad();
  const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));

  useEffect(() => {
    if (article && isOpen) {
      getDocument(article.fileId).then((response) => setDocument(modalDocumentAdapter(response.data)));
    }
  }, [article, isOpen]);

  const handleCloseModalViewer = () => {
    setIsOpen(false);
    setDocument(null);
  };
  return (
    <>
      <div className="image image-pdf" onClick={() => setIsOpen(true)}>
        <PictureAsPdfIcon />
      </div>
      {isOpen && document && (
        <HmyModalFileViewer
          isOpen={isOpen}
          canEdit={false}
          file={document}
          handleClose={handleCloseModalViewer}
          hanldeClickVisible={() => setIsOpen(true)}
        >
          <HmyPdfViewer
            base64String={document.url}
            pdfName={document.description ?? document.name}
            zoomManagement={true}
            rotationManagement={!matches}
            closeManagement={false}
            navBarHeight={50}
            handleClose={handleCloseModalViewer}
          />
        </HmyModalFileViewer>
      )}
    </>
  );
};

export default PdfArticle;
