import './hmy-table-row.scss';
import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

type HmyTableRowProps = {
    children: ReactNode;
    isFirst: boolean;
    isLast: boolean;
};

const HmyTableRow: FC<HmyTableRowProps> = ({isFirst, isLast, children}) => {
  return (
    <Box
      className={isFirst ? "hmy-table-row first" : (isLast ? "hmy-table-row last" : "hmy-table-row")}
    >
        {children}
    </Box>
  )
}

export { HmyTableRow };