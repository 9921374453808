import './section-carousel.scss';
import { FC, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { InspirationImageModelService, inspirationServices } from 'src/services';
import { inspirationImageAdapter } from 'src/adapters';
import { InspirationImage } from 'src/models';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import { HmyLinkBtn } from 'src/components';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { PaginationOptions } from 'swiper/types';

const SectionCarousel: FC = () => {

  const { loading, callEndpoint } = useFetchAndLoad();

  const currentUser = useSelector(currentUserSelector);

  const matches = useMediaQuery('(max-width: 768px)');

  const { trackEvent } = useMatomo();
  
  const [imagesIns, setImagesIns] = useState<InspirationImage[]>([]);

  const getCarouselImages = async () => await callEndpoint(inspirationServices.getInspirationImages());

  const adaptImages = (data: InspirationImageModelService[]) => {

    let imageList = data.map(x => inspirationImageAdapter(x));
    setImagesIns(imageList);

  };

  const pagination: PaginationOptions = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} swiper-pagination-mybullet"></span>`;
    },
  };

  const handleClick = (projectUrl: string, projectName: string) => {
    window.open(`${projectUrl}&mihmy=${currentUser.id}`);
    trackEvent({ category: 'onClick', action: 'Inspiration gallery access', name: `${projectName} access` });
  }

  const handleClickToAll = () => {
    window.open(`https://gallery.hmy-bus.app?lang=${currentUser.language}&mihmy=${currentUser.id}`);
    trackEvent({ category: 'onClick', action: 'Inspiration gallery access', name: 'Global access' });
  }

  useAsync(getCarouselImages, adaptImages, () => { }, () => { }, []);

  return (
    <Box className="home-section-carousel" id="sectionCarousel">
      {loading || imagesIns.length === 0
      ?
        null
      :
        <Box
          className="home-carousel-container"
        >
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={matches ? 1 : 2}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 50,
              modifier: 5,
              slideShadows: false
            }}
            pagination={matches ? true : pagination}
            onSwiper={(swiper) => swiper.slideTo(matches ? 3 : 4)}
            modules={[EffectCoverflow, Pagination, Navigation]} 
            loop={true}
            className="mySwiper"
            style={{backgroundColor: "inherit", height: "90%", width: "90%", borderRadius: 8}}
          >  
            {imagesIns.map((x, index) => (
              <SwiperSlide
                key={`slide-${index}`}
                onClick={() => handleClick(x.projectrUrl, x.projectName)}
              >
                {({ isActive, isPrev, isNext }) => (
                  isActive && !matches ?
                    <Box 
                      className="home-carousel-front"
                    >
                      <Box
                        className="home-carousel-front-container"
                      >
                        <img src={x.imageUrl} alt="image-active" ></img>
                        <Box
                          className="home-carousel-front-project-name-opacity"
                        >
                        </Box> 
                        <Box
                          className="home-carousel-front-project-name-container"
                        >
                          <Typography
                            variant="h1"
                            className="home-carousel-front-project-name"
                          >
                            {x.projectName}                    
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  :
                  <Box>
                    <img src={x.imageUrl} style={{maxWidth: "150%"}} alt="image-active" ></img>        
                  </Box>                           
                )}
              </SwiperSlide>
            ))}
          </Swiper> 
        </Box> 
      }
      <Box
        className="hmy-btn-container"
      >
        <HmyLinkBtn 
          title="seeMore"
          width={150}
          handleClick={handleClickToAll}
          color="secondary"
        />      
      </Box> 
    </Box>
  )
}

export default SectionCarousel;