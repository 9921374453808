import {http} from '../http-client';
import { InspirationImageModelService, ProductModelService, SectorModelService } from "./model-service";

const getSectors = () => {
    const controller = new AbortController();

    return { call: http.get<SectorModelService[]>("inspiration/sectors", { signal: controller.signal }), controller };
}

const getProducts = () => {
    const controller = new AbortController();

    return { call: http.get<ProductModelService[]>("inspiration/products", { signal: controller.signal }), controller };
}

const getInspirationImages = () => {
    const controller = new AbortController();

    return { call: http.get<InspirationImageModelService[]>("inspiration/gallerycontent", { signal: controller.signal }), controller };
}

const getObservatoryAccess = (email: string) => {
    const controller = new AbortController();

    return { call: http.post("inspiration/observatoryaccess", { email }, { signal: controller.signal }), controller };
}

export const inspirationServices = {
    getSectors,
    getProducts,
    getInspirationImages,
    getObservatoryAccess
}