import './hmy-single-form.scss';
import { FC, ReactNode, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';

type HmySingleFormProps = {
    children: ReactNode;
    isMultiline: boolean;
    isFullWidth: boolean;
    showTitle: boolean;
    title: string | null;
    textValues: string[] | null;
    canEdit: boolean;
    canDelete: boolean;
    hasErrors: boolean;
    onClickSubmitForm: () => void;
    onClickCancelForm: () => void;
    onDeleteForm: () => void;
};

const HmySingleForm: FC<HmySingleFormProps> = ({ children, isMultiline, isFullWidth, showTitle, title, textValues, canEdit, canDelete, hasErrors, onClickSubmitForm, onClickCancelForm, onDeleteForm}) => {

    const [editMode, setEditMode] = useState<boolean>(false);

    const handleCancelForm = () => {
        setEditMode(false);
        onClickCancelForm();
    }

    const handleSubmit = () => {
        if(!hasErrors){
            setEditMode(false);
            onClickSubmitForm();
        }  
    }

  return (
    <Box
        className={isMultiline ? (isFullWidth ? "hmy-single-form-element-multiline-fullwidth" : "hmy-single-form-element-multiline") : (isFullWidth ? "hmy-single-form-element-fullwidth" : "hmy-single-form-element")}
    >
        {editMode
            ?
                <>
                    {showTitle && title !== null &&
                        <Box
                            className="hmy-single-form-element-title"
                        >
                            <FormattedMessage  id={title} />:
                        </Box>
                    }       
                    <Box
                        className="hmy-single-form-element-content"
                    >
                        {children}
                    </Box>
                    <Box
                        className="hmy-single-form-element-buttons"
                    >
                        <Box
                            className={hasErrors ? "hmy-single-form-button-save-disabled" : "hmy-single-form-button-save"}
                            onClick={() => handleSubmit()}
                        >
                            <FormattedMessage id="save" />
                        </Box>
                        <Box
                            className="hmy-single-form-button-cancel"
                            onClick={() => handleCancelForm()}
                        >
                            <FormattedMessage id="btn.cancel" />
                        </Box>
                    </Box>
                </>        
            :
                <Box 
                    className={textValues && textValues?.length > 1 ? "hmy-single-form-no-edit-multiline" : "hmy-single-form-no-edit"}
                >
                    {title !== null &&
                        <Box
                        className="hmy-single-form-element-title"
                        >
                            <FormattedMessage  id={title} />:
                        </Box>                 
                    }
                    <Box
                        className="hmy-single-form-element-text-container"
                    >
                        {textValues !== null &&
                            textValues.map((textValue, index) => (
                                <Box
                                    className="hmy-single-form-element-text"
                                    key={`${textValue}-${index}`}
                                >
                                    {textValue}
                                </Box>
                            ))  
                        }
                    </Box>
                    {canEdit 
                        ?
                            <Box
                                className="hmy-single-form-no-edit-open-button"
                            >
                                {canDelete &&
                                    <IconButton
                                        onClick={() => onDeleteForm()}
                                        sx={{fontSize: "30px"}}
                                    >
                                        <DeleteIcon color="error" fontSize="inherit" />
                                    </IconButton>
                                } 
                                <IconButton
                                    onClick={() => setEditMode(true)}
                                    sx={{fontSize: "30px"}}
                                >
                                    <DriveFileRenameOutlineIcon color="primary" fontSize="inherit" />
                                </IconButton>                                              
                            </Box>
                        :
                            null
                    }            
                </Box>
        }   
    </Box>
  )
}

export { HmySingleForm }