import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

type LabComponentProps = {
  showSmallEnabled: boolean;
};

const LabComponent: FC<LabComponentProps> = ({showSmallEnabled}) => {

  const currentUser = useSelector(currentUserSelector);
  const { trackEvent } = useMatomo();

  const handleClick = () => {
    window.open(`https://materialab.hmy-bus.app/index.php?lang=${currentUser.language}&mihmy=${currentUser.id}`);
    trackEvent({ category: 'onClick', action: 'materialab access', name: 'materialab access' });
  }

  return (
    <Box
      className={showSmallEnabled ? "section-lab-laboratorio-active" : "section-lab-laboratorio"}
      onClick={() => handleClick()}
    >
      <h2>
        MateriaLab
      </h2>
      <Typography className="section-lab-laboratorio-text">
        <FormattedMessage id="textLab"/>
      </Typography>
      <Typography className="section-lab-laboratorio-text">
        <FormattedMessage id="click"/> <u><FormattedMessage id="here"/></u>
      </Typography>
    </Box>
  )
}

export default LabComponent;