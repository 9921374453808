import './section-lab.scss';
import { FC } from 'react';
import { Box, Fab, Link } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LabComponent from './lab-component';

const SingleSectionLab: FC = () => {
  return (
    <Box className="section-lab simple-section-lab" id="simpleSectionLab">
        <LabComponent 
          showSmallEnabled={true}
        />  
        <Fab className='btn-up'>
          <Link
            href="#sectionWelcome"       
          >
            <ArrowUpwardIcon sx={{fontSize: '50px'}} />
          </Link>    
        </Fab>         
    </Box>
  )
}

export default SingleSectionLab;