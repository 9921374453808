import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Badge, Box, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { EVENT_SHOPPING_LIST_UPDATED } from 'src/hooks/use-shopping-list.hook';
import { storageServices } from 'src/services';
import ShoppingMenuModal from './shopping-menu-modal';
import './shopping-menu.scss';

const ShoppingMenu: FC = () => {
  const [shoppingListItems, setShoppingListItems] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    function checkshoppingList() {
      const shoppingList = storageServices.getShoppingList();

      if (shoppingList && shoppingList.lines) {
        setShoppingListItems(shoppingList.lines.length);
      }
    }
    checkshoppingList();
    document.addEventListener(EVENT_SHOPPING_LIST_UPDATED, checkshoppingList);

    return () => {
      document.removeEventListener(EVENT_SHOPPING_LIST_UPDATED, checkshoppingList);
    };
  }, []);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button id="shopping-button" onClick={handleClick} color="inherit">
        <Badge badgeContent={shoppingListItems} color="primary">
          <ShoppingCartOutlinedIcon style={{ fontSize: 30 }} />
        </Badge>
      </Button>
      {open && <ShoppingMenuModal isOpen close={handleClose} />}
    </Box>
  );
};

export default ShoppingMenu;
