import './hmy-icon-document.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

type HmyIconDocumentProps = {
    color: "auto" | "visible" | "no-visible";
    fontSize: "small" | "inherit" | "large" | "medium";
    fileId: string;
    isVisible: boolean;
    onClick: (fileId: string, isVisible: boolean) => void;
};

const HmyIconDocument: FC<HmyIconDocumentProps> = ({color, fontSize, fileId, isVisible, onClick}) => {
  return (
    <Box
      className={color === "auto" ? "hmy-icon-document" : (color === "visible" ? "hmy-icon-document-visible" : "hmy-icon-document-novisible" )}
      onClick={() => onClick(fileId, isVisible)}
    >
        <TextSnippetIcon fontSize="inherit" />
    </Box>
  )
}

export { HmyIconDocument };