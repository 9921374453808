import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { RefreshTokenDto } from "src/dtos";
import {storageServices} from './storage.service';

enum StatusCode {
    BadRequest = 401,
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Credentials": true,
    "X-Requested-With": "XMLHttpRequest",
};

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
      const token = storageServices.getMiHmyAccessToken();
  
      if (config.headers && token != null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error: any) {
      throw new Error(error);
    }
};

class Http {
    private instance: AxiosInstance | null = null;
  
    private get http(): AxiosInstance {
      return this.instance != null ? this.instance : this.initHttp();
    }
  
    initHttp() {
      const http = axios.create({
        baseURL: process.env.REACT_APP_API_URL as string ?? '',
        headers
      });
  
      http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
  
      http.interceptors.response.use(
        (response) => response,
        (error) => {
          const originalRequest = error.config;
          if(!error.response && !originalRequest._retry){
              originalRequest._retry = true;
              window.location.href = '/serverdown';
          } 

          const { response } = error;
          if (response.status === StatusCode.Unauthorized && originalRequest.url === 'auth/refresh') {
            storageServices.clearMiHmyAccessToken();
            storageServices.setExpiredSession();
            window.location.href = '/';
          }
          else if (response.status === StatusCode.Unauthorized && (originalRequest._retry === undefined || !originalRequest._retry)) 
          {
            originalRequest._retry = true;
            const request: RefreshTokenDto ={
              accessToken: storageServices.getMiHmyAccessToken() ?? "",
              refreshToken: storageServices.getMiHmyRefreshToken() ?? ""
            };
            return this.post('auth/refresh', request)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        storageServices.setMiHmyAccessToken(response.data.accessToken);
                        storageServices.setMiHmyRefreshToken(response.data.refreshToken);
                        http.defaults.headers.common['Authorization'] = `Bearer ${storageServices.getMiHmyAccessToken()}`;
                        return http(originalRequest);
                    }
                    else{
                        storageServices.clearMiHmyAccessToken();
                        storageServices.setExpiredSession();
                        window.location.href = '/';
                    }           
            }).catch(error => {
                return Promise.reject(error);
            });
          }
          return Promise.reject(response);
          //return this.handleError(response);
        }
      );
  
      this.instance = http;
      return http;
    }
  
    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
      return this.http.request(config);
    }
  
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
      return this.http.get<T, R>(url, config);
    }
  
    post<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: T,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return this.http.post<T, R>(url, data, config);
    }
  
    put<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: T,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return this.http.put<T, R>(url, data, config);
    }

    patch<T = any, R = AxiosResponse<T>>(
      url: string,
      data?: T,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return this.http.patch<T, R>(url, data, config);
    }
  
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
      return this.http.delete<T, R>(url, config);
    }
  
    // Handle global app errors
    // We can handle generic app errors depending on the status code
    // private handleError(error : any) {
    //     if(error){
    //         debugger
    //         const { status } = error;
    
    //         switch (status) {
    //           case StatusCode.BadRequest: {
    //               // Handle BadRequest
    //               console.log('BadRequest');
    //               break;
    //           }
    //           case StatusCode.InternalServerError: {
    //               // Handle InternalServerError
    //               console.log('InternalServerError');
    //               break;
    //           }
    //           case StatusCode.Forbidden: {
    //               // Handle Forbidden
    //               break;
    //           }
    //           case StatusCode.Unauthorized: {
    //               // Handle Unauthorized
    //             break;
    //           }
    //           case StatusCode.TooManyRequests: {
    //               // Handle TooManyRequests
    //               break;
    //           }
    //         }

    //         return Promise.reject(error);
    //     }
    // }
  }
  
export const http = new Http();