import './user-list.scss';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LoadComponent } from 'src/components';
import { SetUsersReportDto } from 'src/dtos';
import { useFetchAndLoad } from 'src/hooks';
import { BasicUser, ErrorMessage, SuccessMessage } from 'src/models';
import { setMessage } from 'src/redux/states';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CloseIcon from '@mui/icons-material/Close';
import ReportSettingsUsersListForm from './report-settings-users-list-form';
import { reportServices } from 'src/services';

type ReportUserListProps = {
  reportId: string;
  title: string;
  users: BasicUser[];
  canEdit: boolean;
  reloadReports: () => void;
  isHMYList: boolean;
};

const ReportUserList: FC<ReportUserListProps> = ({ reportId, title, users, canEdit, reloadReports, isHMYList }) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [usersCopy, setUsersCopy] = useState<BasicUser[]>([]);
  const [openForm, setOpenForm] = useState<boolean>(false);

  const setUsersReport = async (request: SetUsersReportDto) =>
    await callEndpoint(reportServices.setReportUsers(request));

  const handleCancelChanges = () => {
    if (users.length > 0) {
      setUsersCopy(JSON.parse(JSON.stringify(users)));
    } else {
      setUsersCopy([]);
    }
  };

  const handleCloseForm = () => {
    if (openForm) {
      setOpenForm(false);
      handleCancelChanges();
    } else {
      setOpenForm(true);
    }
  };

  const handleSetUsers = async (addUsers: string[], removeUsers: string[]) => {
    let request: SetUsersReportDto = {
      reportId: reportId,
      add: addUsers ?? [],
      remove: removeUsers ?? [],
    };

    try {
      await setUsersReport(request);
      handleCloseForm();
      dispatch(setMessage(SuccessMessage('success.reportUpdated', true)));
      reloadReports();
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.updatingReport', true)));
    }
  };

  useEffect(() => {
    handleCancelChanges();
  }, [users]);

  return (
    <Box className="report-settings-users">
      <Box className="report-settings-users-title-container">
        <Typography className="report-settings-users-title">
          <FormattedMessage id={title} />
        </Typography>
        {canEdit ? (
          <Box className="report-settings-icon-edit" onClick={() => handleCloseForm()}>
            {openForm ? <CloseIcon fontSize="inherit" /> : <DriveFileRenameOutlineIcon fontSize="inherit" />}
          </Box>
        ) : null}
      </Box>
      {loading ? <LoadComponent /> : null}
      {openForm ? (
        <ReportSettingsUsersListForm
          users={users}
          usersCopy={usersCopy}
          setUsersCopy={setUsersCopy}
          handleCancelChanges={handleCancelChanges}
          handleSetUsers={handleSetUsers}
          isHMYList={isHMYList}
        />
      ) : (
        <Box className="report-settings-users-list-container">
          {users.map((user) => (
            <Box key={user.id}>
              <Box className="report-settings-user-name">{user.fullName}</Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ReportUserList;
