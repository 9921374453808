import { BasicReport } from "src/models";
import { BasicReportModelService } from "src/services";
import { basicUserAdapater } from "./user.adapter";

export const basicReportAdapater = (basicReport: BasicReportModelService): BasicReport => ({
    reportId: basicReport.reportId,
    title: basicReport.title,
    link: basicReport.link,
    users: basicReport.users.map(x => basicUserAdapater(x))
});
