import { InspirationImage, Product, Sector } from "src/models";
import { InspirationImageModelService, ProductModelService, SectorModelService } from "src/services/inspiration";

export const sectorAdapter = (sector: SectorModelService): Sector => ({
    sectorId: sector.sectorId,
    sectorName: sector.sectorName
});

export const productAdapter = (product: ProductModelService): Product => ({
    productoId: product.productoId,
    productName: product.productName
});

export const inspirationImageAdapter = (inspirationImage: InspirationImageModelService): InspirationImage => ({
    projectrUrl: inspirationImage.projectrUrl,
    projectName: inspirationImage.projectName,
    imageUrl: inspirationImage.imageUrl
});