import './hmy-autocomplete.scss';
import { FC, useEffect, useState, KeyboardEvent } from 'react';
import { Box, TextField, useMediaQuery } from '@mui/material';
import BasicAutocomplete from './basic-autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModalComponent } from '../modal-component';

type HmyAutocompleteAddProps = {
    label: string;
    showLabel: boolean;
    fullWidth: boolean;
    value: string | null;
    options: string[];
    loading: boolean;
    helperText: string | null;
    handleNewElement: (value: string) => void;
    setValue: (value: string | null) => void;
    loadResults: (filter: string) => void;
  };

const HmyAutocompleteAdd: FC<HmyAutocompleteAddProps> = ({ label, showLabel, fullWidth, value, options, loading, helperText, handleNewElement, setValue, loadResults }) => {
    
    const [inputValue, setInputValue] = useState<string>('');
    const [newValue, setNewValue] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleAcceptModal = () => {  
        if(newValue !== null && newValue.trim().length > 0){
            handleNewElement(newValue);
            handleDismissModal();
        }
    }

    const handleDismissModal = () => {
        setOpenModal(false);
        setNewValue(null);
    }

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value);
    };

    useEffect(() => {
        loadResults(inputValue);  
    },[]);
    
    return (
        <Box
            className="hmy-autocomplete-container"
        >
            <BasicAutocomplete 
                label={label}
                showLabel={showLabel}
                fullWidth={fullWidth}
                value={value}
                options={options}
                loading={loading}
                helperText={helperText}
                inputValue={inputValue}
                setValue={setValue}
                setInputValue={setInputValue}
            />  
            <AddCircleIcon 
                fontSize="inherit" 
                color="secondary" 
                className="hmy-autocomplete-add-icon" 
                onClick={() => setOpenModal(true)}
            />
            <ModalComponent
                isOpen={openModal}
                disMissText="btn.add"
                acceptText="btn.cancel"
                colorAcceptButton="green"
                handleDismiss={handleDismissModal}
                handleAccept={handleAcceptModal}
            >
                <Box
                    className="groupings-modal-text"
                >
                    <TextField 
                        label="" 
                        variant="standard" 
                        value={newValue}
                        onChange={handleChangeValue}
                        fullWidth
                        autoFocus
                        inputProps={{style: {fontSize: 22}}}
                    />
                </Box>
            </ModalComponent>
        </Box>
    )
}

export { HmyAutocompleteAdd }