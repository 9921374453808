import './hmy-icon-visibility.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type HmyIconVisibilityProps = {
    isVisible: boolean;
    fontSize: "small" | "inherit" | "large" | "medium";
};

const HmyIconVisibility: FC<HmyIconVisibilityProps> = ({isVisible, fontSize}) => {
  return (
    <Box 
      className={isVisible ? "hmy-icon-visibility-on" : "hmy-icon-visibility-off"}
    >
        {isVisible
        ?
            <VisibilityIcon fontSize="inherit" />
        :
            <VisibilityOffIcon fontSize="inherit"/>
        }                    
    </Box>
  )
}

export { HmyIconVisibility };