import { FC } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const LoadComponent: FC = () => {
  return (
    <Backdrop 
      sx={{zIndex: 1000}}
      open={true}
    >
      <CircularProgress/>
    </Backdrop>
  )
}

export {LoadComponent};