import './report-settings-user-list-form-element.scss'
import { FC } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { BasicUser } from 'src/models';
import DeleteIcon from '@mui/icons-material/Delete';
import { ADD_REPORTS } from 'src/permissions/permissions';
import CAN from 'src/permissions';
import { adjustString } from 'src/utilities';

type ReportSettingsUserListFormElementProps = {
    user: BasicUser;
    handleDeleteUser: (userId: string) => void;
};

const ReportSettingsUserListFormElement: FC<ReportSettingsUserListFormElementProps> = ({user, handleDeleteUser}) => {

    const matches = useMediaQuery('(max-width: 768px)');
    
    return (
    <Box
        className="report-settings-users-list-form-element"
    >
        <Box
            className="report-settings-user-form"
        >
            <Box
                className="report-settings-user-form-email"
            >
                {matches ? 
                    adjustString(user.email, 17)
                    :
                    (user.email)
                }
            </Box>
        </Box>
        {CAN(ADD_REPORTS) 
        ?
            <Box
                className="report-settings-user-form-icon-delete"
                onClick={() => handleDeleteUser(user.id)}
            >
                <DeleteIcon />
            </Box>
        :
            null
        }      
    </Box>
  )
}

export default ReportSettingsUserListFormElement;