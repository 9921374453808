import { TextField, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { HmyForm, HmyFormElement, HmyFormSection } from 'src/components'
import { CreateReportDto } from 'src/dtos';

export type reportError = {
  title: string | null;
  link: string | null;
  total: number;
}

const initialValues: CreateReportDto = {
  title: "",
  link: ""
};

type Props = {
  handleAddReport: (title: string, link: string) => void;
  handleCancelAdd: () => void;
}

const ReportNew: FC<Props> = ({ handleAddReport, handleCancelAdd }) => {

  const matches = useMediaQuery('(max-width: 768px)');
  
  const [values, setValues] = useState<CreateReportDto>(initialValues);
  const [reportErrors, setReportErrors] = useState<reportError>({
    title: null,
    link: null,
    total: 0
  });

  const validateName = (name: string) : number => {
    let totalErrors: number = (reportErrors.title === null ? 0 : 1) + (reportErrors.link === null ? 0 : 1);
    if(name === null || name.trim() === ""){
      setReportErrors({
            ... reportErrors,
            title: "error.required",
            total: totalErrors + 1
        })
        return 1;
    }
    else{
      setReportErrors({
            ... reportErrors,
            title: null,
            total: totalErrors
        })
        return 0;
    }
  }

  const validateLink = (link: string) : number => {
    let totalErrors: number = (reportErrors.title === null ? 0 : 1) + (reportErrors.link === null ? 0 : 1);
    if(link === null || link.trim() === ""){
      setReportErrors({
            ... reportErrors,
            link: "error.required",
            total: totalErrors + 1
        })
        return 1;
    }
    else{
      setReportErrors({
            ... reportErrors,
            link: null,
            total: totalErrors
        })
        return 0;
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(reportErrors.title !== null){
          validateName(event.target.value);
      }
      setValues({
          ... values,
          title: event.target.value
      });
  };

const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(reportErrors.link !== null){
        validateLink(event.target.value);
    }
    setValues({
        ... values,
        link: event.target.value
    });
  };

  const handleCancelForm = () => {
    handleCancelAdd();
  }

  const handleResetForm = () => {
    setValues(initialValues);
    setReportErrors({
      title: null,
      link: null,
      total: 0
    });
  }

  const handleSubmitForm = async () => {
    if(validateLink(values.link) + validateName(values.title) === 0){
      handleAddReport(values.title, values.link);
    }
  }
  
  return (
    <HmyForm
        submitFormDisabled={reportErrors.total > 0}
        handleResetForm={handleResetForm}
        handleSubmitForm={handleSubmitForm}
        handleCancelForm={handleCancelForm}
    >
      <HmyFormSection>
        <HmyFormElement
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="name"
        >
            <TextField 
                label="" 
                variant="standard" 
                value={values.title}
                onChange={handleChangeName}
                onBlur={e => validateName(values.title)}
                error={reportErrors.title !== null}
                helperText={reportErrors.title !== null ? <FormattedMessage id={reportErrors.title} /> : null}
                fullWidth
                inputProps={{style: {fontSize: matches ? 18 : 20}}}
            />   
        </HmyFormElement>
        <HmyFormElement
          isMultiline={false}
          isFullWidth={false}
          showTitle={true}
          title="link"
        >
            <TextField 
                label="" 
                variant="standard" 
                value={values.link}
                onChange={handleChangeLink}
                onBlur={e => validateLink(values.link)}
                error={reportErrors.link !== null}
                helperText={reportErrors.link !== null ? <FormattedMessage id={reportErrors.link} /> : null}
                fullWidth
                inputProps={{style: {fontSize: matches ? 18 : 20}}}
            />   
        </HmyFormElement>
      </HmyFormSection>
    </HmyForm>
  )
}

export default ReportNew