import { BasicOrder } from 'src/models/orders';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { OrderTransportRequestModel } from 'src/services/orders';

export const getOrderPostTransport = (orderData: BasicOrder | BasicShoppingOrder): OrderTransportRequestModel => {
  const { projectId, urgent, lines } = orderData as BasicOrder;

  return {
    projectId,
    urgent: urgent !== null ? urgent : false,
    catalogueLines:
      lines?.map((line) => ({
        catalogueLineId: line.catalogueLineId,
        quantity: line.quantity,
      })) ?? [],
  };
};
