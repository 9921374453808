import { FC, useEffect, useState } from 'react';
import BasicAutocompleteReload from './basic-autocomplete-reload';

type HmyAutocompleteReloadProps = {
    label: string;
    showLabel: boolean;
    fullWidth: boolean;
    value: string | null;
    options: string[];
    loading: boolean;
    helperText: string | null;
    setValue: (value: string | null) => void;
    loadResults: (filter: string) => void;
  };

const HmyAutocompleteReload: FC<HmyAutocompleteReloadProps> = ({ label, showLabel, fullWidth, value, options, loading, helperText, setValue, loadResults }) => {

    const [inputValue, setInputValue] = useState<string>('');

    const [textValue, setTextValue] = useState<string>("");

    useEffect(() => {
        if(textValue !== inputValue){
            setTextValue(inputValue);
            const handler = setTimeout(() => {
                loadResults(inputValue);
            }, 900);
    
            return () => {
                clearTimeout(handler);
            }
        }
    },[inputValue]);

    useEffect(() => {
        loadResults(inputValue);  
    },[]);

    return (
        <BasicAutocompleteReload 
            label={label}
            showLabel={showLabel}
            fullWidth={fullWidth}
            value={value}
            options={options}
            loading={loading}
            helperText={helperText}
            inputValue={inputValue}
            setValue={setValue}
            setInputValue={setInputValue}
        />
    )
}

export { HmyAutocompleteReload }