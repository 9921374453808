import { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Selectable, SelectableDefaultValue } from 'src/models';

type HmySelectableSmartProps = {
    title: string; //Esto tiene que ser el id de la traducción
    showTitle: boolean;
    defaultValue: string;
    elements: Selectable[];
    value: string;
    isFormattedValue: boolean;
    fullWidth: boolean;
    setValue: (value: string) => void;
  };

const HmySelectableSmart: FC<HmySelectableSmartProps> = ({title, showTitle, defaultValue, elements, value, isFormattedValue, fullWidth, setValue}) => {

    const matches = useMediaQuery('(max-width: 768px)');

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };

  return (
    showTitle
    ?
    <FormControl fullWidth={fullWidth} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="hmy-select-smart-label" color="primary"><FormattedMessage id={title} /></InputLabel>
        <Select
            labelId="hmy-select-smart-label"
            id="hmy-select-smart"
            value={value}
            label={<FormattedMessage id={title} />}
            onChange={handleChange}
            size={matches ? "small" : "medium"}
            sx={{fontSize: matches ? "18px" : "20px"}}
            color="primary"
            fullWidth={fullWidth}
        >
            {defaultValue !==""
            ?
                <MenuItem
                    value={SelectableDefaultValue}
                    sx={{fontSize: matches ? "18px" : "20px"}}
                >
                    <FormattedMessage id={defaultValue} />
                </MenuItem>
            :
                null
            }      
            {elements.map((element, index) => (
                <MenuItem 
                    key={element.id}
                    value={element.id}
                    sx={{fontSize: matches ? "18px" : "20px"}}
                >
                    {isFormattedValue ? <FormattedMessage id={element.text} /> : element.text}
                </MenuItem>
            ))}
        </Select>
      </FormControl>
    :
        <Select
            labelId="hmy-select-smart-label"
            id="hmy-select-smart"
            value={value}
            variant="standard"
            onChange={handleChange}
            size={matches ? "small" : "medium"}
            sx={{fontSize: matches ? "18px" : "20px"}}
            color="primary"
            fullWidth={fullWidth}
        >
            {defaultValue !==""
            ?
                <MenuItem 
                    value={SelectableDefaultValue}
                    sx={{fontSize: matches ? "18px" : "20px"}}
                >
                    <FormattedMessage id={defaultValue} />
                </MenuItem>
            :
                null
            }      
            {elements.map((element, index) => (
                <MenuItem 
                    key={element.id}
                    value={element.id}
                    sx={{fontSize: matches ? "18px" : "20px"}}
                >
                    {isFormattedValue ? <FormattedMessage id={element.text} /> : element.text}
                </MenuItem>
            ))}
        </Select>
  )
}

export { HmySelectableSmart }