import './management.scss'
import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { managementSubRoutes, SUBROUTE_COMPANIES, SUBROUTE_REPORTS, SUBROUTE_STATISTICS, SUBROUTE_USER, SUBROUTE_USERS, SUBROUTE_USER_NEW } from 'src/components';
import ManagementTopBar from './management-top-bar';
import { NotFound } from '../not-found';
import CAN from '../../permissions';
import { ADD_USERS, READ_COMPANIES, READ_REPORTS, READ_STATISTICS, READ_USERS } from 'src/permissions/permissions';
import Users from './users';
import UserNew from './user-new';
import User from './user';
import Statistics from './statistics';
import Companies from './companies';
import Reports from './reports';

const Management: FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [optionSelected, setOptionSelected] = useState<managementSubRoutes>(SUBROUTE_USERS)

  const handleClickElement = (value: managementSubRoutes) => {
    navigate(value);
  }

  const handleClickUser = (userId: string) => {
    navigate(`${SUBROUTE_USERS}/${userId}`);
  }

  const handleClickAddUser = () => {
    navigate(`${SUBROUTE_USERS}/${SUBROUTE_USER_NEW}`);
  }

  const handleClickCancelAddUser = () => {
    navigate(SUBROUTE_USERS);
  }

  /**
   * Marca el elemento seleccionado en el menu
   */
  const manageActiveElement = () => {
    let elementActive = optionSelected;
    if(location.pathname.includes(SUBROUTE_USERS))
    {
      elementActive = SUBROUTE_USERS;
    }
    else if(location.pathname.includes(SUBROUTE_REPORTS)){
      elementActive = SUBROUTE_REPORTS;
    }
    else if(location.pathname.includes(SUBROUTE_STATISTICS)){
      elementActive = SUBROUTE_STATISTICS;
    }
    else if(location.pathname.includes(SUBROUTE_COMPANIES)){
      elementActive = SUBROUTE_COMPANIES;
    }
    else{
      elementActive = SUBROUTE_USERS;
    }
    
    if(elementActive !== optionSelected){
      setOptionSelected(elementActive);
    }
  }

  useEffect(() => {
    manageActiveElement();
  },[location.pathname])

  return (
    <Box 
      className="management-container"
    >
      <Box
        className="management-main"
      >
        <ManagementTopBar 
          selected={optionSelected}
          handleClickElement={handleClickElement}
        />
        <Box className='content-box-management'>
          <Routes>
            {!CAN(READ_USERS) ? null : <Route index element={<Users handleClickUser={handleClickUser} handleClickAddUser={handleClickAddUser} />}/>}
            {!CAN(READ_USERS) ? null : <Route path={SUBROUTE_USERS} element={<Users handleClickUser={handleClickUser} handleClickAddUser={handleClickAddUser} />}/>}
            {!CAN(READ_USERS) ? null : <Route path={`${SUBROUTE_USERS}${SUBROUTE_USER}`} element={<User goToUsers={handleClickCancelAddUser} />}/>}
            {!CAN(ADD_USERS) ? null : <Route path={`${SUBROUTE_USERS}/${SUBROUTE_USER_NEW}`} element={<UserNew goToUsers={handleClickCancelAddUser} />} />}
            {!CAN(READ_REPORTS) ? null : <Route path={`${SUBROUTE_REPORTS}`} element={<Reports />} />}
            {/* {!CAN(READ_STATISTICS) ? null : <Route path={SUBROUTE_STATISTICS} element={<Statistics />} />}
            {!CAN(READ_COMPANIES) ? null : <Route path={SUBROUTE_COMPANIES} element={<Companies />} />} */}
            <Route path="*" element={<NotFound />} />
          </Routes> 
        </Box>
      </Box>
    </Box>
  )
}

export default Management;