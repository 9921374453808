import './hmy-icon-launch.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

type HmyIconLaunchProps = {
    fontSize: "small" | "inherit" | "large" | "medium";
};

const HmyIconLaunch: FC<HmyIconLaunchProps> = ({fontSize}) => {
  return (
    <Box
        className="hmy-icon-launch"
    >
        <LaunchIcon fontSize="inherit" />
    </Box>
  )
}

export { HmyIconLaunch };