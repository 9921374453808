import { http } from '../http-client';
import { GetFilterProjectsDto, GetProjectsDto, SetUsersProjectDto, SetVstoreEnabledDto } from 'src/dtos';
import { CompleteProject, GeneralCollection, RequestCollection } from 'src/models';
import { EncodeGetURI } from 'src/utilities';
import {
  BasicProjectModelService,
  ImportProjectModelService,
  ProjectCommercialModelService,
  ProjectProfileModelService,
  VstoreFileModelService,
} from './model-service';
import { AddImportProjectDto } from 'src/dtos/projects/add-import-project.dto';
import { GetVstoreFilesDto } from 'src/dtos/projects/get-vstore-files.dto';

const getProjects = (request: GetProjectsDto) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<BasicProjectModelService>>(EncodeGetURI('project', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const deleteProject = (projectId: string) => {
  const controller = new AbortController();

  return { call: http.delete(`project/${projectId}`, { signal: controller.signal }), controller };
};

const getProject = (projectId: string) => {
  const controller = new AbortController();

  return { call: http.get<CompleteProject>(`project/${projectId}`, { signal: controller.signal }), controller };
};

const getProjectsToImport = (request: RequestCollection) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<ImportProjectModelService>>(EncodeGetURI('project/pivot', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const importProjects = (projects: AddImportProjectDto[]) => {
  const controller = new AbortController();

  return {
    call: http.put('project/importprojectsext', { projects: projects }, { signal: controller.signal }),
    controller,
  };
};

const getVstoreFiles = (request: GetVstoreFilesDto) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<VstoreFileModelService>>(EncodeGetURI('project/vstore/files', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const setVstoreEnabled = (request: SetVstoreEnabledDto) => {
  const controller = new AbortController();

  return { call: http.put('project/vstore/setenabled', request, { signal: controller.signal }), controller };
};

const setVstoreFilevisibility = (projectId: string, fileUrn: string, visible: boolean) => {
  const controller = new AbortController();

  return {
    call: http.post('project/vstore/setfilevisibility', { projectId, fileUrn, visible }, { signal: controller.signal }),
    controller,
  };
};

const setProjectUsers = (request: SetUsersProjectDto) => {
  const controller = new AbortController();

  return { call: http.post('project/setprojectusers', request, { signal: controller.signal }), controller };
};

const updateProjectName = (projectId: string, projectName: string) => {
  const controller = new AbortController();

  return {
    call: http.patch(
      `project/name/${projectId}`,
      [
        {
          op: 'add',
          path: '/projectname',
          value: projectName,
        },
      ],
      { signal: controller.signal, headers: { 'Content-Type': 'application/json-patch+json' } }
    ),
    controller,
  };
};

const getProjectProfiles = () => {
  const controller = new AbortController();

  return {
    call: http.get<ProjectProfileModelService[]>('project/profiles', { signal: controller.signal }),
    controller,
  };
};

const getFilterProjects = (request: GetFilterProjectsDto) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<BasicProjectModelService>>(EncodeGetURI('project/nopagination', request), {
      signal: controller.signal,
    }),
    controller,
  };
};

const getProjectCommercial = (projectId: string) => {
  const controller = new AbortController();

  return {
    call: http.get<ProjectCommercialModelService>(EncodeGetURI('project/commercial', { projectId }), {
      signal: controller.signal,
    }),
    controller,
  };
};

export const projectServices = {
  getProjects,
  deleteProject,
  getProject,
  getProjectsToImport,
  importProjects,
  getVstoreFiles,
  setVstoreEnabled,
  setVstoreFilevisibility,
  setProjectUsers,
  updateProjectName,
  getProjectProfiles,
  getFilterProjects,
  getProjectCommercial,
};
