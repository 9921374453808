import { BasicUser, CompleteUser, Selectable, User, UserGroupingUser } from 'src/models';
import { BasicUserModelService, CompleteUserModelService, UserModelService } from 'src/services';
import { TinyCustomerModelService } from 'src/services/customer';
import { customerProjectAdapater } from './customer.adapter';

export const userAdapter = (user: UserModelService): User => {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    surName: user.surName,
    fullName: user.fullName,
    userName: user.userName,
    mustBeActive: user.mustBeActive,
    language: user.language,
    hmy: user.hmy,
    roles: user.roles,
    emailConfirmed: user.emailConfirmed,
    emailConfirmExpired: user.emailConfirmExpired,
    active: user.active,
    projects: user.projects ?? 0,
    isRemovable: user.isRemovable,
  };
};

export const completeUserAdapter = (user: CompleteUserModelService): CompleteUser => {
  let customers = user.customers?.map((x: TinyCustomerModelService) => {
    return customerProjectAdapater(x);
  });
  let supervisedUsers = user.supervisedUsers?.map((x: BasicUserModelService) => {
    return basicUserAdapater(x);
  });
  let sharedCustomers = user.sharedCustomers?.map((x: BasicUserModelService) => {
    return basicUserAdapater(x);
  });

  return {
    id: user.id,
    email: user.email,
    name: user.name,
    surName: user.surName,
    fullName: user.fullName,
    userName: user.userName,
    mustBeActive: user.mustBeActive,
    language: user.language,
    hmy: user.hmy,
    roles: user.roles,
    isRemovable: user.isRemovable,
    customers: customers,
    notificationsActive: user.notificationsActive,
    observatoryAccess: user.observatoryAccess,
    showMateriaLab: user.showMateriaLab,
    showRoomSpain: user.showRoomSpain,
    showRoomFrance: user.showRoomFrance,
    showRoomEuroshop: user.showRoomEuroshop,
    sectors: user.sectors,
    products: user.products,
    boss: user.boss ? basicUserAdapater(user.boss) : null,
    supervisedUsers: supervisedUsers,
    sharedCustomers: sharedCustomers,
  };
};

export const basicUserSelectableAdapater = (basicUser: BasicUserModelService): Selectable => ({
  id: basicUser.id,
  text: basicUser.email,
});

export const basicUserAdapater = (basicUser: BasicUserModelService): BasicUser => ({
  id: basicUser.id,
  email: basicUser.email,
  fullName: basicUser.fullName,
  isCustomer: basicUser.isCustomer,
});

export const basicUserUGUserAdapater = (uGUser: UserGroupingUser): BasicUser => ({
  id: uGUser.id,
  email: uGUser.email,
  fullName: uGUser.fullName,
});
