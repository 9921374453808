import './user-new.scss';
import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CreateUserDto, GetSelectableCustomersDto, GetSelectableUsersDto } from 'src/dtos';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import CAN from '../../../permissions';
import { ADD_ROLE } from 'src/permissions/permissions';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { HmyForm, HmyFormElement, HmyFormSection, HmySelectableSmart, LoadComponent } from 'src/components';
import {
  BasicUserModelService,
  inspirationServices,
  ProductModelService,
  SectorModelService,
  userServices,
} from 'src/services';
import { FormattedMessage } from 'react-intl';
import { BasicUser, ErrorMessage, Language, Product, Roles, Sector, Selectable } from 'src/models';
import { basicUserAdapater } from 'src/adapters/user.adapter';
import { TinyCustomerModelService } from 'src/services/customer';
import { UserCustomersForm, UserHmyForm } from './user-forms';
import { customerProjectAdapater, productAdapter, sectorAdapter } from 'src/adapters';
import { TinyCustomer } from 'src/models/customer';
import { IsAdmin } from 'src/utilities';

type UserNewProps = {
  goToUsers: () => void;
};

export type userCustomerError = {
  name: string | null;
  surname: string | null;
  userName: string | null;
  commercial: string | null;
  total: number;
};

export type userHmyError = {
  userName: string | null;
  total: number;
};

const UserNew: FC<UserNewProps> = ({ goToUsers }) => {
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  const getListRoles = async () => await callEndpoint(userServices.getListRoles());
  const getSelectableUsers = async (request: GetSelectableUsersDto) =>
    await callEndpoint(userServices.getSelectableUsers(request));
  const getSelectableCustomers = async (request: GetSelectableCustomersDto) =>
    await callEndpoint(userServices.getSelectableCustomers(request));
  const getSectors = async () => await callEndpoint(inspirationServices.getSectors());
  const getProducts = async () => await callEndpoint(inspirationServices.getProducts());
  const createUser = async () => await callEndpoint(userServices.createUser(values));

  const initialValues: CreateUserDto = {
    name: '',
    surname: '',
    userName: '',
    language: currentUser.language,
    role: CAN(ADD_ROLE) ? 'Comercial' : 'Cliente',
    boss: null,
    customer: null,
    users: [],
    showMateriaLab: true,
    showRoomSpain: true,
    showRoomFrance: true,
    showRoomEuroshop: true,
    sectors: [],
    products: [],
    commercial: null,
  };
  const [roles, setRoles] = useState<string[]>([]);
  const [supervisors, setSupervisors] = useState<BasicUser[]>([]);
  const [supervised, setSupervised] = useState<BasicUser[]>([]);
  const [sharedUsers, setSharedUsers] = useState<BasicUser[]>([]);
  const [customers, setCustomers] = useState<TinyCustomer[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [commercials, setCommercials] = useState<BasicUser[]>([]);
  const [values, setValues] = useState<CreateUserDto>(initialValues);
  const [customerErrors, setCustomerErrors] = useState<userCustomerError>({
    name: null,
    surname: null,
    userName: null,
    commercial: null,
    total: 0,
  });
  const [hmyErrors, setHmyErrors] = useState<userHmyError>({
    userName: null,
    total: 0,
  });

  const adaptListRoles = (data: string[]) => {
    setRoles(data);
  };

  const adaptSectors = (data: SectorModelService[]) => {
    setSectors(data.map((x) => sectorAdapter(x)));
  };

  const adaptProducts = (data: ProductModelService[]) => {
    setProducts(data.map((x) => productAdapter(x)));
  };

  const handleUpdateRole = (value: string) => {
    setValues({
      ...initialValues,
      role: value,
    });
  };

  const validateEmailCustomer = (email: string): number => {
    const regex = RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[^@]+.[a-zA-Z]{2,}$");
    let totalErrors: number =
      (customerErrors.name === null ? 0 : 1) +
      (customerErrors.surname === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (email === null || email.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        userName: 'error.required',
        total: totalErrors + 1,
      });

      return totalErrors + 1;
    } else if (!regex.test(email.trim()) || email.trim().endsWith('@hmy-group.com')) {
      setCustomerErrors({
        ...customerErrors,
        userName: 'error.email',
        total: 1,
      });

      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        userName: null,
        total: totalErrors,
      });

      return 0;
    }
  };

  const validateEmailHmy = (email: string): number => {
    const regex = RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[^@]+.[a-zA-Z]{2,}$");
    if (email === null || email.trim() === '') {
      setHmyErrors({
        ...hmyErrors,
        userName: 'error.required',
        total: 1,
      });
      return 1;
    } else if (!regex.test(`${email.trim()}@hmy-group.com`)) {
      setHmyErrors({
        ...hmyErrors,
        userName: 'error.email',
        total: 1,
      });
      return 1;
    } else {
      setHmyErrors({
        ...customerErrors,
        userName: null,
        total: 0,
      });
      return 0;
    }
  };

  const validateName = (name: string): number => {
    let totalErrors: number =
      (customerErrors.userName === null ? 0 : 1) +
      (customerErrors.surname === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (name === null || name.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        name: 'error.required',
        total: totalErrors + 1,
      });
      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        name: null,
        total: totalErrors,
      });
      return 0;
    }
  };

  const validateSurname = (surname: string): number => {
    let totalErrors: number =
      (customerErrors.userName === null ? 0 : 1) +
      (customerErrors.name === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (surname === null || surname.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        surname: 'error.required',
        total: totalErrors + 1,
      });
      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        surname: null,
        total: totalErrors,
      });
      return 0;
    }
  };

  const validateCommercial = (commercial: BasicUser | null): number => {
    let totalErrors: number =
      (customerErrors.userName === null ? 0 : 1) +
      (customerErrors.name === null ? 0 : 1) +
      (customerErrors.surname === null ? 0 : 1);
    if (IsAdmin(currentUser) && commercial === null) {
      setCustomerErrors({
        ...customerErrors,
        commercial: 'error.required',
        total: totalErrors + 1,
      });
      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        commercial: null,
        total: totalErrors,
      });
      return 0;
    }
  };

  const handleCancelForm = () => {
    goToUsers();
  };

  const handleResetForm = () => {
    setValues(initialValues);
    setCustomerErrors({
      name: null,
      surname: null,
      userName: null,
      commercial: null,
      total: 0,
    });
    setHmyErrors({
      userName: null,
      total: 0,
    });
  };

  const handleSubmitForm = async () => {
    let result = 0;
    if (values.role === Roles.CUSTOMER) {
      result = result + validateEmailCustomer(values.userName);
      result = result + validateName(values.name);
      result = result + validateSurname(values.surname);
      result = result + validateCommercial(values.commercial);
    } else {
      result = result + validateEmailHmy(values.userName);
    }
    if (result === 0) {
      try {
        await createUser();
        goToUsers();
      } catch (error: any) {
        dispatch(setMessage(ErrorMessage(error.data.errors.id, true)));
      }
    }
  };

  const adaptBasicUsers = (data: BasicUserModelService[]): BasicUser[] => {
    return data.map((x) => basicUserAdapater(x));
  };

  const adaptCustomers = (data: TinyCustomerModelService[]): TinyCustomer[] => {
    return data.map((x) => customerProjectAdapater(x));
  };

  const adaptRoleToSelectable = (value: string): Selectable => {
    return {
      id: value,
      text: value.toLowerCase(),
    };
  };

  const adaptLanguageToSelectable = (value: Language): Selectable => {
    return {
      id: value.name,
      text: value.translate,
    };
  };

  const handleLoadSupervisors = async (isSupervisorBoss: boolean) => {
    try {
      let request: GetSelectableUsersDto = {
        roleName: isSupervisorBoss ? Roles.BOSSSUPERVISOR : Roles.SUPERVISOR,
        usersDiscard: [],
        withoutSupervisor: false,
      };

      const response = await getSelectableUsers(request);
      setSupervisors(adaptBasicUsers(response.data));
    } catch (error) {}
  };

  const handleLoadSupervised = async (excludedUsers: string[]) => {
    try {
      let request: GetSelectableUsersDto = {
        roleName: values.role === Roles.BOSSSUPERVISOR ? Roles.SUPERVISOR : Roles.COMMERCIAL,
        usersDiscard: excludedUsers,
        withoutSupervisor: true,
      };

      const response = await getSelectableUsers(request);
      setSupervised(adaptBasicUsers(response.data));
    } catch (error) {}
  };

  const handleLoadSharedUsers = async (excludedUsers: string[]) => {
    try {
      let request: GetSelectableUsersDto = {
        roleName: Roles.COMMERCIAL,
        usersDiscard: excludedUsers,
        withoutSupervisor: false,
      };

      const response = await getSelectableUsers(request);
      setSharedUsers(adaptBasicUsers(response.data));
    } catch (error) {}
  };

  const handleLoadCommercials = async () => {
    try {
      let request: GetSelectableUsersDto = {
        roleName: Roles.COMMERCIAL,
        usersDiscard: [],
        withoutSupervisor: false,
      };

      const response = await getSelectableUsers(request);
      setCommercials(adaptBasicUsers(response.data));
    } catch (error) {}
  };

  const handleLoadCustomers = async (filter: string) => {
    try {
      let request: GetSelectableCustomersDto = {
        take: 15,
        filter: filter,
        includeEtl: true,
      };

      const response = await getSelectableCustomers(request);
      setCustomers(adaptCustomers(response.data));
    } catch (error) {}
  };

  useAsync(
    getListRoles,
    adaptListRoles,
    () => {},
    () => {},
    []
  );
  useAsync(
    getSectors,
    adaptSectors,
    () => {},
    () => {},
    []
  );
  useAsync(
    getProducts,
    adaptProducts,
    () => {},
    () => {},
    []
  );

  return (
    <Box className="user-new-container">
      <Typography className="user-new-title">
        <FormattedMessage id="newUser" />
      </Typography>
      <HmyForm
        submitFormDisabled={values.role === Roles.CUSTOMER ? customerErrors.total > 0 : hmyErrors.total > 0}
        handleResetForm={handleResetForm}
        handleSubmitForm={handleSubmitForm}
        handleCancelForm={handleCancelForm}
      >
        {loading ? <LoadComponent /> : null}
        {CAN(ADD_ROLE) && (
          <HmyFormSection>
            <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="type">
              <HmySelectableSmart
                title="type"
                showTitle={false}
                defaultValue=""
                elements={roles.map((x) => adaptRoleToSelectable(x))}
                value={values.role}
                isFormattedValue={true}
                fullWidth={true}
                setValue={handleUpdateRole}
              />
            </HmyFormElement>
          </HmyFormSection>
        )}
        {values.role === Roles.CUSTOMER ? (
          <UserCustomersForm
            values={values}
            loading={loading}
            sharedUsers={sharedUsers}
            customers={customers}
            sectors={sectors}
            products={products}
            commercials={commercials}
            customerErrors={customerErrors}
            setValues={setValues}
            adaptLanguageToSelectable={adaptLanguageToSelectable}
            loadSharedUsers={handleLoadSharedUsers}
            loadCommercials={handleLoadCommercials}
            loadCustomers={handleLoadCustomers}
            validateEmail={validateEmailCustomer}
            validateName={validateName}
            validateSurname={validateSurname}
            validateCommercial={validateCommercial}
          />
        ) : (
          <UserHmyForm
            values={values}
            loading={loading}
            supervisors={supervisors}
            supervised={supervised}
            uerHmyErrors={hmyErrors}
            setValues={setValues}
            adaptLanguageToSelectable={adaptLanguageToSelectable}
            loadSupervisors={handleLoadSupervisors}
            loadSupervised={handleLoadSupervised}
            validateEmail={validateEmailHmy}
          />
        )}
      </HmyForm>
    </Box>
  );
};

export default UserNew;
