import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
/**
 * 
 * @param asyncFn Función asíncrona que se espera ejecutar
 * @param successFunction Función que se ejecuta si la llamada ha tenido éxito
 * @param errorFunction Función que se ejecuta si la llamada ha fallado
 * @param returnFunction Función que se ejecuta siempre
 * @param dependencies Dependencias para el useEffect
 */
export const useAsync = (
  asyncFn: () => Promise<AxiosResponse<any, any>>,
  successFunction: Function,
  errorFunction: Function,
  returnFunction: Function,
  dependencies: any[] = []
) => {
  useEffect(() => {
    let isActive = true;
    asyncFn().then((result) => {
      if (isActive) successFunction(result.data);
    }).catch(error =>{
      if (isActive) errorFunction(error);
    });
    return () => {
      returnFunction && returnFunction();
      isActive = false;
    };
  }, dependencies);
};