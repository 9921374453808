import { Box } from '@mui/material';
import { FC } from 'react';
import { parseISO, format } from 'date-fns';
import './incidence-comment.scss';

type IncidenceCommentProps = {
  data: { comment: string; date: string; userName: string };
  odd: boolean;
};

const IncidenceComment: FC<IncidenceCommentProps> = ({ data, odd }) => {
  const formatDate = (date: string) => {
    const parseDate = parseISO(date);
    return format(parseDate, 'dd/MM/yyyy, HH:mm');
  };
  return (
    <Box className="incidence-comment">
      <div className="incidence-comment-head">
        <span className="incidence-comment-username">{data.userName}</span>
        <span className="incidence-comment-date">{formatDate(data.date)}</span>
      </div>
      <div className="incidence-comment-body">{data.comment}</div>
    </Box>
  );
};

export default IncidenceComment;
