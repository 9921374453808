import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import Recaptcha from 'react-recaptcha';

type CaptchaProps = {
    language: string;
    setIsVerified: (value : boolean) => void;
    setRecaptchaShowed: (value : boolean) => void;
    resetCaptcha: boolean;
}

const Captcha: FC<CaptchaProps> = ({ language, setIsVerified, setRecaptchaShowed = null, resetCaptcha = false }) => {

    let recaptchaInstance: Recaptcha | null;

    const recaptchaLoaded = () => {
        if (setRecaptchaShowed) {
            setRecaptchaShowed(true);
        }
    }

    const handleVerifyCallback = (response: string) => {
        if (response) {
            setIsVerified(true);
        }
    }

    const handleExpiredCallback = () => {
        if(recaptchaInstance !== null){
            recaptchaInstance.reset();
        }
        setIsVerified(false);
    }

    useEffect(() => {
        if (resetCaptcha === true && recaptchaInstance !== null) {
            recaptchaInstance.reset();
        }
        // eslint-disable-next-line
    }, [resetCaptcha]);

    return (
        <Box>
            <Recaptcha
                ref={e => recaptchaInstance = e}
                sitekey={process.env.REACT_APP_recaptchaKey}
                render="explicit"
                onloadCallback={recaptchaLoaded}
                verifyCallback={handleVerifyCallback}
                expiredCallback={handleExpiredCallback}
                type="image"
                size="normal"
                hl={language}
            />
        </Box>
    )
}

export { Captcha };