import './grouping-new.scss';
import { FC, useState } from 'react'
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { useFetchAndLoad } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormattedMessage } from 'react-intl';
import { HmyAutocomplete, HmyForm, HmyFormElement, HmyFormSection, LoadComponent, ROUTE_USER_GROUPINGS } from 'src/components';
import { CreateGroupingDto, GetSelectableUsersDto } from 'src/dtos';
import { BasicUserModelService, groupingServices, userServices } from 'src/services';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { BasicUser, ErrorMessage, Roles, SuccessMessage } from 'src/models';
import DeleteIcon from '@mui/icons-material/Delete';
import { basicUserAdapater } from 'src/adapters/user.adapter';

const GroupingNew: FC = () => {

  const { loading, callEndpoint } = useFetchAndLoad();
  const matches = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: CreateGroupingDto = {
    name: "",
    users: [],
    hmyUsers: []
  };

  const [values, setValues] = useState<CreateGroupingDto>(initialValues);
  const [nameError, setNameError] = useState<string | null>(null);
  const [hmyUsers, setHmyUsers] = useState<BasicUser[]>([]);
  const [users, setUsers] = useState<BasicUser[]>([]);

  const createGrouping = async () => await callEndpoint(groupingServices.createGrouping(values));
  const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));

  const goBack = () => {
    navigate(-1);
  }

  const adaptBasicUsers = (data: BasicUserModelService[]): BasicUser[] => {
    return data.map(x => basicUserAdapater(x));
  };

  const validateName = (name: string) : number => {
    if(name === null || name.trim() === ""){
      setNameError("error.required");
      return 1;
    }
    else{
      setNameError(null);
      return 0;
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(nameError !== null){
        validateName(event.target.value);
    }
    setValues({
        ...values,
        name: event.target.value
    });
  };

  const handleCancelElementUser = (value: BasicUser, isHmyUser: boolean) => {
    if(isHmyUser){
      let newHmyUsers = [...values.hmyUsers.filter(x => x.id !== value.id)];
      setValues({
          ...values,
          hmyUsers: newHmyUsers
      });
    }
    else{
      let newUsers = [...values.users.filter(x => x.id !== value.id)];
      setValues({
          ...values,
          users: newUsers
      });
    }
  }

  const handleLoadHmyUsers = async () => {
    try {
        let request: GetSelectableUsersDto = {
            roleName: Roles.COMMERCIAL,
            usersDiscard: values.hmyUsers.map(x => x.id),
            withoutSupervisor: false
        };

        const response = await getSelectableUsers(request);
        setHmyUsers(adaptBasicUsers(response.data));

    } catch (error) {
    }
  }

  const handleSetHmyUser = (value: string | null) => {
    if(value !== null){
        let newUser = hmyUsers.find(x => x.email === value);
        let newUsers = [...values.hmyUsers];
        newUsers.push(newUser!);
        setValues({
            ...values,
            hmyUsers: newUsers
        });
    } 
  }

  const handleLoadUsers = async () => {
    try {
        let request: GetSelectableUsersDto = {
            roleName: Roles.CUSTOMER,
            usersDiscard: values.users.map(x => x.id),
            withoutSupervisor: false
        };

        const response = await getSelectableUsers(request);
        setUsers(adaptBasicUsers(response.data));

    } catch (error) {
    }
  }

  const handleSetUser = (value: string | null) => {
    if(value !== null){
        let newUser = users.find(x => x.email === value);
        let newUsers = [...values.users];
        newUsers.push(newUser!);
        setValues({
            ...values,
            users: newUsers
        });
    } 
  }

  const handleResetForm = () => {
    setValues(initialValues);
  }
  
  const handleSubmitForm = async () => {
    try {
      await createGrouping();
      goBack();
      dispatch(setMessage(SuccessMessage("success.userGroupingCreated", true)));
    } catch (error: any) {
      dispatch(setMessage(ErrorMessage("error.createUserGrouping", true)));
    }
  }

  return (
    <Box
      className="user-grouping-new-container"
    >
      {loading ? <LoadComponent /> : null}
      <Box
        className="grouping-new-main"
      >
        <Box
          className="grouping-new-title"
          onClick={() => navigate(ROUTE_USER_GROUPINGS)}
        >
          <ChevronLeftIcon /> 
          <Typography className="grouping-new-title-text">
            <FormattedMessage id="btn.comeBack" />
          </Typography>
        </Box>
        <Box
          className="grouping-new-form-container"
        >
          <HmyForm
            submitFormDisabled={values.name === ''}
            handleResetForm={handleResetForm}
            handleSubmitForm={handleSubmitForm}
            handleCancelForm={goBack}
          >
            <HmyFormSection>
              <HmyFormElement
                isMultiline={false}
                isFullWidth={true}
                showTitle={true}
                title="groupName"
              >
                <TextField 
                    label="" 
                    variant="standard" 
                    value={values.name}
                    onChange={handleChangeName}
                    onBlur={e => validateName(values.name)}
                    error={nameError !== null}
                    helperText={nameError !== null ? <FormattedMessage id={nameError} /> : null}
                    fullWidth
                    inputProps={{style: {fontSize: matches ? 16 : 20}}}
                />   
              </HmyFormElement>
            </HmyFormSection>
            <HmyFormSection>
              <HmyFormElement
                  isMultiline={true}
                  isFullWidth={false}
                  showTitle={true}
                  title="hmyAccounts"
              >
                  {values.hmyUsers.map((hmyUser, index) => (
                      <Box
                          key={`${hmyUser.id}-${index}`}
                          className="grouping-new-form-element-loaded"
                      >
                          <Typography>{hmyUser.email}</Typography>
                          <DeleteIcon 
                              onClick={() => handleCancelElementUser(hmyUser, true)}
                              color="error"
                              style={{cursor: "pointer"}}
                          />
                      </Box>
                  ))}
                  <HmyAutocomplete 
                      label="hmyAccounts"
                      showLabel={false}
                      fullWidth={true}
                      value={null}
                      loading={loading}
                      options={hmyUsers.filter(x => !values.users.map(u => u.id).includes(x.id)).map(x => x.email)}
                      helperText={null}
                      setValue={handleSetHmyUser}
                      loadResults={handleLoadHmyUsers}
                  />  
              </HmyFormElement>
              <HmyFormElement
                  isMultiline={true}
                  isFullWidth={false}
                  showTitle={true}
                  title="customerAccounts"
              >
                  {values.users.map((user, index) => (
                      <Box
                          key={`${user.id}-${index}`}
                          className="grouping-new-form-element-loaded"
                      >
                          <Typography>{user.email}</Typography>
                          <DeleteIcon 
                              onClick={() => handleCancelElementUser(user, false)}
                              color="error"
                              style={{cursor: "pointer"}}
                          />
                      </Box>
                  ))}
                  <HmyAutocomplete 
                      label="customerAccounts"
                      showLabel={false}
                      fullWidth={true}
                      value={null}
                      loading={loading}
                      options={users.filter(x => !values.users.map(u => u.id).includes(x.id)).map(x => x.email)}
                      helperText={null}
                      setValue={handleSetUser}
                      loadResults={handleLoadUsers}
                  />  
              </HmyFormElement>
            </HmyFormSection>
          </HmyForm>
        </Box>
      </Box> 
    </Box>
  )
}

export default GroupingNew;