import './hmy-form-element.scss';
import { FC, ReactNode } from 'react'
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type HmyFormElementProps = {
    children: ReactNode;
    isMultiline: boolean;
    isFullWidth: boolean;
    showTitle: boolean;
    title: string | null;
};
 
const HmyFormElement: FC<HmyFormElementProps> = ({ children, isMultiline, isFullWidth, showTitle, title }) => {
  return (
    <Box
        className={isMultiline ? (isFullWidth ? "hmy-form-element-multiline-fullwidth" : "hmy-form-element-multiline") : (isFullWidth ? "hmy-form-element-fullwidth" : "hmy-form-element")}
    >
        {showTitle && title !== null &&
            <Box
                className="hmy-form-element-title"
            >
                <FormattedMessage  id={title} />:
            </Box>
        }       
        <Box
            className="hmy-form-element-content"
        >
            {children}
        </Box> 
    </Box>
  )
}

export { HmyFormElement };