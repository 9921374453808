import './hmy-pdf-viewer-nav.scss';
import { FC } from "react";
import { ArrowBack, ArrowForward, RotateLeft, RotateRight, ZoomIn, ZoomOut, Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from "@mui/material";

type HmyPdfViewerNavBarProps = {
  navBarHeight: number,
  pdfName?: string,
  viewportRatio?: number,
  visualViewport?: any,
  managePagination: boolean,
  manageZoom: boolean,
  manageRotation: boolean,
  manageClose: boolean,
  currentPage: number,
  totalPages: number,
  scale: number,
  isMobile: boolean,
  handleOnClose: () => void,
  handleOnNextPage: () => void,
  handleOnPreviousPage: () => void,
  handleOnZoomIn?: () => void,
  handleOnZoomOut?: () => void,
  handleOnRotateClockWise?: () => void,
  handleOnRotateAntiClockWise?: () => void
}

const HmyPdfViewerNavBar: FC<HmyPdfViewerNavBarProps> = ({ navBarHeight,
  pdfName,
  viewportRatio,
  managePagination,
  manageZoom,
  manageRotation,
  manageClose,
  currentPage,
  totalPages,
  scale,
  isMobile,
  handleOnClose,
  handleOnNextPage,
  handleOnPreviousPage,
  handleOnZoomIn,
  handleOnZoomOut,
  handleOnRotateClockWise,
  handleOnRotateAntiClockWise }) => {

  return (
    <Box
      className="hmy-pdf-viewer-navBar"
      style={{
        height: navBarHeight
      }}
    >
      {
        isMobile && pdfName
          ?
          <Box>
            <Typography
              style={{
                fontSize: (navBarHeight / 3),
                lineHeight: navBarHeight + "px",
                fontWeight: 400
              }}
            >
              {pdfName?.length > 17 ? pdfName?.substring(0,17) + "..." : pdfName}
            </Typography>
          </Box>
          :
          null
      }
      {
        manageZoom
          ?
          <Box
            className="hmy-pdf-viewer-navBar-Zoom"
            style={{
              height: navBarHeight
            }}
          >
            {
              !isMobile
                ?
                <>
                  <IconButton
                    onClick={handleOnZoomIn}
                    style={{
                      padding: (navBarHeight / 5),
                      height: navBarHeight,
                      width: navBarHeight,
                      fontSize: (navBarHeight / 3)
                    }}
                    disabled={scale === 400 ? true : false}
                  >
                    <ZoomIn />
                  </IconButton>
                  <Typography
                    style={{
                      fontSize: (navBarHeight / 3),
                      lineHeight: navBarHeight + "px",
                    }}
                  >
                    {scale} %
                  </Typography>
                  <IconButton
                    onClick={handleOnZoomOut}
                    style={{
                      padding: (navBarHeight / 5),
                      height: navBarHeight,
                      width: navBarHeight,
                      fontSize: (navBarHeight / 3)
                    }}
                    disabled={scale === 60 ? true : false}
                  >
                    <ZoomOut />
                  </IconButton>
                </>
                :
                null
            }
          </Box>
          :
          null
      }
      {
        manageRotation
          ?
          <Box
            style={{
              height: navBarHeight
            }}
          >
            <IconButton
              onClick={handleOnRotateClockWise}
              style={{
                padding: (navBarHeight / 5),
                height: navBarHeight,
                width: navBarHeight,
                fontSize: (navBarHeight / 3)
              }}
            >
              <RotateRight />
            </IconButton>
            <IconButton
              onClick={handleOnRotateAntiClockWise}
              style={{
                padding: (navBarHeight / 5),
                height: navBarHeight,
                width: navBarHeight,
                fontSize: (navBarHeight / 3)
              }}
            >
              <RotateLeft />
            </IconButton>
          </Box>
          :
          null
      }
      <Box
        className="hmy-pdf-viewer-navBar-Pagination"
        style={{
          height: navBarHeight
        }}
      >
        <IconButton
          onClick={handleOnPreviousPage}
          style={{
            padding: (navBarHeight / 5),
            height: navBarHeight,
            width: navBarHeight,
            fontSize: (navBarHeight / 3)
          }}
          disabled={currentPage === 1 || !managePagination ? true : false}
        >
          <ArrowBack />
        </IconButton>
        <Typography
          style={{
            fontSize: (navBarHeight / 3),
            lineHeight: navBarHeight + "px",
          }}
        >
          {currentPage} / {totalPages}
        </Typography>
        <IconButton
          onClick={handleOnNextPage}
          style={{
            padding: (navBarHeight / 5),
            height: navBarHeight,
            width: navBarHeight,
            fontSize: (navBarHeight / 3)
          }}
          disabled={currentPage === totalPages || !managePagination ? true : false}
        >
          <ArrowForward />
        </IconButton>
      </Box>
      {
        isMobile || manageClose
          ?
          <Box
            className="hmy-pdf-viewer-navBar-Close"
            style={{
              height: navBarHeight
            }}
          >
            <IconButton
              onClick={handleOnClose}
              style={{
                padding: (navBarHeight / 5),
                height: navBarHeight,
                width: navBarHeight,
                fontSize: (navBarHeight / 3)
              }}
              disabled={ !manageClose }
            >
              <Close />
            </IconButton>
          </Box>
          :
          null
      }
    </Box>
  );
}

export { HmyPdfViewerNavBar };