import './menu-bottom.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  loginRoutes,
  mainRoutes,
  ROUTE_INSPIRATION,
  ROUTE_LANDING,
  ROUTE_LOGIN,
  ROUTE_MANAGEMENT,
  ROUTE_ORDERS,
  ROUTE_PROFILE,
  ROUTE_PROJECTS,
  ROUTE_USER_GROUPINGS,
} from '../routes';
import { SubRoute } from 'src/models/app/route.model';
import { FormattedMessage } from 'react-intl';
import { IsAdmin, IsRoot, combineUrl } from 'src/utilities';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import {
  READ_GROUPINGS,
  READ_HOME,
  READ_MANAGEMENT,
  READ_ORDERS,
  READ_PROFILE,
  READ_PROJECTS,
} from 'src/permissions/permissions';
import InspirationIcon from '../../assets/images/Inspiracion.svg';
import ProjectsIcon from '../../assets/images/Proyectos.svg';
import OrdersIcon from '../../assets/images/Pedidos.svg';
import GroupsIcon from '../../assets/images/Agrupaciones.svg';
import UserIcon from '../../assets/images/Usuario.svg';
import SettingsIcon from '../../assets/images/Administracion.svg';
import LoginIcon from '../../assets/images/Login.svg';
import HomeIcon from '../../assets/images/Home.svg';

const MenuBottom: FC = () => {
  const navigate = useNavigate();

  const currentUser = useSelector(currentUserSelector);

  const [active, setActive] = useState<string>('home');

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
    event.preventDefault();

    navigate(link);
  };

  /**
   * Nos da los tabs visibles por el usuario
   */
  const getRoutesWithRights = (): SubRoute[] => {
    if (
      window.location.pathname === process.env.REACT_APP_PUBLIC_URL! ||
      window.location.pathname === `${process.env.REACT_APP_PUBLIC_URL!}/` ||
      window.location.pathname === combineUrl([process.env.REACT_APP_PUBLIC_URL!, ROUTE_LOGIN]) ||
      window.location.pathname.includes('staticre') ||
      currentUser.email === ''
    ) {
      return loginRoutes;
    } else {
      let routes: SubRoute[] = [];
      mainRoutes.forEach(function (value) {
        switch (value.link) {
          case ROUTE_INSPIRATION: {
            if (currentUser.permissions.findIndex((X) => X === READ_HOME) > -1) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_PROJECTS: {
            if (currentUser.permissions.findIndex((X) => X === READ_PROJECTS) > -1) {
              routes.push({
                name: value.name,
                link: `${value.link}?page=1&take=10&typeOrder=DESC`,
              });
            }
            break;
          }
          case ROUTE_ORDERS: {
            if (
              currentUser.permissions.findIndex((X) => X === READ_ORDERS) > -1 &&
              (IsRoot(currentUser) || IsAdmin(currentUser))
            ) {
              routes.push({
                name: value.name,
                link: `${value.link}?page=1&take=10&typeOrder=DESC`,
              });
            }
            break;
          }
          case ROUTE_USER_GROUPINGS: {
            if (currentUser.permissions.findIndex((X) => X === READ_GROUPINGS) > -1 && currentUser.hasGroupings) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_MANAGEMENT: {
            if (currentUser.permissions.findIndex((X) => X === READ_MANAGEMENT) > -1) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_PROFILE: {
            if (currentUser.permissions.findIndex((X) => X === READ_PROFILE) > -1) {
              routes.push(value);
            }
            break;
          }
        }
      });
      return routes;
    }
  };

  const getIcon = (value: string): string => {
    let result: string = InspirationIcon;

    switch (value) {
      case ROUTE_INSPIRATION: {
        result = InspirationIcon;
        break;
      }
      case ROUTE_PROJECTS: {
        result = ProjectsIcon;
        break;
      }
      case ROUTE_ORDERS: {
        result = OrdersIcon;
        break;
      }
      case ROUTE_USER_GROUPINGS: {
        result = GroupsIcon;
        break;
      }
      case ROUTE_MANAGEMENT: {
        result = SettingsIcon;
        break;
      }
      case ROUTE_PROFILE: {
        result = UserIcon;
        break;
      }
      case ROUTE_LANDING: {
        result = HomeIcon;
        break;
      }
      case ROUTE_LOGIN: {
        result = LoginIcon;
        break;
      }
    }

    if (value.includes(ROUTE_PROJECTS)) {
      result = ProjectsIcon;
    }
    if (value.includes(ROUTE_ORDERS)) {
      result = OrdersIcon;
    }

    return result;
  };

  useEffect(() => {
    let res = getRoutesWithRights().find((x) =>
      window.location.pathname.includes(x.name.includes('project') ? 'project' : x.name)
    );
    if (res) {
      setActive(res.name);
    } else if (window.location.pathname === ROUTE_LANDING) {
      res = getRoutesWithRights().find((x) => x.link === ROUTE_LANDING);
      if (res) {
        setActive(res.name);
      }
    }
  }, [window.location.pathname]);

  return !window.location.pathname.includes('staticre') ? (
    <Box className="menu-bottom-container">
      <ul className={`menu-bottom-container-list-${getRoutesWithRights().length}`}>
        {getRoutesWithRights().map((tab, i) => (
          <li key={`tab-${i}`} className={active === tab.name ? 'list active' : 'list'}>
            <Link
              href={tab.link}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, tab.link)}
            >
              <span className="icon">
                <img src={getIcon(tab.link)} alt={tab.name} />
              </span>
              <span className="text">
                <FormattedMessage
                  id={tab.name === 'inspiration' && getRoutesWithRights().length === 2 ? 'home' : tab.name}
                />
              </span>
            </Link>
          </li>
        ))}
        <Box className="indicator"></Box>
      </ul>
    </Box>
  ) : null;
};

export { MenuBottom };
