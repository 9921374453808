import './groupin-file-row.scss';
import { FC, useState } from 'react'
import { UserGroupingFile } from 'src/models';
import { HmyTableRow, StringMultiple } from 'src/components';
import { Box, Dialog } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import imageIcon from '../../../../../assets/images/imageIcon.png';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { adjustString } from 'src/utilities';
import { FormattedDate } from 'react-intl';
import infoIcon from '../../../../../assets/images/info.png';
import CloseIcon from '@mui/icons-material/Close';

type GroupinFileRowProps = {
  file: UserGroupingFile;
  isFirst: boolean;
  isLast: boolean;
  canEdit: boolean;
  setSelectedFile: (fileId: string) => void;
  setEditFile: (fileId: string) => void;
};

const GroupinFileRow: FC<GroupinFileRowProps> = ({ isFirst, isLast, file, canEdit, setSelectedFile, setEditFile}) => {

    const [showDescription, setShowDescription] = useState<boolean>(false);

    const handleClose = () => {
      setShowDescription(false);
    }

    return (
      <HmyTableRow
        isFirst={isFirst}
        isLast={isLast}
      >
        <Box
          className="grouping-file-table-row-element"
          style={{width: "14.5%", justifyContent: "center"}}
        >
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={file.date} />
        </Box>  
        <Box
          className="grouping-file-table-row-element"
          style={{width: canEdit ? "25%" : "37%", justifyContent: "center", cursor: file.description !== "" ? "pointer" : "auto"}}
          onClick={() => setShowDescription(file.description !== "")}
        >
          {adjustString( file.name, 25)}
        </Box>
        <Box
          className="grouping-file-table-row-element"
          style={{width: "15.5%", justifyContent: "center"}}
        >
          {file.kind.name} 
        </Box>
        <Box
          className="grouping-file-table-row-element"
          style={{width: canEdit ? "13.2%" : "14%", justifyContent: "center"}}
        >
          {file.category.name}
        </Box>
        <Box
          className="grouping-file-table-row-element"
          style={{width: "12.3%", justifyContent: "center"}}
        >
          <StringMultiple 
            elements={file.tags.map(x => x.name)}
            cutString={10}
          />     
        </Box>
        <Box
          className="grouping-file-table-row-element"
          style={{width: canEdit ? "11%" : "9.2%", justifyContent: "center", cursor: "pointer"}}
          onClick={() => setSelectedFile(file.id)}
        >
            {file.extension.toLowerCase().includes('pdf')
              ?
                <Box
                  className="grouping-file-document-icon"
                >
                  <TextSnippetIcon fontSize="small" />
                </Box>
              :
                <img src={imageIcon} style={{width: "40px", height: "40px"}} />
            }
        </Box> 
      {canEdit
          ?
          <Box
              className="grouping-file-table-row-element"
              style={{width: "9%", justifyContent: "center"}}
              onClick={() => setEditFile(file.id)}
          >
            <Box
              className="grouping-file-edit-icon"
            >
              <DriveFileRenameOutlineIcon />
            </Box>              
          </Box>
          :
          null
      }
        <Dialog open={showDescription} onClose={() => handleClose()} sx={{borderRadius: "25px !important"}}>
          <Box
            className="grouping-file-content-modal"
          >
            <Box
              className="grouping-file-content-modal-close"
            >
              <CloseIcon 
                fontSize="inherit" 
                onClick={() => handleClose()}
                style={{cursor: "pointer"}} 
              />
            </Box>
            <img src={infoIcon} alt="icon-inbox" />
            <span>{file.description}</span>
          </Box>
        </Dialog>
      </HmyTableRow>
    )
}

export default GroupinFileRow