import './settings.scss';
import { FC, useEffect, useState } from 'react';
import { CompleteProject, ErrorMessage, ProjectProfile, SuccessMessage, UserProject, VStoreStatus } from 'src/models';
import { Box, Switch, TextField } from '@mui/material';
import { ADD_PROJECTHMYUSER, ADD_PROJECTUSER, EDIT_PROJECTNAME, EDIT_PROJECTVSTORE } from 'src/permissions/permissions';
import ProjectSettingsUsers from './project-settings-users';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { ProjectProfileModelService, projectServices } from 'src/services';
import { addProjectProfileAdapter } from 'src/adapters';
import { HmyFormSection, HmySingleForm, LoadComponent } from 'src/components';
import { SetVstoreEnabledDto } from 'src/dtos';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { useIntl } from 'react-intl';

type SettingsProps = {
  project: CompleteProject;
  reloadProject: () => void;
};

const Settings: FC<SettingsProps> = ({project, reloadProject}) => {
  
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();

  const [openUsersForm, setOpenUsersForm] = useState<boolean>(false);
  const [openHmyUsersForm, setOpenHmyUsersForm] = useState<boolean>(false);
  const [projectProfiles, setProjectProfiles] = useState<ProjectProfile[]>([]);
  const [usersHmy, setUsersHmy] = useState<UserProject[]>([]);
  const [usersCustomer, setUsersCustomer] = useState<UserProject[]>([]);
  const [descriptionValue, setDescriptionValue]= useState<string>("");
  const [vstoreValue, setVstoreValue]= useState<boolean>(false);

  const getProfiles = async () => await callEndpoint(projectServices.getProjectProfiles());
  const setVstoreEnabled = async (request: SetVstoreEnabledDto) => await callEndpoint(projectServices.setVstoreEnabled(request));
  const updateProjectName = async () => await callEndpoint(projectServices.updateProjectName(project.projectId, descriptionValue));

  const adaptProfiles = (data: ProjectProfileModelService[]) => {

    let newProfiles = data.map(x => addProjectProfileAdapter(x));
    
    setProjectProfiles(newProfiles);

  };

  const handleCloseDescriptionForm = () => {
    let description = project.projectName ?? project.description;
    setDescriptionValue(description);
  }

  const handleCloseVStoreForm = () => {
    setVstoreValue(project.vStore.vStoreStatus === VStoreStatus.Enabled);
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
  };

  const handleChangeVStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVstoreValue(event.target.checked);
  };

  const handleSaveDescriptionForm = async () => {

    try {
      await updateProjectName();
      dispatch(setMessage(SuccessMessage("success.projectNameUpdated", true)));
      reloadProject();
      handleCloseDescriptionForm();
    } 
    catch (error) {
      dispatch(setMessage(ErrorMessage("error.updateProjectName", true)));
    }
  }

  const handleSaveVstoreForm = async () => {

    let request: SetVstoreEnabledDto = {
      projectId: project.projectId,
      enabled: vstoreValue
    };

    try {
      await setVstoreEnabled(request);
      dispatch(setMessage(SuccessMessage("success.projectUpdated", true)));
      reloadProject();
      handleCloseVStoreForm();
    } 
    catch (error) {
      dispatch(setMessage(ErrorMessage("error.updatingProject", true)));
    }
  }

  const getVstoreText = () : string => {
    switch(project.vStore.vStoreStatus){
      case VStoreStatus.Disabled:
        return "disabled";
      case VStoreStatus.Enabled:
        return "activated";
      case VStoreStatus.Unavailable:
        return "not3dModels";
      default:
        return "vstoreNotAvailable";
    }
  }

  useEffect(() => {
    setUsersHmy(project.users.filter(x => x.profileName !== "Customer"));
    setUsersCustomer(project.users.filter(x => x.profileName === "Customer"));
    let description = project.projectName ?? project.description;
    setDescriptionValue(description);
    setVstoreValue(project.vStore.vStoreStatus === VStoreStatus.Enabled);
  },[]);

  useAsync(getProfiles, adaptProfiles, () => { }, () => { }, []);

  return (
    <Box
      className="project-Settings-container"
    >
      <Box
        className="project-Settings-content"
      >
        <HmyFormSection>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={true}
              showTitle={true}
              title="projectName"
              textValues={[project.projectName ?? project.description]}
              canEdit={project.permissions.findIndex(x => x === EDIT_PROJECTNAME) > -1}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={handleSaveDescriptionForm}
              onClickCancelForm={handleCloseDescriptionForm}
              onDeleteForm={() => console.log()}
          >
            <TextField 
                label="" 
                variant="standard" 
                value={descriptionValue}
                onChange={handleChangeDescription}
                fullWidth
                inputProps={{style: {fontSize: 18}}}
            />
          </HmySingleForm>
        </HmyFormSection>
        <HmyFormSection>
          <HmySingleForm
              isMultiline={false}
              isFullWidth={true}
              showTitle={true}
              title="virtualStore"
              textValues={[intl.formatMessage({id: getVstoreText()})]}
              canEdit={project.permissions.findIndex(x => x === EDIT_PROJECTVSTORE) > -1 && 
                (project.vStore.vStoreStatus === VStoreStatus.Disabled || project.vStore.vStoreStatus === VStoreStatus.Enabled)}
              canDelete={false}
              hasErrors={false}
              onClickSubmitForm={handleSaveVstoreForm}
              onClickCancelForm={handleCloseVStoreForm}
              onDeleteForm={() => console.log()}
          >
            <Switch
              checked={vstoreValue}
              onChange={handleChangeVStore}
              color="secondary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </HmySingleForm>
        </HmyFormSection>

        {loading
        ?
          <LoadComponent />
        :
          <Box
            className="project-Settings-users-container"
          >
            <ProjectSettingsUsers
              projectId={project.projectId}
              title="hmyAccounts"
              canEdit={project.permissions.findIndex(x => x === ADD_PROJECTHMYUSER) > -1}
              openForm={openHmyUsersForm}
              projectProfiles={projectProfiles.filter(x => x.name !== "Customer")}
              users={usersHmy}
              isHmy={true}
              setOpenForm={setOpenHmyUsersForm}
              reloadProject={reloadProject}
            />
            <ProjectSettingsUsers
              projectId={project.projectId}
              title="customerAccounts"
              canEdit={project.permissions.findIndex(x => x === ADD_PROJECTUSER) > -1}
              openForm={openUsersForm}
              projectProfiles={projectProfiles.filter(x => x.name === "Customer")}
              users={usersCustomer}
              isHmy={false}
              setOpenForm={setOpenUsersForm}
              reloadProject={reloadProject}
            />
          </Box>
        }
      </Box>
    </Box>
  )
}

export default Settings;