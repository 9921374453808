import { createTheme } from "@mui/material";
import RoobertRegularWoff from './assets/styles/fonts/Roobert-Regular.woff';
import RoobertRegularWoff2 from './assets/styles/fonts/Roobert-Regular.woff2';
import RoobertRegularTtf from './assets/styles/fonts/Roobert-Regular.ttf';

export const theme = createTheme({
  palette:{
    primary:{
      main: "#04225F",
      light: "#384CFF",
      contrastText: "#fff"
    },
    secondary:{
      main: '#384CFF',
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: 'Roobert',
    fontSize: 18
  },
  components:{

    MuiCssBaseline:{

      styleOverrides:`

      @font-face {

        font-family: 'Roobert';

        src: url(${RoobertRegularWoff}) format('woff2'),

             url(${RoobertRegularWoff2}) format('woff'),

             url(${RoobertRegularTtf}) format('truetype');

      }

      `

    }
  }
});