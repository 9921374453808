import { Box, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { blobDocumentAdapter, documentPropsAdapter, modalDocumentAdapter } from 'src/adapters';
import { CreateInboxBlobDto, CreateLargeInboxBlobDto, GetBlobsInboxDto, UpdateBlobDto } from 'src/dtos';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import {
  BlobDocument,
  CompleteProject,
  DocumentProps,
  ErrorMessage,
  HmyModalDocumentBase,
  SuccessMessage,
} from 'src/models';
import { ADD_PROJECTINBOXFILE, EDIT_PROJECTINBOXFILE, READ_PROJECTINBOX } from 'src/permissions/permissions';
import { setMessage } from 'src/redux/states';
import { CHUNK_SIZE, DocumentModelService, DocumentPropsModelService, blobServices } from 'src/services';
import { getBase64 } from 'src/utilities/get-base64';
import { HmyImageViewer, HmyModalFileViewer, HmyPdfViewer, LoadComponent } from '../../../components';
import InboxFileAdd, { InboxFileAddObject } from './inbox-file-add';
import { InboxListDocuments } from './inbox-list-documents';
import './inbox.scss';

type InboxProps = {
  project: CompleteProject;
};

const Inbox: FC<InboxProps> = ({ project }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 768px)');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [documents, setDocuments] = useState<BlobDocument[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<HmyModalDocumentBase | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
  const [documentOptions, setDocumentOptions] = useState<GetBlobsInboxDto>({
    projectId: project.projectId,
    seeHidden: true,
    filter: '',
    documentType: '',
    extension: '',
    date: null,
  });

  const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));
  const getDocuments = async () => await callEndpoint(blobServices.getBlobsInbox(documentOptions));
  const createInboxBlob = async (request: CreateInboxBlobDto[]) =>
    await callEndpoint(blobServices.createInboxBlob(request));
  const createInboxLargeBlob = async (request: CreateLargeInboxBlobDto) =>
    await callEndpoint(blobServices.createInboxLargeBlob(request));
  const getDocumentProps = async () => await callEndpoint(blobServices.getDocumentProps());
  const uploadChunks = async (request: FormData) => await callEndpoint(blobServices.uploadChunks(request));
  const updateBlob = async (request: UpdateBlobDto) => await callEndpoint(blobServices.updateBlob(request));
  const deleteBlob = async (documentId: string) => await callEndpoint(blobServices.deleteBlob(documentId));
  const doVisible = async (fileId: string) => await callEndpoint(blobServices.doVisible(fileId));

  const adaptDocuments = (data: DocumentModelService[]) => {
    let newDocuments = data.map((x) => blobDocumentAdapter(x));

    setDocuments(newDocuments);
  };

  const adaptDocumentProps = (data: DocumentPropsModelService) => {
    let docProps = documentPropsAdapter(data);

    setDocumentProperties(docProps);
  };

  // Helper function to process each file and create a DTO
  const processFile = async (file: InboxFileAddObject, projectId: string): Promise<CreateInboxBlobDto> => {
    const base64 = await getBase64(file.file!); // Get base64 asynchronously
    return {
      ...file,
      data: (base64 as string).split(',')[1],
      projectId,
    };
  };

  // Helper function to upload chunks of a large file
  const handleFileChunkUpload = async (file: CreateInboxBlobDto) => {
    let beginingOfTheChunk = 0;
    let endOfTheChunk = CHUNK_SIZE;

    for (let counter = 1; counter <= file.totalCount; counter++) {
      let chunk = file!.file.slice(beginingOfTheChunk, endOfTheChunk);
      const formData = new FormData();
      formData.append('id', counter.toString());
      formData.append('fileName', file.name);
      formData.append('formFile', chunk);

      try {
        await uploadChunks(formData);
        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk += CHUNK_SIZE;

        if (counter === file.totalCount) {
          const request: CreateLargeInboxBlobDto = {
            name: file.name,
            validName: file.name,
            description: file.description,
            projectId: file.projectId,
            date: file.date,
            documentType: file.documentType,
          };
          await createInboxLargeBlob(request);
        }
      } catch (error) {
        dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
      }
    }
  };

  // Helper function to process big files
  const processBigFiles = async (bigFiles: CreateInboxBlobDto[]) => {
    for (const file of bigFiles) {
      await handleFileChunkUpload(file);
    }
  };

  // Helper function for final successful upload handling
  const handleFinalUpload = async () => {
    dispatch(setMessage(SuccessMessage('success.fileUploadedSuccessfully', true)));
    try {
      const newDocs = await getDocuments();
      adaptDocuments(newDocs.data);
    } catch (error) {
      // Handle secondary error
    }
  };

  const createNewInboxBlob = async (files: InboxFileAddObject[]): Promise<boolean> => {
    const projectId = project.projectId;

    try {
      // Process files asynchronously and create DTOs
      const definitiveRequest: CreateInboxBlobDto[] = await Promise.all(
        files.map((file) => processFile(file, projectId))
      );

      // Filter normal and big files
      const normalFiles = definitiveRequest.filter((file) => !file.isBigFile);
      const bigFiles = definitiveRequest.filter((file) => file.isBigFile);

      // Upload normal files if any
      if (normalFiles.length > 0) {
        await createInboxBlob(normalFiles);
      }

      // Process and upload big files if any
      if (bigFiles.length > 0) {
        await processBigFiles(bigFiles);
      }

      // Handle final successful upload
      await handleFinalUpload();
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
      return false;
    }
  };

  /**
   * Actualiza un documento del inbox
   */
  const handleUpdateDocument = async (request: UpdateBlobDto): Promise<boolean> => {
    try {
      await updateBlob(request);
      dispatch(setMessage(SuccessMessage('success.fileUpdatedSuccessfully', true)));
      let docs = [...documents];
      if (request.date !== null) {
        docs.find((x) => x.blobId === request.blobId)!.date = new Date(request.date);
        docs.find((x) => x.blobId === request.blobId)!.simpleDate = new Date(request.date);
      }
      if (request.description !== null) {
        docs.find((x) => x.blobId === request.blobId)!.description = request.description;
      }
      if (request.documentType !== null) {
        docs.find((x) => x.blobId === request.blobId)!.documentType = request.documentType;
      }

      setDocuments(docs);
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.updatingFile', true)));
      return false;
    }
  };

  /**
   *
   * @param documentId Borra un documento del inbox
   */
  const handleDeleteDocument = async (documentId: string) => {
    try {
      await deleteBlob(documentId);
      dispatch(setMessage(SuccessMessage('success.fileDeletedSuccessfully', true)));
      try {
        const newDocs = await getDocuments();
        adaptDocuments(newDocs.data);
      } catch (error2) {}
      return true;
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.errorDeletingFile', true)));
      return false;
    }
  };

  const handleSelectedDocumentToSee = async (documentId: string) => {
    try {
      const response = await getDocument(documentId);
      if (response.data.isImage || response.data.extension === 'pdf') {
        setSelectedDocument(modalDocumentAdapter(response.data));
        setOpenDialog(true);
      } else {
        window.open(response.data.url);
      }
    } catch (error) {}
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
    setSelectedDocument(null);
  };

  const handleModifyVisible = (blobId: string) => {
    let docs = [...documents];
    const index = docs.findIndex((x) => x.blobId === blobId);
    docs[index].visible = !docs[index].visible;
    setDocuments(docs);
  };

  const hanldeClickVisible = async (fileId: string) => {
    try {
      await doVisible(fileId);
      handleModifyVisible(fileId);
      if (selectedDocument !== null) {
        setSelectedDocument({
          ...selectedDocument,
          isVisible: !selectedDocument.isVisible,
        });
      }
    } catch (error) {}
  };

  useAsync(
    getDocuments,
    adaptDocuments,
    () => {},
    () => {},
    [documentOptions]
  );

  useAsync(
    getDocumentProps,
    adaptDocumentProps,
    () => {},
    () => {},
    []
  );

  return (
    <Box className="inbox-container">
      {project.permissions.findIndex((x) => x === ADD_PROJECTINBOXFILE) > -1 && documentProperties && (
        <InboxFileAdd documentOptions={documentProperties} createNewInboxBlob={createNewInboxBlob} />
      )}
      {project.permissions.findIndex((x) => x === READ_PROJECTINBOX) > -1 &&
        documentProperties &&
        (loading ? (
          <LoadComponent />
        ) : (
          <InboxListDocuments
            documents={documents}
            documentOptions={documentOptions}
            documentProperties={documentProperties}
            searchText={searchText}
            canEditDocuments={project.permissions.findIndex((x) => x === EDIT_PROJECTINBOXFILE) > -1}
            setSearchText={setSearchText}
            setDocumentOptions={setDocumentOptions}
            updateDocument={handleUpdateDocument}
            handleDeleteBlob={handleDeleteDocument}
            handleSelectDocument={handleSelectedDocumentToSee}
          />
        ))}
      {selectedDocument !== null ? (
        <HmyModalFileViewer
          isOpen={openDialog}
          canEdit={project.permissions.findIndex((x) => x === EDIT_PROJECTINBOXFILE) > -1}
          file={selectedDocument}
          handleClose={handleCloseModal}
          hanldeClickVisible={hanldeClickVisible}
        >
          {selectedDocument.isImage ? (
            <HmyImageViewer document={selectedDocument} />
          ) : (
            <HmyPdfViewer
              base64String={selectedDocument.url}
              pdfName={selectedDocument.description ?? selectedDocument.name}
              zoomManagement={true}
              rotationManagement={!matches}
              closeManagement={false}
              navBarHeight={50}
              handleClose={handleCloseModal}
            />
          )}
        </HmyModalFileViewer>
      ) : null}
    </Box>
  );
};

export default Inbox;
