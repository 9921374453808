import './section-france-matterport.scss'
import { FC } from 'react'
import { Box, Typography } from '@mui/material';
import imageShowroom from '../../../assets/images/Showroom_Moneteau.png';
import { FormattedMessage } from 'react-intl';
import { HmyBtn } from 'src/components';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

type SectionFranceMatterportProps = {
    handleOpenMatterport: (source: string) => void;
  }

const SectionFranceMatterport: FC<SectionFranceMatterportProps> = ({ handleOpenMatterport }) => {

    const { trackEvent } = useMatomo();
    
    const handleClick = () => {
        handleOpenMatterport("https://shoootin.com/scan/62e40a13bb956e14b24091d6");
        trackEvent({ category: 'onClick', action: 'matterport access', name: 'France matterport access' });
    }

    return (
        <Box className="section-france-matterport" id="sectionFranceMatterport">
            <Box
                className="matterport-france-container-image"
            >
                <img src={imageShowroom} alt="image-Showroom" />
            </Box>
            <Box
                className="matterport-france-container-text"
            >
                <Box
                    className="matterport-france-text"
                >
                    <Typography sx={{ fontSize: { md: '20px', xs: '18px' }, paddingLeft: {md: '0px', xs: '20px'}, paddingRight: {md: '0px', xs: '10px'}}} align="left" >
                        <FormattedMessage id="matterportFranceText" />
                    </Typography>
                    <Box
                        className="btn-france-matterport-access"
                    >
                        <HmyBtn 
                            title="accessVirtualTour"
                            width={300}
                            fullwith={false}
                            handleClick={handleClick}
                            color="secondary"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SectionFranceMatterport;