import {http} from '../http-client';
import { EncodeGetURI } from 'src/utilities';
import { GeneralCollection } from 'src/models';
import { CreateUserDto, GetSelectableCustomersDto, GetSelectableUsersDto, GetUsersDto, UpdateCustomersDto, UpdateInspirationZoneDto, UpdateBasicsDto, UpdateRoleDto, UpdateSharedCustomersDto, UpdateSupervisedDto, UpdateSupervisorDto, UpdateCommercialDto } from 'src/dtos';
import { CompleteUserModelService, UserModelService } from './model-service';
import { TinyCustomerModelService } from '../customer';

const getUsers = (request: GetUsersDto) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<UserModelService>>(EncodeGetURI("user", request), { signal: controller.signal }), controller };
}

const getUser = (userId: string) => {
    const controller = new AbortController();

    return { call: http.get<CompleteUserModelService>(`user/${userId}`, { signal: controller.signal }), controller };
}

const getSelectableUsers = (request: GetSelectableUsersDto) => {
    const controller = new AbortController();

    return { call: http.post<GetSelectableUsersDto>("user/selectableUsers", request, { signal: controller.signal }), controller };
}

const deleteUser = (userId: string) => {
    const controller = new AbortController();

    return { call: http.delete(`user/${userId}`, { signal: controller.signal }), controller };
}

const getListRoles = () => {
    const controller = new AbortController();

    return { call: http.get<string[]>("user/roles", { signal: controller.signal }), controller };
}

const getSelectableCustomers = (request: GetSelectableCustomersDto) => {
    const controller = new AbortController();

    return { call: http.get<TinyCustomerModelService[]>(EncodeGetURI("user/selectablecustomers", request), { signal: controller.signal }), controller };
}

const createUser = (request: CreateUserDto) => {
    const controller = new AbortController();

    return { call: http.post<CreateUserDto>("user", request, { signal: controller.signal }), controller };
}

const updateBasics = (request: UpdateBasicsDto) => {
    const controller = new AbortController();

    return { call: http.put("user", request, { signal: controller.signal }), controller };
}

const updateRole = (request: UpdateRoleDto) => {
    const controller = new AbortController();

    return { call: http.put("user/role", request, { signal: controller.signal }), controller };
}

const updateSupervisor = (request: UpdateSupervisorDto) => {
    const controller = new AbortController();

    return { call: http.put("user/supervisor", request, { signal: controller.signal }), controller };
}

const updateCommercial = (request: UpdateCommercialDto) => {
    const controller = new AbortController();

    return { call: http.put("user/commercial", request, { signal: controller.signal }), controller };
}

const updateSupervised = (request: UpdateSupervisedDto) => {
    const controller = new AbortController();

    return { call: http.put("user/supervised", request, { signal: controller.signal }), controller };
}

const updateInspirationZone = (request: UpdateInspirationZoneDto) => {
    const controller = new AbortController();

    return { call: http.put("user/inspirationzone", request, { signal: controller.signal }), controller };
}

const updateSharedCustomers = (request: UpdateSharedCustomersDto) => {
    const controller = new AbortController();

    return { call: http.put("user/sharedcustomers", request, { signal: controller.signal }), controller };
}

const updateCustomers = (request: UpdateCustomersDto) => {
    const controller = new AbortController();

    return { call: http.put("user/customers", request, { signal: controller.signal }), controller };
}

export const userServices = {
    getUsers,
    getUser,
    getSelectableUsers,
    deleteUser,
    getListRoles,
    getSelectableCustomers,
    createUser,
    updateRole,
    updateSupervisor,
    updateCommercial,
    updateSupervised,
    updateBasics,
    updateInspirationZone,
    updateSharedCustomers,
    updateCustomers
}