import { BasicShoppingOrder } from './catalogue';

export const ACCESS_TOKEN = 'MiHmy_accessToken';
export const REFRESH_TOKEN = 'MiHmy_refreshToken';
export const LANGUAGE = 'language';
export const ACCEPTED_COOKIES = 'MiHmy_Accepted_cookies';
export const ACCEPTED_MAT = 'MiHmy_Accepted_mat';
export const PENDING_ROUTE = 'MiHmy_Pending_Route';
export const EXPIRED_SESSION = 'MiHmy_Expired_Session';
export const SHOPPING_LIST = 'MiHmy_Shopping_list';
export const LANGUAGES: string[] = ['es', 'en', 'fr', 'pt', 'tr'];

/**
 * Nos devuelve el token JWT para llamar al backend
 * @returns token o null
 */
const getMiHmyAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN);

/**
 * Almacenamos el token JWT que identificará al usuario en el backend
 * @param {string} value
 * @returns nothing
 */
const setMiHmyAccessToken = (value: string): void => localStorage.setItem(ACCESS_TOKEN, value);

/**Elimina el accessToken del localstorage */
const clearMiHmyAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);

/**
 * Nos devuelve el refresh token JWT solicitar de nuevo el accessToken
 * @returns token o null
 */
const getMiHmyRefreshToken = (): string | null => localStorage.getItem(REFRESH_TOKEN);

/**
 * Almacenamos el el refresh token JWT
 * @param {string} value
 * @returns nothing
 */
const setMiHmyRefreshToken = (value: string): void => localStorage.setItem(REFRESH_TOKEN, value);

/**Nos devuelve el idioma de la aplicación */
const getLanguage = () => {
  if (!localStorage.getItem(LANGUAGE) || localStorage.getItem(LANGUAGE) === 'null') {
    setLanguage(getBrowserLang());
  }

  return localStorage.getItem(LANGUAGE);
};

/**Obtiene el idioma por defecto del navegador*/
const getBrowserLang = () => {
  if (navigator.languages !== undefined) {
    if (navigator.languages.length === 2) {
      return navigator.languages[1];
    } else {
      return navigator.languages[0].split('-')[0];
    }
  } else {
    return 'es';
  }
};

/**
 * Almacenamos el idioma de la PDA
 * @param {string} value
 * @returns lenguaje selecionado
 */
const setLanguage = (value: string): void => localStorage.setItem(LANGUAGE, value);

/**Nos devuelve si el ususario a seleccionado las cookies */
const getAcceptCookies = () => {
  return localStorage.getItem(ACCEPTED_COOKIES) === 'true';
};

/**Almacena que el usuario a aceptado las cookies */
const setAcceptCookies = (value: boolean) => {
  localStorage.setItem(ACCEPTED_COOKIES, String(value));
};

const existAcceptMatomo = (): boolean => {
  return localStorage.getItem(ACCEPTED_MAT) !== null;
};

/**Almacena si el ususario ha permitido el uso de matomo */
const setAcceptMatomo = (value: boolean) => {
  localStorage.setItem(ACCEPTED_MAT, String(value));
};

/**Nos devuelve si el ususario ha permitido el uso de matomo */
const getAcceptMatomo = (): boolean => {
  return localStorage.getItem(ACCEPTED_MAT) === 'true';
};

const getPendingRoute = (): string | null => sessionStorage.getItem(PENDING_ROUTE);

const setPendingRoute = (value: string): void => sessionStorage.setItem(PENDING_ROUTE, value);

const setExpiredSession = () => localStorage.setItem(EXPIRED_SESSION, 'true');

const getShoppingList = (): BasicShoppingOrder => {
  const shoppingList = sessionStorage.getItem(SHOPPING_LIST);
  return shoppingList ? JSON.parse(shoppingList) : { projectId: '', phoneNumber: '', lines: [] };
};
const setShoppingList = (shoppingList: BasicShoppingOrder) =>
  sessionStorage.setItem(SHOPPING_LIST, JSON.stringify(shoppingList));

const closeSession = () => {
  localStorage.removeItem(EXPIRED_SESSION);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.clear();
};

/**Servicio dedicado a gestionar el localStorage del navegador*/
export const storageServices = {
  getMiHmyAccessToken,
  setMiHmyAccessToken,
  clearMiHmyAccessToken,
  getMiHmyRefreshToken,
  setMiHmyRefreshToken,
  getLanguage,
  setLanguage,
  getAcceptCookies,
  setAcceptCookies,
  existAcceptMatomo,
  setAcceptMatomo,
  getAcceptMatomo,
  getPendingRoute,
  setPendingRoute,
  setExpiredSession,
  getShoppingList,
  setShoppingList,
  closeSession,
};
