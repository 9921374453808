import './hmy-link-btn.scss';
import { FC } from 'react';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl'

type HmyLinkBtnProps = {
    title: string; //Esto tiene que ser el id de la traducción
    width: number;
    handleClick: () => void;
    color: "primary" | "secondary";
  };

const HmyLinkBtn: FC<HmyLinkBtnProps> = ({title, width, handleClick, color}) => {
  return ( 
    <Box
      onClick={() => handleClick()}
      className={color === "primary" ? "hmy-link-btn" : "hmy-link-btn-secondary"}
      style={{ width: width > 0 ? `${width}px` : "auto"}}
    >
        <FormattedMessage id={title}/>
    </Box> 
  )
}

export { HmyLinkBtn };