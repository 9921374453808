import "./message-component.scss"
import { FC } from "react";
import { useDispatch,useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { messageSelector, setOpen } from "src/redux/states";
import { Snackbar, Alert } from "@mui/material"

type MessageComponentProps = {
  duration: number,
  horizontal: "left" | "center" | "right",
  vertical: "top" | "bottom"
}

export const MessageComponent: FC<MessageComponentProps> = ({ duration, horizontal, vertical }) => {

  const dispatch = useDispatch();
  const { message, severity, isOpen } = useSelector(messageSelector);

  const handleClose = () => {
    dispatch(setOpen(false))
  }

  return (
    <div>
      <Snackbar
        autoHideDuration={duration}
        anchorOrigin={{ horizontal, vertical }}
        open={isOpen}
        onClose={handleClose}
      >
        <Alert
          className="message-snackbar"
          severity={severity}
          variant="filled"
        >
          {message?.includes("error.") || message?.includes("success.")
          ?
            <FormattedMessage id={message}/>
          :
            message
          } 
        </Alert>
      </Snackbar>
    </div>
  )
}