import './hmy-input-password.scss';
import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FormattedMessage } from 'react-intl';

type errorPassword = {
    errorNameOrEmail: boolean;
    minLength: boolean;
    anyNumber: boolean;
}

type ValidatorPasswordProps = {
    password: string;
    userName: string;
    email: string;
    setIsValid: (value: boolean) => void;
};

const ValidatorPassword: FC<ValidatorPasswordProps> = ({ password, userName, email, setIsValid }) => {

    const [errors, setErrors] = useState<errorPassword>({
        errorNameOrEmail: true,
        minLength: true,
        anyNumber: true
    });

    const regex = RegExp('.*[^a-zA-Z]+.*');

    useEffect(() => {
        setErrors({
            errorNameOrEmail: password.toLowerCase().includes(userName.toLowerCase()) || password.toLowerCase().includes(email.toLowerCase()),
            minLength: password.length < 8,
            anyNumber: !regex.test(password)
        });
    },[password]);

    useEffect(() => {
        setIsValid(!errors.errorNameOrEmail && !errors.minLength && !errors.anyNumber);
    },[errors]);

    return (
        <Box
            className="validator-password-container"
        >
            <Typography
                fontSize="inherit"
                className={(errors.errorNameOrEmail || errors.minLength || errors.anyNumber) ? 
                    "validator-password-text-error" : 
                    (password.length < 12 ? "validator-password-text-safe" : "validator-password-text")}
            >
                <Box className="validator-password-icon-container">
                    {errors.errorNameOrEmail || errors.minLength || errors.anyNumber
                        ?
                            
                            <CloseIcon color="inherit" fontSize="inherit" />
                        
                        :
                            <TaskAltIcon color="inherit" fontSize="inherit" />                  
                    }      
                </Box>          
                <span
                    className="validator-password-text-black"
                >
                    <FormattedMessage id="passwordSecurity" />
                </span>
                <FormattedMessage 
                    id={errors.errorNameOrEmail || errors.minLength || errors.anyNumber ? "unsafe" : (password.length < 12 ? "safe" : "verySecure")}                            
                />
            </Typography>
            <Typography
                fontSize="inherit"
                className={errors.errorNameOrEmail ? "validator-password-text-error" : "validator-password-text"}
            >
                <Box className="validator-password-icon-container">
                    {errors.errorNameOrEmail
                        ?
                            
                            <CloseIcon color="inherit" fontSize="inherit" />
                        
                        :
                            <TaskAltIcon color="inherit" fontSize="inherit" />                  
                    }      
                </Box>  
                <FormattedMessage id="passwordNotNameOrEmail" />
            </Typography>
            <Typography
                fontSize="inherit"
                className={errors.minLength ? "validator-password-text-error" : "validator-password-text"}
            >
                <Box className="validator-password-icon-container">
                    {errors.minLength
                        ?
                            
                            <CloseIcon color="inherit" fontSize="inherit" />
                        
                        :
                            <TaskAltIcon color="inherit" fontSize="inherit" />                  
                    }      
                </Box>
                <FormattedMessage id="least8Characters" />
            </Typography>
            <Typography
                fontSize="inherit"
                className={errors.anyNumber ? "validator-password-text-error" : "validator-password-text"}
            >
                <Box className="validator-password-icon-container">
                    {errors.anyNumber
                        ?
                            
                            <CloseIcon color="inherit" fontSize="inherit" />
                        
                        :
                            <TaskAltIcon color="inherit" fontSize="inherit" />                  
                    }      
                </Box>
                <FormattedMessage id="containsNumberOrSymbol" />
            </Typography>
        </Box>
    )
}

export default ValidatorPassword;